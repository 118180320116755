import { Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { AddressComponentClass } from 'lib/components/address/address-component.class';
import { AddressValidator } from 'lib/validators/address.validator';
import { contains, isBrowser, wait } from 'lib/tools';
import { get, isEmpty } from 'lodash';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { AccountService } from 'lib/services/account/account.service';
import { GrecaptchaService } from 'lib/services/google/grecaptcha.service';
import { MessageService } from 'lib/services/message.service';
import { SessionService } from 'lib/services/session.service';

const scriptName = 'address.component';

const fields = {
    all: [
        'addressee',
        'address1',
        'address2',
        'city',
        'state',
        'zip',
        'country',
        'phone',
        'email',
        'isDefaultBilling',
        'isDefaultShipping',
        'isConfirmed',
    ],
    default: ['addressee', 'address1', 'address2', 'city', 'state', 'zip', 'country', 'phone', 'isConfirmed'],
};

@Component({
    selector: 'lib-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss'],
})
export class AddressComponent extends AddressComponentClass implements OnChanges, OnInit {
    // Inputs: data

    @Input() address: any; // Required.
    @Input() componentId: string; // Required. A descriptive name that is different from other address components.
    @Input() disableEditButton: boolean; // Optional.
    @Input() fields: string[] = fields.default; // Optional. A list of fields; values given here are default.
    @Input() groupAddressLines: boolean = false; // Optional.
    @Input() groupStateAndZip: boolean = false; // Optional.
    @Input() mode: string; // Required. create, update, readOnly
    @Input() showLabels: boolean = false; // Optional.

    // Inputs: class and text

    @Input() cancelButtonClass: string; // Required if showCancelButton is true.
    @Input() checkboxClass: string; // Required if default address field are included in the fields array.
    @Input() formClass: string; // Required.
    @Input() editButtonClass: string; // Optional.
    @Input() readOnlyClass: string; // Optional.
    @Input() readOnlyConfirmationBoxClass: string; // Optional.
    @Input() readOnlyDefaultsClass: string; // Optional.
    @Input() readOnlyDefaultsActiveClass: string; // Optional.
    @Input() saveButtonClass: string; // Required.
    @Input() saveButtonText: string = 'Save'; // Optional.

    // Outputs

    @Output() onModeChange: EventEmitter<string> = new EventEmitter<string>(); // Emits mode: create, update, readOnly
    @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>(); // Emits address returned from save in DB

    // Properties: private

    private isGuest: boolean;

    // Properties: public

    showEdit: boolean;

    // Properties: public getters

    get hasAddress() {
        return !isEmpty(this.address);
    }
    get showForm() {
        return this.mode == 'create' || this.mode == 'update';
    }

    constructor(ac: AccountService, av: AddressValidator, f: FormBuilder, g: GrecaptchaService, s: SessionService, m: MessageService) {
        super({
            dependencies: {
                accountService: ac,
                addressValidator: av,
                formBuilder: f,
                grecaptchaService: g,
                sessionService: s,
                messageService: m,
            },
        });
        this.isGuest = s.isGuestSession();
    }

    ngOnChanges(changes: SimpleChanges) {
        try {
            const mode: string = get(changes, 'mode.currentValue') as any;
            if (!mode) {
                return;
            }
            if (!this.form && this.showForm) {
                this.init();
            }
        } catch (err) {
            console.error(...new ExxComError(782993, scriptName, err).stamp());
        }
    }

    ngOnInit() {
        try {
            this.init();
        } catch (err) {
            console.error(...new ExxComError(294882, scriptName, err).stamp());
        }
    }

    // Initializers

    init() {
        try {
            if (this.mode == 'readOnly') {
                this.initReadOnly();
            } else if (this.mode == 'create') {
                this.initCreate();
            } else if (this.mode == 'update') {
                this.initUpdate();
            }
        } catch (err) {
            console.error(...new ExxComError(499992, scriptName, err).stamp());
        }
    }

    public async handleAddressChange(address: any) {
        let tempState;
        this.form.controls['address1'].setValue(address.name);
        address.address_components.forEach((element) => {
            if (element.types[0] == 'locality') {
                this.form.controls['city'].setValue(element.long_name);
            } else if (element.types[0] == 'country') {
                this.form.controls['country'].setValue(element.long_name);
            } else if (element.types[0] == 'administrative_area_level_1' && this.form.controls['state']) {
                tempState = element.short_name.toUpperCase(); // set state after loop so we can set it after country for validation
            } else if (element.types[0] == 'postal_code' || (element.types[1] && element.types[1] == 'postal_code')) {
                this.form.controls['zip'].setValue(element.long_name);
            }
        });
        this.form.controls['state'].setValue(tempState);
    }

    initReadOnly() {
        try {
            if (!isBrowser()) {
                return;
            }
            this.mode = 'readOnly';
            this.onModeChange.emit('readOnly');
        } catch (err) {
            console.error(...new ExxComError(754998, scriptName, err).stamp());
        }
    }

    toggleEdit() {
        try {
            if (!isBrowser()) {
                return;
            }
            this.showEdit = !this.showEdit;
        } catch (err) {
            console.error(...new ExxComError(764998, scriptName, err).stamp());
        }
    }

    initUpdate() {
        try {
            if (!isBrowser()) {
                return;
            }
            this.mode = 'update';
            this.initForm();
            this.onModeChange.emit('update');
        } catch (err) {
            console.error(...new ExxComError(839941, scriptName, err).stamp());
        }
    }

    initCreate() {
        try {
            if (!isBrowser()) {
                return;
            }
            this.mode = 'create';
            this.initForm({ empty: true });
            this.onModeChange.emit('create');
        } catch (err) {
            console.error(...new ExxComError(728829, scriptName, err).stamp());
        }
    }

    // Helpers

    useField(fieldName: string) {
        return contains(this.fields, fieldName) && (this.showForm || this.address[fieldName] !== undefined);
    }

    validatePristine(fieldName: string, inputValue: any) {
        try {
            if (inputValue == this.address[fieldName]) {
                this[fieldName].markAsPristine();
            }
        } catch (err) {
            console.error(...new ExxComError(908744, scriptName, err).stamp());
        }
    }

    // Submit handlers

    async submit() {
        try {
            this.isPending = true;
            this.formatSelectValue('state');
            this.formatSelectValue('country');
            await wait('200ms');
            await this.addressValidator.triggerValidation();
            if (this.form.invalid || !(await this.isValidRecaptcha())) {
                this.isPending = false;
                return;
            }
            if (this.isGuest) {
                this.onSuccess({ data: this.form.value });
            } else {
                const values = this.getValues();
                if (isEmpty(values)) {
                    this.isPending = false;
                    throw new Error('There were no values to add or update.');
                }
                /**
                 * Here ifs are used instead of bracket notation on the accountService
                 * so the functions are discoverable when searching for them in the
                 * codebase using an IDE's search-in-directory feature. For example,
                 * search in "exxcom/clients" for "createAddress" will find the
                 * following line, but it would not if the line were
                 *   this.accountService[`${this.mode}Address`](values);
                 */
                let res: any;
                if (this.mode == 'create') {
                    res = await this.accountService.createAddress(values);
                } else if (this.mode == 'update') {
                    res = await this.accountService.updateAddress(values);
                } else if (this.mode == 'delete') {
                    res = await this.accountService.deleteAddress(values);
                }
                if (!res.success) {
                    this.messageService.addLocal(this.componentId, 'Server error').clearOnTimeout(5);
                    throw res;
                }
                this.onSuccess(res);
            }
        } catch (err) {
            console.error(...new ExxComError(123992, scriptName, err).stamp());
        }
    }

    private async isValidRecaptcha() {
        try {
            if (await this.grecaptchaService.isValid('address')) {
                return true;
            }
            this.messageService.addLocal(this.componentId, this.grecaptchaService.uiErrorMessage).clearOnTimeout(5);
            throw new Error(this.grecaptchaService.consoleErrorMessage);
        } catch (err) {
            console.error(...new ExxComError(689230, scriptName, err).stamp());
        }
    }

    private onSuccess(res: any) {
        try {
            this.onSubmit.emit(res.data);
            this.reset();
        } catch (err) {
            console.error(...new ExxComError(299393, scriptName, err).stamp());
        }
    }

    private async reset() {
        try {
            this.initReadOnly();
            this.addressValidator.reset();
            this.form.setErrors(null);
            await this.accountService.account.get();
            this.isPending = false;
        } catch (err) {
            console.error(...new ExxComError(299393, scriptName, err).stamp());
        }
    }
}
