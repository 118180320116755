import { debounce } from 'lodash';
import { ExxComError } from 'lib/classes/exxcom-error.class';

const scriptName = 'cms-blog-header-menu-narrow';

export class CmsBlogHeaderMenuNarrow {
    currentLevel: number = 0;

    constructor(
        private isNarrow: boolean,
        private elems: any
    ) {}

    init() {
        try {
            this.calculateHeight();
            // Debounce to prevent too many function calls if a user clicks excessively
            this.open = debounce(this.open, 500, { leading: true });
            this.close = debounce(this.close, 500, { leading: true });
            // Close on click away from menu
            document.addEventListener('click', (event: MouseEvent) => this.closeOnClickAway(event));
        } catch (err) {
            console.error(...new ExxComError(692391, scriptName, err).stamp());
        }
    }

    /**
     * closeOnClickAway() has to be triggered before prev(), which is why prev()
     * is wrapped in a setTimeout. If closeOnClickAway() is triggered after
     * prev(), then, because prev() changes the currentLevel, the Angular *ngIf
     * has already removed the elements, so the JavaScript contains cannot find
     * them.
     */
    private closeOnClickAway(event: MouseEvent) {
        try {
            if (!this.isNarrow || this.currentLevel == 0) {
                return;
            }
            const target: any = event.target;
            const menu = document.getElementById('cms-blog-header-menu-narrow');
            if (menu && menu != target && !menu.contains(target)) {
                this.close();
            }
        } catch (err) {
            console.error(...new ExxComError(623092, scriptName, err).stamp());
        }
    }

    toggle() {
        try {
            this.currentLevel == 0 ? this.open() : this.close();
        } catch (err) {
            console.error(...new ExxComError(220993, scriptName, err).stamp());
        }
    }

    open() {
        try {
            this.currentLevel = 1;
            this.elems.toggle.nativeElement.classList.add('open');
            this.elems.level1.nativeElement.classList.add('open');
            document.getElementsByTagName('html')[0].style.overflow = 'hidden';
            document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        } catch (err) {
            console.error(...new ExxComError(792882, scriptName, err).stamp());
        }
    }

    close() {
        try {
            this.elems.toggle.nativeElement.classList.remove('open');
            if (this.currentLevel == 1) {
                const level1Elem = this.getOpenElemAtLevel(1);
                level1Elem.classList.remove('open');
            }
            this.currentLevel = 0;
            document.getElementsByTagName('html')[0].style.overflow = '';
            document.getElementsByTagName('body')[0].style.overflow = '';
        } catch (err) {
            console.error(...new ExxComError(766261, scriptName, err).stamp());
        }
    }

    private getElemsAtLevel(level: number) {
        try {
            if (level == 1) {
                return this.elems.level1.nativeElement;
            } else {
                return this.elems[`level${level}`].toArray().map((elem: any) => elem.nativeElement);
            }
        } catch (err) {
            console.error(...new ExxComError(420921, scriptName, err).stamp());
        }
    }

    private getOpenElemAtLevel(level: number) {
        try {
            if (level == 1) {
                return this.elems.level1.nativeElement;
            } else {
                const elem = this.getElemsAtLevel(level).find((elem: HTMLElement) => elem.classList.contains('open'));
                return elem;
            }
        } catch (err) {
            console.error(...new ExxComError(883882, scriptName, err).stamp());
        }
    }

    calculateHeight() {
        try {
            const windowHeight = window.innerHeight;
            const header = document.getElementById('cms-blog-header');
            if (!header) {
                return;
            }
            const headerHeight = header.offsetHeight;
            const calculatedHeight = windowHeight - headerHeight + 2 + 'px';
            const level1 = this.getElemsAtLevel(1);
            level1.style.height = calculatedHeight;
        } catch (err) {
            console.error(...new ExxComError(699299, scriptName, err).stamp());
        }
    }
}
