import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { Inject, Injectable, Optional } from '@angular/core';

import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';
import { get } from 'lodash';

import { contains, queryParams } from 'lib/tools';
import { ExxComError } from 'lib/classes/exxcom-error.class';

const scriptName = 'router.service';

@Injectable()
export class RouterService {
    private subscriptions: any[] = [];

    baseUrl: string = '';
    isAccount: boolean = false;
    isCart: boolean = false;
    isCheckout: boolean = false;
    isCheckoutBilling: boolean = false;
    isCheckoutConfirmation: boolean = false;
    isCheckoutShipping: boolean = false;
    isFirstRoute: boolean = true;
    url: any = {
        previous: '',
        current: '',
        component: '',
        componentPrev: '',
        params: {},
        parts: [],
    };

    constructor(
        @Optional() @Inject(REQUEST) protected req: Request,
        @Inject('environment') private environment: any,
        public router: Router
    ) {}

    init() {
        try {
            this.initRouteReuseStrategy();
            this.setBaseUrl();
            this.initUrl();
        } catch (err) {
            console.error(...new ExxComError(677634, scriptName, err).stamp());
        }
    }

    private initRouteReuseStrategy() {
        try {
            this.router.routeReuseStrategy.shouldReuseRoute = (activatedRouteSnapshot: ActivatedRouteSnapshot) => false;
        } catch (err) {
            console.error(...new ExxComError(620938, scriptName, err).stamp());
        }
    }

    private initUrl() {
        try {
            this.onRouteChange('parseUrl', (event: any) => {
                this.url.base = this.baseUrl;
                this.url.previous = this.url.current;
                this.url.current = event.url;
                this.url.full = `${this.baseUrl}${this.url.current}`;
                this.url.params = queryParams.get(this.router.url);
                const urlParts = decodeURIComponent(this.router.url || '')
                    .slice(1)
                    .split('?');
                this.url.componentPrev = this.url.component;
                this.url.component = urlParts[0];
                this.url.parts = urlParts[0].split('/').filter(Boolean);
                this.url.fullPath = this.url.full.split('?')[0];
                this.isFirstRoute = !this.url.previous;
                this.isAccount = this.url.parts[0] == 'account';
                this.isCart = this.url.parts[1] == 'cart';
                this.isCheckout = this.url.parts[0] == 'checkout';
                this.isCheckoutShipping = this.url.parts[1] == 'shipping';
                this.isCheckoutBilling = this.url.parts[1] == 'billing';
                this.isCheckoutConfirmation = this.url.parts[1] == 'confirmation';
            });
        } catch (err) {
            console.error(...new ExxComError(520938, scriptName, err).stamp());
        }
    }

    private setBaseUrl() {
        try {
            this.baseUrl = `${this.environment.backendApiUrl}`;
        } catch (err) {
            console.error(...new ExxComError(729088, scriptName, err).stamp());
        }
    }

    isCategoryPage(paths: string[]) {
        try {
            return get(this.url, 'parts[1]') && this.url.parts.length == 2 && contains(paths, this.url.parts[1]);
        } catch (err) {
            console.error(...new ExxComError(902873, scriptName, err).stamp());
        }
    }

    onRouteChange(scriptName: string, callback: any) {
        try {
            if (this.isOn(scriptName)) {
                return;
            }
            this.subscriptions[scriptName] = this.router.events.subscribe((event: any) => {
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                const urlParts = event.url.split('/').filter(Boolean);
                callback(event, urlParts);
            });
        } catch (err) {
            console.error(...new ExxComError(620394, scriptName, err).stamp());
        }
    }

    offRouteChange(scriptName: string) {
        try {
            if (!this.isOn[scriptName]) {
                return;
            }
            const subscription = this.subscriptions[scriptName];
            subscription && subscription.unsubscribe();
            delete this.subscriptions[scriptName];
        } catch (err) {
            console.error(...new ExxComError(902834, scriptName, err).stamp());
        }
    }

    isOn(scriptName: string) {
        try {
            return !!this.subscriptions[scriptName];
        } catch (err) {
            console.error(...new ExxComError(520933, scriptName, err).stamp());
        }
    }

    navigate(path) {
        try {
            return this.router.navigate(path);
        } catch (err) {
            console.error(...new ExxComError(527933, scriptName, err).stamp());
        }
    }
}
