<header id="header" data-cy="spc-header">
  <div id="header-row1" class="header-row fx-row fx-row-h-right fx-row-v-center">
    <!-- Both header cart components load on the server first; the browser CSS determines which one is displayed before browser JS removes one. -->
    <app-header-account
      *ngIf="!isBrowser || !isNarrow"
      id="header-row1-account"
      [isBrowser]="isBrowser"
      [isGuestSession]="isGuestSession"
      [isValidLocalSessionData]="isValidLocalSessionData"
      (isActiveChange)="onActiveChange('account', $event, 102)"
    ></app-header-account>
    <app-header-cart
      *ngIf="!isBrowser || !isNarrow"
      id="header-row1-cart"
      [isNarrow]="isNarrow"
      (isActiveChange)="onActiveChange('cart', $event, 102)"
    ></app-header-cart>
  </div>

  <div
    id="header-row2"
    class="fx-row fx-row-h-space-between fx-row-v-center"
    [class.active-search]="toggleSearch && isNarrow"
  >
    <a id="header-logo-desk" routerLink="/">
      <img id="header-wide-logo" alt="SabrePC" src="https://assets.sabrepc.com/img/spc/logo/SPC-Wordmark.svg" />
    </a>
    <div id="header-menu-spacer"></div>
    <div class="fx-row fx-row-h-space-between fx-row-v-center col-md-12 pr-0 pl-0">
      <app-header-menu
        id="app-header-menu"
        [isNarrow]="isNarrow"
        (isActiveChange)="onActiveChange('menu', $event, 100)"
      >
      </app-header-menu>

      <a *ngIf="isNarrow" id="header-logo-mob" class="fx-row fx-row-h-right" routerLink="/">
        <div class="header-logo-container">
          <img id="header-narrow-logo" alt="SabrePC" src="https://assets.sabrepc.com/img/spc/logo/SPC_Logo.svg" />
        </div>
      </a>
      <div class="app-header-searchbar-container fx-row fx-row-v-center fx-row-h-right col-sm-12">
        <app-header-search
          id="searchbar"
          [ngClass]="isNarrow ? '' : 'searchbar-wide'"
          [isNarrow]="isNarrow"
          (isActiveChange)="onActiveChange('search', $event, 100)"
          (searchActive)="onToggleSearch($event)"
        >
        </app-header-search>
        <mat-icon
          [class.hide]="!toggleSearch"
          *ngIf="isNarrow"
          id="header-search-narrow-close"
          #searchClose
          svgIcon="x"
        ></mat-icon>

        <app-header-cart
          *ngIf="!isBrowser || isNarrow"
          id="header-row2-cart"
          [isNarrow]="isNarrow"
          (isActiveChange)="onActiveChange('cart', $event, 100)"
        >
        </app-header-cart>
      </div>
    </div>
    <!-- Both header cart components load on the server first; the browser CSS determines which one is displayed before browser JS removes one. -->
  </div>

  <lib-overlay [zIndex]="overlayZindex" [isVisible]="overlayVisible"></lib-overlay>
</header>
