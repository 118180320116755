import { SimpleChanges } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { get } from 'lodash';
import { initServiceClassBoolean } from 'lib/tools';
import { ProductChanges, ProductCorrelated, ProductData, ProductSiteData } from 'lib/services/product/product.interface';

const scriptName = 'product.class';

export class Product {
    // Schema

    _id?: string = null;
    changes?: ProductChanges[] = [];
    correlated?: ProductCorrelated[] = [];
    createdAt?: string | Date = null;
    height?: number = null;
    highlights?: string = null;
    images?: string[] = [];
    leadTime?: string = null;
    length?: number = null;
    manufacturer?: string = null;
    mpn?: string = null;
    name?: string = null;
    ncnr?: boolean = null;
    nsid?: string = null;
    preOrderDate?: string | Date = null;
    showPriceInCart?: boolean = null;
    specialOrder?: boolean = null;
    specifications?: string = null;
    type?: string = null;
    upc?: string = null;
    weight?: number = null;
    width?: number = null;
    sites?: {
        exx?: ProductSiteData;
        spc?: ProductSiteData;
    } = {
        exx: {
            breadcrumb: null,
            category: null,
            cmsGroupId: null,
            condition: null,
            description: null,
            displayInStore: null, // boolean
            inventory: null,
            marketingInfo: null,
            metaDescription: null,
            price: null,
            showPrice: null, // boolean
            subcategory: null,
            topCategory: null,
            urlComponent: null,
        },
        spc: {
            breadcrumb: null,
            category: null,
            cmsGroupId: null,
            condition: null,
            description: null,
            displayInStore: null, // boolean
            inventory: null,
            marketingInfo: null,
            metaDescription: null,
            price: null,
            showPrice: null, // boolean
            subcategory: null,
            topCategory: null,
            urlComponent: null,
        },
    };

    // Client-side only

    changed$: BehaviorSubject<SimpleChanges> = new BehaviorSubject<SimpleChanges>(null);

    constructor(data?: ProductData) {
        if (data) {
            this.init(data);
        }
    }

    init(data: ProductData) {
        try {
            const changes: SimpleChanges = {};

            this._id = data._id || this._id || null;
            this.changes = data.changes || this.changes || [];
            this.correlated = data.correlated || this.correlated || [];
            this.createdAt = data.createdAt ? new Date(data.createdAt) : this.createdAt || null;
            this.height = data.height || this.height || null;
            this.highlights = data.highlights || this.highlights || null;
            this.images = data.images || this.images || [];
            this.leadTime = data.leadTime || this.leadTime || null;
            this.length = data.length || this.length || null;
            this.manufacturer = data.manufacturer || this.manufacturer || null;
            this.mpn = data.mpn || this.mpn || null;
            this.name = data.name || this.name || null;
            this.ncnr = initServiceClassBoolean('ncnr', data.ncnr, this);
            this.nsid = data.nsid || this.nsid || null;
            this.preOrderDate = data.preOrderDate ? new Date(data.preOrderDate) : this.preOrderDate || null;
            this.showPriceInCart = initServiceClassBoolean('showPriceInCart', data.showPriceInCart, this);
            this.specialOrder = initServiceClassBoolean('specialOrder', data.specialOrder, this);
            this.specifications = data.specifications || this.specifications || null;
            this.type = data.type || this.type || null;
            this.upc = data.upc || this.upc || null;
            this.weight = data.weight || this.weight || null;
            this.width = data.width || this.width || null;

            const exx = get(data, 'sites.exx');
            if (exx) {
                this.sites.exx.breadcrumb = exx.breadcrumb || this.sites.exx.breadcrumb || null;
                this.sites.exx.category = exx.category || this.sites.exx.category || null;
                this.sites.exx.cmsGroupId = exx.cmsGroupId || this.sites.exx.cmsGroupId || null;
                this.sites.exx.condition = exx.condition || this.sites.exx.condition || null;
                this.sites.exx.description = exx.description || this.sites.exx.description || null;
                this.sites.exx.displayInStore = initServiceClassBoolean('sites.exx.displayInStore', this.sites.exx.displayInStore, this);
                this.sites.exx.inventory = exx.inventory || this.sites.exx.inventory || null;
                this.sites.exx.marketingInfo = exx.marketingInfo || this.sites.exx.marketingInfo || null;
                this.sites.exx.metaDescription = exx.metaDescription || this.sites.exx.metaDescription || null;
                this.sites.exx.price = exx.price || this.sites.exx.price || null;
                this.sites.exx.showPrice = initServiceClassBoolean('sites.exx.showPrice', this.sites.exx.showPrice, this);
                this.sites.exx.subcategory = exx.subcategory || this.sites.exx.subcategory || null;
                this.sites.exx.topCategory = exx.topCategory || this.sites.exx.topCategory || null;
                this.sites.exx.urlComponent = exx.urlComponent || this.sites.exx.urlComponent || null;
            }

            const spc = get(data, 'sites.spc');
            if (spc) {
                this.sites.spc.breadcrumb = spc.breadcrumb || this.sites.spc.breadcrumb || null;
                this.sites.spc.category = spc.category || this.sites.spc.category || null;
                this.sites.spc.cmsGroupId = spc.cmsGroupId || this.sites.spc.cmsGroupId || null;
                this.sites.spc.condition = spc.condition || this.sites.spc.condition || null;
                this.sites.spc.description = spc.description || this.sites.spc.description || null;
                this.sites.spc.displayInStore = initServiceClassBoolean('sites.spc.displayInStore', this.sites.spc.displayInStore, this);
                this.sites.spc.inventory = spc.inventory || this.sites.spc.inventory || null;
                this.sites.spc.marketingInfo = spc.marketingInfo || this.sites.spc.marketingInfo || null;
                this.sites.spc.metaDescription = spc.metaDescription || this.sites.spc.metaDescription || null;
                this.sites.spc.price = spc.price || this.sites.spc.price || null;
                this.sites.spc.showPrice = initServiceClassBoolean('sites.spc.showPrice', this.sites.spc.showPrice, this);
                this.sites.spc.subcategory = spc.subcategory || this.sites.spc.subcategory || null;
                this.sites.spc.topCategory = spc.topCategory || this.sites.spc.topCategory || null;
                this.sites.spc.urlComponent = spc.urlComponent || this.sites.spc.urlComponent || null;
            }

            this.changed$.next(changes);
        } catch (err) {
            console.error(...new ExxComError(420477, scriptName, err).stamp());
        }
    }
}
