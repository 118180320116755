import { Injectable, Inject, Optional, makeStateKey, TransferState } from '@angular/core';

import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';

import { Account } from 'lib/services/account/account.class';
import { ApiService } from 'lib/services/api.service';
import { AuthService } from 'lib/services/auth.service';
import { BehaviorSubject } from 'rxjs';
import { contains, getEmailFromBrowser, isDevServer, removeEmailFromBrowser } from 'lib/tools';
import { each, isEmpty, isEqual, kebabCase, omit, startCase, uniq } from 'lodash';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { SessionService } from 'lib/services/session.service';
import { Subscription } from 'rxjs';

const scriptName = 'account.service';

let apiService: ApiService;
let authService: AuthService;
let environment: any;
let sessionService: SessionService;
let transferState: TransferState;

const ACCOUNT_STATE_KEY = makeStateKey<string | Pick<any, any>>('account');

@Injectable()
export class AccountService {
    private logoutSubscription: Subscription;

    account: Account = null;
    accountChanged$: BehaviorSubject<null> = new BehaviorSubject<null>(null);

    constructor(
        @Optional() @Inject(REQUEST) protected req: Request,
        @Inject('environment') e: any,
        ap: ApiService,
        au: AuthService,
        s: SessionService,
        t: TransferState
    ) {
        try {
            apiService = ap;
            authService = au;
            environment = e;
            sessionService = s;
            transferState = t;
        } catch (err) {
            console.error(...new ExxComError(439828, scriptName, err).stamp());
        }
    }

    async init(isLoginRegister?: boolean) {
        try {
            if (!(await sessionService.isValidSession())) {
                return;
            }

            // Initialize account data

            const getAccount = async (transferredState?: any) => {
                this.account = new Account({
                    environment,
                    apiService,
                    authService,
                });
                this.account = transferredState ? Object.assign(this.account, transferredState) : this.account;
                this.account.email = getEmailFromBrowser(this.req);
                if (this.account.email) {
                    await this.account.get();
                }
                this.account.changed$.subscribe(() => this.accountChanged$.next(null));
            };

            if (isDevServer() || isLoginRegister) {
                await getAccount();
            } else {
                const transferredState: any = transferState.get(ACCOUNT_STATE_KEY, 'firstLoad_server');
                if (transferredState == 'firstLoad_server' || transferredState == 'subsequentLoads_browser') {
                    await getAccount();
                    if (transferredState == 'firstLoad_server') {
                        transferState.set(ACCOUNT_STATE_KEY, omit(this.account, 'changed$'));
                    }
                } else {
                    // secondLoad_browser
                    if (transferredState._id == null) {
                        return authService.logout();
                    }
                    await getAccount(transferredState);
                    transferState.set(ACCOUNT_STATE_KEY, 'subsequentLoads_browser');
                }
            }

            // Initialize logout subscription

            if (this.logoutSubscription) {
                return;
            }
            this.logoutSubscription = authService.logout$.subscribe(() => {
                removeEmailFromBrowser();
                this.account = null;
            });
        } catch (err) {
            console.error(...new ExxComError(298388, scriptName, err).stamp());
        }
    }

    async updateCustomer(values: any, { getAccount }: { getAccount?: boolean } = {}) {
        try {
            if (this.account.updated) {
                values.updated = uniq(this.account.updated.concat(Object.keys(omit(values, ['_id', 'cart']))));
            }
            const toUpdate = {};
            each(values, (v: any, k: string) => {
                if (!isEqual(v, this.account[k])) {
                    toUpdate[k] = v;
                }
            });
            if (isEmpty(toUpdate)) {
                return;
            }
            const res = await apiService.post(`customers/update/${this.account._id}`, toUpdate);
            if (!res.success) {
                throw res;
            }
            if (getAccount !== false) {
                await this.account.get();
            }
            return res;
        } catch (err) {
            return err;
        } // In the calling script, if !res.success, throw res, then catch, and pass err into ExxComError
    }

    async updateCartId(cartId: string) {
        try {
            await this.updateCustomer({ cart: cartId }, { getAccount: false });
            this.account.cart = cartId;
        } catch (err) {
            return { success: false, error: err };
        }
        // In the calling script, if !res.success, throw res, then catch, and pass err into ExxComError
    }

    async createAddress(values: any, { getAccount }: { getAccount?: boolean } = {}) {
        try {
            values.email = this.account.email;
            values.customerNsid = this.account.nsid;
            const res = await apiService.post('addresses/create', values);
            if (!res.success) {
                throw res;
            }
            if (getAccount !== false) {
                await this.account.get();
            }
            return res;
        } catch (err) {
            return err;
        } // In the calling script, if !res.success, throw res, then catch, and pass err into ExxComError
    }

    async updateAddress(values: any, { getAccount }: { getAccount?: boolean } = {}) {
        try {
            const address = this.account.addresses.all.find((a: any) => a._id == values._id);
            if (!address) {
                return { success: true };
            }
            if (address.updated) {
                values.updated = uniq(address.updated.concat(Object.keys(omit(values, '_id'))));
            }
            const res = await apiService.post('addresses/update', values);
            if (!res.success) {
                throw res;
            }
            if (getAccount !== false) {
                await this.account.get();
            }
            return res;
        } catch (err) {
            return err;
        } // In the calling script, if !res.success, throw res, then catch, and pass err into ExxComError
    }

    async deleteAddress(id: string, { getAccount }: { getAccount?: boolean } = {}) {
        try {
            const res = await apiService.delete(`addresses/delete/${id}`);
            if (!res.success) {
                throw res;
            }
            if (getAccount !== false) {
                await this.account.get();
            }
            return res;
        } catch (err) {
            return err;
        } // In the calling script, if !res.success, throw res, then catch, and pass err into ExxComError
    }

    getDefaultKey(type: string) {
        try {
            return `isDefault${startCase(type)}`;
        } catch (err) {
            console.error(...new ExxComError(629938, scriptName, err).stamp());
        }
    }

    async setDefaultAddress(type: string, address: any, { getAccount }: { getAccount?: boolean } = {}) {
        try {
            const isDefault = this.getDefaultKey(type);
            const values = { _id: address._id };
            values[isDefault] = true;
            const res = await this.updateAddress(values);
            if (!res.success) {
                throw res;
            }
            if (getAccount !== false) {
                await this.account.get();
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(782887, scriptName, err).stamp());
        }
    }

    getCardIconPath(brand: string) {
        try {
            const brands = ['Discover', 'MasterCard', 'Visa', 'American Express'];
            // return '/assets/images/' + (contains(brands, brand) ? kebabCase(brand) : 'generic-card') + '.svg';
            return 'assets/images/' + (contains(brands, brand) ? kebabCase(brand) : 'generic-card') + '.svg'; // this line fixes missing cards on sb but needs testing, might break qa/prod?
        } catch (err) {
            console.error(...new ExxComError(109393, scriptName, err).stamp());
        }
    }
}
