import { Component, Inject, Input } from '@angular/core';
import { Location } from '@angular/common';
import { CmsPartialComponentClass } from 'lib/components/cms/cms-partial-component.class';
import { RouterService } from 'lib/services/router.service';
import { WebpService } from 'lib/services/webp.service';
import { CategoryService } from 'lib/services/category/category.service';

@Component({
    selector: 'cms-partial-category-top-software-stack',
    templateUrl: './software-stack.component.html',
    styleUrls: ['./software-stack.component.scss'],
})
export class SoftwareStackComponent extends CmsPartialComponentClass {
    @Input() softwareStackRow: any;
    @Input() grayBackgroundRows: any = [];
    getWebpImg: (src: string) => string;

    constructor(
        @Inject('environment') environment: any,
        routerService: RouterService,
        webpService: WebpService,
        location: Location,
        categoryService: CategoryService
    ) {
        super({
            dependencies: {
                environment,
                routerService,
                location,
                categoryService,
            },
        });
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
    }
}
