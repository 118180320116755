import { Component, OnInit } from '@angular/core';

import { CmsBlogHeaderSearchComponentClass } from 'lib/components/cms/blog/cms-blog-header-search-component.class';
import { CmsService } from 'lib/services/cms.service';
import { RouterService } from 'lib/services/router.service';
import { calculateReadingTime } from 'lib/tools';

@Component({
    selector: 'app-cms-blog-header-search',
    templateUrl: './cms-blog-header-search.component.html',
    styleUrls: ['./cms-blog-header-search.component.scss'],
})
export class CmsBlogHeaderSearchComponent extends CmsBlogHeaderSearchComponentClass implements OnInit {
    calculateReadingTime: (entry: any) => string;
    constructor(cmsService: CmsService, routerService: RouterService) {
        super({ dependencies: { cmsService, routerService } });
        this.calculateReadingTime = calculateReadingTime;
    }
}
