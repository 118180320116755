import { contains } from 'lib/tools';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { isEmpty, pickBy, isNil } from 'lodash';

const scriptName = 'category-sorting.class';

export class CategorySorting {
    options: any[] = [];
    selectedIndex: number = 0;
    selectedLabel: string = '';
    defaultLabel: string = 'Sort By';

    constructor() {}

    init(resSorting: any, params: any) {
        try {
            if (!resSorting) {
                return;
            }
            this.options = resSorting.options;
            this.getSelectedIndex(params);
            this.setSelectedLabel();
        } catch (err) {
            console.error(...new ExxComError(790203, scriptName, err).stamp());
        }
    }

    private getSelectedIndex(params: any) {
        try {
            if (!params || typeof params != 'object') {
                return;
            }
            this.selectedIndex = 0;
            const sortParam = this.getParam(params);
            if (isEmpty(sortParam)) {
                this.selectedIndex = null;
                this.selectedLabel = this.defaultLabel;
                return;
            }
            const field = Object.keys(sortParam)[0];
            const direction = sortParam[field];
            this.options.forEach((o: any, i: number) => {
                const found = o.field == field.replace('sort.', '') && o.direction == direction;
                if (!found) {
                    return;
                }
                this.selectedIndex = i;
            });
        } catch (err) {
            console.error(...new ExxComError(299231, scriptName, err).stamp());
        }
    }

    setSelectedLabel() {
        try {
            if (isNil(this.selectedIndex)) {
                return;
            }
            this.selectedLabel = this.options[this.selectedIndex].label;
        } catch (err) {
            console.error(...new ExxComError(999293, scriptName, err).stamp());
        }
    }

    getParam(params: any) {
        try {
            if (!params || typeof params != 'object') {
                return;
            }
            return pickBy(params, (v: string, k: string) => contains(k, 'sort.'));
        } catch (err) {
            console.error(...new ExxComError(802001, scriptName, err).stamp());
        }
    }
}
