import { Inject, Injectable } from '@angular/core';

import { ApiService } from 'lib/services/api.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { Suggestions } from 'lib/interfaces/suggestions';
import { RouterService } from './router.service';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MetaService } from './meta.service';
import { contains } from 'lib/tools';
import { isEmpty } from 'lodash';
import { CmsService } from './cms.service';

const scriptName = 'searchspring.service';

let apiService: ApiService;
let cmsService: CmsService;
let environment: any;
let routerService: RouterService;
let metaService: MetaService;

@Injectable()
export class SearchSpringService {
    routeThing: any;
    constructor(
        @Inject('environment') e: any,
        @Inject(DOCUMENT) private doc: Document,
        a: ApiService,
        cms: CmsService,
        r: RouterService,
        m: MetaService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        try {
            environment = e;
            apiService = a;
            cmsService = cms;
            routerService = r;
            metaService = m;
        } catch (err) {
            console.error(...new ExxComError(430298, scriptName, err).stamp());
        }
    }

    setFirstPage() {
        try {
            // Get current URL
            const currentUrl = this.router.url;

            // Split into base URL and query string
            const [base, query] = currentUrl.split('?');

            // Parse query string to object
            let queryParams = new URLSearchParams(query);

            // Add page param
            queryParams.set('page', '1');

            // Construct new URL
            const newUrl = `${base}?${queryParams.toString()}`;

            // Navigate to new URL
            this.router.navigateByUrl(newUrl, { replaceUrl: true });
        } catch (err) {
            console.error(...new ExxComError(232122, scriptName, err).stamp());
        }
    }

    async search(type: string, term: string, page?: string | number, filters?: string, sort?: string, skipPageRedirect?: boolean): Promise<any> {
        try {
            const url = [
                'https://api.searchspring.net/api/search/search.json',
                `?siteId=${environment.searchspring.siteId}`,
                '&resultsFormat=native',
                `&resultsPerPage=${environment.searchspring.resultsPerPage}`,
                `&page=${page || 1}`,
            ];
            if (sort) {
                url.push(`&${sort}`);
            }
            if (type == 'search') {
                url.push(`&q=${term}&`);
            } else if (type == 'tag') {
                url.push(`&tag=${encodeURIComponent(term)}`);
            } else if (type == 'category') {
                url.push(`&bgfilter.ss_breadcrumb=${encodeURIComponent(term)}`);
            }
            if (filters) {
                let tempFilters = '';
                const parentSplit = filters.split('&filter.');
                if (parentSplit.length >= 2) {
                    for (let i = 1; i < parentSplit.length; i += 1) {
                        const split = parentSplit[i].split('=');
                        if (split.length >= 2) {
                            tempFilters += '&filter.' + split[0] + '=' + encodeURIComponent(split[1]);
                        }
                    }
                    url.push(tempFilters);
                } else {
                    url.push(filters);
                }
            }
            const res = await apiService.get(url.join(''), {
                useFullUrl: true,
            });
            if (res.pagination.currentPage != 1 && res.pagination.totalPages != 0 && res.pagination.currentPage > res.pagination.totalPages) {
                const pageUrl = routerService.url.current.replace(res.pagination.currentPage, res.pagination.totalPages);
                res.pagination.currentPage = res.pagination.totalPages;
                routerService.router.navigateByUrl(pageUrl);
            }

            if (res.pagination.currentPage == 1 && skipPageRedirect != true) {
                if (!routerService?.url.params.page && !isEmpty(res.results)) {
                    const isLandingPageRes = await cmsService.getPage('category_page', {
                        fields: ['islandingpage'],
                    });
                    if (!isLandingPageRes.islandingpage) {
                        this.setFirstPage();
                    }
                }
            }

            this.setMetaTitle(res.pagination.currentPage);

            // canonical html for pagination
            const currentURL = routerService.url.fullPath;
            const headElem = document.getElementsByTagName('head')[0];
            const canonical = document.querySelector("[rel='canonical']");
            const canonicalNext = document.querySelector("[id='canonical-next']");
            const canonicalPrev = document.querySelector("[id='canonical-prev']");
            if (canonical) {
                canonical.setAttribute('href', `${currentURL}?page=${res.pagination.currentPage}`);
            }
            if (canonicalNext) {
                canonicalNext.setAttribute('href', `${currentURL}?page=${res.pagination.nextPage}`);
            } else if (!canonicalNext && res.pagination.currentPage !== res.pagination.totalPages) {
                headElem.insertAdjacentHTML(
                    'beforeend',
                    `
        <link id="canonical-next" rel="next" href="${currentURL}?page=${res.pagination.nextPage}">
      `
                );
            }
            if (canonicalNext && res.pagination.currentPage == res.pagination.totalPages) {
                canonicalNext.remove();
            }
            if (canonicalPrev) {
                canonicalPrev.setAttribute('href', `${currentURL}?page=${res.pagination.previousPage}`);
            }
            if (!canonicalPrev && res.pagination.currentPage !== 1) {
                headElem.insertAdjacentHTML(
                    'beforeend',
                    `
        <link id="canonical-prev" rel="prev" href="${currentURL}?page=${res.pagination.previousPage}">
      `
                );
            }
            if (canonicalPrev && res.pagination.currentPage == 1) {
                canonicalPrev.remove();
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(673663, scriptName, err).stamp());
        }
    }

    setMetaTitle(page: number) {
        if (contains(this.doc.title, 'Search')) {
            metaService.add({ title: `Search - Page ${page}` });
        } else {
            return;
        }
    }

    async suggest(value: string): Promise<Suggestions> {
        try {
            const url = 'https://suggest-cache.searchspring.net/api/suggest/legacy';
            const values = {
                siteId: environment.searchspring.siteId,
                query: value,
            };
            const res: Promise<Suggestions> = await apiService.post(url, values, { useFullUrl: true });
            return res;
        } catch (err) {
            console.error(...new ExxComError(293931, scriptName, err).stamp());
        }
    }
}
