import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { CategoryComponentClass } from 'lib/components/category/category-component.class';
import { CategoryService } from 'lib/services/category/category.service';
import { CmsService } from 'lib/services/cms.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { MetaService } from 'lib/services/meta.service';
import { RouterService } from 'lib/services/router.service';
import { WebstoreProductService } from 'lib/services/webstore-product/webstore-product.service';
import { WebpService } from 'lib/services/webp.service';
import { MarketoService } from 'lib/services/marketo.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Location } from '@angular/common';

const scriptName = 'category.component';

@Component({
    selector: 'app-category',
    templateUrl: './category.component.html',
    styleUrls: ['./category.component.scss'],
})
export class CategoryComponent extends CategoryComponentClass implements OnInit {
    getWebpImg: (src: string) => string;
    bsModalRef?: BsModalRef;
    constructor(
        @Inject('environment') e: any,
        b: BreakpointObserver,
        bsModalRef: BsModalRef,
        modalService: BsModalService,
        ca: CategoryService,
        c: CmsService,
        l: Location,
        d: MatDialog,
        m: MetaService,
        ma: MarketoService,
        pr: WebstoreProductService,
        r: RouterService,
        w: WebpService
    ) {
        super({
            dependencies: {
                breakpointObserver: b,
                bsModalRef: bsModalRef,
                modalService: modalService,
                categoryService: ca,
                cmsService: c,
                dialog: d,
                environment: e,
                location: l,
                metaService: m,
                marketoService: ma,
                productService: pr,
                routerService: r,
            },
        });
        this.getWebpImg = (src: string) => w.getWebpImg(src);
    }

    async ngOnInit() {
        try {
            if (this.isBrowser) {
                document.documentElement.addEventListener('click', this.hideIfBackdropClick.bind(this));
            }
            await this.init();
        } catch (err) {
            console.error(...new ExxComError(623095, scriptName, err).stamp());
        }
    }
}
