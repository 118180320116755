export const HeaderMenuStaticItems = {
    name: 'Company',
    href: '/Company',
    categories: [
        { name: 'About Us', href: '/About-Us' },
        { name: 'Blog', href: '/blog' },
        { name: 'B2B Services', href: '/B2B-Services' },
        { name: 'Contact Us', href: '/Contact-Us' },
        { name: 'RMA Request', href: '/RMA-Request' },
        { name: 'Service & Support', href: '/Service-Support' },
    ],
};
