<div id="cms-partial-category-top">
  <!-- ROWS -->

  <div *ngIf="!isEmpty(get(data, 'rows', ''))" id="rows">
    <div *ngFor="let row of get(data, 'rows', []); let i = index">
      <!-- Hero Banner -->
      <cms-partial-category-top-hero-banner
        *ngIf="get(row, 'category_top_banner', '')"
        [heroBannerRow]="row"
        [dataUrl]="data.url"
        [dataTitle]="data.title"
      ></cms-partial-category-top-hero-banner>
      <!-- Cards -->
      <cms-partial-category-top-cards [cardsRow]="row" *ngIf="get(row, 'cards', '')"></cms-partial-category-top-cards>

      <!-- Software Stack -->
      <cms-partial-category-top-software-stack
        [softwareStackRow]="row"
        *ngIf="get(row, 'software_stack', '')"
      ></cms-partial-category-top-software-stack>

      <!-- Subcategory Links -->
      <cms-partial-category-top-subcategory-links
        [subcategoryLinksRow]="row"
        *ngIf="get(row, 'subcategory_links', '')"
      ></cms-partial-category-top-subcategory-links>

      <!-- Category Top Feature Solutions -->
      <cms-partial-category-top-feature-solutions
        [featureSolutionsRow]="row"
        [solutionUrl]="data.url"
        *ngIf="get(row, 'feature_solutions', '')"
      ></cms-partial-category-top-feature-solutions>

      <!-- MULTIPLE COLUMNS GRID -->
      <cms-partial-category-top-multiple-columns
        [multipleColumnsRow]="row"
        *ngIf="get(row, 'multi_columns', '')"
      ></cms-partial-category-top-multiple-columns>

      <!-- TABLE -->
      <cms-partial-category-top-table [tableRow]="row" *ngIf="get(row, 'tables', '')"></cms-partial-category-top-table>

      <!-- LEAD GEN -->
      <cms-partial-category-top-lead-gen
        [leadGenRow]="row"
        [dataUrl]="data.url"
        [dataTitle]="data.title"
        *ngIf="get(row, 'lead_gen', '')"
      ></cms-partial-category-top-lead-gen>

      <cms-partial-category-top-colored-block
        [coloredBlockRow]="row"
        [dataUrl]="data.url"
        [dataTitle]="data.title"
        *ngIf="get(row, 'colored_block', '')"
      ></cms-partial-category-top-colored-block>

      <!-- LOGOS -->
      <cms-partial-category-top-logos [logosRow]="row" *ngIf="get(row, 'logos', '')"></cms-partial-category-top-logos>

      <!-- Paragraph -->
      <cms-partial-category-top-paragraph
        [paragraphRow]="row"
        *ngIf="get(row, 'paragraph', '')"
      ></cms-partial-category-top-paragraph>

      <!-- IMAGE AND TEXT -->
      <cms-partial-category-top-image-and-text
        [imageAndTextRow]="row"
        [index]="i"
        *ngIf="get(row, 'image_and_text', '')"
      ></cms-partial-category-top-image-and-text>
    </div>
  </div>
  <!-- ROW SET (Product Facets) -->

  <div *ngIf="!isEmpty(get(data, 'rowSets', ''))" id="row-sets">
    <div *ngFor="let rowSet of get(data, 'rowSets', [])">
      <cms-partial-category-top-row-set
        [rowSetData]="rowSet"
        *ngIf="!isEmpty(get(rowSet, 'products.category_top_facets[0]', ''))"
      ></cms-partial-category-top-row-set>
    </div>
  </div>
</div>
