<div id="auth-login-register" class="auth-container">
  <h2>How would you like to checkout?</h2>

  <div class="fx-row fx-wrap fx-row-h-space-between">
    <app-auth-login
      formClass="form-1 mat-elevation-z5 p-4"
      [authType]="authType"
      [showFormHeading]="true"
      [showLabels]="true"
    ></app-auth-login>
    <app-auth-register
      formClass="form-1 mat-elevation-z5 p-4"
      [authType]="authType"
      [showFormHeading]="true"
      [showLabels]="true"
    ></app-auth-register>
  </div>
</div>
