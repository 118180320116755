<section id="page-not-found">
  <div class="col-xs-12 fx-wrap first-row-text">
    <h1 class="col-xs-12 text-color text-center mb-0">Wow!</h1>
    <h3 class="col-xs-12 text-color text-center mb-0">Seems you're lost.</h3>
    <p class="col-xs-12 text-color text-center">
      We’re not sure how you got here, but we hope to help you find what you’re looking for.
    </p>
  </div>
  <p class="col-xs-12 text-center last-row-text">404</p>
</section>
