import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { CmsPartialComponentClass } from 'lib/components/cms/cms-partial-component.class';
import { RouterService } from 'lib/services/router.service';
import { WebpService } from 'lib/services/webp.service';
import { CategoryService } from 'lib/services/category/category.service';
import { BootstrapMarketoService } from 'lib/services/bootstrap/bootstrap-marketo.service';
import { ConfiguratorService } from 'lib/services/configuration/configurator.service';
import { WebstoreProductService } from 'lib/services/webstore-product/webstore-product.service';
import * as feather from 'feather-icons';

@Component({
    selector: 'cms-partial-category-top-feature-solutions',
    templateUrl: './feature-solutions.component.html',
    styleUrls: ['./feature-solutions.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FeatureSolutionsComponent extends CmsPartialComponentClass implements OnInit {
    @Input() featureSolutionsRow: any;
    @Input() solutionUrl: string;
    @Input() grayBackgroundRows: any = [];
    getWebpImg: (src: string) => string;

    constructor(
        @Inject('environment') environment: any,
        public bootstrapMarketoService: BootstrapMarketoService,
        routerService: RouterService,
        webpService: WebpService,
        location: Location,
        categoryService: CategoryService,
        public configuratorService: ConfiguratorService,
        private webstoreProductService: WebstoreProductService
    ) {
        super({
            dependencies: {
                bootstrapMarketoService,
                environment,
                routerService,
                location,
                categoryService,
                configuratorService,
            },
        });
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
        // feather.replace();
    }

    async ngOnInit() {
        // feather.replace();
        if (this.featureSolutionsRow.feature_solutions.feature_solutions[0].feature_solutions) {
            const solutions = this.featureSolutionsRow.feature_solutions.feature_solutions[0].feature_solutions;

            const productsMap = new Map();

            // First, map over all the products
            for (const solution of solutions) {
                const mpn = solution.part_number;

                try {
                    const product = await this.webstoreProductService.getProductByMpnComponentConfig(mpn);
                    if (product == null) {
                        continue; // Skip to the next iteration
                    }
                    const newProduct = { ...product };

                    const newHighlights = this.formatHighlights(newProduct?.highlights);
                    newProduct.highlights = newHighlights;

                    productsMap.set(mpn, newProduct);
                } catch (error) {
                    console.error(`Error fetching product for MPN ${mpn}:`, error);
                } finally {
                    // feather.replace();
                }
            }

            // Then, place the products on the correct solution
            await solutions.forEach(async (solution) => {
                const partNumber = solution.part_number;
                const product = productsMap.get(partNumber);
                solution.isBlackListed = this.configuratorService.isBlacklisted(partNumber);

                if (product) {
                    solution.product = product;
                    solution.product.price = 0; // Prevent usage of default product pricing
                } else {
                    console.log(`No product found for solution with part number ${partNumber}`);
                }

                // Check for solution on exxcom config_v2 db
                const solutionExxcom = await this.configuratorService.getConfigurationByUrlComponent(
                    'spc',
                    solution.part_number,
                    null,
                    null,
                    true,
                    true
                );

                if (solutionExxcom && solutionExxcom[0] && solutionExxcom[0].isActive && solutionExxcom[0].sierraActive) {
                    solution.exxcomIsActive = true;
                    const spcConfig = await this.configuratorService.getConfigurationWithBasePrice('spc', solutionExxcom[0].mpn);
                    if (solution.product) solution.product.price = spcConfig[0]?.referenceId?.optimizedConfigurations?.good?.spcPriceTotal;
                } else {
                    solution.exxcomIsActive = false;
                }
            });
            // feather.replace();
        } else {
            console.log('No feature solutions to process.');
        }
    }

    formatHighlights(highlights: string) {
        if (!highlights || highlights.length < 2) return '';
        // Add a line break after each colon
        let formattedHighlights = highlights.split(':').join(':\n');

        // Wrap text after <li> and before \n in a span with class "li-title"
        formattedHighlights = formattedHighlights.replace(/<li>([^:\n]+):/g, '<li><span class="li-title">$1</span>:');

        // Add class "list" to the ul
        formattedHighlights = formattedHighlights.replace(/<ul>/g, '<ul class="list">');

        return formattedHighlights;
    }

    splitWord(title: string): { firstPart: string; lastPart: string } {
        const words = title.trim().split(' ');
        const lastWord = words.pop() || '';
        const firstPart = words.join(' ');
        return { firstPart, lastPart: lastWord };
    }
}
