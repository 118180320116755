import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { AuthLoginRegisterComponent } from './auth-login-register/auth-login-register.component';
import { AuthResetPasswordComponent } from './auth-reset-password/auth-reset-password.component';
import { AuthRegisterComponent } from './auth-register/auth-register.component';
import { AuthLoginComponent } from './auth-login/auth-login.component';
import { AuthDialogComponent } from './auth-dialog/auth-dialog.component';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [AuthLoginRegisterComponent, AuthResetPasswordComponent, AuthRegisterComponent, AuthLoginComponent, AuthDialogComponent],
    imports: [CommonModule, FormsModule, MatIconModule, MatDialogModule, MatProgressSpinnerModule, ReactiveFormsModule, RouterModule],
    exports: [AuthLoginRegisterComponent, AuthResetPasswordComponent, AuthRegisterComponent, AuthLoginComponent, AuthDialogComponent, CommonModule],
})
export class AuthModule {}
