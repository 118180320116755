<div #productImageDialog class="dialog" [class.narrow]="data?.isNarrow">
  <button id="product-image-dialog-close" class="button pt-1 pr-1" [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
  <div *ngIf="!data?.isBrowser">
    <img *ngFor="let image of data?.images" [src]="getWebpImg(image?.imageUrl)" alt="product image" />
  </div>
  <mod-swiper
    *ngIf="data?.images.length > 0"
    class="bottom-swiper-nav"
    containerClass="zoom-preview-container"
    [slidesPerView]="1"
    navType="bottom"
    [initialSlide]="initialSlide"
  >
    <img
      *ngFor="let image of data?.images"
      class="swiper-slide zoom-preview-image"
      [src]="getWebpImg(image.imageUrl)"
    />
  </mod-swiper>
</div>
