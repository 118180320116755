import { Inject, Injectable } from '@angular/core';

import { ExxComError } from 'lib/classes/exxcom-error.class';
import { isBrowser } from 'lib/tools';
import { RouterService } from 'lib/services/router.service';
import { waitFor } from 'lib/tools';

const scriptName = 'marketo.service';

declare let MktoForms2: any; // https://developers.marketo.com/javascript-api/forms/api-reference/
declare let Munchkin: any; // https://developers.marketo.com/javascript-api/lead-tracking/
declare let SimpleDTO: any; // Clicky
declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window {
        clicky_site_ids: any;
        clicky_custom: any;
    }
}

let config: any;
let environment: any;
let routerService: any;

@Injectable()
export class MarketoService {
    constructor(@Inject('environment') e: any, r: RouterService) {
        try {
            config = e.marketo;
            environment = e;
            routerService = r;
        } catch (err) {
            console.error(...new ExxComError(420394, scriptName, err).stamp());
        }
    }

    async init(): Promise<void> {
        try {
            if (!isBrowser() || !(await waitFor(() => !!MktoForms2 && !!Munchkin && !!SimpleDTO, '3s'))) {
                return;
            }
            MktoForms2.setOptions({
                formXDPath: `/rs/${config.munchkin.id}/images/marketo-xdframe-relative.html`,
            });
            Munchkin.init(config.munchkin.id, config.munchkin.settings);
            this.initClicky();
        } catch (err) {
            console.error(...new ExxComError(902837, scriptName, err).stamp());
        }
    }

    async initLoaded() {
        if (document.readyState === 'complete') {
            // The page is fully loaded.
            setTimeout(() => {
                this.loaded();
            }, 200);
        }
    }

    async loaded() {
        const arrayify = getSelection.call.bind([].slice);

        const sources = {
            breadcrumb: document.querySelector("div[id$='-breadcrumb']"),
            meta: document.querySelector('meta[name=collection]') as HTMLMetaElement,
        };

        // console.log('DEBUG: Munchkin supplemental sources', sources);
        const hitBaseURL = '/_supplemental/v1/collection/';
        let hitComponents = [];

        if (sources.breadcrumb) {
            const bcContent = arrayify(sources.breadcrumb.querySelectorAll('a')).map(function (bcLink) {
                return bcLink.textContent.trim();
            });

            hitComponents = hitComponents.concat(bcContent);
        }

        if (sources.meta) {
            hitComponents = hitComponents.concat(sources.meta.content);
        }

        if (hitComponents.length && hitComponents[0] != '') {
            Munchkin.munchkinFunction('visitWebPage', {
                url: hitBaseURL + hitComponents.join(','),
            });
            localStorage.setItem('breadcrumb', hitBaseURL + hitComponents.join(','));
        } else {
            Munchkin.munchkinFunction('visitWebPage', {
                url: await routerService.router.url,
            });
        }
    }

    sendConfigurator(breadcrumb: string) {
        Munchkin.munchkinFunction('visitWebPage', { url: breadcrumb });
    }

    // https://codepen.io/figureone/pen/a9d0621b0ed0a17762341b15cb705d0b?editors=1010
    private initClicky(): void {
        try {
            if (environment.envAbbr != 'prod') {
                return;
            } // Comment for testing

            let clickyExistingVisitor: any = {};
            try {
                clickyExistingVisitor = JSON.parse(sessionStorage.clicky_visitor);
            } catch (e) {
                console.error(...new ExxComError(8265497, scriptName, e).stamp());
            }
            if (clickyExistingVisitor.username) {
                this.clickyInitManual(config.clickyId, clickyExistingVisitor);
            } else {
                const DTO = new SimpleDTO({
                    debug: true,
                    mode: 'receive',
                    transport: 'message',
                    messageSource: 'https://go.exxactcorp.com', // Marketo secure LP domain (use custom domain only if secure)
                    messageTarget: [
                        // Allowed origins
                        'https://cdpn.io',
                        'https://www.exxactcorp.com',
                        'https://exxactcorp.com',
                        'https://blog.exxactcorp.com',
                        'https://www.sabrepc.com',
                        'https://sabrepc.com',
                        'https://exxactcorp.com',
                        'https://go.exxactcorp.com',
                        'https://blog.sabrepc.com',
                        'https://gcp.sabrepc.com',
                        'https://gcp.exxactcorp.com',
                        'https://mars.sabrepc.com',
                        'https://mars.exxactcorp.com',
                        'https://venus.sabrepc.com',
                        'https://venus.exxactcorp.com',
                        'https://jupiter.sabrepc.com',
                        'https://saturn.sabrepc.com',
                        'https://sb-split.sabrepc.com',
                        'https://prod-split.sabrepc.com/',
                        'https://sb.sabrepc.com', // Uncomment for Testing
                        'https://localhost:4200', // Uncomment for testing
                        'http://localhost:4200',
                        'https://localhost:8080',
                        'http://localhost:8080',
                    ],
                    dataSrc: 'https://go.exxactcorp.com/dtp-2.0.0.html', // DTP URL
                    cb: function (instance: any, mktoFields: any) {
                        const clickyNewVisitor = mktoFields['Lead.Id']
                            ? {
                                  username: `Marketo Lead ID ${mktoFields['Lead.Id']}`,
                              }
                            : {};
                        sessionStorage.clicky_visitor = JSON.stringify(clickyNewVisitor);
                        this.clickyInitManual(config.clickyId, clickyNewVisitor);
                        DTO.cleanup();
                    },
                });
            }
        } catch (err) {
            console.error(...new ExxComError(629838, scriptName, err).stamp());
        }
    }

    clickyInitManual(siteId: any, visitor: any) {
        window.clicky_site_ids = window.clicky_site_ids || [];
        window.clicky_site_ids.push(siteId);
        window.clicky_custom = window.clicky_custom || {};
        window.clicky_custom.visitor = visitor;
        const clickyLib = document.createElement('script');
        clickyLib.async = true;
        clickyLib.src = '//static.getclicky.com/js';
        document.head.appendChild(clickyLib);
    }

    /*
  this helper function can be used to retrieve marketo cookie and sync 
  anonymous leads if needed
  */
    getMarketoCookie() {
        if (!isBrowser()) {
            return;
        }
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const name = cookies[i].split('=')[0].toLowerCase();
            const value = cookies[i].split('=')[1].toLowerCase();
            if (name === ' _mkto_trk') {
                return value;
            } else if (value === ' _mkto_trk') {
                return name;
            }
        }
        return '';
    }

    load(formId: number | string): Promise<void> {
        return new Promise((resolve) => {
            try {
                if (!isBrowser()) {
                    return;
                }
                MktoForms2.loadForm(config.forms.baseUrl, config.munchkin.id, formId, (form: any) => resolve(form));
            } catch (err) {
                console.error(...new ExxComError(293884, scriptName, err).stamp());
            }
        });
    }

    /**
     * @function send
     * @param {String} formId
     * @param {Object} values - The form fields and values
     * @description Sends data to Marketo via a hidden form.
     */
    send(formId: string, values: any): Promise<void> {
        return new Promise((resolve) => {
            try {
                if (!isBrowser() || !MktoForms2) {
                    resolve();
                }
                const formElem = document.createElement('form');
                formElem.id = `mktoForm_${formId}`;
                formElem.style.display = 'none';
                document.body.appendChild(formElem);
                MktoForms2.loadForm(config.forms.baseUrl, config.munchkin.id, formId, (form: any) => {
                    form.addHiddenFields(values);
                    form.onSuccess(() => false); // Prevent form submission from refreshing page
                    form.onSubmit(() => {
                        formElem.remove();
                        resolve();
                    });
                    form.submit();
                });
            } catch (err) {
                console.error(...new ExxComError(938371, scriptName, err).stamp());
            }
        });
    }
}
