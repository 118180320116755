import { ActivatedRoute } from '@angular/router';
import { OnInit, Directive } from '@angular/core';

import { CmsBlogComponentClass } from 'lib/components/cms/blog/cms-blog-component.class';
import { CmsService } from 'lib/services/cms.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { InfiniteScrollService } from 'lib/services/infinite-scroll.service';
import { isBrowser, wait } from 'lib/tools';
import { MetaService } from 'lib/services/meta.service';

const scriptName = 'cms-blog-search-component.class';

@Directive()
export class CmsBlogSearchComponentClass extends CmsBlogComponentClass implements OnInit {
    // Dependencies

    activatedRoute: ActivatedRoute;
    cmsService: CmsService;
    infiniteScrollService: InfiniteScrollService;
    metaService: MetaService;

    // Properties: public

    results: any = [];
    type: string = '';
    value: string = '';
    entry: any;
    filteredEntries: any;
    blogCategories: any;

    constructor({ dependencies }) {
        super({ dependencies });
        this.type = 'search';
    }

    async ngOnInit() {
        try {
            this.initMetaData();
            this.entry = await this.cmsService.getEntries(`${this.environment.siteAbbr}_blog_homepage`, {
                references: ['reference'],
            });
            this.blogCategories = this.entry[0]?.reference;

            this.value = this.activatedRoute.snapshot.paramMap.get('value');
            this.type = this.activatedRoute.snapshot.paramMap.get('type');
            await wait('100ms');
            if (this.environment.siteAbbr == 'spc') {
                this.infiniteScroll();
            }
            if (this.type == 'tag') {
                this.metaService.add({
                    title: `Blog Tag - ${this.value} | ${this.environment.siteName}`,
                });
            }
            if (isBrowser()) {
                document.getElementById('infinite-scroll-global-container').scrollTo(0, 0);
            }
        } catch (err) {
            console.error(...new ExxComError(445387, scriptName, err).stamp());
        }
    }

    ngOnDestroy() {
        try {
            this.infiniteScrollService.unregister('postsSearchResults');
        } catch (err) {
            console.error(...new ExxComError(843094, scriptName, err).stamp());
        }
    }

    private infiniteScroll() {
        try {
            if (!isBrowser()) {
                return;
            }
            const limit = 10;
            const retriever = async (skip: number) => {
                const addition =
                    this.type == 'input'
                        ? await this.cmsService.searchBlogPostsByTitle(this.value, limit, skip)
                        : await this.cmsService.searchBlogPostsByTag(this.value, limit, skip);
                this.results = this.results.concat(addition);
                this.isInitialized = true;
            };

            const el = document.body;
            this.infiniteScrollService.register({
                container: el,
                key: 'postsSearchResults',
                containerHeight: '99vh',
                limit: limit,
                retriever: retriever,
            });
        } catch (err) {
            console.error(...new ExxComError(530498, scriptName, err).stamp());
        }
    }
}
