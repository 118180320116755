import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-category-no-results',
    templateUrl: './category-no-results.component.html',
    styleUrls: ['./category-no-results.component.scss'],
})
export class CategoryNoResultsComponent implements OnInit {
    @Input() category: any = {};

    constructor() {}

    ngOnInit() {}
}
