<ng-container *ngIf="!isEmpty(get(softwareStackRow, 'software_stack', ''))">
  <div *ngFor="let row of get(softwareStackRow, 'software_stack.software_stack', []); let i = index">
    <section
      id="rows"
      *ngIf="!isEmpty(get(row, 'software_stack', ''))"
      class="d-flex flex-wrap flex-row justify-content-center container-gridless software-stacks"
    >
      <div class="category-top-title d-flex flex-row justify-content-center pb-2">
        <h2 class="col-md-7 text-center">
          {{ get(row, "software_stack_title", "") }}
        </h2>
      </div>
      <div *ngFor="let stack of get(row, 'software_stack', [])" class="col-xs-6 col-sm-6 col-md-4 col-lg-3 mb-3">
        <div id="group-card-image" class="d-flex flex-column justify-content-center card-1 text-center">
          <a
            [routerLink]="get(stack, 'link.routerLink', '')"
            [queryParams]="get(stack, 'link.queryParams', '')"
            [class.inactive-link]="!stack?.link?.routerLink"
          >
            <img
              *ngIf="get(stack, 'software_logo.href', '')"
              [src]="get(stack, 'software_logo.href')"
              [alt]="get(stack, 'software_logo.title', '')"
            />
          </a>
        </div>
      </div>
    </section>
  </div>
</ng-container>
<hr *ngIf="get(softwareStackRow, 'software_stack.split_line', '')" class="m-0" />
