<div id="auth-register" class="auth-container">
  <div *ngIf="!dialogRef && showOptions" id="auth-register-options" [class]="formClass">
    <h3>New Customer</h3>

    <div class="auth-register-option fx-col">
      <span class="auth-register-option-message"> No Account? No Problem, you can checkout as a guest. </span>
      <button class="button-primary button-base" (click)="selectRegistration('guest')">Checkout as a Guest</button>
    </div>

    <div class="auth-register-option fx-col">
      <span class="auth-register-option-message">
        Create an account and take advantage of faster checkouts and other great benefits.
      </span>
      <button class="button-primary button-base" (click)="selectRegistration('customer')">Create Account</button>
    </div>
  </div>

  <!-- NON-GUEST REGISTRATION -->

  <form
    *ngIf="!dialogRef && !showOptions && registrationType == 'customer'"
    [class]="formClass"
    [formGroup]="customerForm"
    (ngSubmit)="onSubmit('customer')"
  >
    <mat-icon *ngIf="authType == 'checkout'" class="auth-register-back" (click)="showOptions = true">
      chevron_left
    </mat-icon>

    <h3>New Customer</h3>

    <div class="form-field">
      <label *ngIf="showLabels" for="auth-register-cFirst" class="form-label">
        First Name <span class="required-asterisk">*</span>
      </label>
      <input
        type="text"
        formControlName="cFirst"
        #cFirstInput
        id="auth-register-cFirst"
        [placeholder]="!showLabels ? 'First' : ''"
        [class.error]="hasError(cFirst)"
        (keyup.enter)="cFirstInput.blur()"
      />
      <div class="form-error" [class.show]="hasError(cFirst)">
        <div class="form-error" [class.show]="hasErrorRequired(cFirst)">First name is required.</div>
      </div>
    </div>

    <div class="form-field">
      <label *ngIf="showLabels" for="auth-register-cLast" class="form-label">
        Last Name <span class="required-asterisk">*</span>
      </label>
      <input
        type="text"
        formControlName="cLast"
        #cLastInput
        id="auth-register-cLast"
        [placeholder]="!showLabels ? 'Last' : ''"
        [class.error]="hasError(cLast)"
        (keyup.enter)="cLastInput.blur()"
      />
      <div class="form-error" [class.show]="hasError(cLast)">
        <div class="form-error" [class.show]="hasErrorRequired(cLast)">Last name is required.</div>
      </div>
    </div>

    <div class="form-field">
      <label *ngIf="showLabels" for="auth-register-cCompany" class="form-label">
        Company <span class="optional">(Optional)</span>
      </label>
      <input
        type="text"
        formControlName="cCompany"
        id="auth-register-cCompany"
        [placeholder]="!showLabels ? 'Company' : ''"
      />
    </div>

    <div class="form-field">
      <label *ngIf="showLabels" for="auth-register-cEmail" class="form-label">
        Email <span class="required-asterisk">*</span>
      </label>
      <input
        type="text"
        formControlName="cEmail"
        #cEmailInput
        id="auth-register-cEmail"
        [placeholder]="!showLabels ? 'Email' : ''"
        [class.error]="hasError(cEmail)"
        (keyup.enter)="cEmailInput.blur()"
      />
      <div class="form-error" [class.show]="hasError(cEmail)">
        <div class="form-error" [class.show]="hasErrorRequired(cEmail)">Email address is required.</div>
        <div class="form-error" [class.show]="hasErrorInvalid(cEmail)">Email address is not valid.</div>
        <div class="form-error" [class.show]="hasErrorEmailTopLevelDomainForNetSuite(cEmail)">
          Last part of email cannot contain numbers.
        </div>
      </div>
    </div>

    <div class="form-field">
      <label *ngIf="showLabels" for="auth-register-cPassword" class="form-label">
        Password <span class="required-asterisk">*</span>
      </label>
      <input
        type="password"
        formControlName="cPassword"
        #cPasswordInput
        id="auth-register-cPassword"
        [placeholder]="!showLabels ? 'Password' : ''"
        [class.error]="hasError(cPassword)"
      />
      <div class="form-error" [class.show]="hasError(cPassword)">
        <div class="form-error" [class.show]="hasErrorRequired(cPassword)">Password is required.</div>
      </div>
    </div>

    <div class="form-field">
      <label *ngIf="showLabels" for="auth-register-cRetypePassword" class="form-label">
        Retype Password <span class="required-asterisk">*</span>
      </label>
      <input
        type="password"
        formControlName="cRetypePassword"
        #cRetypePasswordInput
        id="auth-register-cRetypePassword"
        [placeholder]="!showLabels ? 'Retype Password' : ''"
        [class.error]="hasError(cRetypePassword)"
      />
      <div class="form-error" [class.show]="hasError(cRetypePassword)">
        <div class="form-error" [class.show]="hasErrorRequired(cRetypePassword)">Please retype your password.</div>
        <div class="form-error" [class.show]="hasErrorMatch(cRetypePassword)">Passwords do not match.</div>
      </div>
    </div>

    <span class="form-messages">{{ messageService.locals.register }}</span>

    <div
      *ngIf="showV2Captcha"
      nbRecaptcha
      key="6Ldu_sQaAAAAAMfA5w7xAU8KJ2bWkXQBjrYlRX5_"
      formControlName="captcha"
    ></div>

    <button class="button-primary button-base">
      <mat-spinner *ngIf="actionPending" diameter="18" class="white"></mat-spinner>
      <span *ngIf="!actionPending">Register</span>
    </button>

    <div class="grecaptcha-message mt-3">
      This site is protected by reCAPTCHA and the Google
      <a href="https://policies.google.com/privacy">Privacy Policy</a> and
      <a href="https://policies.google.com/terms">Terms of Service</a> apply.
    </div>
  </form>

  <!-- GUEST REGISTRATION -->

  <form
    *ngIf="(dialogRef || !showOptions) && registrationType == 'guest'"
    [class]="formClass"
    [formGroup]="guestForm"
    (ngSubmit)="onSubmit('guest')"
  >
    <mat-icon *ngIf="!dialogRef && authType == 'checkout'" class="auth-register-back" (click)="showOptions = true">
      chevron_left
    </mat-icon>

    <div *ngIf="showFormHeading">
      <h3>New Customer</h3>
      <h4>Checkout as a guest and you will have an opportunity to create an account when you are finished.</h4>
    </div>

    <div class="form-field">
      <label *ngIf="showLabels" for="auth-register-gFirst" class="form-label">
        First Name <span *ngIf="!dialogRef" class="required-asterisk">*</span>
      </label>
      <input
        type="text"
        formControlName="gFirst"
        #gFirstInput
        tabindex="1"
        id="auth-register-gFirst"
        data-cy="spc-guest-checkout-first-name"
        [placeholder]="!showLabels ? 'First' : ''"
        [class.error]="hasError(gFirst)"
        (keydown.enter)="gFirstInput.blur(); gLastInput.focus()"
      />
      <div class="form-error" [class.show]="hasError(gFirst)">
        <div class="form-error" [class.show]="hasErrorRequired(gFirst)">First name is required.</div>
      </div>
    </div>

    <div class="form-field">
      <label *ngIf="showLabels" for="auth-register-gLast" class="form-label">
        Last Name <span *ngIf="!dialogRef" class="required-asterisk">*</span>
      </label>
      <input
        type="text"
        formControlName="gLast"
        #gLastInput
        tabindex="2"
        id="auth-register-gLast"
        data-cy="spc-guest-checkout-last-name"
        [placeholder]="!showLabels ? 'Last' : ''"
        [class.error]="hasError(gLast)"
        (keydown.enter)="gLastInput.blur(); gEmailInput.focus()"
      />
      <div class="form-error" [class.show]="hasError(gLast)">
        <div class="form-error" [class.show]="hasErrorRequired(gLast)">Last name is required.</div>
      </div>
    </div>

    <div class="form-field">
      <label *ngIf="showLabels" for="auth-register-gEmail" class="form-label">
        Email <span *ngIf="!dialogRef" class="required-asterisk">*</span>
        <span *ngIf="!dialogRef" id="auth-register-email-message"> (To contact you about your order)</span>
      </label>
      <input
        type="text"
        formControlName="gEmail"
        #gEmailInput
        tabindex="3"
        id="auth-register-gEmail"
        data-cy="spc-guest-checkout-email"
        [placeholder]="!showLabels ? 'Email' : ''"
        [class.error]="hasError(gEmail)"
        (keydown.enter)="gEmailInput.blur()"
      />
      <div class="form-error fx-col fx-col-v-center" [class.show]="hasError(gEmail)">
        <div class="form-error" [class.show]="hasErrorRequired(gEmail)">Email address is required.</div>
        <div class="form-error" [class.show]="hasErrorInvalid(gEmail)">Email address is not valid.</div>
        <div class="form-error" [class.show]="hasErrorEmailTopLevelDomainForNetSuite(gEmail)">
          Last part of email cannot contain numbers.
        </div>
      </div>
    </div>

    <span class="form-messages">{{ messageService.locals.register }}</span>

    <button type="submit" *ngIf="!dialogRef" class="button-primary button-base">Proceed to Checkout</button>
    <button
      type="submit"
      *ngIf="dialogRef"
      id="auth-register-guest-4"
      data-cy="checkout-guest-button"
      class="button-primary button-base"
      tabindex="4"
      [disabled]="guestForm.invalid"
    >
      <mat-spinner *ngIf="actionPending" diameter="18" class="white"></mat-spinner>
      <span *ngIf="!actionPending">Checkout</span>
    </button>

    <div
      *ngIf="showV2Captcha"
      nbRecaptcha
      key="6Ldu_sQaAAAAAMfA5w7xAU8KJ2bWkXQBjrYlRX5_"
      formControlName="captcha"
    ></div>

    <div *ngIf="!dialogRef" id="auth-register-guest-create-account-button" class="auth-register-option fx-col">
      <span class="auth-register-option-message">
        Create an account and take advantage of faster checkouts and other great benefits.
      </span>
      <button class="button-primary button-base" (click)="showOptions = false; registrationType = 'customer'">
        Create Account
      </button>
    </div>

    <div *ngIf="!dialogRef" class="grecaptcha-message mt-3">
      This site is protected by reCAPTCHA and the Google
      <a href="https://policies.google.com/privacy">Privacy Policy</a> and
      <a href="https://policies.google.com/terms">Terms of Service</a> apply.
    </div>
  </form>
</div>
