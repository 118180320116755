<picture *ngIf="data?.src">
  <source *ngIf="data?.webp" [srcset]="data?.webp" type="image/webp" />
  <img [id]="data?.id" [class]="data?.classes" [src]="data.src" [alt]="data?.alt" />
</picture>

<section
  [style.visible]="data?.bgSrc"
  [ngStyle]="data?.bgSrc ? { 'background-image': 'url(' + data.bgSrc + ')' } : null"
  [class]="bgClass"
  #bgSection
></section>
