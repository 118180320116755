import { Component, Input, ViewEncapsulation } from '@angular/core';
import { WebpService } from 'lib/services/webp.service';

@Component({
    selector: 'product-recommended',
    templateUrl: './product-recommended.component.html',
    styleUrls: ['./product-recommended.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ProductRecommendedComponent {
    @Input() imageUrl: string = undefined;
    @Input() heading: string = '';
    @Input() linkUrl: string = '';
    getWebpImg: (src: string) => string;

    constructor(webpService: WebpService) {
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
    }
}
