<div
    *ngIf="!isCategoryPage()"
    id="cms-blog-footer"
    class="fx-row-container fx-row-h-left fx-wrap pl-2 pr-2"
>
    <hr class="divider" />
    <p>
        Copyright © {{ currentYear }} SabrePC Inc. All rights reserved.
        <span class="vertical-bar mx-1">|</span>
        <a routerLink="/privacy" class="link-gray-inverted">Privacy</a>
        <span class="vertical-bar mx-1">|</span>
        <a routerLink="/cookies" class="link-gray-inverted">Cookies</a>
        <span class="vertical-bar mx-1">|</span>
        <a
            class="link-gray-inverted"
            href="#"
            onclick="window.displayPreferenceModal();return false;"
            id="termly-consent-preferences"
            >Consent Preferences</a
        >
    </p>
</div>
