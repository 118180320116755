import { AccountWishlist } from './account-wishlist.class';
import { ApiService } from 'lib/services/api.service';
import { AuthService } from 'lib/services/auth.service';
import { calcSubtotal } from 'lib/services/cart/cart-summary.class';
import { countriesByCode } from 'lib/lists/countries';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { isEmpty, omit, startCase } from 'lodash';
import { WebstoreProduct } from 'lib/services/webstore-product/webstore-product.class';
import { Subject } from 'rxjs';

const scriptName = 'account.class';

let apiService: ApiService;
let authService: AuthService;
let environment: any;

export class Account {
    _id: string = null;
    addresses: any = { all: [], other: [], billing: {}, shipping: {} };
    cards: any[] = [];
    cart: string = null;
    changed$: Subject<any> = new Subject<any>();
    company: string = null;
    email: string = null;
    first: string = null;
    hasAddresses: boolean = false;
    last: string = null;
    nsid: string = null;
    orders: any[] = [];
    phone: string = null;
    registrationType: string = null;
    status: string = null;
    stripeId: string = null;
    updated: string[] = [];
    webstore: string = null;
    wishlist: AccountWishlist = null;

    constructor(dependencies: any) {
        try {
            apiService = dependencies.apiService;
            authService = dependencies.authService;
            environment = dependencies.environment;
            this.webstore = environment.siteAbbr;
        } catch (err) {
            console.error(...new ExxComError(792993, scriptName, err).stamp());
        }
    }

    async get() {
        // Only if logged in
        try {
            if (!this.email) {
                throw new Error('Email is required to retrieve account data');
            }
            const url = `customers/find/${this.webstore}/${this.email}?populate=addresses,wishlist.product,cards`;
            const res = await apiService.get(url);
            if (!res) {
                throw new Error('No response when retrieving customer account');
            } else if (res.statusText == 'Unauthorized') {
                authService.logout();
                this.changed$.next(void 0);
                return;
            } else if (!res.success) {
                throw res;
            } else if (isEmpty(res.data)) {
                throw new Error('Customer not found');
            }
            await this.init(res);
        } catch (err) {
            console.error(...new ExxComError(668827, scriptName, err).stamp());
        }
    }

    private async init(res: any) {
        try {
            Object.assign(this, omit(res.data, ['addresses', 'cart', 'orders', 'wishlist']));
            this.getAddresses(res.data.addresses);
            this.cart = res.data.cart || null;
            this.orders = await this.getOrders(res.data.orders);
            this.wishlist = new AccountWishlist({ environment, apiService });
            this.wishlist.init(this.webstore, this.email, this._id, res.data.wishlist);
            this.changed$.next(void 0);
        } catch (err) {
            console.error(...new ExxComError(401962, scriptName, err).stamp());
        }
    }

    private getAddresses(addresses: any) {
        try {
            if (!addresses) {
                return;
            }
            addresses.forEach((address: any) => (address.country = countriesByCode[address.country].name));
            this.addresses.all = addresses;
            this.addresses.billing = this.getAddress('billing') || {};
            this.addresses.shipping = this.getAddress('shipping') || {};
            this.addresses.other = this.getAddress('other') || [];
            this.hasAddresses = this.addresses.all.length > 0;
        } catch (err) {
            console.error(...new ExxComError(987353, scriptName, err).stamp());
        }
    }

    private getAddress(type: string) {
        try {
            if (type == 'other') {
                const addresses = this.addresses.all.filter((a: any) => !a.isDefaultBilling && !a.isDefaultShipping);
                addresses.forEach((address: any) => {
                    delete address.__v;
                    if (!address.updated) {
                        address.updated = [];
                    }
                });
            } else {
                const address = this.addresses.all.find((a: any) => a[`isDefault${startCase(type)}`]) || {};
                if (!isEmpty(address) && !address.updated) {
                    address.updated = [];
                }
                delete address.__v;
                return address;
            }
        } catch (err) {
            console.error(...new ExxComError(297594, scriptName, err).stamp());
        }
    }

    private async getOrders(orderIds: any) {
        try {
            if (isEmpty(orderIds)) {
                return;
            }
            const url = `orders/find?ids=${orderIds}&populate=lines.product`;
            const res: any = await apiService.get(url);
            if (!res.success && res.statusText != 'Unauthorized') {
                throw res;
            } // Suppress 401
            const orders =
                res.data.map((order: any) => {
                    order.lines = order.lines.reduce((lines: any[], line: any) => {
                        if (!line.product) {
                            return lines;
                        }
                        line.product = new WebstoreProduct(line.product, {
                            environment: environment,
                        });
                        lines.push(line);
                        return lines;
                    }, []);
                    order.subtotal = calcSubtotal(order.lines);
                    return order;
                }) || [];
            return orders;
        } catch (err) {
            console.error(...new ExxComError(602989, scriptName, err).stamp());
        }
    }
}
