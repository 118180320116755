import { Inject, Injectable } from '@angular/core';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { CmsService } from './cms.service';

const scriptName = 'blog-count.service';

let apiService: ApiService;
let cmsService: CmsService;
let environment;

@Injectable()
export class BlogCountService {
    public blogCategoryArray = [];
    private blogInfo = new BehaviorSubject([]);
    blogInfoSource = this.blogInfo.asObservable();

    constructor(@Inject('environment') e: any, a: ApiService, c: CmsService) {
        try {
            apiService = a;
            cmsService = c;
            environment = e;
        } catch (err) {
            console.error(...new ExxComError(937763, scriptName, err).stamp());
        }

        this.getBlogCountInformation();
    }

    async getBlogCountInformation() {
        try {
            const promises = [];
            const categories: any = await cmsService.getBlogCategories(`${environment.siteAbbr}_blog_category`);
            categories.splice(
                categories.findIndex((item) => item.title === 'Recent'),
                1
            );
            for (const category of categories) {
                promises.push(this.getBlogCountByCategory(category?.title));
            }
            const blogCategoryCounts = await Promise.all(promises);
            let total: number = 0;

            for (const category of blogCategoryCounts) {
                const categoryItem = category?.data;

                total += categoryItem[Object.keys(categoryItem)[0]];

                this.blogCategoryArray.push(categoryItem);
            }
            this.blogCategoryArray.push({ Recent: total });

            this.blogInfo.next(this.blogCategoryArray);
        } catch (err) {
            console.error(...new ExxComError(211763, scriptName, err).stamp());
        }
    }

    async getBlogCountByCategory(blogCategory: string) {
        return apiService.post('blog/entry/search', { category: blogCategory });
    }
}
