import { ExxComError } from 'lib/classes/exxcom-error.class';
import { reduce } from 'lodash';

const scriptName = 'cart-summary.class';

export function calcSubtotal(items: any) {
    try {
        return reduce(items, (sum: number, i: any) => sum + i.rate * i.quantity, 0);
    } catch (err) {
        console.error(...new ExxComError(535499, scriptName, err).stamp());
    }
}

export class CartSummary {
    subtotal: number;
    shipping: number;
    tax: number;
    total: number;

    constructor(subtotal?: number, shipping?: number, tax?: number, total?: number) {
        try {
            this.subtotal = subtotal || 0;
            this.shipping = shipping || 0;
            this.tax = tax || 0;
            this.total = total || 0;
        } catch (err) {
            console.error(...new ExxComError(729854, scriptName, err).stamp());
        }
    }

    update(items?: any[], shipping?: number, tax?: number) {
        try {
            this.shipping = shipping === 0 ? 0 : shipping || this.shipping;
            this.tax = tax || this.tax;
            this.subtotal = items ? calcSubtotal(items) : this.subtotal;
            this.total = this.subtotal + this.shipping + this.tax;
        } catch (err) {
            console.error(...new ExxComError(290493, scriptName, err).stamp());
        }
    }
}
