import { MatDialog } from '@angular/material/dialog';

import { CmsService } from 'lib/services/cms.service';
import { isBrowser, copyText } from 'lib/tools';
import { ExxComComponentClass } from 'lib/components/exxcom-component.class';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { MetaService } from 'lib/services/meta.service';
import { RouterService } from 'lib/services/router.service';
import { BlogCountService } from 'lib/services/blog-count.service';

const scriptName = 'cms-blog-component.class';

export class CmsBlogComponentClass extends ExxComComponentClass {
    // Dependencies
    blogCountService: BlogCountService;
    cmsService: CmsService;
    dialog: MatDialog;
    environment: any;
    metaService: MetaService;
    routerService: RouterService;

    // Properties: public

    entries: any = [];
    entry: any = {};
    isInitialized: boolean = false;
    type: string = ''; // Options: homepage, category, post, search

    constructor({ dependencies }: { dependencies?: any } = {}) {
        super({ dependencies });
    }

    initMetaData(data?: any) {
        try {
            const metas: any = {};
            if (this.type == 'post') {
                this.metaService.add(data);
                return;
            } else if (this.type == 'homepage') {
                metas.title = `Blog | ${this.environment.siteName}`;
            } else if (this.type == 'category') {
                metas.title = `Blog - ${data} | ${this.environment.siteName}`;
            } else if (this.type == 'search') {
                metas.title = `Blog - Search | ${this.environment.siteName}`;
            }
            this.metaService.add({ title: metas.title });
        } catch (err) {
            console.error(...new ExxComError(205882, scriptName, err).stamp());
        }
    }

    abbreviate(entry: any) {
        try {
            if (!entry || !entry.article) {
                return '';
            }
            const s = entry.article.replace(/<[^>]*>/g, '');
            return `${s.substring(0, 200)} ...`;
        } catch (err) {
            console.error(...new ExxComError(529984, scriptName, err).stamp());
            return '';
        }
    }

    reformatDate(entry: any) {
        try {
            if (!entry || !entry.date) {
                return '';
            }
            return new Date(entry.date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            });
        } catch (err) {
            console.error(...new ExxComError(543675, scriptName, err).stamp());
        }
    }

    isCategoryPage() {
        try {
            return this.routerService.url.parts.length == 2;
        } catch (err) {
            console.error(...new ExxComError(534098, scriptName, err).stamp());
        }
    }

    getCategoryName(url: string) {
        try {
            if (!url || typeof url != 'string') {
                return '';
            }
            const urlParts = url.split('/');
            // eslint-disable-next-line no-useless-escape
            return urlParts[2] && urlParts[2].replace(/\-/g, ' ');
        } catch (err) {
            console.error(...new ExxComError(893911, scriptName, err).stamp());
        }
    }

    copyLink(event: any) {
        isBrowser() && copyText(event, window.location.href);
    }
}
