import { Injectable } from '@angular/core';

import { ExxComError } from 'lib/classes/exxcom-error.class';
import { wait } from 'lib/tools';

const scriptName = 'infinite-scroll.service';

@Injectable()
export class InfiniteScrollService {
    private container: HTMLElement;
    private scrollers: any = {};

    constructor() {}

    /**
     * @function init
     * @param {HTMLElement} container Required. An HTML element.
     * @param {number} containerHeight Required. Height of the container.
     * @param {number} limit Required. The number of items to scroll before retrieving more data.
     * @param {Function} retriever Required. The function that is called to retrieve the next set of items.
     * @description Enables "infinite" scroll within a container element. The given
     * container is an Angular ViewChild element. The container's height must be
     * less than the total height of the items it contains.
     */
    init({
        container,
        containerHeight,
        limit,
        retriever,
    }: {
        container: HTMLElement;
        containerHeight: number | string;
        limit: number;
        retriever: any;
    }) {
        try {
            // https://codepen.io/wernight/details/YyvNoW
            if (!container) {
                return;
            }
            container.style.height = typeof containerHeight == 'number' ? containerHeight + 'px' : containerHeight;
            container.style.overflow = 'scroll';
            document.documentElement.style.setProperty('overflow', 'hidden');
            let skip = 0;
            const listener = () => {
                if (container.scrollTop + container.clientHeight >= container.scrollHeight - 5) {
                    skip += limit;
                    retriever(skip);
                }
            };
            container.addEventListener('scroll', listener);
            retriever(skip);
            return {
                removeListener: () => container.removeEventListener('scroll', listener),
            };
        } catch (err) {
            console.error(...new ExxComError(520938, scriptName, err).stamp());
        }
    }

    initGlobalContainer(container: HTMLElement) {
        try {
            this.container = container;
        } catch (err) {
            console.error(...new ExxComError(620938, scriptName, err).stamp());
        }
    }

    async register({
        key,
        container,
        containerHeight,
        limit,
        retriever,
    }: {
        key: string;
        container?: HTMLElement;
        containerHeight: number | string;
        limit: number;
        retriever: any;
    }) {
        try {
            await wait('100ms');
            this.scrollers[key] = this.init({
                container: container || this.container,
                containerHeight: containerHeight,
                limit: limit,
                retriever: retriever,
            });
        } catch (err) {
            console.error(...new ExxComError(509332, scriptName, err).stamp());
        }
    }

    unregister(key: string) {
        try {
            this.scrollers[key] && this.scrollers[key].removeListener && this.scrollers[key].removeListener();
        } catch (err) {
            console.error(...new ExxComError(830894, scriptName, err).stamp());
        }
    }
}
