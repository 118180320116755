import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { get } from 'lodash';

@Component({
    selector: 'app-product-spec-dialog',
    templateUrl: './product-spec-dialog.component.html',
    styleUrls: ['./product-spec-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ProductSpecDialogComponent {
    selectedTable: number = 0;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

    get(ob: any, args: any, defaultValue?: any) {
        return get(ob, args, defaultValue);
    }
}
