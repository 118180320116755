import { SimpleChanges } from '@angular/core';

import { Address } from 'lib/services/address/address.class';
import { AddressData } from 'lib/services/address/address.interface';
import { BehaviorSubject } from 'rxjs';
import { CustomerData } from 'lib/services/customer/customer.interface';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { Order } from 'lib/services/order/order.class';
import { OrderData } from 'lib/services/order/order.interface';

const scriptName = 'customer.class';

export class Customer {
    // Schema

    _id: string = null;
    addresses: string[] | AddressData[] | Address[] = [];
    company: string = null;
    createdAt: string | Date = null;
    email: string = null;
    first: string = null;
    last: string = null;
    name: string = null;
    nsid: string = null;
    orders: string[] | OrderData | Order[] = [];
    partition: string = null;
    paymentTerms: string = null;
    phone: string = null;
    registrationType: string = null;
    salesRep: string = null;
    status: string = null;
    stripeId: string = null;
    updated: string[] = [];
    updatedAt: string | Date = null;
    webstore: string = null;

    // Client-side only

    changed$: BehaviorSubject<SimpleChanges> = new BehaviorSubject<SimpleChanges>(null);

    constructor(data?: CustomerData) {
        if (data) {
            this.init(data);
        }
    }

    init(data: CustomerData) {
        try {
            const changes: SimpleChanges = {};

            this._id = data._id || this._id || null;
            this.addresses = data.addresses || this.addresses || [];
            this.company = data.company || this.company || null;
            this.createdAt = data.createdAt ? new Date(data.createdAt) : this.createdAt || null;
            this.email = data.email || this.email || null;
            this.first = data.first || this.first || null;
            this.last = data.last || this.last || null;
            this.name = data.name || this.name || null;
            this.nsid = data.nsid || this.nsid || null;
            this.orders = data.orders || this.orders || [];
            this.partition = data.partition || this.partition || null;
            this.paymentTerms = data.paymentTerms || this.paymentTerms || null;
            this.phone = data.phone || this.phone || null;
            this.registrationType = data.registrationType || this.registrationType || null;
            this.salesRep = data.salesRep || this.salesRep || null;
            this.status = data.status || this.status || null;
            this.stripeId = data.stripeId || this.stripeId || null;
            this.updated = data.updated || this.updated || [];
            this.updatedAt = data.updatedAt ? new Date(data.updatedAt) : this.updatedAt || null;
            this.webstore = data.webstore || this.webstore || null;

            this.changed$.next(changes);
        } catch (err) {
            console.error(...new ExxComError(240592, scriptName, err).stamp());
        }
    }
}
