<div id="cms-b2b-services">
  <!-- Hero Banner Background -->
  <section
    class="fx-wrap fx-row-v-center hero-banner-background"
    [ngStyle]="{ 'background-image': 'url(' + entry?.hero_banner_background_image?.href + ')' }"
  >
    <div class="fx-row-container-reverse-sm fx-wrap fx-row-v-center cms-hero-bg-text-section">
      <div class="col-sm-6 hero-banner-background-copy">
        <h1>{{ entry?.hero_banner_title }}</h1>
        <p>{{ entry?.hero_banner_copy }}</p>
      </div>
    </div>
  </section>

  <section class="fx-wrap fx-row-container row-1">
    <h2 class="col-xs-12 text-center pb-2">{{ entry?.row_1_title }}</h2>
    <div *ngFor="let group of entry?.row_1" class="col-sm-6 col-md-4 card-container">
      <div class="fx-row fx-row-h-center fx-row-v-center card-symbol">
        <img *ngIf="group?.card_image?.href" [src]="getWebpImg(group.card_image.href)" [alt]="group.card_image.title" />
      </div>
      <div class="card-1 text-center">
        <h3 class="mt-4">{{ group?.card_title }}</h3>
        <p>{{ group?.card_copy }}</p>
      </div>
    </div>
  </section>

  <section class="row-2 gray-bg">
    <div class="fx-wrap fx-row-container">
      <h2 class="col-xs-12 pb-2">{{ entry?.row_2_title }}</h2>
      <div [ngClass]="!isNarrow ? 'fx-row fx-wrap' : 'fx-col'">
        <div *ngFor="let group of entry?.row_2" class="fx-col col-md-4 col-xs-12">
          <img
            *ngIf="group?.card_image?.href"
            [src]="getWebpImg(group.card_image.href)"
            [alt]="group.card_image.title"
          />
          <h4>{{ group?.card_title }}</h4>
          <p>{{ group?.card_copy }}</p>
        </div>
      </div>
    </div>
  </section>

  <section class="fx-wrap fx-wrap fx-row-container row-3">
    <div class="col-md-6 text-center">
      <img
        *ngIf="entry?.row_3_image?.href"
        [src]="getWebpImg(entry.row_3_image.href)"
        [alt]="entry.row_3_image.title"
      />
    </div>
    <div class="col-md-6 fx-col fx-col-v-center">
      <h2 class="pb-2">{{ entry?.row_3_title }}</h2>
      <p [innerHTML]="entry?.row_3_copy"></p>
    </div>
  </section>

  <section class="fx-wrap fx-row-container row-4" data-cy="b2b-services-row-4">
    <h2 class="col-xs-12 text-center pb-2">{{ entry?.partners_title }}</h2>
    <div class="logo-icon-facets">
      <div class="brand-container">
        <a *ngFor="let partner of entry?.partners_row">
          <img
            *ngIf="partner?.partners_logo?.href"
            [src]="getWebpImg(partner.partners_logo.href)"
            [alt]="partner.partners_logo.title"
            class="px-2"
          />
        </a>
      </div>
    </div>
  </section>

  <section class="fx-wrap fx-row-container row-5" data-cy="b2b-services-row-5">
    <h2 class="col-xs-12 text-center pb-2">{{ entry?.row_5_titile }}</h2>
    <p class="col-xs-12 text-center">{{ entry?.row_5_copy }}</p>
    <div class="col-xs-12 text-center">
      <a
        class="button-primary button-base"
        (click)="bootstrapMarketoService.openBootstrapMarketoDialog(formId, 'edit')"
        data-cy="spc-b2b-services-form-button"
        >{{ entry?.row_5_button?.title }}</a
      >
    </div>
  </section>
</div>
