// Parse from: http://api.geonames.org/countryInfoJSON?username=exxactdev
export const countries = [
    // { name: 'Afghanistan', code: 'AF' },
    // { name: 'Albania', code: 'AL' },
    // { name: 'Algeria', code: 'DZ' },
    // { name: 'American Samoa', code: 'AS' },
    // { name: 'Andorra', code: 'AD' },
    // { name: 'Angola', code: 'AO' },
    // { name: 'Anguilla', code: 'AI' },
    // { name: 'Antarctica', code: 'AQ' },
    // { name: 'Antigua and Barbuda', code: 'AG' },
    // { name: 'Argentina', code: 'AR' },
    // { name: 'Armenia', code: 'AM' },
    // { name: 'Aruba', code: 'AW' },
    // { name: 'Australia', code: 'AU' },
    // { name: 'Austria', code: 'AT' },
    // { name: 'Azerbaijan', code: 'AZ' },
    // { name: 'Bahamas', code: 'BS' },
    // { name: 'Bahrain', code: 'BH' },
    // { name: 'Bangladesh', code: 'BD' },
    // { name: 'Barbados', code: 'BB' },
    // { name: 'Belarus', code: 'BY' },
    // { name: 'Belgium', code: 'BE' },
    // { name: 'Belize', code: 'BZ' },
    // { name: 'Benin', code: 'BJ' },
    // { name: 'Bermuda', code: 'BM' },
    // { name: 'Bhutan', code: 'BT' },
    // { name: 'Bolivia', code: 'BO' },
    // { name: 'Bonaire, Sint Eustatius, and Saba', code: 'BQ' },
    // { name: 'Bosnia and Herzegovina', code: 'BA' },
    // { name: 'Botswana', code: 'BW' },
    // { name: 'Bouvet Island', code: 'BV' },
    // { name: 'Brazil', code: 'BR' },
    // { name: 'British Indian Ocean Territory', code: 'IO' },
    // { name: 'British Virgin Islands', code: 'VG' },
    // { name: 'Brunei', code: 'BN' },
    // { name: 'Bulgaria', code: 'BG' },
    // { name: 'Burkina Faso', code: 'BF' },
    // { name: 'Burundi', code: 'BI' },
    // { name: 'Cabo Verde', code: 'CV' },
    // { name: 'Cambodia', code: 'KH' },
    // { name: 'Cameroon', code: 'CM' },
    { name: 'Canada', code: 'CA' },
    // { name: 'Cayman Islands', code: 'KY' },
    // { name: 'Central African Republic', code: 'CF' },
    // { name: 'Chad', code: 'TD' },
    // { name: 'Chile', code: 'CL' },
    // { name: 'China', code: 'CN' },
    // { name: 'Christmas Island', code: 'CX' },
    // { name: 'Cocos [Keeling] Islands', code: 'CC' },
    // { name: 'Colombia', code: 'CO' },
    // { name: 'Comoros', code: 'KM' },
    // { name: 'Congo Republic', code: 'CG' },
    // { name: 'Cook Islands', code: 'CK' },
    // { name: 'Costa Rica', code: 'CR' },
    // { name: 'Croatia', code: 'HR' },
    // { name: 'Cuba', code: 'CU' },
    // { name: 'Curaçao', code: 'CW' },
    // { name: 'Cyprus', code: 'CY' },
    // { name: 'Czechia', code: 'CZ' },
    // { name: 'DR Congo', code: 'CD' },
    // { name: 'Denmark', code: 'DK' },
    // { name: 'Djibouti', code: 'DJ' },
    // { name: 'Dominica', code: 'DM' },
    // { name: 'Dominican Republic', code: 'DO' },
    // { name: 'Ecuador', code: 'EC' },
    // { name: 'Egypt', code: 'EG' },
    // { name: 'El Salvador', code: 'SV' },
    // { name: 'Equatorial Guinea', code: 'GQ' },
    // { name: 'Eritrea', code: 'ER' },
    // { name: 'Estonia', code: 'EE' },
    // { name: 'Eswatini', code: 'SZ' },
    // { name: 'Ethiopia', code: 'ET' },
    // { name: 'Falkland Islands', code: 'FK' },
    // { name: 'Faroe Islands', code: 'FO' },
    // { name: 'Fiji', code: 'FJ' },
    // { name: 'Finland', code: 'FI' },
    // { name: 'France', code: 'FR' },
    // { name: 'French Guiana', code: 'GF' },
    // { name: 'French Polynesia', code: 'PF' },
    // { name: 'French Southern Territories', code: 'TF' },
    // { name: 'Gabon', code: 'GA' },
    // { name: 'Gambia', code: 'GM' },
    // { name: 'Georgia', code: 'GE' },
    // { name: 'Germany', code: 'DE' },
    // { name: 'Ghana', code: 'GH' },
    // { name: 'Gibraltar', code: 'GI' },
    // { name: 'Greece', code: 'GR' },
    // { name: 'Greenland', code: 'GL' },
    // { name: 'Grenada', code: 'GD' },
    // { name: 'Guadeloupe', code: 'GP' },
    // { name: 'Guam', code: 'GU' },
    // { name: 'Guatemala', code: 'GT' },
    // { name: 'Guernsey', code: 'GG' },
    // { name: 'Guinea', code: 'GN' },
    // { name: 'Guinea-Bissau', code: 'GW' },
    // { name: 'Guyana', code: 'GY' },
    // { name: 'Haiti', code: 'HT' },
    // { name: 'Heard Island and McDonald Islands', code: 'HM' },
    // { name: 'Honduras', code: 'HN' },
    // { name: 'Hong Kong', code: 'HK' },
    // { name: 'Hungary', code: 'HU' },
    // { name: 'Iceland', code: 'IS' },
    // { name: 'India', code: 'IN' },
    // { name: 'Indonesia', code: 'ID' },
    // { name: 'Iran', code: 'IR' },
    // { name: 'Iraq', code: 'IQ' },
    // { name: 'Ireland', code: 'IE' },
    // { name: 'Isle of Man', code: 'IM' },
    // { name: 'Israel', code: 'IL' },
    // { name: 'Italy', code: 'IT' },
    // { name: 'Ivory Coast', code: 'CI' },
    // { name: 'Jamaica', code: 'JM' },
    // { name: 'Japan', code: 'JP' },
    // { name: 'Jersey', code: 'JE' },
    // { name: 'Jordan', code: 'JO' },
    // { name: 'Kazakhstan', code: 'KZ' },
    // { name: 'Kenya', code: 'KE' },
    // { name: 'Kiribati', code: 'KI' },
    // { name: 'Kosovo', code: 'XK' },
    // { name: 'Kuwait', code: 'KW' },
    // { name: 'Kyrgyzstan', code: 'KG' },
    // { name: 'Laos', code: 'LA' },
    // { name: 'Latvia', code: 'LV' },
    // { name: 'Lebanon', code: 'LB' },
    // { name: 'Lesotho', code: 'LS' },
    // { name: 'Liberia', code: 'LR' },
    // { name: 'Libya', code: 'LY' },
    // { name: 'Liechtenstein', code: 'LI' },
    // { name: 'Lithuania', code: 'LT' },
    // { name: 'Luxembourg', code: 'LU' },
    // { name: 'Macao', code: 'MO' },
    // { name: 'Madagascar', code: 'MG' },
    // { name: 'Malawi', code: 'MW' },
    // { name: 'Malaysia', code: 'MY' },
    // { name: 'Maldives', code: 'MV' },
    // { name: 'Mali', code: 'ML' },
    // { name: 'Malta', code: 'MT' },
    // { name: 'Marshall Islands', code: 'MH' },
    // { name: 'Martinique', code: 'MQ' },
    // { name: 'Mauritania', code: 'MR' },
    // { name: 'Mauritius', code: 'MU' },
    // { name: 'Mayotte', code: 'YT' },
    // { name: 'Mexico', code: 'MX' },
    // { name: 'Micronesia', code: 'FM' },
    // { name: 'Moldova', code: 'MD' },
    // { name: 'Monaco', code: 'MC' },
    // { name: 'Mongolia', code: 'MN' },
    // { name: 'Montenegro', code: 'ME' },
    // { name: 'Montserrat', code: 'MS' },
    // { name: 'Morocco', code: 'MA' },
    // { name: 'Mozambique', code: 'MZ' },
    // { name: 'Myanmar', code: 'MM' },
    // { name: 'Namibia', code: 'NA' },
    // { name: 'Nauru', code: 'NR' },
    // { name: 'Nepal', code: 'NP' },
    // { name: 'Netherlands', code: 'NL' },
    // { name: 'New Caledonia', code: 'NC' },
    // { name: 'New Zealand', code: 'NZ' },
    // { name: 'Nicaragua', code: 'NI' },
    // { name: 'Niger', code: 'NE' },
    // { name: 'Nigeria', code: 'NG' },
    // { name: 'Niue', code: 'NU' },
    // { name: 'Norfolk Island', code: 'NF' },
    // { name: 'North Korea', code: 'KP' },
    // { name: 'North Macedonia', code: 'MK' },
    // { name: 'Northern Mariana Islands', code: 'MP' },
    // { name: 'Norway', code: 'NO' },
    // { name: 'Oman', code: 'OM' },
    // { name: 'Pakistan', code: 'PK' },
    // { name: 'Palau', code: 'PW' },
    // { name: 'Palestine', code: 'PS' },
    // { name: 'Panama', code: 'PA' },
    // { name: 'Papua New Guinea', code: 'PG' },
    // { name: 'Paraguay', code: 'PY' },
    // { name: 'Peru', code: 'PE' },
    // { name: 'Philippines', code: 'PH' },
    // { name: 'Pitcairn Islands', code: 'PN' },
    // { name: 'Poland', code: 'PL' },
    // { name: 'Portugal', code: 'PT' },
    // { name: 'Puerto Rico', code: 'PR' },
    // { name: 'Qatar', code: 'QA' },
    // { name: 'Romania', code: 'RO' },
    // { name: 'Russia', code: 'RU' },
    // { name: 'Rwanda', code: 'RW' },
    // { name: 'Réunion', code: 'RE' },
    // { name: 'Saint Barthélemy', code: 'BL' },
    // { name: 'Saint Helena', code: 'SH' },
    // { name: 'Saint Lucia', code: 'LC' },
    // { name: 'Saint Martin', code: 'MF' },
    // { name: 'Saint Pierre and Miquelon', code: 'PM' },
    // { name: 'Samoa', code: 'WS' },
    // { name: 'San Marino', code: 'SM' },
    // { name: 'Saudi Arabia', code: 'SA' },
    // { name: 'Senegal', code: 'SN' },
    // { name: 'Serbia', code: 'RS' },
    // { name: 'Seychelles', code: 'SC' },
    // { name: 'Sierra Leone', code: 'SL' },
    // { name: 'Singapore', code: 'SG' },
    // { name: 'Sint Maarten', code: 'SX' },
    // { name: 'Slovakia', code: 'SK' },
    // { name: 'Slovenia', code: 'SI' },
    // { name: 'Solomon Islands', code: 'SB' },
    // { name: 'Somalia', code: 'SO' },
    // { name: 'South Africa', code: 'ZA' },
    // { name: 'South Georgia and South Sandwich Islands', code: 'GS' },
    // { name: 'South Korea', code: 'KR' },
    // { name: 'South Sudan', code: 'SS' },
    // { name: 'Spain', code: 'ES' },
    // { name: 'Sri Lanka', code: 'LK' },
    // { name: 'St Kitts and Nevis', code: 'KN' },
    // { name: 'St Vincent and Grenadines', code: 'VC' },
    // { name: 'Sudan', code: 'SD' },
    // { name: 'Suriname', code: 'SR' },
    // { name: 'Svalbard and Jan Mayen', code: 'SJ' },
    // { name: 'Sweden', code: 'SE' },
    // { name: 'Switzerland', code: 'CH' },
    // { name: 'Syria', code: 'SY' },
    // { name: 'São Tomé and Príncipe', code: 'ST' },
    // { name: 'Taiwan', code: 'TW' },
    // { name: 'Tajikistan', code: 'TJ' },
    // { name: 'Tanzania', code: 'TZ' },
    // { name: 'Thailand', code: 'TH' },
    // { name: 'Timor-Leste', code: 'TL' },
    // { name: 'Togo', code: 'TG' },
    // { name: 'Tokelau', code: 'TK' },
    // { name: 'Tonga', code: 'TO' },
    // { name: 'Trinidad and Tobago', code: 'TT' },
    // { name: 'Tunisia', code: 'TN' },
    // { name: 'Turkey', code: 'TR' },
    // { name: 'Turkmenistan', code: 'TM' },
    // { name: 'Turks and Caicos Islands', code: 'TC' },
    // { name: 'Tuvalu', code: 'TV' },
    // { name: 'U.S. Minor Outlying Islands', code: 'UM' },
    // { name: 'U.S. Virgin Islands', code: 'VI' },
    // { name: 'Uganda', code: 'UG' },
    // { name: 'Ukraine', code: 'UA' },
    // { name: 'United Arab Emirates', code: 'AE' },
    // { name: 'United Kingdom', code: 'GB' },
    { name: 'United States', code: 'US' },
    // { name: 'Uruguay', code: 'UY' },
    // { name: 'Uzbekistan', code: 'UZ' },
    // { name: 'Vanuatu', code: 'VU' },
    // { name: 'Vatican City', code: 'VA' },
    // { name: 'Venezuela', code: 'VE' },
    // { name: 'Vietnam', code: 'VN' },
    // { name: 'Wallis and Futuna', code: 'WF' },
    // { name: 'Western Sahara', code: 'EH' },
    // { name: 'Yemen', code: 'YE' },
    // { name: 'Zambia', code: 'ZM' },
    // { name: 'Zimbabwe', code: 'ZW' },
    // { name: 'Åland', code: 'AX' }
];

export const countriesByNameLower = {
    // 'afghanistan': { name: 'Afghanistan', code: 'AF' },
    // 'albania': { name: 'Albania', code: 'AL' },
    // 'algeria': { name: 'Algeria', code: 'DZ' },
    // 'american samoa': { name: 'American Samoa', code: 'AS' },
    // 'andorra': { name: 'Andorra', code: 'AD' },
    // 'angola': { name: 'Angola', code: 'AO' },
    // 'anguilla': { name: 'Anguilla', code: 'AI' },
    // 'antarctica': { name: 'Antarctica', code: 'AQ' },
    // 'antigua and barbuda': { name: 'Antigua and Barbuda', code: 'AG' },
    // 'argentina': { name: 'Argentina', code: 'AR' },
    // 'armenia': { name: 'Armenia', code: 'AM' },
    // 'aruba': { name: 'Aruba', code: 'AW' },
    // 'australia': { name: 'Australia', code: 'AU' },
    // 'austria': { name: 'Austria', code: 'AT' },
    // 'azerbaijan': { name: 'Azerbaijan', code: 'AZ' },
    // 'bahamas': { name: 'Bahamas', code: 'BS' },
    // 'bahrain': { name: 'Bahrain', code: 'BH' },
    // 'bangladesh': { name: 'Bangladesh', code: 'BD' },
    // 'barbados': { name: 'Barbados', code: 'BB' },
    // 'belarus': { name: 'Belarus', code: 'BY' },
    // 'belgium': { name: 'Belgium', code: 'BE' },
    // 'belize': { name: 'Belize', code: 'BZ' },
    // 'benin': { name: 'Benin', code: 'BJ' },
    // 'bermuda': { name: 'Bermuda', code: 'BM' },
    // 'bhutan': { name: 'Bhutan', code: 'BT' },
    // 'bolivia': { name: 'Bolivia', code: 'BO' },
    // 'bonaire, sint eustatius, and saba': { name: 'Bonaire, Sint Eustatius, and Saba', code: 'BQ' },
    // 'bosnia and herzegovina': { name: 'Bosnia and Herzegovina', code: 'BA' },
    // 'botswana': { name: 'Botswana', code: 'BW' },
    // 'bouvet island': { name: 'Bouvet Island', code: 'BV' },
    // 'brazil': { name: 'Brazil', code: 'BR' },
    // 'british indian ocean territory': { name: 'British Indian Ocean Territory', code: 'IO' },
    // 'british virgin islands': { name: 'British Virgin Islands', code: 'VG' },
    // 'brunei': { name: 'Brunei', code: 'BN' },
    // 'bulgaria': { name: 'Bulgaria', code: 'BG' },
    // 'burkina faso': { name: 'Burkina Faso', code: 'BF' },
    // 'burundi': { name: 'Burundi', code: 'BI' },
    // 'cabo verde': { name: 'Cabo Verde', code: 'CV' },
    // 'cambodia': { name: 'Cambodia', code: 'KH' },
    // 'cameroon': { name: 'Cameroon', code: 'CM' },
    canada: { name: 'Canada', code: 'CA' },
    // 'cayman islands': { name: 'Cayman Islands', code: 'KY' },
    // 'central african republic': { name: 'Central African Republic', code: 'CF' },
    // 'chad': { name: 'Chad', code: 'TD' },
    // 'chile': { name: 'Chile', code: 'CL' },
    // 'china': { name: 'China', code: 'CN' },
    // 'christmas island': { name: 'Christmas Island', code: 'CX' },
    // 'cocos [keeling] islands': { name: 'Cocos [Keeling] Islands', code: 'CC' },
    // 'colombia': { name: 'Colombia', code: 'CO' },
    // 'comoros': { name: 'Comoros', code: 'KM' },
    // 'congo republic': { name: 'Congo Republic', code: 'CG' },
    // 'cook islands': { name: 'Cook Islands', code: 'CK' },
    // 'costa rica': { name: 'Costa Rica', code: 'CR' },
    // 'croatia': { name: 'Croatia', code: 'HR' },
    // 'cuba': { name: 'Cuba', code: 'CU' },
    // 'curaçao': { name: 'Curaçao', code: 'CW' },
    // 'cyprus': { name: 'Cyprus', code: 'CY' },
    // 'czechia': { name: 'Czechia', code: 'CZ' },
    // 'dr congo': { name: 'DR Congo', code: 'CD' },
    // 'denmark': { name: 'Denmark', code: 'DK' },
    // 'djibouti': { name: 'Djibouti', code: 'DJ' },
    // 'dominica': { name: 'Dominica', code: 'DM' },
    // 'dominican republic': { name: 'Dominican Republic', code: 'DO' },
    // 'ecuador': { name: 'Ecuador', code: 'EC' },
    // 'egypt': { name: 'Egypt', code: 'EG' },
    // 'el salvador': { name: 'El Salvador', code: 'SV' },
    // 'equatorial guinea': { name: 'Equatorial Guinea', code: 'GQ' },
    // 'eritrea': { name: 'Eritrea', code: 'ER' },
    // 'estonia': { name: 'Estonia', code: 'EE' },
    // 'eswatini': { name: 'Eswatini', code: 'SZ' },
    // 'ethiopia': { name: 'Ethiopia', code: 'ET' },
    // 'falkland islands': { name: 'Falkland Islands', code: 'FK' },
    // 'faroe islands': { name: 'Faroe Islands', code: 'FO' },
    // 'fiji': { name: 'Fiji', code: 'FJ' },
    // 'finland': { name: 'Finland', code: 'FI' },
    // 'france': { name: 'France', code: 'FR' },
    // 'french guiana': { name: 'French Guiana', code: 'GF' },
    // 'french polynesia': { name: 'French Polynesia', code: 'PF' },
    // 'french southern territories': { name: 'French Southern Territories', code: 'TF' },
    // 'gabon': { name: 'Gabon', code: 'GA' },
    // 'gambia': { name: 'Gambia', code: 'GM' },
    // 'georgia': { name: 'Georgia', code: 'GE' },
    // 'germany': { name: 'Germany', code: 'DE' },
    // 'ghana': { name: 'Ghana', code: 'GH' },
    // 'gibraltar': { name: 'Gibraltar', code: 'GI' },
    // 'greece': { name: 'Greece', code: 'GR' },
    // 'greenland': { name: 'Greenland', code: 'GL' },
    // 'grenada': { name: 'Grenada', code: 'GD' },
    // 'guadeloupe': { name: 'Guadeloupe', code: 'GP' },
    // 'guam': { name: 'Guam', code: 'GU' },
    // 'guatemala': { name: 'Guatemala', code: 'GT' },
    // 'guernsey': { name: 'Guernsey', code: 'GG' },
    // 'guinea': { name: 'Guinea', code: 'GN' },
    // 'guinea-bissau': { name: 'Guinea-Bissau', code: 'GW' },
    // 'guyana': { name: 'Guyana', code: 'GY' },
    // 'haiti': { name: 'Haiti', code: 'HT' },
    // 'heard island and mcdonald islands': { name: 'Heard Island and McDonald Islands', code: 'HM' },
    // 'honduras': { name: 'Honduras', code: 'HN' },
    // 'hong kong': { name: 'Hong Kong', code: 'HK' },
    // 'hungary': { name: 'Hungary', code: 'HU' },
    // 'iceland': { name: 'Iceland', code: 'IS' },
    // 'india': { name: 'India', code: 'IN' },
    // 'indonesia': { name: 'Indonesia', code: 'ID' },
    // 'iran': { name: 'Iran', code: 'IR' },
    // 'iraq': { name: 'Iraq', code: 'IQ' },
    // 'ireland': { name: 'Ireland', code: 'IE' },
    // 'isle of man': { name: 'Isle of Man', code: 'IM' },
    // 'israel': { name: 'Israel', code: 'IL' },
    // 'italy': { name: 'Italy', code: 'IT' },
    // 'ivory coast': { name: 'Ivory Coast', code: 'CI' },
    // 'jamaica': { name: 'Jamaica', code: 'JM' },
    // 'japan': { name: 'Japan', code: 'JP' },
    // 'jersey': { name: 'Jersey', code: 'JE' },
    // 'jordan': { name: 'Jordan', code: 'JO' },
    // 'kazakhstan': { name: 'Kazakhstan', code: 'KZ' },
    // 'kenya': { name: 'Kenya', code: 'KE' },
    // 'kiribati': { name: 'Kiribati', code: 'KI' },
    // 'kosovo': { name: 'Kosovo', code: 'XK' },
    // 'kuwait': { name: 'Kuwait', code: 'KW' },
    // 'kyrgyzstan': { name: 'Kyrgyzstan', code: 'KG' },
    // 'laos': { name: 'Laos', code: 'LA' },
    // 'latvia': { name: 'Latvia', code: 'LV' },
    // 'lebanon': { name: 'Lebanon', code: 'LB' },
    // 'lesotho': { name: 'Lesotho', code: 'LS' },
    // 'liberia': { name: 'Liberia', code: 'LR' },
    // 'libya': { name: 'Libya', code: 'LY' },
    // 'liechtenstein': { name: 'Liechtenstein', code: 'LI' },
    // 'lithuania': { name: 'Lithuania', code: 'LT' },
    // 'luxembourg': { name: 'Luxembourg', code: 'LU' },
    // 'macao': { name: 'Macao', code: 'MO' },
    // 'madagascar': { name: 'Madagascar', code: 'MG' },
    // 'malawi': { name: 'Malawi', code: 'MW' },
    // 'malaysia': { name: 'Malaysia', code: 'MY' },
    // 'maldives': { name: 'Maldives', code: 'MV' },
    // 'mali': { name: 'Mali', code: 'ML' },
    // 'malta': { name: 'Malta', code: 'MT' },
    // 'marshall islands': { name: 'Marshall Islands', code: 'MH' },
    // 'martinique': { name: 'Martinique', code: 'MQ' },
    // 'mauritania': { name: 'Mauritania', code: 'MR' },
    // 'mauritius': { name: 'Mauritius', code: 'MU' },
    // 'mayotte': { name: 'Mayotte', code: 'YT' },
    // 'mexico': { name: 'Mexico', code: 'MX' },
    // 'micronesia': { name: 'Micronesia', code: 'FM' },
    // 'moldova': { name: 'Moldova', code: 'MD' },
    // 'monaco': { name: 'Monaco', code: 'MC' },
    // 'mongolia': { name: 'Mongolia', code: 'MN' },
    // 'montenegro': { name: 'Montenegro', code: 'ME' },
    // 'montserrat': { name: 'Montserrat', code: 'MS' },
    // 'morocco': { name: 'Morocco', code: 'MA' },
    // 'mozambique': { name: 'Mozambique', code: 'MZ' },
    // 'myanmar': { name: 'Myanmar', code: 'MM' },
    // 'namibia': { name: 'Namibia', code: 'NA' },
    // 'nauru': { name: 'Nauru', code: 'NR' },
    // 'nepal': { name: 'Nepal', code: 'NP' },
    // 'netherlands': { name: 'Netherlands', code: 'NL' },
    // 'new caledonia': { name: 'New Caledonia', code: 'NC' },
    // 'new zealand': { name: 'New Zealand', code: 'NZ' },
    // 'nicaragua': { name: 'Nicaragua', code: 'NI' },
    // 'niger': { name: 'Niger', code: 'NE' },
    // 'nigeria': { name: 'Nigeria', code: 'NG' },
    // 'niue': { name: 'Niue', code: 'NU' },
    // 'norfolk island': { name: 'Norfolk Island', code: 'NF' },
    // 'north korea': { name: 'North Korea', code: 'KP' },
    // 'north macedonia': { name: 'North Macedonia', code: 'MK' },
    // 'northern mariana islands': { name: 'Northern Mariana Islands', code: 'MP' },
    // 'norway': { name: 'Norway', code: 'NO' },
    // 'oman': { name: 'Oman', code: 'OM' },
    // 'pakistan': { name: 'Pakistan', code: 'PK' },
    // 'palau': { name: 'Palau', code: 'PW' },
    // 'palestine': { name: 'Palestine', code: 'PS' },
    // 'panama': { name: 'Panama', code: 'PA' },
    // 'papua new guinea': { name: 'Papua New Guinea', code: 'PG' },
    // 'paraguay': { name: 'Paraguay', code: 'PY' },
    // 'peru': { name: 'Peru', code: 'PE' },
    // 'philippines': { name: 'Philippines', code: 'PH' },
    // 'pitcairn islands': { name: 'Pitcairn Islands', code: 'PN' },
    // 'poland': { name: 'Poland', code: 'PL' },
    // 'portugal': { name: 'Portugal', code: 'PT' },
    // 'puerto rico': { name: 'Puerto Rico', code: 'PR' },
    // 'qatar': { name: 'Qatar', code: 'QA' },
    // 'romania': { name: 'Romania', code: 'RO' },
    // 'russia': { name: 'Russia', code: 'RU' },
    // 'rwanda': { name: 'Rwanda', code: 'RW' },
    // 'réunion': { name: 'Réunion', code: 'RE' },
    // 'saint barthélemy': { name: 'Saint Barthélemy', code: 'BL' },
    // 'saint helena': { name: 'Saint Helena', code: 'SH' },
    // 'saint lucia': { name: 'Saint Lucia', code: 'LC' },
    // 'saint martin': { name: 'Saint Martin', code: 'MF' },
    // 'saint pierre and miquelon': { name: 'Saint Pierre and Miquelon', code: 'PM' },
    // 'samoa': { name: 'Samoa', code: 'WS' },
    // 'san marino': { name: 'San Marino', code: 'SM' },
    // 'saudi arabia': { name: 'Saudi Arabia', code: 'SA' },
    // 'senegal': { name: 'Senegal', code: 'SN' },
    // 'serbia': { name: 'Serbia', code: 'RS' },
    // 'seychelles': { name: 'Seychelles', code: 'SC' },
    // 'sierra leone': { name: 'Sierra Leone', code: 'SL' },
    // 'singapore': { name: 'Singapore', code: 'SG' },
    // 'sint maarten': { name: 'Sint Maarten', code: 'SX' },
    // 'slovakia': { name: 'Slovakia', code: 'SK' },
    // 'slovenia': { name: 'Slovenia', code: 'SI' },
    // 'solomon islands': { name: 'Solomon Islands', code: 'SB' },
    // 'somalia': { name: 'Somalia', code: 'SO' },
    // 'south africa': { name: 'South Africa', code: 'ZA' },
    // 'south georgia and south sandwich islands': { name: 'South Georgia and South Sandwich Islands', code: 'GS' },
    // 'south korea': { name: 'South Korea', code: 'KR' },
    // 'south sudan': { name: 'South Sudan', code: 'SS' },
    // 'spain': { name: 'Spain', code: 'ES' },
    // 'sri lanka': { name: 'Sri Lanka', code: 'LK' },
    // 'st kitts and nevis': { name: 'St Kitts and Nevis', code: 'KN' },
    // 'st vincent and grenadines': { name: 'St Vincent and Grenadines', code: 'VC' },
    // 'sudan': { name: 'Sudan', code: 'SD' },
    // 'suriname': { name: 'Suriname', code: 'SR' },
    // 'svalbard and jan mayen': { name: 'Svalbard and Jan Mayen', code: 'SJ' },
    // 'sweden': { name: 'Sweden', code: 'SE' },
    // 'switzerland': { name: 'Switzerland', code: 'CH' },
    // 'syria': { name: 'Syria', code: 'SY' },
    // 'são tomé and príncipe': { name: 'São Tomé and Príncipe', code: 'ST' },
    // 'taiwan': { name: 'Taiwan', code: 'TW' },
    // 'tajikistan': { name: 'Tajikistan', code: 'TJ' },
    // 'tanzania': { name: 'Tanzania', code: 'TZ' },
    // 'thailand': { name: 'Thailand', code: 'TH' },
    // 'timor-leste': { name: 'Timor-Leste', code: 'TL' },
    // 'togo': { name: 'Togo', code: 'TG' },
    // 'tokelau': { name: 'Tokelau', code: 'TK' },
    // 'tonga': { name: 'Tonga', code: 'TO' },
    // 'trinidad and tobago': { name: 'Trinidad and Tobago', code: 'TT' },
    // 'tunisia': { name: 'Tunisia', code: 'TN' },
    // 'turkey': { name: 'Turkey', code: 'TR' },
    // 'turkmenistan': { name: 'Turkmenistan', code: 'TM' },
    // 'turks and caicos islands': { name: 'Turks and Caicos Islands', code: 'TC' },
    // 'tuvalu': { name: 'Tuvalu', code: 'TV' },
    // 'u.s. minor outlying islands': { name: 'U.S. Minor Outlying Islands', code: 'UM' },
    // 'u.s. virgin islands': { name: 'U.S. Virgin Islands', code: 'VI' },
    // 'uganda': { name: 'Uganda', code: 'UG' },
    // 'ukraine': { name: 'Ukraine', code: 'UA' },
    // 'united arab emirates': { name: 'United Arab Emirates', code: 'AE' },
    // 'united kingdom': { name: 'United Kingdom', code: 'GB' },
    'united states': { name: 'United States', code: 'US' },
    // 'uruguay': { name: 'Uruguay', code: 'UY' },
    // 'uzbekistan': { name: 'Uzbekistan', code: 'UZ' },
    // 'vanuatu': { name: 'Vanuatu', code: 'VU' },
    // 'vatican city': { name: 'Vatican City', code: 'VA' },
    // 'venezuela': { name: 'Venezuela', code: 'VE' },
    // 'vietnam': { name: 'Vietnam', code: 'VN' },
    // 'wallis and futuna': { name: 'Wallis and Futuna', code: 'WF' },
    // 'western sahara': { name: 'Western Sahara', code: 'EH' },
    // 'yemen': { name: 'Yemen', code: 'YE' },
    // 'zambia': { name: 'Zambia', code: 'ZM' },
    // 'zimbabwe': { name: 'Zimbabwe', code: 'ZW' },
    // 'åland': { name: 'Åland', code: 'AX' }
};

export const countriesByCode = {
    // AF: { name: 'Afghanistan', code: 'AF' },
    // AL: { name: 'Albania', code: 'AL' },
    // DZ: { name: 'Algeria', code: 'DZ' },
    // AS: { name: 'American Samoa', code: 'AS' },
    // AD: { name: 'Andorra', code: 'AD' },
    // AO: { name: 'Angola', code: 'AO' },
    // AI: { name: 'Anguilla', code: 'AI' },
    // AQ: { name: 'Antarctica', code: 'AQ' },
    // AG: { name: 'Antigua and Barbuda', code: 'AG' },
    // AR: { name: 'Argentina', code: 'AR' },
    // AM: { name: 'Armenia', code: 'AM' },
    // AW: { name: 'Aruba', code: 'AW' },
    // AU: { name: 'Australia', code: 'AU' },
    // AT: { name: 'Austria', code: 'AT' },
    // AZ: { name: 'Azerbaijan', code: 'AZ' },
    // BS: { name: 'Bahamas', code: 'BS' },
    // BH: { name: 'Bahrain', code: 'BH' },
    // BD: { name: 'Bangladesh', code: 'BD' },
    // BB: { name: 'Barbados', code: 'BB' },
    // BY: { name: 'Belarus', code: 'BY' },
    // BE: { name: 'Belgium', code: 'BE' },
    // BZ: { name: 'Belize', code: 'BZ' },
    // BJ: { name: 'Benin', code: 'BJ' },
    // BM: { name: 'Bermuda', code: 'BM' },
    // BT: { name: 'Bhutan', code: 'BT' },
    // BO: { name: 'Bolivia', code: 'BO' },
    // BQ: { name: 'Bonaire, Sint Eustatius, and Saba', code: 'BQ' },
    // BA: { name: 'Bosnia and Herzegovina', code: 'BA' },
    // BW: { name: 'Botswana', code: 'BW' },
    // BV: { name: 'Bouvet Island', code: 'BV' },
    // BR: { name: 'Brazil', code: 'BR' },
    // IO: { name: 'British Indian Ocean Territory', code: 'IO' },
    // VG: { name: 'British Virgin Islands', code: 'VG' },
    // BN: { name: 'Brunei', code: 'BN' },
    // BG: { name: 'Bulgaria', code: 'BG' },
    // BF: { name: 'Burkina Faso', code: 'BF' },
    // BI: { name: 'Burundi', code: 'BI' },
    // CV: { name: 'Cabo Verde', code: 'CV' },
    // KH: { name: 'Cambodia', code: 'KH' },
    // CM: { name: 'Cameroon', code: 'CM' },
    CA: { name: 'Canada', code: 'CA' },
    // KY: { name: 'Cayman Islands', code: 'KY' },
    // CF: { name: 'Central African Republic', code: 'CF' },
    // TD: { name: 'Chad', code: 'TD' },
    // CL: { name: 'Chile', code: 'CL' },
    // CN: { name: 'China', code: 'CN' },
    // CX: { name: 'Christmas Island', code: 'CX' },
    // CC: { name: 'Cocos [Keeling] Islands', code: 'CC' },
    // CO: { name: 'Colombia', code: 'CO' },
    // KM: { name: 'Comoros', code: 'KM' },
    // CG: { name: 'Congo Republic', code: 'CG' },
    // CK: { name: 'Cook Islands', code: 'CK' },
    // CR: { name: 'Costa Rica', code: 'CR' },
    // HR: { name: 'Croatia', code: 'HR' },
    // CU: { name: 'Cuba', code: 'CU' },
    // CW: { name: 'Curaçao', code: 'CW' },
    // CY: { name: 'Cyprus', code: 'CY' },
    // CZ: { name: 'Czechia', code: 'CZ' },
    // CD: { name: 'DR Congo', code: 'CD' },
    // DK: { name: 'Denmark', code: 'DK' },
    // DJ: { name: 'Djibouti', code: 'DJ' },
    // DM: { name: 'Dominica', code: 'DM' },
    // DO: { name: 'Dominican Republic', code: 'DO' },
    // EC: { name: 'Ecuador', code: 'EC' },
    // EG: { name: 'Egypt', code: 'EG' },
    // SV: { name: 'El Salvador', code: 'SV' },
    // GQ: { name: 'Equatorial Guinea', code: 'GQ' },
    // ER: { name: 'Eritrea', code: 'ER' },
    // EE: { name: 'Estonia', code: 'EE' },
    // SZ: { name: 'Eswatini', code: 'SZ' },
    // ET: { name: 'Ethiopia', code: 'ET' },
    // FK: { name: 'Falkland Islands', code: 'FK' },
    // FO: { name: 'Faroe Islands', code: 'FO' },
    // FJ: { name: 'Fiji', code: 'FJ' },
    // FI: { name: 'Finland', code: 'FI' },
    // FR: { name: 'France', code: 'FR' },
    // GF: { name: 'French Guiana', code: 'GF' },
    // PF: { name: 'French Polynesia', code: 'PF' },
    // TF: { name: 'French Southern Territories', code: 'TF' },
    // GA: { name: 'Gabon', code: 'GA' },
    // GM: { name: 'Gambia', code: 'GM' },
    // GE: { name: 'Georgia', code: 'GE' },
    // DE: { name: 'Germany', code: 'DE' },
    // GH: { name: 'Ghana', code: 'GH' },
    // GI: { name: 'Gibraltar', code: 'GI' },
    // GR: { name: 'Greece', code: 'GR' },
    // GL: { name: 'Greenland', code: 'GL' },
    // GD: { name: 'Grenada', code: 'GD' },
    // GP: { name: 'Guadeloupe', code: 'GP' },
    // GU: { name: 'Guam', code: 'GU' },
    // GT: { name: 'Guatemala', code: 'GT' },
    // GG: { name: 'Guernsey', code: 'GG' },
    // GN: { name: 'Guinea', code: 'GN' },
    // GW: { name: 'Guinea-Bissau', code: 'GW' },
    // GY: { name: 'Guyana', code: 'GY' },
    // HT: { name: 'Haiti', code: 'HT' },
    // HM: { name: 'Heard Island and McDonald Islands', code: 'HM' },
    // HN: { name: 'Honduras', code: 'HN' },
    // HK: { name: 'Hong Kong', code: 'HK' },
    // HU: { name: 'Hungary', code: 'HU' },
    // IS: { name: 'Iceland', code: 'IS' },
    // IN: { name: 'India', code: 'IN' },
    // ID: { name: 'Indonesia', code: 'ID' },
    // IR: { name: 'Iran', code: 'IR' },
    // IQ: { name: 'Iraq', code: 'IQ' },
    // IE: { name: 'Ireland', code: 'IE' },
    // IM: { name: 'Isle of Man', code: 'IM' },
    // IL: { name: 'Israel', code: 'IL' },
    // IT: { name: 'Italy', code: 'IT' },
    // CI: { name: 'Ivory Coast', code: 'CI' },
    // JM: { name: 'Jamaica', code: 'JM' },
    // JP: { name: 'Japan', code: 'JP' },
    // JE: { name: 'Jersey', code: 'JE' },
    // JO: { name: 'Jordan', code: 'JO' },
    // KZ: { name: 'Kazakhstan', code: 'KZ' },
    // KE: { name: 'Kenya', code: 'KE' },
    // KI: { name: 'Kiribati', code: 'KI' },
    // XK: { name: 'Kosovo', code: 'XK' },
    // KW: { name: 'Kuwait', code: 'KW' },
    // KG: { name: 'Kyrgyzstan', code: 'KG' },
    // LA: { name: 'Laos', code: 'LA' },
    // LV: { name: 'Latvia', code: 'LV' },
    // LB: { name: 'Lebanon', code: 'LB' },
    // LS: { name: 'Lesotho', code: 'LS' },
    // LR: { name: 'Liberia', code: 'LR' },
    // LY: { name: 'Libya', code: 'LY' },
    // LI: { name: 'Liechtenstein', code: 'LI' },
    // LT: { name: 'Lithuania', code: 'LT' },
    // LU: { name: 'Luxembourg', code: 'LU' },
    // MO: { name: 'Macao', code: 'MO' },
    // MG: { name: 'Madagascar', code: 'MG' },
    // MW: { name: 'Malawi', code: 'MW' },
    // MY: { name: 'Malaysia', code: 'MY' },
    // MV: { name: 'Maldives', code: 'MV' },
    // ML: { name: 'Mali', code: 'ML' },
    // MT: { name: 'Malta', code: 'MT' },
    // MH: { name: 'Marshall Islands', code: 'MH' },
    // MQ: { name: 'Martinique', code: 'MQ' },
    // MR: { name: 'Mauritania', code: 'MR' },
    // MU: { name: 'Mauritius', code: 'MU' },
    // YT: { name: 'Mayotte', code: 'YT' },
    // MX: { name: 'Mexico', code: 'MX' },
    // FM: { name: 'Micronesia', code: 'FM' },
    // MD: { name: 'Moldova', code: 'MD' },
    // MC: { name: 'Monaco', code: 'MC' },
    // MN: { name: 'Mongolia', code: 'MN' },
    // ME: { name: 'Montenegro', code: 'ME' },
    // MS: { name: 'Montserrat', code: 'MS' },
    // MA: { name: 'Morocco', code: 'MA' },
    // MZ: { name: 'Mozambique', code: 'MZ' },
    // MM: { name: 'Myanmar', code: 'MM' },
    // NA: { name: 'Namibia', code: 'NA' },
    // NR: { name: 'Nauru', code: 'NR' },
    // NP: { name: 'Nepal', code: 'NP' },
    // NL: { name: 'Netherlands', code: 'NL' },
    // NC: { name: 'New Caledonia', code: 'NC' },
    // NZ: { name: 'New Zealand', code: 'NZ' },
    // NI: { name: 'Nicaragua', code: 'NI' },
    // NE: { name: 'Niger', code: 'NE' },
    // NG: { name: 'Nigeria', code: 'NG' },
    // NU: { name: 'Niue', code: 'NU' },
    // NF: { name: 'Norfolk Island', code: 'NF' },
    // KP: { name: 'North Korea', code: 'KP' },
    // MK: { name: 'North Macedonia', code: 'MK' },
    // MP: { name: 'Northern Mariana Islands', code: 'MP' },
    // NO: { name: 'Norway', code: 'NO' },
    // OM: { name: 'Oman', code: 'OM' },
    // PK: { name: 'Pakistan', code: 'PK' },
    // PW: { name: 'Palau', code: 'PW' },
    // PS: { name: 'Palestine', code: 'PS' },
    // PA: { name: 'Panama', code: 'PA' },
    // PG: { name: 'Papua New Guinea', code: 'PG' },
    // PY: { name: 'Paraguay', code: 'PY' },
    // PE: { name: 'Peru', code: 'PE' },
    // PH: { name: 'Philippines', code: 'PH' },
    // PN: { name: 'Pitcairn Islands', code: 'PN' },
    // PL: { name: 'Poland', code: 'PL' },
    // PT: { name: 'Portugal', code: 'PT' },
    // PR: { name: 'Puerto Rico', code: 'PR' },
    // QA: { name: 'Qatar', code: 'QA' },
    // RO: { name: 'Romania', code: 'RO' },
    // RU: { name: 'Russia', code: 'RU' },
    // RW: { name: 'Rwanda', code: 'RW' },
    // RE: { name: 'Réunion', code: 'RE' },
    // BL: { name: 'Saint Barthélemy', code: 'BL' },
    // SH: { name: 'Saint Helena', code: 'SH' },
    // LC: { name: 'Saint Lucia', code: 'LC' },
    // MF: { name: 'Saint Martin', code: 'MF' },
    // PM: { name: 'Saint Pierre and Miquelon', code: 'PM' },
    // WS: { name: 'Samoa', code: 'WS' },
    // SM: { name: 'San Marino', code: 'SM' },
    // SA: { name: 'Saudi Arabia', code: 'SA' },
    // SN: { name: 'Senegal', code: 'SN' },
    // RS: { name: 'Serbia', code: 'RS' },
    // SC: { name: 'Seychelles', code: 'SC' },
    // SL: { name: 'Sierra Leone', code: 'SL' },
    // SG: { name: 'Singapore', code: 'SG' },
    // SX: { name: 'Sint Maarten', code: 'SX' },
    // SK: { name: 'Slovakia', code: 'SK' },
    // SI: { name: 'Slovenia', code: 'SI' },
    // SB: { name: 'Solomon Islands', code: 'SB' },
    // SO: { name: 'Somalia', code: 'SO' },
    // ZA: { name: 'South Africa', code: 'ZA' },
    // GS: { name: 'South Georgia and South Sandwich Islands', code: 'GS' },
    // KR: { name: 'South Korea', code: 'KR' },
    // SS: { name: 'South Sudan', code: 'SS' },
    // ES: { name: 'Spain', code: 'ES' },
    // LK: { name: 'Sri Lanka', code: 'LK' },
    // KN: { name: 'St Kitts and Nevis', code: 'KN' },
    // VC: { name: 'St Vincent and Grenadines', code: 'VC' },
    // SD: { name: 'Sudan', code: 'SD' },
    // SR: { name: 'Suriname', code: 'SR' },
    // SJ: { name: 'Svalbard and Jan Mayen', code: 'SJ' },
    // SE: { name: 'Sweden', code: 'SE' },
    // CH: { name: 'Switzerland', code: 'CH' },
    // SY: { name: 'Syria', code: 'SY' },
    // ST: { name: 'São Tomé and Príncipe', code: 'ST' },
    // TW: { name: 'Taiwan', code: 'TW' },
    // TJ: { name: 'Tajikistan', code: 'TJ' },
    // TZ: { name: 'Tanzania', code: 'TZ' },
    // TH: { name: 'Thailand', code: 'TH' },
    // TL: { name: 'Timor-Leste', code: 'TL' },
    // TG: { name: 'Togo', code: 'TG' },
    // TK: { name: 'Tokelau', code: 'TK' },
    // TO: { name: 'Tonga', code: 'TO' },
    // TT: { name: 'Trinidad and Tobago', code: 'TT' },
    // TN: { name: 'Tunisia', code: 'TN' },
    // TR: { name: 'Turkey', code: 'TR' },
    // TM: { name: 'Turkmenistan', code: 'TM' },
    // TC: { name: 'Turks and Caicos Islands', code: 'TC' },
    // TV: { name: 'Tuvalu', code: 'TV' },
    // UM: { name: 'U.S. Minor Outlying Islands', code: 'UM' },
    // VI: { name: 'U.S. Virgin Islands', code: 'VI' },
    // UG: { name: 'Uganda', code: 'UG' },
    // UA: { name: 'Ukraine', code: 'UA' },
    // AE: { name: 'United Arab Emirates', code: 'AE' },
    // GB: { name: 'United Kingdom', code: 'GB' },
    US: { name: 'United States', code: 'US' },
    // UY: { name: 'Uruguay', code: 'UY' },
    // UZ: { name: 'Uzbekistan', code: 'UZ' },
    // VU: { name: 'Vanuatu', code: 'VU' },
    // VA: { name: 'Vatican City', code: 'VA' },
    // VE: { name: 'Venezuela', code: 'VE' },
    // VN: { name: 'Vietnam', code: 'VN' },
    // WF: { name: 'Wallis and Futuna', code: 'WF' },
    // EH: { name: 'Western Sahara', code: 'EH' },
    // YE: { name: 'Yemen', code: 'YE' },
    // ZM: { name: 'Zambia', code: 'ZM' },
    // ZW: { name: 'Zimbabwe', code: 'ZW' },
    // AX: { name: 'Åland', code: 'AX' }
};

export function isCountryCode(value: string) {
    return !!countriesByCode[value];
}

export function isCountryName(value: string) {
    return !!countriesByNameLower[value.toLowerCase()];
}
