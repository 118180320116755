<div id="cms-warranty-policy">
  <section id="policy-title" class="fx-wrap fx-row-container mt-4">
    <div class="col-sm-12">
      <h1>{{ entry?.title }}</h1>
      <hr class="divider" />
    </div>
  </section>

  <section id="policy-table" class="fx-wrap fx-row-container mt-4">
    <div class="col-sm-3">
      <h2>Table of Contents</h2>
    </div>
    <div class="col-sm-5">
      <div class="fx-row fx-wrap">
        <div *ngFor="let group of entry?.policy_row" class="col-xl-12 px-0 pb-2">
          <a class="button-primary button-base" (click)="onClick(group.policy_row_title)">{{
            group.policy_row_title
          }}</a>
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <img *ngIf="entry?.policy_image?.href" [src]="entry.policy_image.href" [alt]="entry.policy_image.title" />
    </div>
  </section>

  <section
    *ngFor="let group of entry?.policy_row"
    id="{{ getSectionId(group?.policy_row_title) }}"
    class="fx-wrap fx-row-container"
  >
    <hr class="divider mx-2" />
    <div class="col-sm-3">
      <h2>{{ group.policy_row_title }}</h2>
    </div>
    <div class="col-sm-9">
      <p [innerHTML]="group.policy_row_copy"></p>
    </div>
  </section>
</div>
