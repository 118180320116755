import { Component, OnInit } from '@angular/core';

import { CmsService } from 'lib/services/cms.service';
import { ExxComComponentClass } from 'lib/components/exxcom-component.class';
import { ExxComError } from 'lib/classes/exxcom-error.class';

const scriptName = 'cms-applications-forms.component';

@Component({
    selector: 'app-cms-applications-forms',
    templateUrl: './cms-applications-forms.component.html',
    styleUrls: ['./cms-applications-forms.component.scss'],
})
export class CmsApplicationsFormsComponent extends ExxComComponentClass implements OnInit {
    entry: any = {};

    constructor(private cmsService: CmsService) {
        super();
    }

    async ngOnInit() {
        try {
            this.entry = await this.cmsService.getPage('applications_and_forms');
        } catch (err) {
            console.error(...new ExxComError(452724, scriptName, err).stamp());
        }
    }
}
