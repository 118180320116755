import { Inject, Injectable } from '@angular/core';

import { AccountService } from 'lib/services/account/account.service';
import { ApiService } from 'lib/services/api.service';
import { Cart } from 'lib/services/cart/cart.class';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { ExxComResponse } from 'lib/interfaces/exxcom-response.interface';
import { get } from 'lodash';
import { isBrowser, multiply, waitFor } from 'lib/tools';
import { Order } from 'lib/services/order/order.class';
import { RouterService } from 'lib/services/router.service';
import { StripeCardResponse, StripeCardUpdateData } from 'lib/services/stripe/stripe.interface';

const scriptName = 'stripe.service';

declare let Stripe: any;
const stripe: any = { exx: {}, spc: {} };
const elements: any = { exx: {}, spc: {} };

let accountService: AccountService;
let apiService: ApiService;
let environment: any;

@Injectable()
export class StripeService {
    private isInitialized: boolean = false;

    constructor(@Inject('environment') e: any, ac: AccountService, ap: ApiService, r: RouterService) {
        try {
            accountService = ac;
            apiService = ap;
            environment = e;
        } catch (err) {
            console.error(...new ExxComError(209935, scriptName, err).stamp());
        }
    }

    async init() {
        try {
            if (!isBrowser() || !(await waitFor(() => !!Stripe, '3s'))) {
                return;
            }
            // Both publishable keys are required for the ExxCom environment
            const exxPublishableKey = get(environment, `sites.exx.stripe.${environment.envAbbr}.publishableKey`);
            const spcPublishableKey = get(environment, `sites.spc.stripe.${environment.envAbbr}.publishableKey`);
            // If a site-specific environment is loaded, then the key from site-specific environment is used
            const publishableKey = get(environment, `stripe.${environment.envAbbr}.publishableKey`);
            if ((environment.siteAbbr == 'exc' && exxPublishableKey) || environment.siteAbbr == 'exx') {
                stripe.exx = Stripe(exxPublishableKey || publishableKey);
                elements.exx = stripe.exx.elements();
            }
            if ((environment.siteAbbr == 'exc' && spcPublishableKey) || environment.siteAbbr == 'spc') {
                stripe.spc = Stripe(spcPublishableKey || publishableKey);
                elements.spc = stripe.spc.elements();
            }
            this.isInitialized = true;
        } catch (err) {
            console.error(...new ExxComError(778451, scriptName, err).stamp());
        }
    }

    // Client

    /**
     * @function createCardElement
     * @param {Object} options - Stripe card creation options
     * @description
     * https://stripe.com/docs/js/elements_object/create_element?type=card
     * Options: https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options
     * Options style object: https://stripe.com/docs/js/appendix/style
     * CSS: https://stripe.com/docs/stripe-js
     *
     * Stripe Elements only allows one card to exist on the elements instance at
     * a time. For that reason, if a card element has already been created, then
     * the existing card element is destroyed. Then a new card is created.
     */
    createCardElement({
        options,
        siteAbbr,
        onChange,
        onBlur,
    }: {
        options?: any;
        siteAbbr?: string;
        onChange?: Function;
        onBlur?: Function;
    } = {}) {
        try {
            if (!isBrowser() || !this.isInitialized) {
                return;
            }
            let cardElement = elements[siteAbbr || environment.siteAbbr].getElement('card');
            if (cardElement) {
                cardElement.destroy();
            }
            cardElement = elements[siteAbbr || environment.siteAbbr].create('card', options);
            if (onChange) {
                cardElement.on('change', (event: any) => onChange(event));
            }
            if (onBlur) {
                cardElement.on('blur', (event: any) => onBlur(event));
            }
            return cardElement;
        } catch (err) {
            console.error(...new ExxComError(592875, scriptName, err).stamp());
        }
    }

    /**
     * @function createToken
     * @param {Object} card - A Stripe card element
     * @param {Object} data - Data used when creating the token
     * @param {String} [siteAbbr] - A site abbreviation (for use with the ExxCom UI)
     * @description
     * https://stripe.com/docs/js/tokens_sources/create_token?type=cardElement
     */
    async createToken({ card, data, siteAbbr }: { card: any; data: any; siteAbbr?: string }): Promise<any> {
        try {
            if (!isBrowser() || !this.isInitialized) {
                return;
            }
            const res = await stripe[siteAbbr || environment.siteAbbr].createToken(card, data);
            if (res.error) {
                throw res;
            }
            res.success = true;
            return res;
        } catch (err) {
            return err;
        }
    }

    // Server core API

    /**
     * @function request
     * @description A generalized function for making requests to the ExxCom
     * Stripe endpoints. For error handling where the functions that call request
     * are called (e.g., createCard), use the standard pattern:
     *   try {
     *     const res = await stripeService.createCard(endpoint, action, { ... });
     *     if (!res.success) { throw res; }
     *     ...
     *   } catch(err) { console.error(...new ExxComError(######, scriptName, err).stamp()); }
     */
    private async request(
        endpoint: string,
        action: string,
        {
            primaryId,
            secondaryId,
            siteAbbr,
            values,
        }: {
            primaryId?: string;
            secondaryId?: string;
            siteAbbr?: string;
            values?: any;
        } = {
            primaryId: null,
            secondaryId: null,
            siteAbbr: null,
            values: {},
        }
    ): Promise<ExxComResponse> {
        try {
            if (!isBrowser() || !this.isInitialized) {
                return;
            }
            const url = [`stripe/${endpoint}/${action}`, `/${primaryId || accountService.account.stripeId}`];
            if (secondaryId) {
                url.push(`/${secondaryId}`);
            }
            if (siteAbbr) {
                url.push(`/${siteAbbr}`);
            }
            let res: ExxComResponse;
            if (action == 'delete') {
                res = await apiService.delete(url.join(''));
            } else {
                res = await apiService.post(url.join(''), values);
            }
            if (!res.success) {
                throw res;
            }
            return res;
        } catch (err) {
            return err;
        }
    }

    // Server cards API

    async getCards({
        stripeCustomerId,
        stripeCardId,
        siteAbbr,
    }: {
        stripeCustomerId?: string;
        stripeCardId?: any;
        siteAbbr?: string;
    } = {}): Promise<StripeCardResponse> {
        try {
            const filters: any = {};
            if (stripeCustomerId) {
                filters.stripeCustomerId = stripeCustomerId;
            }
            if (stripeCardId) {
                filters.stripeCardId = stripeCardId;
            }
            const url = [`stripe/cards/find${siteAbbr ? '/' + siteAbbr : ''}`, `?filters=${JSON.stringify(filters)}`];
            const res: StripeCardResponse = await apiService.get(url);
            if (!res.success) {
                throw res;
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(342927, scriptName, err).stamp());
            return { success: false, error: err } as ExxComResponse;
        }
    }

    createCard(
        {
            stripeTokenId,
            stripeCustomerId,
            siteAbbr,
        }: {
            stripeTokenId: string;
            stripeCustomerId?: string;
            siteAbbr?: string;
        } = { stripeTokenId: null }
    ): Promise<ExxComResponse> {
        return this.request('cards', 'create', {
            primaryId: stripeCustomerId,
            secondaryId: stripeTokenId,
            siteAbbr,
        });
    }

    /**
     * @function updateCard
     * @description Updats values for the fields specified in the
     * StripeCardUpdateData interface. If a value (except for expMonth and
     * expYear) is set to empty, then it will clear the value in Stripe, so it is
     * important to pass in only the key: value pairs that need to be updated.
     */
    updateCard(
        {
            stripeCardId,
            stripeCustomerId,
            values,
            siteAbbr,
        }: {
            stripeCardId: string;
            stripeCustomerId?: string;
            values?: StripeCardUpdateData;
            siteAbbr?: string;
        } = { stripeCardId: null }
    ): Promise<ExxComResponse> {
        return this.request('cards', 'update', {
            primaryId: stripeCustomerId,
            secondaryId: stripeCardId,
            values,
            siteAbbr,
        });
    }

    deleteCard(
        {
            stripeCardId,
            stripeCustomerId,
            siteAbbr,
        }: {
            stripeCardId: string;
            stripeCustomerId?: string;
            siteAbbr?: string;
        } = { stripeCardId: null }
    ): Promise<ExxComResponse> {
        return this.request('cards', 'delete', {
            primaryId: stripeCustomerId,
            secondaryId: stripeCardId,
            siteAbbr,
        });
    }

    // Server customers API

    updateCustomer(
        {
            stripeCardId,
            stripeCustomerId,
            values,
            siteAbbr,
        }: {
            stripeCardId: string;
            stripeCustomerId?: string;
            values?: any;
            siteAbbr?: string;
        } = { stripeCardId: null }
    ): Promise<ExxComResponse> {
        return this.request('customers', 'update', {
            primaryId: stripeCustomerId,
            secondaryId: stripeCardId,
            values,
            siteAbbr,
        });
    }

    // Server payment intents API

    /**
     * @function findPaymentIntent
     */
    async findPaymentIntent({ intentId, siteAbbr }: { intentId: string; siteAbbr?: string }): Promise<ExxComResponse> {
        try {
            const res: ExxComResponse = await this.request('payment-intents', 'find', { primaryId: intentId, siteAbbr });
            if (!res.success) {
                throw res;
            }
            return res;
        } catch (err) {
            return err;
        }
    }

    /**
     * @function createPaymentIntent
     * @description Stripe recommends creating a payment intent as soon as the
     * total of the order is known. They also recommend persisting the payment
     * intent along with the cart throughout the checkout process.
     *
     * createPaymentIntent calls the Stripe payment intents create endpoint, which
     * calls the server-side Stripe service, which creates the payment intent and
     * saves it on the ExxCom cart or order. If the cart or order already has a
     * payment intent and client secret, then it simply returns the existing
     * payment data from the database cart or order.
     *
     * If the payment intent was not created because it already existed and the
     * data was simply returned, the data would already be on the cart or order
     * that was retrieved from the database when the cart was initialized in the
     * client-side cart service, so even though it is set again after the response
     * is returned from the ExxCom Stripe endpoint, the only time it MUST be set
     * is if a payment intent was created.
     *
     * Either a cart instance or an order instance is required.
     *
     * createPaymentIntent is called in,
     * - checkout.component > ngOnInit > initPaymentIntent > cart.changed$
     * - orders.component > testStage9_submitOrder
     *
     * Reference:
     * https://stripe.com/docs/payments/payment-intents
     * https://stripe.com/docs/payments/accept-a-payment?integration=elements
     * Display additional card action modal using 4000002500003155
     */
    async createPaymentIntent({
        stripeCustomerId,
        cart,
        order,
        siteAbbr,
    }: {
        stripeCustomerId?: string;
        cart?: Cart;
        order?: Order;
        siteAbbr?: string;
    }) {
        try {
            const res: ExxComResponse = await this.request('payment-intents', 'create', {
                primaryId: stripeCustomerId,
                secondaryId: get(cart, '_id') || get(order, '_id'),
                siteAbbr,
            });
            if (!res.success) {
                throw res;
            }
            if (cart) {
                const d = get(res, 'data', {});
                cart.stripePayment = {
                    amount: d.amount,
                    clientSecret: d.client_secret,
                    intentId: d.id,
                };
            }
            return res;
        } catch (err) {
            return err;
        }
    }

    /**
     * @function updatePaymentIntent
     * @description Although it is primarily used to update the payment intent
     * amount in Stripe, it could be used to update any of the payment intent
     * parameters. Architectural thought would need to go into that change,
     * however.
     *
     * Either a cart instance or an order instance is required.
     *
     * updatePaymentIntent is used in,
     * - checkout.component > ngOnInit > initPaymentIntent > cart.changed$
     *
     * Reference:
     * https://stripe.com/docs/api/payment_intents/update?lang=node
     */
    async updatePaymentIntent({
        values,
        cart,
        order,
        siteAbbr,
    }: {
        values: any;
        cart?: Cart;
        order?: Order;
        siteAbbr?: string;
    }): Promise<ExxComResponse> {
        try {
            const instance = cart || order;
            const intentId = get(instance, 'stripePayment.intentId');
            const res: any = await this.findPaymentIntent({
                intentId,
                siteAbbr,
            });
            if (!res.success) {
                return;
            }
            if (get(res, 'data.status') == 'requires_capture' && values.amount) {
                return res;
            } // Handled when attempting to confirm the payment again
            return await this.request('payment-intents', 'update', {
                primaryId: intentId,
                secondaryId: instance._id,
                siteAbbr,
                values,
            });
        } catch (err) {
            return err;
        }
    }

    /**
     * @function cancelPaymentIntent
     * @description
     *
     * Either a cart ID or an order ID is required.
     *
     * Reference:
     * https://stripe.com/docs/api/payment_intents/cancel
     */
    async cancelPaymentIntent({
        intentId,
        cartId,
        orderId,
        reason,
        siteAbbr,
    }: {
        intentId: string;
        cartId?: string;
        orderId?: string;
        reason?: string;
        siteAbbr?: string;
    }) {
        try {
            const res: ExxComResponse = await this.request('payment-intents', 'cancel', {
                primaryId: intentId,
                secondaryId: cartId || orderId,
                siteAbbr,
                values: { reason },
            });
            if (!res.success) {
                throw res;
            }
            return res;
        } catch (err) {
            return err;
        }
    }

    /**
     * @function confirmPaymentIntent
     * @description
     *
     * An order instance is always required.
     *
     * A cart instance is required if loading in a webstore environment.
     *
     * References:
     * https://stripe.com/docs/js/payment_intents/confirm_card_payment?lang=node
     * https://stripe.com/docs/testing
     * 4000002760003184 - This card requires authentication on all transactions,
     * regardless of how the card is set up.
     * 4000008260003178 - This card requires authentication for one-time payments.
     * All payments will be declined with an insufficient_funds failure code even
     * after being successfully authenticated or previously set up.
     */
    async confirmPaymentIntent(
        { order, cart, siteAbbr }: { order: Order; cart?: Cart; siteAbbr?: string } = {
            order: {} as Order,
        }
    ): Promise<any> {
        try {
            let amount = get(order, 'amountTotal');
            if (order.discountedSummary.couponData) {
                amount = order.discountedSummary.summary.total;
            }
            const orderId = get(order, '_id');
            const shippingAddress = get(order, 'addresses.shipping');
            const stripeCustomerId = get(order, 'customer.stripeId');

            const cardId = get(order, 'stripePayment.card.id');
            const clientSecret = get(order, 'stripePayment.clientSecret');
            const intentId = get(order, 'stripePayment.intentId');

            const cartId = get(cart, '_id');

            if (!clientSecret) {
                throw new Error('Payment data not available');
            }
            if (!cardId) {
                throw new Error('Card data not available');
            }

            let res: any = await this.findPaymentIntent({ intentId, siteAbbr });
            if (res.error) {
                res.success = false;
                return res;
            }

            const status = get(res, 'data.status');
            const piAmount = get(res, 'data.amount');

            if (status == 'requires_capture') {
                if (piAmount == multiply(amount, 100)) {
                    res = { paymentIntent: res.data };
                } else {
                    res = await this.cancelPaymentIntent({
                        intentId,
                        reason: 'duplicate',
                        cartId,
                        orderId,
                        siteAbbr,
                    });
                    if (res.error) {
                        res.success = false;
                        return res;
                    }
                    res = await this.createPaymentIntent({
                        stripeCustomerId,
                        cart,
                        order,
                        siteAbbr,
                    });
                    if (res.error) {
                        res.success = false;
                        return res;
                    }
                }
            } else {
                res = await stripe[siteAbbr || environment.siteAbbr].confirmCardPayment(clientSecret, {
                    payment_method: cardId,
                    shipping: {
                        name: shippingAddress.addressee,
                        address: {
                            line1: shippingAddress.address1,
                            city: shippingAddress.city,
                            state: shippingAddress.state,
                            postal_code: shippingAddress.zip,
                        },
                    },
                });

                const errorCode = get(res, 'error.code', {});
                /**
                 * In this case it's because the payment was already confirmed. "The
                 * PaymentIntent's state was incompatible with the operation you were
                 * trying to perform." This may happen if there was no error when
                 * confirming the payment with Stripe, but then an error occurred when
                 * trying to create the order in ExxCom, and then the customer submitted
                 * the order again. Suppressing the error allows the customer to
                 * resubmit the order.
                 * https://stripe.com/docs/error-codes#payment-intent-unexpected-state
                 */
                if (errorCode == 'payment_intent_unexpected_state') {
                    res = { paymentIntent: res.error.paymentIntent };
                }
            }

            res.success = !res.error;
            return res;
        } catch (err) {
            return err;
        }
    }
}
