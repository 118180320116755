import { BreakpointObserver } from '@angular/cdk/layout';

import { get, isEmpty } from 'lodash';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { BsModalService } from 'ngx-bootstrap/modal';

const scriptName = 'exxcom-component.class';

export class ExxComComponentClass {
    breakpointObserver: BreakpointObserver;
    environment: any;
    isBrowser: boolean;
    isNarrow: boolean;
    isTablet: boolean;
    modalService: BsModalService;
    constructor({ dependencies }: { dependencies?: any } = {}) {
        try {
            if (!isEmpty(dependencies)) {
                Object.keys(dependencies).forEach((k: string) => (this[k] = dependencies[k]));
            }
            if (get(dependencies, 'breakpointObserver')) {
                this.initBreakpointListener();
            }
        } catch (err) {
            console.error(...new ExxComError(295772, scriptName, err).stamp());
        }
    }

    initBreakpointListener() {
        try {
            this.breakpointObserver
                .observe(['only screen and (min-width: 1100px)', 'only screen and (min-width: 576px)'])
                .subscribe((result: any) => {
                    this.isNarrow = !result.breakpoints['only screen and (min-width: 1100px)'];
                    this.isTablet = result.breakpoints['only screen and (min-width: 576px)'] && this.isNarrow;
                });
        } catch (err) {
            console.error(...new ExxComError(650050, scriptName, err).stamp());
        }
    }

    // HTML helpers

    isEmpty(ob: any) {
        return isEmpty(ob);
    }

    get(ob: any, args: any, defaultValue?: any) {
        return get(ob, args, defaultValue);
    }
}
