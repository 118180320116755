<section *ngIf="get(coloredBlockRow, 'colored_block.colored_block', false)" id="colored-block-section" class="mx-2">
  <div *ngFor="let row of get(coloredBlockRow, 'colored_block.colored_block', [])">
    <div
      class="container d-flex justify-content-center color-block colored-block container-fluid"
      [ngStyle]="{
        'background-color': get(row, 'color_picker[0]', ''),
        'background-repeat': 'no-repeat'
      }"
    >
      <div class="grid partial-grid justify-content-between">
        <div class="g-col-12 g-col-md-6 order-md-2 p-0 img-block-container">
          <img [src]="getWebpImg(get(row, 'background_design_link.href', ''))" class="block-img-bkg" />
          <img [src]="getWebpImg(get(row, 'image.href', ''))" class="block-img" />
        </div>
        <div class="g-col-12 g-col-md-6 order-md-1 fx-col-v-center text-content-padding">
          <h5 class="color-block-label mb-base text-center text-md-start">
            {{ get(row, "label", "") }}
          </h5>
          <h2 class="mb-3 text-center text-md-start">{{ get(row, "headline", "") }}</h2>
          <div [innerHtml]="get(row, 'copy', '') | safe: 'html'" class="copy-content"></div>
          <!-- Marketo Form Button -->
          <div class="d-flex flex-row justify-content-md-start justify-content-center">
            <button
              *ngIf="get(row, 'marketo_forms.id', '') && get(row, 'link.routerLink', '') == ''"
              class="button-base button-color-block d-flex flex-row justify-content-center align-items-center align-self-start"
              (click)="
                bootstrapMarketoService.openBootstrapMarketoDialog(get(row, 'marketo_forms.id', ''), 'edit', {
                  url: dataUrl,
                  title: dataTitle
                })
              "
            >
              <span>{{ get(row, "link.title", "") }}</span>
            </button>
          </div>
          <!-- RouterLink Button -->
          <div class="d-flex flex-row justify-content-md-start justify-content-center">
            <button
              *ngIf="get(row, 'link.routerLink', '') !== ''"
              class="button-base button-color-block d-flex flex-row justify-content-center align-items-center align-self-start"
              (click)="buttonLinkRouter(get(row, 'link.routerLink', '/'))"
            >
              <span>{{ get(row, "link.title", "") }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<hr *ngIf="get(coloredBlockRow, 'colored_block.split_line', '')" class="m-0" />
