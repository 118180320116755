// https://gist.github.com/mshafrir/2646763
export const states = [
    { name: 'Alabama', code: 'AL' },
    { name: 'Alaska', code: 'AK' },
    { name: 'American Samoa', code: 'AS' },
    { name: 'Arizona', code: 'AZ' },
    { name: 'Arkansas', code: 'AR' },
    { name: 'California', code: 'CA' },
    { name: 'Colorado', code: 'CO' },
    { name: 'Connecticut', code: 'CT' },
    { name: 'Delaware', code: 'DE' },
    { name: 'District Of Columbia', code: 'DC' },
    { name: 'Federated States Of Micronesia', code: 'FM' },
    { name: 'Florida', code: 'FL' },
    { name: 'Georgia', code: 'GA' },
    { name: 'Guam', code: 'GU' },
    { name: 'Hawaii', code: 'HI' },
    { name: 'Idaho', code: 'ID' },
    { name: 'Illinois', code: 'IL' },
    { name: 'Indiana', code: 'IN' },
    { name: 'Iowa', code: 'IA' },
    { name: 'Kansas', code: 'KS' },
    { name: 'Kentucky', code: 'KY' },
    { name: 'Louisiana', code: 'LA' },
    { name: 'Maine', code: 'ME' },
    { name: 'Marshall Islands', code: 'MH' },
    { name: 'Maryland', code: 'MD' },
    { name: 'Massachusetts', code: 'MA' },
    { name: 'Michigan', code: 'MI' },
    { name: 'Minnesota', code: 'MN' },
    { name: 'Mississippi', code: 'MS' },
    { name: 'Missouri', code: 'MO' },
    { name: 'Montana', code: 'MT' },
    { name: 'Nebraska', code: 'NE' },
    { name: 'Nevada', code: 'NV' },
    { name: 'New Hampshire', code: 'NH' },
    { name: 'New Jersey', code: 'NJ' },
    { name: 'New Mexico', code: 'NM' },
    { name: 'New York', code: 'NY' },
    { name: 'North Carolina', code: 'NC' },
    { name: 'North Dakota', code: 'ND' },
    { name: 'Northern Mariana Islands', code: 'MP' },
    { name: 'Ohio', code: 'OH' },
    { name: 'Oklahoma', code: 'OK' },
    { name: 'Oregon', code: 'OR' },
    { name: 'Palau', code: 'PW' },
    { name: 'Pennsylvania', code: 'PA' },
    { name: 'Puerto Rico', code: 'PR' },
    { name: 'Rhode Island', code: 'RI' },
    { name: 'South Carolina', code: 'SC' },
    { name: 'South Dakota', code: 'SD' },
    { name: 'Tennessee', code: 'TN' },
    { name: 'Texas', code: 'TX' },
    { name: 'Utah', code: 'UT' },
    { name: 'Vermont', code: 'VT' },
    { name: 'Virgin Islands', code: 'VI' },
    { name: 'Virginia', code: 'VA' },
    { name: 'Washington', code: 'WA' },
    { name: 'West Virginia', code: 'WV' },
    { name: 'Wisconsin', code: 'WI' },
    { name: 'Wyoming', code: 'WY' },
];

export const statesByCodeLower = {
    ak: { name: 'Alaska', code: 'AK' },
    al: { name: 'Alabama', code: 'AL' },
    ar: { name: 'Arkansas', code: 'AR' },
    as: { name: 'American Samoa', code: 'AS' },
    az: { name: 'Arizona', code: 'AZ' },
    ca: { name: 'California', code: 'CA' },
    co: { name: 'Colorado', code: 'CO' },
    ct: { name: 'Connecticut', code: 'CT' },
    dc: { name: 'District Of Columbia', code: 'DC' },
    de: { name: 'Delaware', code: 'DE' },
    fl: { name: 'Florida', code: 'FL' },
    fm: { name: 'Federated States Of Micronesia', code: 'FM' },
    ga: { name: 'Georgia', code: 'GA' },
    gu: { name: 'Guam', code: 'GU' },
    hi: { name: 'Hawaii', code: 'HI' },
    ia: { name: 'Iowa', code: 'IA' },
    id: { name: 'Idaho', code: 'ID' },
    il: { name: 'Illinois', code: 'IL' },
    in: { name: 'Indiana', code: 'IN' },
    ks: { name: 'Kansas', code: 'KS' },
    ky: { name: 'Kentucky', code: 'KY' },
    la: { name: 'Louisiana', code: 'LA' },
    ma: { name: 'Massachusetts', code: 'MA' },
    md: { name: 'Maryland', code: 'MD' },
    me: { name: 'Maine', code: 'ME' },
    mh: { name: 'Marshall Islands', code: 'MH' },
    mi: { name: 'Michigan', code: 'MI' },
    mn: { name: 'Minnesota', code: 'MN' },
    mo: { name: 'Missouri', code: 'MO' },
    mp: { name: 'Northern Mariana Islands', code: 'MP' },
    ms: { name: 'Mississippi', code: 'MS' },
    mt: { name: 'Montana', code: 'MT' },
    nc: { name: 'North Carolina', code: 'NC' },
    nd: { name: 'North Dakota', code: 'ND' },
    ne: { name: 'Nebraska', code: 'NE' },
    nh: { name: 'New Hampshire', code: 'NH' },
    nj: { name: 'New Jersey', code: 'NJ' },
    nm: { name: 'New Mexico', code: 'NM' },
    nv: { name: 'Nevada', code: 'NV' },
    ny: { name: 'New York', code: 'NY' },
    oh: { name: 'Ohio', code: 'OH' },
    ok: { name: 'Oklahoma', code: 'OK' },
    or: { name: 'Oregon', code: 'OR' },
    pa: { name: 'Pennsylvania', code: 'PA' },
    pr: { name: 'Puerto Rico', code: 'PR' },
    pw: { name: 'Palau', code: 'PW' },
    ri: { name: 'Rhode Island', code: 'RI' },
    sc: { name: 'South Carolina', code: 'SC' },
    sd: { name: 'South Dakota', code: 'SD' },
    tn: { name: 'Tennessee', code: 'TN' },
    tx: { name: 'Texas', code: 'TX' },
    ut: { name: 'Utah', code: 'UT' },
    va: { name: 'Virginia', code: 'VA' },
    vi: { name: 'Virgin Islands', code: 'VI' },
    vt: { name: 'Vermont', code: 'VT' },
    wa: { name: 'Washington', code: 'WA' },
    wi: { name: 'Wisconsin', code: 'WI' },
    wv: { name: 'West Virginia', code: 'WV' },
    wy: { name: 'Wyoming', code: 'WY' },
};
