import { SimpleChanges } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { contains } from 'lib/tools';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { Role } from 'lib/services/role/role.class';
import { RoleData } from 'lib/services/role/role.interface';
import { UserData } from 'lib/services/user/user.interface';

const scriptName = 'user.class';

export class User {
    // Schema

    _id: string = null;
    email: string = null;
    first: string = null;
    last: string = null;
    roles: string[] | RoleData[] | Role[] = [];

    // Client-side only

    changed$: BehaviorSubject<SimpleChanges> = new BehaviorSubject<SimpleChanges>(null);

    constructor(data?: UserData) {
        if (data) {
            this.init(data);
        }
    }

    init(data: UserData) {
        try {
            const changes: SimpleChanges = {};

            this._id = data._id || this._id || null;
            this.email = data.email || this.email || null;
            this.first = data.first || this.first || null;
            this.last = data.last || this.last || null;
            this.roles = data.roles ? (data.roles as RoleData[]).map((data: RoleData) => new Role(data)) : this.roles || [];

            this.changed$.next(changes);
        } catch (err) {
            console.error(...new ExxComError(620377, scriptName, err).stamp());
        }
    }

    /**
     * @function hasRole
     * @param {Sting[]} names - An array of role names
     * @description Determines if the user has any one of the roles listed in the
     * names array.
     */
    hasRole(names: string[]): boolean {
        try {
            if (!this.roles) {
                return false;
            }
            const userRoleNames = (this.roles as Role[]).map((role: Role) => role.name);
            const hasRole = contains(userRoleNames, names);
            return hasRole;
        } catch (err) {
            console.error(...new ExxComError(240287, scriptName, err).stamp());
        }
    }

    /**
     * @function hasPermission
     * @param {String[]} name - An array of permission names
     * @description Determines if the user has any one of the permissions listed
     * in the names array.
     */
    hasPermission(names: string[]): boolean {
        try {
            if (!this.roles) {
                return false;
            }
            const userPermissions = (this.roles as Role[]).reduce((permissions: any, role: Role) => {
                permissions.push(role.permissions);
                return permissions;
            }, []);
            const hasPermission = contains(userPermissions, names);
            return hasPermission;
        } catch (err) {
            console.error(...new ExxComError(204761, scriptName, err).stamp());
        }
    }
}
