import { Component, Input } from '@angular/core';
import { CmsPartialComponentClass } from 'lib/components/cms/cms-partial-component.class';
import { WebpService } from 'lib/services/webp.service';
import { v4 as uuid } from 'uuid';

@Component({
    selector: 'app-image-zoom',
    templateUrl: './image-zoom.component.html',
    styleUrls: ['./image-zoom.component.scss'],
})
export class ImageZoomComponent extends CmsPartialComponentClass {
    @Input() image: string;
    @Input() altText: string;
    uniqueId: number = uuid();

    getWebpImg: (src: string) => string;

    constructor(webpService: WebpService) {
        super({
            dependencies: {
                webpService,
            },
        });
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
    }
}
