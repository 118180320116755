<ng-container *ngIf="!isEmpty(get(imageAndTextRow, 'image_and_text', ''))">
  <div *ngFor="let row of get(imageAndTextRow, 'image_and_text.image_and_text', []); let i = index">
    <section id="rows" [class.gray-bg]="get(imageAndTextRow, 'image_and_text.gray_background', false)">
      <div
        *ngIf="isEmpty(get(imageAndTextRow, 'image_and_text.image_and_text[0].button_row', false))"
        class="grid container partial-grid"
      >
        <div
          class="g-col-lg-6 g-col-12 d-flex flex-column justify-content-center enlargement-container"
          [class.order-1]="get(row, 'style', '') === 'Image on the right' && !isNarrow"
        >
          <div class="enlargement-positioning">
            <img
              class="enlargement-image"
              *ngIf="get(row, 'image_link', '') && get(row, 'image_enlargement', '') === true"
              [src]="getWebpImg(get(row, 'image_link.href', ''))"
              [alt]="row.image_link.title"
              data-bs-toggle="modal"
              data-backdrop="true"
              [attr.data-bs-target]="'#imageEnlargement-' + index"
            />

            <img
              *ngIf="get(row, 'image_link', '') && get(row, 'image_enlargement', '') !== true"
              [src]="getWebpImg(get(row, 'image_link.href', ''))"
              [alt]="row.image_link.title"
            />

            <div class="enlargement-gradient">
              <div class="maximize-icon"><mat-icon svgIcon="maximize"></mat-icon></div>
              <div class="image-enlargement-overlay"></div>
            </div>
          </div>
        </div>
        <div
          class="g-col-lg-6 g-col-12 d-flex flex-column justify-content-center mt-4"
          [class.order-0]="get(row, 'style', '') === 'Image on the right' && !isNarrow"
        >
          <div class="injected-html-content" [innerHTML]="get(row, 'text', '')"></div>
        </div>
      </div>
      <div
        *ngIf="!isEmpty(get(imageAndTextRow, 'image_and_text.image_and_text[0].button_row', false))"
        class="container"
        id="dynamic-buttons"
      >
        <div class="grid">
          <h2 *ngIf="isNarrow" class="g-col-12">
            {{ get(imageAndTextRow, "image_and_text.image_and_text[0].title", "") }}
          </h2>
          <div
            class="g-col-12 g-col-lg-6 d-flex flex-row align-items-center"
            [class.justify-content-center]="!isNarrow"
            id="button-row-image-container"
          >
            <img
              id="button-row-image"
              class="h-100"
              #buttonRowImageElement
              [src]="
                currentButtonRowImage !== ''
                  ? currentButtonRowImage
                  : imageAndTextRow.image_and_text.image_and_text[0].button_row[0].button_image.href
              "
              [alt]="
                currentButtonRowImage !== ''
                  ? currentButtonRowImageAlt
                  : imageAndTextRow.image_and_text.image_and_text[0].button_row[0].button_image.title
              "
            />
          </div>
          <div id="button-row-and-text-container" class="g-col-12 g-col-lg-6 d-flex flex-column justify-content-center">
            <h2 *ngIf="!isNarrow">{{ get(imageAndTextRow, "image_and_text.image_and_text[0].title", "") }}</h2>
            <div class="button-row-container">
              <div class="button-row-margins">
                <button
                  class="button button-gradient button-row-button"
                  *ngFor="
                    let button of get(imageAndTextRow, 'image_and_text.image_and_text[0].button_row', []);
                    let i = index
                  "
                  id="{{ 'button-' + i }}"
                  [class.active]="'button-' + i === activeButtonId || (i === 0 && activeButtonId === '')"
                  (click)="setActiveButtonAndImage(i, button.button_image.href)"
                >
                  {{ button.button_text }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</ng-container>
<hr *ngIf="get(imageAndTextRow, 'image_and_text.split_line', '')" class="m-0" />

<!-- Image enlargement modal -->
<div class="modal fade" [id]="'imageEnlargement-' + index" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <img
          class="enlargement-properties"
          [src]="getWebpImg(get(imageAndTextRow.image_and_text.image_and_text, '[0].image_link.href', ''))"
          [alt]="get(imageAndTextRow.image_and_text.image_and_text, 'image_link.title', '')"
        />
      </div>
    </div>
  </div>
</div>
