import { Component, Inject, Input } from '@angular/core';
import { Location } from '@angular/common';
import { BreakpointObserver } from '@angular/cdk/layout';
import { CmsPartialComponentClass } from 'lib/components/cms/cms-partial-component.class';
import { BootstrapMarketoService } from 'lib/services/bootstrap/bootstrap-marketo.service';
import { RouterService } from 'lib/services/router.service';
import { WebpService } from 'lib/services/webp.service';
import { CategoryService } from 'lib/services/category/category.service';

@Component({
    selector: 'cms-partial-category-top-hero-banner',
    templateUrl: './hero-banner.component.html',
    styleUrls: ['./hero-banner.component.scss'],
})
export class HeroBannerComponent extends CmsPartialComponentClass {
    @Input() heroBannerRow: any;
    @Input() dataUrl: string;
    @Input() dataTitle: string;
    @Input() grayBackgroundRows: any = [];
    getWebpImg: (src: string) => string;

    constructor(
        @Inject('environment') environment: any,
        breakpointObserver: BreakpointObserver,
        routerService: RouterService,
        public bootstrapMarketoService: BootstrapMarketoService,
        webpService: WebpService,
        location: Location,
        categoryService: CategoryService
    ) {
        super({
            dependencies: {
                breakpointObserver,
                bootstrapMarketoService,
                environment,
                routerService,
                location,
                categoryService,
            },
        });
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
    }
}
