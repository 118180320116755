import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ExxComError } from 'lib/classes/exxcom-error.class';
import { take } from 'rxjs/operators';

const scriptName = 'auth-dialog.component';

@Component({
    selector: 'app-auth-dialog',
    templateUrl: './auth-dialog.component.html',
    styleUrls: ['./auth-dialog.component.scss'],
})
export class AuthDialogComponent implements OnInit {
    type: string = 'customer'; // Required. Options: customer, guest
    isInitialized: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<AuthDialogComponent>
    ) {}

    ngOnInit() {
        try {
            this.dialogRef
                .afterOpened()
                .pipe(take(1))
                .subscribe((res: any) => (this.isInitialized = true));
        } catch (err) {
            console.error(...new ExxComError(628773, scriptName, err).stamp());
        }
    }
}
