import { OnInit, Directive } from '@angular/core';

import { CmsBlogComponentClass } from 'lib/components/cms/blog/cms-blog-component.class';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { isBrowser } from 'lib/tools';

const scriptName = 'cms-blog-header-component.class';

@Directive()
export class CmsBlogHeaderComponentClass extends CmsBlogComponentClass implements OnInit {
    // Properties: public

    isNarrow: boolean;
    displayMagnifier: boolean = true;
    menuActive: boolean;
    isBrowser: boolean = isBrowser();
    overlayVisible: boolean = false;
    overlayZindex: number = 100;
    searchActive: boolean;

    constructor({ dependencies }) {
        super({ dependencies });
    }

    ngOnInit() {
        try {
            this.initBreakpointObserver();
        } catch (err) {
            console.error(...new ExxComError(110977, scriptName, err).stamp());
        }
    }

    private initBreakpointObserver() {
        try {
            this.breakpointObserver.observe(['only screen and (min-width: 1100px)']).subscribe((result) => {
                this.isNarrow = !result.matches;
                /**
                 * Everything closes when the breakpoint changes. The overlay is
                 * controlled in here. Closing the individual menus is controlled in the
                 * child components.
                 */
                this.overlayVisible = false;
                this.menuActive = false;
                this.searchActive = false;
                this.displayMagnifier = true;
            });
        } catch (err) {
            console.error(...new ExxComError(834996, scriptName, err).stamp());
        }
    }

    private setOverlayVisibility() {
        try {
            this.overlayVisible = this.menuActive || this.searchActive;
        } catch (err) {
            console.error(...new ExxComError(830994, scriptName, err).stamp());
        }
    }

    setMagnifier(display: boolean) {
        try {
            this.displayMagnifier = display;
            this.overlayVisible = !display;
        } catch (err) {
            console.error(...new ExxComError(900923, scriptName, err).stamp());
        }
    }

    onActiveChange(which: string, $event: boolean, zIndex: number) {
        try {
            this[`${which}Active`] = $event;
            this.overlayZindex = zIndex;
            if (this.isNarrow) {
                return;
            }
            this.setOverlayVisibility();
        } catch (err) {
            console.error(...new ExxComError(802991, scriptName, err).stamp());
        }
    }
}
