<section *ngIf="!isEmpty(get(heroBannerRow, 'category_top_banner', ''))" class="m-0">
  <div *ngFor="let row of get(heroBannerRow, 'category_top_banner.category_top_banner', [])">
    <div id="hero-banner">
      <div
        *ngIf="row"
        id="hero-banner-image"
        [style.background-image]="'url(' + getWebpImg(get(row, 'background_image.href', '')) + ')'"
      ></div>
      <div id="hero-banner-content" class="grid container align-items-center p-0">
        <div
          id="hero-banner-text-block"
          [ngClass]="!isNarrow ? 'my-5' : 'pt-3 px-3'"
          class="g-col-12 g-col-lg-6 d-flex flex-column"
          [class.light]="get(row, 'font_color_light_theme')"
        >
          <h5>{{ get(row, "banner_label", "") }}</h5>
          <h1>{{ get(row, "banner_title", "") }}</h1>
          <p class="banner-copy" *ngIf="get(row, 'banner_copy')">{{ get(row, "banner_copy", "") }}</p>
          <div class="fx-row mt-3" *ngIf="get(row, 'button_text_select')">
            <button
              *ngIf="get(row, 'marketo_form.id', '') && get(row, 'banner_button.routerLink', '') == ''"
              class="button-primary button-base d-flex align-items-center"
              (click)="
                bootstrapMarketoService.openBootstrapMarketoDialog(get(row, 'marketo_form.id', ''), 'edit', {
                  title: dataTitle,
                  url: dataUrl
                })
              "
            >
              {{ get(row, "button_text_select", "") }}
              <mat-icon>chevron_right</mat-icon>
            </button>
            <!-- RouterLink Button -->
            <button
              *ngIf="!get(row, 'marketo_form.id', '') && get(row, 'banner_button.href', '')"
              class="button-primary button-base d-flex align-items-center"
              [routerLink]="get(row, 'banner_button.routerLink', '')"
            >
              {{ get(row, "button_text_select", "") }}
              <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
