import * as moment from 'moment';
import { differenceWith, get, isEmpty, isEqual } from 'lodash';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import axios from 'axios';

const scriptName = 'tools';

export const isBrowser = function () {
    try {
        return !!window && !!document;
    } catch (err) {
        return false;
    }
};

export const isDevServer = function () {
    try {
        return isBrowser() && window.location.port == '4200';
    } catch (err) {
        console.error(...new ExxComError(203882, scriptName, err).stamp());
    }
};

export const queryParams = {
    get: (url: string, { decode }: { decode?: boolean } = { decode: true }) => {
        try {
            if (!url || url == 'undefined') {
                return {};
            }
            const urlQueryString = url.indexOf('?') == -1 ? url : url.split('?')[1];
            const urlParams = urlQueryString.indexOf('&') == -1 ? [urlQueryString] : urlQueryString.split('&');
            if (urlParams.length == 0) {
                return {};
            }
            const params: any = {};
            for (let i = 0; i < urlParams.length; i++) {
                let param: string | string[] = urlParams[i];
                if (param.indexOf('=') == -1) {
                    continue;
                }
                param = param.split('=');
                // params[param[0]] = decode ? decodeURIComponent(param[1]) : param[1];
                params[param[0]] = param[1];
            }
            return params;
        } catch (err) {
            console.error(...new ExxComError(534095, scriptName, err).stamp());
        }
    },
    add: (url: string, params: any) => {
        try {
            return queryParams.getUrl(url, params);
        } catch (err) {
            console.error(...new ExxComError(562301, scriptName, err).stamp());
        }
    },
    getUrl: (url: string, params: any) => {
        try {
            if (Object.keys(params).length > 0) {
                url.indexOf('?') == -1 ? (url += '?') : (url += '&');
            }
            return (url += `${Object.keys(params)
                .map((k) => k + '=' + params[k])
                .join('&')}`);
        } catch (err) {
            console.error(...new ExxComError(120932, scriptName, err).stamp());
        }
    },
};

export function contains(searchIn: string[] | string, searchFor: string[] | string) {
    try {
        const contains = (searchIn: string | string[], searchFor: any) => {
            return searchIn && !!searchIn.indexOf ? searchIn.indexOf(searchFor) != -1 : false;
        };
        if (typeof searchIn == 'string' && Array.isArray(searchFor)) {
            let found = false;
            for (let i = 0; i < searchFor.length; i++) {
                if (found) {
                    break;
                }
                found = contains(searchIn, searchFor[i]);
            }
            return found;
        } else if (Array.isArray(searchIn) && Array.isArray(searchFor)) {
            let found = false;
            for (let i = 0; i < searchFor.length; i++) {
                if (found) {
                    break;
                }
                for (let j = 0; j < searchIn.length; j++) {
                    if (found) {
                        break;
                    }
                    found = contains(searchIn[j], searchFor[i]);
                }
            }
            return found;
        } else {
            return contains(searchIn, searchFor);
        }
    } catch (err) {
        console.error(...new ExxComError(520399, scriptName, err).stamp());
    }
}

export const duration = {
    startTime: null,
    start: function () {
        this.startTime = moment();
    },
    get: function () {
        const endTime = moment();
        const duration = moment.duration(endTime.diff(this.startTime), 'ms').asMilliseconds();
        return moment.utc(duration).format('HH:mm:ss.SSS');
    },
    log: function () {
        console.log(this.get());
    },
};

export function copyText(event: any, value: string) {
    // https://stackoverflow.com/questions/49618618/copy-current-url-to-clipboard
    try {
        const copiedMessage = document.createElement('div');
        copiedMessage.style.position = 'absolute';
        copiedMessage.style.display = 'inline-block';
        copiedMessage.style.fontSize = '10px';
        copiedMessage.style.padding = '3px 5px';
        copiedMessage.style.borderRadius = '5px';
        copiedMessage.style.top = `${event.clientY - 30}px`;
        copiedMessage.style.left = `${event.clientX}px`;
        copiedMessage.style.opacity = '0';
        copiedMessage.style.transition = 'opacity .5s';
        copiedMessage.style.backgroundColor = 'DarkGray';
        copiedMessage.style.color = 'White';
        copiedMessage.innerHTML = 'Copied!';

        const toCopy = document.createElement('input');
        toCopy.value = value;
        toCopy.style.position = 'absolute';
        toCopy.style.left = '-10000px';
        document.body.appendChild(toCopy);
        toCopy.select();
        document.execCommand('copy');
        toCopy.remove();

        document.body.appendChild(copiedMessage);
        setTimeout(() => {
            copiedMessage.style.opacity = '.8';
            setTimeout(() => (copiedMessage.style.opacity = '0'), 1000);
            setTimeout(() => copiedMessage.remove(), 2000);
        });
    } catch (err) {
        console.error(...new ExxComError(979933, scriptName, err).stamp());
    }
}

export function timestamp(format?: string) {
    try {
        return `[${moment().format(format || 'MM-DD-YY hh:mm:ssa')}]`;
    } catch (err) {
        console.error(...new ExxComError(902882, scriptName, err).stamp());
    }
}

export function removeProperty(o: any, p: string) {
    try {
        const value = o[p];
        delete o[p];
        return value;
    } catch (err) {
        console.error(...new ExxComError(809333, scriptName, err).stamp());
    }
}

export function getStyleValue(elem: HTMLElement, property: string) {
    try {
        return window.getComputedStyle(elem, null).getPropertyValue(property);
    } catch (err) {
        console.error(...new ExxComError(720934, scriptName, err).stamp());
    }
}

export function getStyleIntValue(elem: HTMLElement, property: string) {
    try {
        const value = getStyleValue(elem, property);
        return parseInt(value.replace('px', ''));
    } catch (err) {
        console.error(...new ExxComError(820394, scriptName, err).stamp());
    }
}

export function iterate(array: any, callee: any, callback?: any) {
    return new Promise((resolve, reject) => {
        try {
            if (!array || array.length == 0) {
                if (callback) {
                    callback([]);
                }
                return resolve([]);
            }
            let i = 0;
            const data = [];
            const each = (i: number) => callee(array[i], next, i, data);
            const push = (d: any) => {
                if ((d && typeof d != 'object') || (typeof d == 'object' && !isEmpty(d))) {
                    data.push(d);
                }
            };
            const next = (d: any) => {
                if (d) {
                    if (d.stop) {
                        if (callback) {
                            callback(data);
                        }
                        return resolve(data);
                    }
                    if (d.error) {
                        return reject(d.error);
                    }
                    push(d);
                }
                i++;
                if (i < array.length) {
                    each(i);
                } else {
                    callback && callback(data);
                    resolve(data);
                }
            };
            each(i);
        } catch (err) {
            console.error(...new ExxComError(733092, scriptName, err).stamp());
        }
    });
}

export function eachRecursive(o: any, callee: Function) {
    // https://stackoverflow.com/questions/2549320/looping-through-an-object-tree-recursively
    if (Array.isArray(o)) {
        for (let i = 0; i < o.length; i++) {
            const v = o[i];
            if (typeof v == 'object' && v !== null) {
                eachRecursive(v, callee);
            } else {
                callee(v, i, o);
            }
        }
    } else {
        for (const k in o) {
            if (Object.prototype.hasOwnProperty.call(o, k)) {
                const v = o[k];
                if (typeof v == 'object' && v !== null) {
                    eachRecursive(v, callee);
                } else {
                    callee(v, k, o);
                }
            }
        }
    }
}

export function loadScript(url: any, { location, async, defer }): Promise<void> {
    return new Promise((resolve) => {
        try {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = url;
            script.async = !!async;
            script.defer = !!defer;
            script.onload = () => resolve();
            if (!location || location == 'head') {
                document.getElementsByTagName('head')[0].appendChild(script);
            } else if (location == 'foot') {
                document.body.appendChild(script);
            }
        } catch (err) {
            console.error(...new ExxComError(762300, scriptName, err).stamp());
        }
    });
}

export function arrayOfObjectsEqual(array1: any[], array2: any[]) {
    try {
        return differenceWith(array1, array2, isEqual).length == 0;
    } catch (err) {
        console.error(...new ExxComError(830945, scriptName, err).stamp());
    }
}

export function isValidDate(d: any) {
    try {
        return d instanceof Date && !isNaN(d as any);
    } catch (err) {
        console.error(...new ExxComError(830499, scriptName, err).stamp());
    }
}

export function saveEmailInBrowser(email: string) {
    try {
        if (!isBrowser() || !email) {
            return;
        }
        localStorage.setItem('email', email);
        cookies.set('email', email);
    } catch (err) {
        console.error(...new ExxComError(830939, scriptName, err).stamp());
    }
}

export function getEmailFromBrowser(req?: any) {
    try {
        if (isBrowser()) {
            return localStorage.getItem('email') || null;
        } else {
            return (req && req.cookies.email) || null;
        }
    } catch (err) {
        console.error(...new ExxComError(298383, scriptName, err).stamp());
        return null;
    }
}

export function removeEmailFromBrowser() {
    try {
        if (!isBrowser()) {
            return;
        }
        localStorage.removeItem('email');
        cookies.remove('email');
    } catch (err) {
        console.error(...new ExxComError(3019938, scriptName, err).stamp());
    }
}

export function initServiceClassBoolean(path: string, dataValue: boolean, instance: any) {
    try {
        if (dataValue === true || dataValue === false) {
            return dataValue;
        }
        const instanceValue = get(instance, path);
        if (instanceValue === true || instanceValue === false) {
            return instanceValue;
        }
        return null;
    } catch (err) {
        console.error(...new ExxComError(520762, scriptName, err).stamp());
    }
}

export function isValidDocId(_id: string) {
    try {
        return _id && /^[0-9A-Fa-f]{24}$/.test(_id);
    } catch (err) {
        console.error(...new ExxComError(132967, scriptName, err).stamp());
    }
}

// //////////////////////////////////////////////////////////////////////////////
// WAIT

export function parseDuration(duration: string | number) {
    let d: number;
    if (!duration) {
        return 0;
    }
    if (typeof duration == 'number') {
        return duration;
    }
    if (contains(duration, 'ms')) {
        d = parseInt(duration.replace('ms', ''));
    } else if (contains(duration, 'm')) {
        d = multiply(parseInt(duration.replace('m', '')), 60000);
    } else if (contains(duration, 's')) {
        d = multiply(parseInt(duration.replace('s', '')), 1000);
    }
    return d; // ms
}

export function wait(duration: string | number): Promise<void> {
    try {
        const d: number = parseDuration(duration);
        return new Promise((resolve) => setTimeout(() => resolve(), d));
    } catch (err) {
        console.error(...new ExxComError(200293, scriptName, err).stamp());
    }
}

/**
 * @function waitFor
 * @param {Function} isTrue
 * @param {String} [duration] - How long to wait for the boolean to be true before stopping iteration
 * @description Waits for "isTrue" to return a truthy value before resolving the
 * promise, or, waits the given duration or 10 seconds by default.
 */
export function waitFor(isTrue: Function, duration?: string) {
    const d: number = parseDuration(duration || '10s');
    return new Promise((resolve, reject) => {
        try {
            let count = 0;
            const interval = setInterval(() => {
                let isAvailable = false;
                try {
                    isAvailable = isTrue();
                } catch (err) {
                    isAvailable = false;
                }
                if (isAvailable || count == d / 100) {
                    clearInterval(interval);
                    resolve(isAvailable);
                }
                count++;
            }, 100);
        } catch (err) {
            console.error(...new ExxComError(443090, scriptName, err).stamp());
        }
    });
}

/**
 * @function onAvailable
 * @param {String} selector - A DOM ID or class selector
 * @returns {Boolean} Indicates if the element became available
 * @description Waits for an element with the given selector to become available
 * within the DOM, and returns a Boolean to indicate whether the element became
 * available.
 */
export function onAvailable(selector: string) {
    return new Promise((resolve) => {
        try {
            let elems: any;
            let counter: number = 0;
            const iteration = setInterval(() => {
                if (contains(selector, '.')) {
                    elems = document.getElementsByClassName(selector.slice(1));
                } else if (contains(selector, '#')) {
                    elems = document.getElementById(selector.slice(1));
                }
                const isCollection = elems instanceof HTMLCollection;
                if ((!isCollection && elems) || (isCollection && elems.length > 0)) {
                    clearInterval(iteration);
                    resolve(true);
                } else {
                    if (counter == 50) {
                        clearInterval(iteration);
                        resolve(false);
                    }
                    counter++;
                }
            }, 100); // Times out after 5 seconds (100ms * 50 iterations) and rejects the promise
        } catch (err) {
            console.error(...new ExxComError(723094, scriptName, err).stamp());
            resolve(false);
        }
    });
}

// //////////////////////////////////////////////////////////////////////////////
// ARITHMETIC

export function toCm(inches: number) {
    try {
        multiply(inches, 2.54);
    } catch (err) {
        console.error(...new ExxComError(984853, scriptName, err).stamp());
    }
}

const getNumberData = (n: number) => {
    try {
        const parts = n.toString().split('.');
        const dec = parts[1];
        const places = (dec && dec.length) || 0;
        let tens = '1';
        for (let i = 0; i < places; i++) {
            tens += '0';
        }
        return {
            n: n,
            int: parseInt(parts.join('')),
            tens: parseInt(tens),
        };
    } catch (err) {
        console.error(...new ExxComError(934884, scriptName, err).stamp());
    }
};

const as = (operation: string, a: number, b: number) => {
    try {
        const c = getNumberData(a);
        const d = getNumberData(b);
        let tens: number;
        if (c.tens > d.tens) {
            tens = c.tens;
            d.int = multiply(d.n, c.tens);
        } else {
            tens = d.tens;
            c.int = multiply(c.n, d.tens);
        }
        const e = getNumberData(operation == '+' ? c.int + d.int : c.int - d.int);
        tens *= e.tens;
        const result = e.int / tens;
        return parseFloat(result.toFixed(12));
    } catch (err) {
        console.error(...new ExxComError(720939, scriptName, err).stamp());
    }
};

const md = (operation: string, a: number, b: number) => {
    try {
        const c = getNumberData(a);
        const d = getNumberData(b);
        const e = getNumberData(operation == '*' ? c.int * d.int : c.int / d.int);
        const tens = (operation == '*' ? c.tens * d.tens : c.tens / d.tens) * e.tens;
        const product = e.int / tens;
        return parseFloat(product.toFixed(12));
    } catch (err) {
        console.error(...new ExxComError(623094, scriptName, err).stamp());
    }
};

export function add(a: number, b: number) {
    return as('+', a, b);
}
export function subtract(a: number, b: number) {
    return as('-', a, b);
}
export function multiply(a: number, b: number) {
    return md('*', a, b);
}
export function divide(a: number, b: number) {
    return md('/', a, b);
}

// //////////////////////////////////////////////////////////////////////////////
// SCROLL

export function scrollTransition(container: HTMLElement, duration: number, direction: string) {
    try {
        const transitionInterval = 20;
        const transitionTotalCalls = duration / transitionInterval;
        let scrollTopChangePerCall: number;
        if (direction == 'up') {
            scrollTopChangePerCall = container.scrollTop / transitionTotalCalls;
        } else if (direction == 'down') {
            scrollTopChangePerCall = (container.scrollHeight - container.scrollTop) / transitionTotalCalls;
        }
        let count = 0;
        const scrollToTop = setInterval(() => {
            if (count == transitionTotalCalls) {
                clearInterval(scrollToTop);
            }
            if (direction == 'up') {
                container.scrollTop -= scrollTopChangePerCall;
            } else if (direction == 'down') {
                container.scrollTop += scrollTopChangePerCall;
            }
            count++;
        }, transitionInterval);
    } catch (err) {
        console.error(...new ExxComError(529384, scriptName, err).stamp());
    }
}

const scrollTransitionInterval = 20;

export function scrollToTop(container: HTMLElement, durationMs?: number, delay?: number) {
    try {
        if (!delay) {
            delay = 0;
        }
        setTimeout(() => {
            if (!durationMs) {
                container.scrollTop = 0;
            } else {
                const transitionInterval = scrollTransitionInterval;
                const transitionTotalCalls = durationMs / transitionInterval;
                const scrollTopChangePerCall = container.scrollTop / transitionTotalCalls;
                let count = 0;
                const scrollIterator = setInterval(() => {
                    if (count == transitionTotalCalls) {
                        clearInterval(scrollIterator);
                    }
                    container.scrollTop -= scrollTopChangePerCall;
                    count++;
                }, transitionInterval);
            }
        }, delay);
    } catch (err) {
        console.error(...new ExxComError(734054, scriptName, err).stamp());
    }
}

export function scrollToBottom(container: HTMLElement, durationMs?: number) {
    try {
        if (!durationMs) {
            container.scrollTop = container.scrollHeight;
        } else {
            const transitionInterval = scrollTransitionInterval;
            const transitionTotalCalls = durationMs / transitionInterval;
            const scrollTopChangePerCall = (container.scrollHeight - container.scrollTop) / transitionTotalCalls;
            let count = 0;
            const scrollIterator = setInterval(() => {
                if (count == transitionTotalCalls) {
                    clearInterval(scrollIterator);
                }
                container.scrollTop += scrollTopChangePerCall;
                count++;
            }, transitionInterval);
        }
    } catch (err) {
        console.error(...new ExxComError(734509, scriptName, err).stamp());
    }
}

// https://gomakethings.com/get-distances-to-the-top-of-the-document-with-native-javascript/#:~:text=The%20other%20will%20get%20the,%3D%200%3B%20if%20(elem.
export function getDistanceFromTop(elem: any): number {
    try {
        let location = 0;
        if (elem.offsetParent) {
            do {
                location += elem.offsetTop;
                elem = elem.offsetParent;
            } while (elem);
        }
        return location >= 0 ? location : 0;
    } catch (err) {
        console.error(...new ExxComError(542039, scriptName, err).stamp());
    }
}

// //////////////////////////////////////////////////////////////////////////////
// COOKIES

export const cookies = {
    // https://www.w3schools.com/js/js_cookies.asp

    set: function setCookie(cname: string, cvalue: string, exdays?: number) {
        try {
            const d = new Date();
            d.setTime(d.getTime() + (exdays || 100000) * 24 * 60 * 60 * 1000);
            document.cookie = `${cname}=${cvalue};expires=${d.toUTCString()};path=/`;
        } catch (err) {
            console.error(...new ExxComError(530001, scriptName, err).stamp());
        }
    },

    get: function getCookie(cname: string) {
        try {
            const name = cname + '=';
            const decodedCookie = decodeURIComponent(document.cookie);
            const ca = decodedCookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return '';
        } catch (err) {
            console.error(...new ExxComError(734509, scriptName, err).stamp());
        }
    },

    remove: function removeCookie(cname: string) {
        try {
            document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        } catch (err) {
            console.error(...new ExxComError(534059, scriptName, err).stamp());
        }
    },

    exists: function cookieExists(cname: string) {
        try {
            return !!this.getCookie(cname);
        } catch (err) {
            console.error(...new ExxComError(945966, scriptName, err).stamp());
        }
    },
};

export function getHeight() {
    try {
        if (!isBrowser()) {
            return;
        }
        // used to get the view height and adjust for navigation bars on some browesers
        // solution found here: https://dev.to/maciejtrzcinski/100vh-problem-with-ios-safari-3ge9
        document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
        document.documentElement.style.setProperty('--overflow-var', 'unset');
    } catch (err) {
        console.error(...new ExxComError(837164, scriptName, err).stamp());
    }
}

// //////////////////////////////////////////////////////////////////////////////
// Blog Post Helpers
export const getRelatedBlogPosts = async ({ cmsService, tags, entry, environment, limit = 3, raw = false, spc = false }) => {
    const formatterForSwiper = (entries: any) => {
        try {
            if (isEmpty(entries)) {
                return [];
            }
            const posts = entries.map((entry: any) => {
                return {
                    ...entry,
                    heading: entry.title,
                    linkUrl: entry.url,
                    imageUrl: entry.feature_image
                        ? entry.feature_image.url
                        : 'https://images.contentstack.io/v3/assets/bltb654d1b96a72ddc4/blteba795fc63fd91c1/5e56f34b9437900dbf81e21b/Blog-MissingImage.jpg',
                };
            });
            return posts.length > limit ? posts.slice(0, limit) : posts;
        } catch (err) {
            console.error(...new ExxComError(468253, scriptName, err).stamp());
        }
    };
    try {
        if (!cmsService) {
            return [];
        }
        const softwareBlogCat = spc
            ? get(entry, 'blog_category[0].title', 'Deep Learning')
            : get(entry, 'blog.blog_category[0].title', 'Deep Learning');
        let res: any;

        if (softwareBlogCat == 'Recent') {
            res = await cmsService.getBlogPosts(limit, 0);
        } else {
            res = await cmsService.getBlogPostsByCategory(softwareBlogCat, limit);
        }
        const posts = Array.isArray(res)
            ? res.filter((post) => {
                  if (post && post.title && entry && entry.title) {
                      return post.title != entry.title;
                  }
              })
            : [];
        if (isEmpty(posts)) {
            return posts;
        }
        posts.sort((a, b) => {
            let x = 0;
            let y = 0;
            a.tags.forEach((tag: any) => {
                // not reporting error when empty since returned tags from contentstack is still array.
                if (tags && tags.includes(tag)) {
                    x++;
                }
            });
            b.tags.forEach((tag: any) => {
                if (tags && tags.includes(tag)) {
                    y++;
                }
            });
            return -subtract(x, y);
        });
        if (raw) {
            // in case chron job fails to properly load unique page views the unique quarterly views are set to 0
            posts.forEach((post) => {
                if (!post.unique_quarterly_views) {
                    post.unique_quarterly_views = 0;
                }
            });
            return posts.sort((a, b) => b.unique_quarterly_views - a.unique_quarterly_views);
        }
        const p = posts && posts.length > limit ? posts.slice(0, limit) : posts;
        p.sort((a, b) => b.unique_quarterly_views - a.unique_quarterly_views);
        return formatterForSwiper(p);
    } catch (err) {
        console.error(...new ExxComError(468253, scriptName, err).stamp());
    }
};

export function calculateReadingTime(entry: any) {
    try {
        if (!entry || !entry.article) {
            return '';
        }
        const articleLength = entry.article.replace(/[^0-9a-z]/gi, '').length;
        // formula and constansts suggested in this article:
        // https://betterprogramming.pub/how-to-estimate-any-articles-read-time-2a8403f0bd79
        const wordsPerMinute = 200;
        const avgWordLen = 5;
        const min = Math.round(articleLength / (avgWordLen * wordsPerMinute));
        return `${min} min read`;
    } catch (err) {
        console.error(...new ExxComError(666995, scriptName, err).stamp());
        return '';
    }
}

export function currentYear() {
    try {
        const currentYear: number = new Date().getFullYear();
        return currentYear;
    } catch (err) {
        console.error(...new ExxComError(666966, scriptName, err).stamp());
    }
}
