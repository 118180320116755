<ng-container *ngIf="!isEmpty(get(logosRow, 'logos', ''))">
    <div *ngFor="let row of get(logosRow, 'logos.logos', []); let i = index">
        <section id="rows" class="d-flex flex-row flex-wrap container-padless logos justify-content-center">
            <div class="category-top-title d-flex flex-row justify-content-center pb-2">
                <h2 class="col-md-7 text-center">{{ get(row, 'logos_title', '') }}</h2>
            </div>
            <div class="logo-icon-facets">
                <div class="grid partial-grid container">
                    <div
                        *ngFor="let logo of get(row, 'logos', [])"
                        target="_self"
                        [routerLink]="get(logo, 'link.routerLink', '') || null"
                        [queryParams]="get(logo, 'link.queryParams', '')"
                        class="brand-animation logo g-col-4 g-col-md-2"
                        routerLinkActive="brand-animation-disabled"
                    >
                        <img *ngIf="get(logo, 'image.href', '')" [src]="getWebpImg(logo.image.href)" [alt]="logo.image.title" />
                    </div>
                </div>
            </div>
        </section>
    </div>
</ng-container>
<hr *ngIf="get(logosRow, 'logos.split_line', '')" class="m-0" />
