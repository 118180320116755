import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';

import { contains, isBrowser } from 'lib/tools';
import { each, get, omit } from 'lodash';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { RouterService } from 'lib/services/router.service';

interface MetaTags {
    title?: string; // Title is technically not a meta tag, but it doesn't warrant its own interface in the current context.
    description?: string;
    keywords?: string;
    og_description?: string;
    'og:description'?: string;
    og_image?: string;
    'og:image'?: string;
    og_title?: string;
    'og:title'?: string;
    og_url?: string;
    'og:url'?: string;
    collection?: string;
}

const scriptName = 'meta.service';

const allTagNames = ['title', 'description', 'keywords', 'og:description', 'og:image', 'og:title', 'og:url'];

let appPageTitles: any;
let dom: any;
let environment: any;
let meta: Meta;
let routerService: RouterService;
let title: Title;

@Injectable()
export class MetaService {
    constructor(@Inject(DOCUMENT) d: any, @Inject('appPageTitles') a: any, @Inject('environment') e: any, m: Meta, r: RouterService, t: Title) {
        try {
            appPageTitles = a;
            dom = d;
            environment = e;
            meta = m;
            routerService = r;
            title = t;
        } catch (err) {
            console.error(...new ExxComError(620934, scriptName, err).stamp());
        }
    }

    init() {
        try {
            this.initRouterListener();
        } catch (err) {
            console.error(...new ExxComError(820934, scriptName, err).stamp());
        }
    }

    private initRouterListener() {
        try {
            routerService.onRouteChange('metaService', (event: any) => {
                this.addCanonical();
                const title = appPageTitles[routerService.url.component];
                if (!title) {
                    return;
                }
                this.add({ title });
            });
        } catch (err) {
            console.error(...new ExxComError(829833, scriptName, err).stamp());
        }
    }

    private addCanonical() {
        try {
            let canonicalPath = get(routerService, 'url.fullPath', '');
            // add the page param to the canonical path if present
            if (get(routerService, 'url.params.page', false)) {
                canonicalPath += `?page=${get(routerService, 'url.params.page', '')}`;
            }
            const prevTag = dom.head.querySelector('link[rel="canonical"]');
            if (prevTag) {
                prevTag.setAttribute('href', canonicalPath);
            } else {
                const tag = dom.createElement('link');
                tag.setAttribute('href', canonicalPath);
                tag.setAttribute('rel', 'canonical');
                dom.head.appendChild(tag);
            }
        } catch (err) {
            console.error(...new ExxComError(2094881, scriptName, err).stamp());
        }
    }

    add(data: MetaTags = {}) {
        try {
            // Parse and get names from data

            const metas: MetaTags = {};
            const names: string[] = Object.keys(data).map((n: string) => {
                const name: string = n.replace('_', ':');
                metas[name] = data[n];
                return name;
            });

            // Remove previous

            allTagNames.forEach((tagName: string) => {
                if (!contains(names, tagName) || !metas[tagName]) {
                    meta.removeTag(`name='${tagName}'`);
                }
            });

            // Title tag

            const newTitle: string = get(metas, 'title');
            newTitle && title.setTitle(newTitle);

            // Non-title meta tags

            const newMetas: MetaTags = omit(metas, 'title');

            // init collections default
            let defaultCollection = '';
            if (!newMetas['collection']) {
                const s = get(routerService, 'url.parts', []).join(' ').split('-');
                defaultCollection = s.join(' ');
            }
            // Defaults

            if (!newMetas['description']) {
                meta.updateTag({
                    name: 'description',
                    content: environment.siteName,
                });
            }
            if (!newMetas['og:description']) {
                meta.updateTag({
                    name: 'og:description',
                    content: newMetas['description'] || environment.siteName,
                });
            }
            if (!newMetas['og:image']) {
                meta.updateTag({
                    name: 'og:image',
                    content: environment.urls.image.logo,
                });
            }
            if (!newMetas['og:url']) {
                meta.updateTag({
                    name: 'og:url',
                    content: routerService.url.full,
                });
            }
            if (!newMetas['title']) {
                meta.updateTag({ name: 'title', content: title.getTitle() });
            }
            if (!newMetas['og:title']) {
                meta.updateTag({ name: 'og:title', content: title.getTitle() });
            }
            if (!newMetas['collection']) {
                meta.updateTag({
                    name: 'collection',
                    content: defaultCollection,
                });
            }

            // Add or update
            each(newMetas, (content: string, name: string) => {
                const definition: MetaDefinition = { name, content };
                if (name == 'description') {
                    const site = environment.siteName == 'Exxact' ? '@Exxactcorp' : '@sabrePC';
                    meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
                    meta.updateTag({ name: 'twitter:site', content: site });
                }
                if (name == 'og:image') {
                    meta.updateTag({ name: 'twitter:image', content: content });
                }
                if (content) {
                    meta.updateTag(definition);
                }
            });
        } catch (err) {
            console.error(...new ExxComError(832203, scriptName, err).stamp());
        }
    }

    // takes in product information and adds the product information to the head of the document as meta info
    addProductMetadata(product: any) {
        try {
            this.addProductStructuredData(product);
            meta.updateTag({ name: 'sku', content: product && product.name });
            meta.updateTag({
                name: 'title',
                content: `${product.description} | ${environment.siteName}`,
            });
            meta.updateTag({
                name: 'productID',
                content: product && `mpn:${product.mpn}`,
            });
            meta.updateTag({
                name: 'url',
                content: routerService.url.base + `${product.urlComponent}`,
            });
            meta.updateTag({
                name: 'brand',
                content: product && product.manufacturer,
            });
            meta.updateTag({
                name: 'description',
                content: product && product.description,
            });
            meta.updateTag({
                name: 'image',
                content: product && product.imageUrl,
            });
            meta.updateTag({
                name: 'mpn',
                content: product && `mpn:${product.mpn}`,
            });
            meta.updateTag({ name: 'name', content: product && product.name });
            meta.updateTag({
                name: 'availability',
                content: product && (product.inventory > 0).toString(),
            });
            meta.updateTag({
                name: 'price',
                content: product && product.price,
            });
            const today = new Date();
            const tomorrow = new Date(today);
            tomorrow.setDate(tomorrow.getDate() + 1);
            meta.updateTag({
                name: 'priceValidUntil',
                content: tomorrow.toString(),
            });
        } catch (err) {
            console.error(...new ExxComError(837203, scriptName, err).stamp());
        }
    }

    addProductStructuredData(product: any = {}) {
        try {
            if (!isBrowser()) {
                return;
            }
            const el = document.getElementById('product-structured-data');
            if (el) {
                return;
            }

            const {
                name = '',
                mpn = '',
                manufacturer,
                description = '',
                imageUrl = '',
                price = 0,
                sku = '',
                inventory = 0,
                urlComponent = '',
            } = product;

            const today = new Date();
            const tomorrow = new Date(today);
            tomorrow.setDate(tomorrow.getDate() + 1);

            const escapedDescription = JSON.stringify(description);

            const script = document.createElement('script');
            script.type = 'application/ld+json';
            script.id = 'product-structured-data';
            script.innerHTML = `{
        "@context": "https://schema.org/",
        "@type": "Product",
        "name": "${name}",
        "description": ${escapedDescription},
        "image": "${imageUrl}",
        "brand": "${manufacturer}",
        "sku": "${sku}",
        "mpn": "${mpn}",
        "offers": {
          "@type": "Offer",
          "price": "${price}",
          "priceValidUntil" : "${tomorrow}",
          "priceCurrency": "USD",
          "availability": "${inventory > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock'}",
          "url": "${urlComponent}"
        }
      }`;

            document.head.appendChild(script);
        } catch (err) {
            console.error(...new ExxComError(837293, scriptName, err).stamp());
        }
    }

    removeProductMetadata() {
        try {
            meta.removeTag("name='sku'");
            meta.removeTag("name='productID'");
            meta.removeTag("name='url'");
            meta.removeTag("name='brand'");
            meta.removeTag("name='description'");
            meta.removeTag("name='image'");
            meta.removeTag("name='mpn'");
            meta.removeTag("name='name'");
            meta.removeTag("name='availability'");
            meta.removeTag("name='priceValidUntil'");
            if (!isBrowser()) {
                return;
            }
            const el = document.getElementById('product-structured-data');
            if (!el) {
                return;
            }
            el.remove();
        } catch (err) {
            console.error(...new ExxComError(937243, scriptName, err).stamp());
        }
    }
}
