import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';

import { catchError } from 'rxjs/operators';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { get, isEmpty } from 'lodash';
import { of } from 'rxjs';
import { RouterService } from './router.service';
import { take } from 'rxjs/operators';

const scriptName = 'api.service';

let httpClient: HttpClient;

const defaultHttpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable()
export class ApiService {
    private baseUrl: string = '';

    constructor(
        @Optional() @Inject(REQUEST) protected req: Request,
        h: HttpClient,
        r: RouterService
    ) {
        try {
            httpClient = h;
            this.baseUrl = `${r.baseUrl}/api`;
        } catch (err) {
            console.error(...new ExxComError(498288, scriptName, err).stamp());
        }
    }

    request(
        {
            method,
            url,
            values,
            httpOptions,
            useFullUrl,
        }: {
            method: string;
            url: string | string[];
            values?: any;
            httpOptions?: any;
            useFullUrl?: boolean;
        } = { method: '', url: '' }
    ) {
        return new Promise((resolve, reject) => {
            try {
                if (!method || !url) {
                    throw new Error('Request method and URL are required');
                }

                url = Array.isArray(url) ? url.join('') : url;
                url = url.replace(/\r|\n|\r\n/g, ' ').replace(/\s\s+/g, ' ');
                url = useFullUrl ? url : `${this.baseUrl}/${url}`;

                if (isEmpty(httpOptions)) {
                    httpOptions = defaultHttpOptions;
                } else if (get(httpOptions, 'headers')) {
                    httpOptions.headers = new HttpHeaders(httpOptions.headers);
                }
                const params = [url];
                if (method == 'post') {
                    params.push(values);
                }
                params.push(httpOptions);

                httpClient[method](...params)
                    .pipe(
                        take(1),
                        catchError((error: HttpErrorResponse) => of(error))
                    )
                    .subscribe((res: any) => {
                        !res || res instanceof HttpErrorResponse
                            ? reject(Object.assign({ success: false }, res || { error: 'No response' }))
                            : resolve(Object.assign({ success: true }, res));
                    });
            } catch (err) {
                console.error(...new ExxComError(398932, scriptName, err).stamp());
                reject(Object.assign({ success: false }, err));
            }
        });
    }

    get(url: string | string[], { httpOptions, useFullUrl }: { httpOptions?: any; useFullUrl?: boolean } = {}): Promise<any> {
        return this.request({ method: 'get', url, httpOptions, useFullUrl });
    }

    post(url: string | string[], values: any, { httpOptions, useFullUrl }: { httpOptions?: any; useFullUrl?: boolean } = {}): Promise<any> {
        return this.request({
            method: 'post',
            url,
            values: values,
            httpOptions,
            useFullUrl,
        });
    }

    delete(url: string | string[], { httpOptions, useFullUrl }: { httpOptions?: any; useFullUrl?: boolean } = {}): Promise<any> {
        return this.request({ method: 'delete', url, httpOptions, useFullUrl });
    }
}
