import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { CmsService } from 'lib/services/cms.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { MetaService } from 'lib/services/meta.service';
import { WebpService } from 'lib/services/webp.service';
import { BootstrapMarketoService } from 'lib/services/bootstrap/bootstrap-marketo.service';

const scriptName = 'cms-b2b-service.component';

@Component({
    selector: 'app-cms-b2b-service',
    templateUrl: './cms-b2b-service.component.html',
    styleUrls: ['./cms-b2b-service.component.scss'],
})
export class CmsB2bServiceComponent implements OnInit {
    entry: any = {};
    formId: any;
    isNarrow: boolean;
    getWebpImg: (src: string) => string;

    constructor(
        private breakpointObserver: BreakpointObserver,
        private cmsService: CmsService,
        private metaService: MetaService,
        public bootstrapMarketoService: BootstrapMarketoService,
        webpService: WebpService
    ) {
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
    }

    async ngOnInit() {
        try {
            this.initBreakpointObserver();
            this.entry = await this.cmsService.getPage('b2b_services');
            this.formId = this.entry.hero_banner_button_form.id;
        } catch (err) {
            console.error(...new ExxComError(452723, scriptName, err).stamp());
        }
    }

    // openMarketoForm(action: string) {
    //   try {
    //     this.dialog.open(MarketoDialogComponent, {
    //       width: '500px',
    //       height: '750px',
    //       autoFocus: false,
    //       data: {
    //         marketoFormId: this.formId,
    //         action: action
    //       }
    //     });
    //   } catch (err){ console.error(...new ExxComError(832992, scriptName, err).stamp()); }
    // }

    private initBreakpointObserver() {
        try {
            this.breakpointObserver.observe(['only screen and (min-width: 1100px)']).subscribe((result) => {
                this.isNarrow = !result.matches;
            });
        } catch (err) {
            console.error(...new ExxComError(832948, scriptName, err).stamp());
        }
    }
}
