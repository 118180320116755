<section id="product-listing" [class.is-quickview]="isQuickView">
    <div class="responsive-container container-padless">
        <div class="fx-row fx-row-h-center product-listing-image solutions">
            <div
                class="image-container text-center d-flex flex-row align-items-center"
                [class.single-product-swiper]="product?.imageUrls.length === 1"
            >
                <img *ngIf="!isBrowser && product.imageUrl" [src]="getWebpImg(product.imageUrl)" [alt]="product?.name" />

                <div class="d-flex flex-column container-padless box-shadow-parent">
                    <mod-swiper
                        (onNumberChange)="setImageIndex($event)"
                        *ngIf="productImgSlides?.length > 0 && isBrowser"
                        class="bottom-swiper-nav container p-0"
                        [slidesPerView]="1"
                        [loop]="false"
                        containerClass="preview-swiper-narrow"
                        [allowPrevArrow]="false"
                        [allowNextArrow]="false"
                        [productImages]="product?.imageUrls"
                        [isSolution]="true"
                        [activeIndex]="amount"
                    >
                        <div class="fx-row fx-row-h-center fx-row-v-center swiper-slide" *ngFor="let slide of productImgSlides">
                            <img [src]="getWebpImg(slide?.imageUrl)" [alt]="slide?.heading" (click)="!isNarrow ? openModal(templateModal) : []" />
                        </div>
                    </mod-swiper>
                    <app-product-page-image-tile-spc
                        *ngIf="product?.imageUrls.length > 1"
                        [(amount)]="amount"
                        [slidesPerView]="4"
                        [productImages]="product?.imageUrls"
                        [(translateAmount)]="translateAmount"
                        [widthPerSlideImage]="66"
                        class="spc-solutions-tiles"
                    >
                    </app-product-page-image-tile-spc>
                    <div
                        *ngIf="isQuickView"
                        [ngClass]="product?.imageUrls.length === 1 ? 'd-flex justify-content-center' : 'd-flex justify-content-center'"
                    >
                        <a
                            (click)="bsModalRef.hide()"
                            [routerLink]="product.urlComponent"
                            data-cy="exx-view-full-details-quick-view-link"
                            class="d-flex align-items-center mt-3"
                            id="see-more"
                            >See Full Information <i-feather name="chevron-right"></i-feather
                        ></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-container d-flex flex-column">
            <div class="d-flex flex-column justify-content-center">
                <div id="title-and-mpn">
                    <div class="mpn-favorite">
                        <span id="wishlist-message" class="mat-elevation-z5 show" [class.show]="showWishlistMessage">
                            Please <a routerLink="/account/login">log in</a> to add an item to your wishlist.
                        </span>
                        <span>MPN: {{ product?.mpn }}</span>
                        <!--  -->
                        <div id="product-listing-wishlist" class="fx-row fx-row-v-center">
                            <i-feather class="heart" [class.favorited]="isOnWishlist" name="heart" (click)="toggleWishlist()"></i-feather>
                            <!-- fix this message -->
                        </div>
                    </div>
                    <h4 id="product-listing-name">{{ product?.description }}</h4>
                </div>
                <div id="product-listing-main" class="fx-row fx-wrap px-0">
                    <hr class="title-highlights-separator" />
                    <div id="product-listing-details" class="w-100">
                        <div class="fx-col" *ngIf="product.freeShipping">
                            <div class="fx-row fx-row-h-space-between fx-row-v-center">
                                <div id="free-shipping">Free shipping</div>
                            </div>
                            <div class="pt-2"></div>
                        </div>
                        <div *ngIf="product.highlights && !isQuickView" id="product-listing-highlights" [class.no-price]="product?.price == 0">
                            <span class="highlights">Highlights</span>
                            <div id="highlights">
                                <div *ngFor="let tr of mutilatedHighlights" class="d-flex flex-row highlight-row">
                                    <div *ngFor="let td of tr; let i = index" class="w-50 d-flex align-items-center" [class.right-spacing]="i == 0">
                                        <!-- <img
                    class="checkmark"
                    *ngIf="i == 0"
                    src="https://resources.exxactcorp.com/resources/exx/assets/icons/value-prop.svg"
                  /> -->
                                        <i-feather class="checkmark" name="check" *ngIf="i == 0"></i-feather>
                                        <div [class.checkmark-spacing]="i == 0">{{ td }}{{ i == 0 && tr.length == 2 ? ':' : '' }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            *ngIf="product.highlights && isQuickView"
                            id="product-listing-highlights"
                            class="quickview"
                            [class.no-price]="product?.price == 0"
                        >
                            <span class="highlights">Highlights</span>
                            <div id="highlights">
                                <div *ngFor="let tr of mutilatedHighlights" class="d-flex flex-row highlight-row align-items-center">
                                    <i-feather class="checkmark mr-1" name="check"></i-feather>
                                    <div class="highlight-text">{{ tr }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="price-border-container" *ngIf="startingPrice && configuratorExists">
                            <div class="starting-price-container">
                                <h3 class="price">{{ startingPrice }}</h3>
                                <span class="starting-price">Starting Price</span>
                            </div>
                        </div>
                        <div id="product-button-container">
                            <div class="d-flex flex-row w-100">
                                <a
                                    class="w-100 mr-2"
                                    [href]="configuratorUrl"
                                    *ngIf="configuratorExists && !configuratorService?.isBlacklisted(product.mpn)"
                                >
                                    <button class="button-primary w-100 cta">
                                        <div class="d-flex justify-content-center mr-0 align-items-center cta-container">
                                            <span class="mr-1"> Customize</span>
                                            <i-feather name="tool"></i-feather>
                                        </div>
                                    </button>
                                </a>
                                <button
                                    class="button-secondary w-100 cta"
                                    data-cy="product-page-get-a-quote-button"
                                    (click)="openBootstrapMarketoDialog('mktoForm_2061', product)"
                                >
                                    <div class="d-flex align-items-center justify-content-center cta-container">
                                        <span class="mr-1"> Get a Quote</span>

                                        <i-feather name="message-square"></i-feather>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="isVisible" id="floating-text-button" class="fx-row fx-row-v-center fx-row-h-space-between align-items-center">
            <a class="w-100 mr-2" [href]="configuratorUrl" *ngIf="configuratorExists && !configuratorService?.isBlacklisted(product.mpn)">
                <button class="button-primary w-100 cta">
                    <div class="d-flex justify-content-center mr-0 align-items-center cta-container">
                        <span class="mr-1"> Customize</span>
                        <i-feather name="tool"></i-feather>
                    </div>
                </button>
            </a>
            <button
                class="button-secondary w-100 cta"
                data-cy="product-page-get-a-quote-button"
                (click)="openBootstrapMarketoDialog('mktoForm_2061', product)"
            >
                <div class="d-flex align-items-center justify-content-center">
                    <span class="mr-1"> Get a Quote</span>

                    <i-feather name="message-square"></i-feather>
                </div>
            </button>
        </div>
    </div>
</section>

<ng-template #templateModal>
    <div id="modal-parent">
        <div class="close-icon" aria-label="Close" (click)="bsModalRef.hide()">
            <span class="d-flex align-items-center">
                <i-feather name="x"></i-feather>
            </span>
        </div>
        <div id="main-image-frame-container">
            <div class="main-image-frame-container">
                <div id="main-image-frame">
                    <div class="slider-container" [ngStyle]="{ transform: 'translateX(-' + amount * 650 + 'px)' }">
                        <div *ngFor="let imageSlide of product?.imageUrls" class="img-container"><img [src]="imageSlide" /></div>
                    </div>
                </div>
            </div>
        </div>
        <app-product-page-image-tile-spc
            [(amount)]="amount"
            [slidesPerView]="4"
            [productImages]="product?.imageUrls"
            [(translateAmount)]="translateAmount"
            [widthPerSlideImage]="66"
        ></app-product-page-image-tile-spc>
    </div>
</ng-template>
