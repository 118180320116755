import { Inject, Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/core';
import { Location } from '@angular/common';

import { ApiService } from '../api.service';
import { BehaviorSubject } from 'rxjs';
import { Category } from 'lib/services/category/category.class';
import { CmsService } from 'lib/services/cms.service';
import { contains, isBrowser, isDevServer } from 'lib/tools';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { get, isEmpty } from 'lodash';
import { RouterService } from 'lib/services/router.service';
import { SearchSpringService } from '../searchspring.service';

const scriptName = 'category.service';

let environment: any;
let apiService: ApiService;
let cmsService: CmsService;
let location: Location;
let routerService: RouterService;
let searchSpringService: SearchSpringService;
let transferState: TransferState;

const CATEGORY_CONTENT_STATE_KEY = makeStateKey<object>('categoryContent');
const CATEGORIES_DATA_STATE_KEY = makeStateKey<object>('categoriesData');
const CMS_DATA_STATE_KEY = makeStateKey<object>('cmsData');

@Injectable()
export class CategoryService {
    // Properties: private

    private categoryContentStateData: any = {
        urlParams: '',
        type: '',
        breadcrumb: '',
        res: {},
    };

    // Properties: public

    category: Category;
    retrieving$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    pageTitle: string = '';
    pageTitles: string[] = [];
    paths: string[] = [];
    queryParamSubscription: any;
    transferredCategory: any;

    constructor(
        @Inject('environment') e: any,
        a: ApiService,
        c: CmsService,
        l: Location,
        r: RouterService,
        s: SearchSpringService,
        t: TransferState
    ) {
        try {
            apiService = a;
            cmsService = c;
            location = l;
            environment = e;
            routerService = r;
            searchSpringService = s;
            transferState = t;
            this.category = new Category({
                environment,
                location,
                routerService,
            });
            this.initRouteListener();
        } catch (err) {
            console.error(...new ExxComError(520993, scriptName, err).stamp());
        }
    }

    private getUrl = () => {
        const path = `/${routerService.url.component}`;
        return path.replace('/category/', '');
    };

    // ROUTE INITIALIZATION

    private initRouteListener() {
        try {
            routerService.onRouteChange('categoryService', (event: any) => {
                if (!routerService.isCategoryPage(this.paths) || contains(event.url, 'reset-password')) {
                    return;
                } // Not category page
                if (contains(event.url, '/search') && (/\/search\??q?=?$/.test(event.url) || !contains(event.url, 'q='))) {
                    // Invalid search
                    this.router.navigateByUrl('/');
                } else {
                    this.initCategory();
                }
            });
        } catch (err) {
            console.error(...new ExxComError(420939, scriptName, err).stamp());
        }
    }

    /**
     * @function initRoutes
     * @param {CategoryComponent} component
     * @description Called in app.initializers ServicesInitializer, it retrieves
     * category URLs from the ExxCom database.
     */
    async initRoutes(component: any) {
        try {
            const setUrls = (exc: any = [], cms: any = []) => {
                this.paths = [];
                if (!exc || !Array.isArray(exc)) {
                    return;
                }
                exc.forEach((d: any) => this.paths.push(d.urlComponent.replace(/^\//, '')));
                if (cms) {
                    cms.forEach((entry: any) => {
                        const url = entry.url.replace(/^\//, '');
                        if (!contains(this.paths, url)) {
                            this.paths.push(url);
                        }
                    });
                }
                this.paths.push('search');
                this.paths.forEach((path: string) => this.router.config.unshift({ path, component }));
            };

            const cmsData = transferState.get(CMS_DATA_STATE_KEY, null);
            const categoriesData = transferState.get(CATEGORIES_DATA_STATE_KEY, null);
            if (categoriesData !== null && cmsData !== null) {
                return setUrls(categoriesData, cmsData);
            }
            const res = await Promise.all([
                await cmsService.getEntries('category_page', {
                    fields: ['url'],
                }),
                await cmsService.getEntries('category_page', {
                    fields: ['url'],
                    skip: 100,
                }),
                await apiService.get(['categories/find', `?filters={ "webstore": "${environment.siteAbbr}" }`, '&fields=urlComponent'].join('')),
            ]);
            const cmsRes = res[0].concat(res[1]);
            if (!isEmpty(cmsRes)) {
                transferState.set(CMS_DATA_STATE_KEY, cmsRes);
            }

            const excRes = res[2] || {};
            if (!excRes.success) {
                throw excRes;
            } else {
                const data = excRes.data;
                if (isEmpty(data)) {
                    return;
                }
                setUrls(data, cmsRes);
                transferState.set(CATEGORIES_DATA_STATE_KEY, excRes.data);
            }
        } catch (err) {
            const suppress = ['Unknown Error', 'cannotconnect'];
            if (err && contains(suppress, err.statusText)) {
                return;
            }
            console.error(...new ExxComError(528834, scriptName, err).stamp());
        }
    }

    get router() {
        return routerService.router;
    }

    // CATEGORY RENDERING

    private async initCategory() {
        try {
            this.retrieving$.next(true);
            if (isDevServer()) {
                await this.loadPage();
            } else {
                this.categoryContentStateData = transferState.get(CATEGORY_CONTENT_STATE_KEY, {});

                if (!isBrowser()) {
                    // Loading on the server
                    await this.loadPage();
                    transferState.set(CATEGORY_CONTENT_STATE_KEY, this.categoryContentStateData);
                } else {
                    // Loading in the browser
                    if (!isEmpty(this.categoryContentStateData)) {
                        // First browser load
                        const d = this.categoryContentStateData;

                        this.category = new Category({
                            environment,
                            routerService,
                        });
                        this.category.init(d.params, d.type, d.breadcrumb, d.res);

                        transferState.set(CATEGORY_CONTENT_STATE_KEY, {});
                    } else {
                        // Subsequent browser loads
                        await this.loadPage();
                    }
                }
            }

            this.retrieving$.next(false);
        } catch (err) {
            console.error(...new ExxComError(402003, scriptName, err).stamp());
        }
    }

    async loadPage() {
        try {
            if (!isBrowser()) {
                return;
            }
            const url = window.location.toString();
            const { searchParams } = new URL(url);
            const params = {
                page: null,
                sort: null,
                filters: null,
                q: null,
            };
            searchParams.forEach((value, key) => {
                params[key] = value;
            });
            const page = params.page || null;
            const sort = this.parseSort((this.category && this.category.sorting.getParam(params)) || '');
            const filters = this.parseFilters(params.filters);
            let websitePageType = '';
            let breadcrumb: string;
            let type: string;
            let term: string;
            if (params.q) {
                type = 'search';
                term = routerService.url.params.q;
            } else {
                const res = await this.getBreadcrumb(this.getUrl());
                websitePageType = get(res, 'websitePageType', '');
                if (!res.success) {
                    return;
                }
                if (res.breadcrumb && websitePageType != 'Tag') {
                    type = 'category';
                    breadcrumb = res.breadcrumb;
                    term = res.breadcrumb;
                } else if (res) {
                    type = 'tag';
                    term = this.getUrl().toLowerCase();
                }
            }

            if (!type || !term) {
                return;
            }
            const res = await searchSpringService.search(type, term, page, filters, sort);
            res.websitePageType = websitePageType;
            this.categoryContentStateData = { params, type, breadcrumb, res };
            this.category.init(params, type, breadcrumb, res);
        } catch (err) {
            console.error(...new ExxComError(320939, scriptName, err).stamp());
        }
    }

    // PARSE SORT AND FILTER ON URL

    private parseSort(sortParams: any): string {
        try {
            if (isEmpty(sortParams)) {
                return '';
            }
            const field = Object.keys(sortParams)[0];
            const direction = sortParams[field];
            const sortQueryString = `${field}=${direction}`;
            return sortQueryString;
        } catch (err) {
            console.error(...new ExxComError(320099, scriptName, err).stamp());
        }
    }

    /**
     * @function parseFilters
     * @param {String} filters A string list of the filters from the URL.
     * @description Parses the URL filters, which are generated by category.class
     * filter() when a filter is clicked. A previously generated URL can also be
     * passed directly into the browser address bar. This function parses the URL
     * filters that were generated in category.class, in order to prepare them to
     * be passed into the searchspring.service search function, which concatenates
     * the full URL for the API request.
     */
    private parseFilters(filters: any): string {
        try {
            if (!filters) {
                return '';
            }
            filters = decodeURIComponent(filters);
            filters = filters.split(';');
            let filterQueryString = '';
            filters.forEach((filter: any) => {
                if (!contains(filter, '::')) {
                    return;
                }
                filter = filter.split('::');
                const filterName = filter[0];
                const filterValues = filter[1].split(',').filter(Boolean);
                filterValues.forEach((value: string) => {
                    if (/^(\*?|0?\.?[0-9]{1,6})-(0?\.?[0-9]{1,6}|\*)$/.test(value)) {
                        const range = value.split('-');
                        filterQueryString += `&filter.${filterName}.low=${range[0]}&filter.${filterName}.high=${range[1]}`;
                    } else {
                        filterQueryString += `&filter.${filterName}=${value}`;
                    }
                });
            });
            return filterQueryString;
        } catch (err) {
            console.error(...new ExxComError(429093, scriptName, err).stamp());
        }
    }

    // RETRIEVE BREADCRUMB

    private async getBreadcrumb(urlComponent: string) {
        try {
            const url = [
                'categories/find',
                `?filters={
          "urlComponent": "${urlComponent}",
          "webstore": "${environment.siteAbbr}"
        }`,
                '&fields=breadcrumb,websitePageType',
            ].join('');
            const res = await apiService.get(url);
            if (!res.success) {
                throw res;
            } else {
                return {
                    success: true,
                    breadcrumb: get(res, 'data[0].breadcrumb'),
                    websitePageType: get(res, 'data[0].websitePageType', ''),
                };
            }
        } catch (err) {
            const suppress = ['Unknown Error', 'cannotconnect'];
            if (err && contains(suppress, err.statusText)) {
                return { success: false };
            }
            console.error(...new ExxComError(623984, scriptName, err).stamp());
        }
    }

    // RETRIEVE CATEGORIES

    /**
     * @function getCategory
     * @param {String} urlComponent - The search parameter
     * @param {String} [fields] - A comma-delimited (no spaces) string (e.g., 'field1,field2')
     * @description Retrieves one category with the given urlComponent from the
     * ExxCom database.
     */
    async getCategory({ urlComponent, fields }: { urlComponent?: string; fields?: string[] } = {}) {
        try {
            if (!urlComponent) {
                urlComponent = this.getUrl();
            }
            if (contains(urlComponent, 'search') || !contains(this.paths, urlComponent)) {
                return null;
            }
            const url = [
                'categories/find',
                `?filters={
          "urlComponent": "${this.getUrl()}",
          "webstore": "${environment.siteAbbr}"
        }`,
                '&limit=1',
            ];
            if (!isEmpty(fields)) {
                url.push(`&fields=${fields.join(',')}`);
            }
            const res = await apiService.get(url.join(''));
            if (!res.success) {
                throw res;
            }
            return res.data[0];
        } catch (err) {
            console.error(...new ExxComError(792893, scriptName, err).stamp());
        }
    }
}
