<ng-container *ngIf="!isEmpty(get(cardsRow, 'cards', ''))">
    <div *ngFor="let row of get(cardsRow, 'cards.cards', []); let i = index">
        <section id="rows" class="feature-cards" [class.gray-bg]="get(cardsRow, 'cards.gray_background', false)">
            <div class="feature-cards-container d-flex flex-wrap container-gridless">
                <div *ngIf="get(row, 'card_section_title', '')" class="category-top-title d-flex flex-row fx-row justify-content-center pb-2">
                    <h2 class="col-md-7 text-center">{{ get(row, 'card_section_title', '') }}</h2>
                </div>
                <div *ngFor="let card of get(row, 'cards', [])" class="col-12 col-md-4 card-container">
                    <div class="d-flex flex-row justify-content-center align-items-center card-symbol">
                        <img *ngIf="get(card, 'image.href', '')" [src]="getWebpImg(card.image.href)" [alt]="card.image.title" />
                    </div>
                    <div class="card-1">
                        <h3 class="text-center mt-4 mb-2">{{ get(card, 'title', '') }}</h3>
                        <p class="text-center">{{ get(card, 'copy', '') }}</p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</ng-container>
<hr *ngIf="get(cardsRow, 'cards.split_line', '')" class="m-0" />
