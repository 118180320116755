<div
  class="fx-row fx-wrap swiper fx-row-h-center"
  [class.product-and-swiper]="productImages?.length > 1 && !isSolution"
>
  <h3 *ngIf="swiperTitle != ''" class="swiper-container-title mb-3">{{ swiperTitle }}</h3>
  <div class="w-100 swiper-container-wrapper">
    <div class="swiper-container" [ngClass]="containerClass">
      <div class="swiper-wrapper">
        <ng-content></ng-content>
      </div>
      <div
        *ngIf="navType == 'innerBottom'"
        class="swiper-inner-bottom-navigation fx-row fx-row-h-center fx-row-v-center"
      >
        <mat-icon class="swiper-button-prev-inner-bottom" (click)="prevSlide()">chevron_left</mat-icon>
        <div *ngIf="productImages?.length > 1" style="width: 100%; height: 50px">
          <img *ngFor="let image of productImages" [src]="image" class="product-swiper-image" />
        </div>
        <span *ngIf="environment.siteAbbr === 'spc'" class="slide-counter ml-2 mr-2"
          >{{ slideCounter }} of {{ swiper?.slides?.length }}</span
        >
        <mat-icon class="swiper-button-next-inner-bottom" (click)="nextSlide()">chevron_right</mat-icon>
      </div>
    </div>
  </div>
  <div
    *ngIf="showPrevArrow && navType == 'normal'"
    class="swiper-button-prev-normal fx-row fx-row-v-center fx-row-h-center"
    (click)="prevSlide()"
  >
    <mat-icon>chevron_left</mat-icon>
  </div>
  <div
    *ngIf="showNextArrow && navType == 'normal'"
    class="swiper-button-next-normal fx-row fx-row-v-center fx-row-h-center"
    (click)="nextSlide()"
  >
    <mat-icon>chevron_right</mat-icon>
  </div>
  <div
    *ngIf="navType == 'bottom' && swiper?.slides?.length > 1"
    class="swiper-bottom-navigation fx-row fx-row-v-center fx-row-h-center mt-2"
  >
    <mat-icon class="swiper-button-prev-unique no-border" (click)="prevSlideSPC()">chevron_left</mat-icon>
    <span class="slide-counter ml-2 mr-2">{{ slideCounter }} of {{ swiper?.slides?.length }}</span>
    <mat-icon class="swiper-button-next-unique no-border" (click)="nextSlideSPC()">chevron_right</mat-icon>
  </div>
</div>
