import { Component } from '@angular/core';
import { isBrowser } from 'lib/tools';
import { get } from 'lodash';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-configurator-error',
    templateUrl: './configurator-error.component.html',
    styleUrls: ['./configurator-error.component.scss'],
})
export class ConfiguratorErrorComponent extends Toast {
    constructor(
        protected toastrService: ToastrService,
        public toastPackage: ToastPackage
    ) {
        super(toastrService, toastPackage);
    }

    isNarrow() {
        if (!isBrowser()) {
            return false;
        }
        return window.innerWidth >= 1100 ? false : true;
    }

    jumpToCategory() {
        const category = get(this.toastPackage, 'toastRef.componentInstance.category');
        if (isBrowser()) {
            const el = document.getElementById('scroll-div') ? document.getElementById('scroll-div') : document.getElementsByTagName('html').item(0);
            const section = document.getElementById(category);
            const offset = this.isNarrow() ? 0 : 150;
            el.scrollTo({
                top: section.offsetTop - offset,
                behavior: 'smooth',
            });
        }
    }
}
