<div id="quick-view-dialog" class="dialog" data-cy="bootstrap-modal-quick-view">
    <div
        class="dialog-top-row close-icon"
        [class.close-padding]="
            data?.product?.manufacturer !== 'Exxact' &&
            environment.siteAbbr === 'spc'
        "
        [class.p-0]="
            environment.siteAbbr === 'spc' && productType !== 'Solution'
        "
        [class.spc-solution-quickview]="
            environment.siteAbbr === 'spc' && productType == 'Solution'
        "
        aria-label="Close"
        (click)="bsModalRef.hide()"
        data-cy="boostrap-modal-quick-view-close-button"
    >
        <span class="x-icon d-flex align-items-center">
            <i-feather name="x" class="x-styling"></i-feather>
        </span>
    </div>

    <app-product-listing
        [isQuickView]="true"
        [product]="data?.product"
        *ngIf="
            (productType !== 'Solution' && environment.siteAbbr == 'spc') ||
            environment.siteAbbr == 'exx'
        "
    >
    </app-product-listing>
    <div *ngIf="environment.siteAbbr == 'spc' && productType == 'Solution'">
        <app-product-listing-solution
            [isQuickView]="true"
            [product]="data?.product"
        >
        </app-product-listing-solution>
    </div>
</div>
