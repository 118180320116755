import { Component, ElementRef, Inject, OnInit, Optional, ViewChild, ViewEncapsulation, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';

import { appIconsList } from './app-icons';
import { CategoryService } from 'lib/services/category/category.service';
import { contains, isBrowser, scrollToTop, wait, getHeight } from 'lib/tools';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { InfiniteScrollService } from 'lib/services/infinite-scroll.service';
import { RouterService } from 'lib/services/router.service';
import { MarketoService } from 'lib/services/marketo.service';

const scriptName = 'app.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
    @ViewChild('infiniteScrollGlobalContainer')
    infiniteScrollGlobalContainer: ElementRef;

    constructor(
        @Optional() @Inject(REQUEST) protected req: Request,
        @Inject(PLATFORM_ID) private platformId: string,
        @Inject(DOCUMENT) private document: Document,
        private categoryService: CategoryService,
        private domSanitizer: DomSanitizer,
        private infiniteScrollService: InfiniteScrollService,
        private marketoService: MarketoService,
        private matIconRegistry: MatIconRegistry,
        private routerService: RouterService
    ) {}

    ngOnInit() {
        try {
            this.initIcons();
            this.initInfiniteScroll();
            this.initRouteListener();
            if (!isBrowser()) {
                return;
            }
            getHeight();
            addEventListener('resize', () => {
                getHeight();
            });
        } catch (err) {
            console.error(...new ExxComError(538478, scriptName, err).stamp());
        }
    }

    private initIcons() {
        try {
            const isServer = isPlatformServer(this.platformId);
            appIconsList.forEach((iconName: any) => {
                const path = `${isServer ? this.document.location.origin : ''}/assets/icons/${iconName}.svg?ignoreCache=1`;
                const url = this.domSanitizer.bypassSecurityTrustResourceUrl(path);
                this.matIconRegistry.addSvgIcon(iconName, url);
            });
        } catch (err) {
            console.error(...new ExxComError(292881, scriptName, err).stamp());
        }
    }

    private initRouteListener() {
        try {
            this.routerService.onRouteChange('appComponent', (event: any) => {
                if (!isBrowser()) {
                    return;
                }
                // Scroll to top of all except categories
                if (!contains(event.url, this.categoryService.paths)) {
                    scrollToTop(document.documentElement);
                    this.marketoService.init();
                    this.marketoService.initLoaded();
                }
            });
        } catch (err) {
            console.error(...new ExxComError(297839, scriptName, err).stamp());
        }
    }

    isBlog() {
        try {
            if (isBrowser()) {
                return contains(window.location.pathname, 'blog');
            } else {
                if (this.req == null) {
                    return false;
                }
                return contains(this.req.originalUrl, 'blog');
            }
        } catch (err) {
            console.error(...new ExxComError(729882, scriptName, err).stamp());
        }
    }

    async initInfiniteScroll() {
        try {
            if (!isBrowser()) {
                return;
            }
            await wait('10ms');
            this.infiniteScrollService.initGlobalContainer(this.infiniteScrollGlobalContainer.nativeElement);
        } catch (err) {
            console.error(...new ExxComError(522992, scriptName, err).stamp());
        }
    }
}
