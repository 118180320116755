import { Injectable } from '@angular/core';

import { AddressData } from '../address/address.interface';
import { ApiService } from 'lib/services/api.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { ExxComResponse } from 'lib/interfaces/exxcom-response.interface';
import { Order } from 'lib/services/order/order.class';
import { OrderResponse, OrderCount, OrderData, OrderShippingMethod, OrderStartedData } from 'lib/services/order/order.interface';
import { StripeCardData } from '../stripe/stripe.interface';

const scriptName = 'order.service';

let apiService: ApiService;

@Injectable()
export class OrderService {
    // orders: Order[] = [];

    constructor(a: ApiService) {
        try {
            apiService = a;
            this.init();
        } catch (err) {
            console.error(...new ExxComError(243028, scriptName, err).stamp());
        }
    }

    // Core API

    async init() {}

    /**
     * @function get
     * @description Enables retrieving one or more order document(s) by _id,
     * refNumber, status, or user ID.
     */
    private async get({
        _id,
        refNumber,
        status,
        user,
        query,
        limit,
        page,
        sort,
        count,
    }: {
        _id?: string;
        refNumber?: string;
        status?: string;
        user?: string;
        query?: any;
        limit?: number;
        page?: number;
        sort?: string;
        count?: boolean;
    } = {}): Promise<OrderResponse> {
        try {
            let filters: any = {};
            if (_id) {
                filters._id = _id;
            } else if (refNumber) {
                filters.refNumber = refNumber;
            }
            if (status) {
                filters.status = status;
            }
            if (user) {
                filters.user = user;
            }
            if (query) {
                filters = Object.assign(filters, query);
            }
            const url = ['orders/find', `?filters=${JSON.stringify(filters)}`];
            if (limit) {
                url.push(`&limit=${limit}`);
            }
            if (page) {
                url.push(`&page=${page}`);
            }
            if (sort) {
                url.push(`&sort=${sort}`);
            }
            if (count) {
                url.push(`&count=true`);
            }
            const res: OrderResponse = await apiService.get(url);
            if (!res.success) {
                throw res;
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(405778, scriptName, err).stamp());
            return { success: false, error: err } as OrderResponse;
        }
    }

    /**
     * @function update
     * @description Enables updating a given order.
     */
    async update({ order, values, action }: { order: Order; values: any; action?: string }): Promise<OrderResponse> {
        try {
            const url = [`orders/update/${order._id}`];
            if (action) {
                url.push(`/${action}`);
            }
            const res: OrderResponse = await apiService.post(url, values);
            if (!res.success) {
                throw res;
            }
            order.init(res.data as OrderData);
            return res;
        } catch (err) {
            console.error(...new ExxComError(636084, scriptName, err).stamp());
            return { success: false, error: err } as ExxComResponse;
        }
    }

    /**
     * @function create
     * @description Enables creating new orders.
     */
    async create(values: Order | OrderStartedData): Promise<OrderResponse> {
        try {
            const res: OrderResponse = await apiService.post('orders/create', values);
            if (!res.success) {
                throw res;
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(242980, scriptName, err).stamp());
            return err as ExxComResponse;
        }
    }

    /**
     * @function delete
     * @description Deletes an order from the database.
     */
    async delete(_id: string): Promise<ExxComResponse> {
        try {
            const res: ExxComResponse = await apiService.delete(`orders/delete/${_id}`);
            if (!res.success) {
                throw res;
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(796354, scriptName, err).stamp());
            return { success: false, error: err } as ExxComResponse;
        }
    }

    // Get API

    /**
     * @function getDetached
     * @description For retrieving instantiated and initialized order or orders.
     */
    async getDetached({
        _id,
        refNumber,
        status,
        user,
        query,
        limit,
        page,
        sort,
    }: {
        _id?: string;
        refNumber?: string;
        status?: string;
        user?: string;
        query?: any;
        limit?: number;
        page?: number;
        sort?: string;
    } = {}): Promise<Order[]> {
        try {
            const res: OrderResponse = await this.get({
                _id,
                refNumber,
                status,
                user,
                query,
                limit,
                page,
                sort,
            });
            if (res.error) {
                throw res;
            }
            const orders: Order[] = ((res.data as OrderData[]) || []).map((data: OrderData) => new Order(data));
            return orders;
        } catch (err) {
            console.error(...new ExxComError(320476, scriptName, err).stamp());
            return [];
        }
    }

    /**
     * @function getCount
     * @description Gets a total count for a given query.
     */
    async getCount({ status, user, query }: { status?: string; user?: string; query?: any } = {}): Promise<number> {
        try {
            const res: OrderResponse = await this.get({
                status,
                query,
                user,
                count: true,
            });
            if (res.error) {
                throw res;
            }
            const count: number = ((res.data as OrderCount) || { count: 0 }).count;
            return count;
        } catch (err) {
            console.error(...new ExxComError(320476, scriptName, err).stamp());
            return 0;
        }
    }

    // Update API

    /**
     * @function setCustomer
     * @description Enables setting a customer on an order.
     */
    async setCustomer({
        order,
        customer: { _id, email, first, last, nsid, partition, paymentTerms, salesRep },
    }: {
        order: Order;
        customer: {
            _id: string;
            email: string;
            first: string;
            last: string;
            nsid: string;
            partition: string;
            paymentTerms: string;
            salesRep: string;
        };
    }) {
        try {
            const res: OrderResponse = await this.update({
                order,
                values: {
                    customer: _id,
                    customerName: `${first} ${last}`,
                    customerNsid: nsid,
                    email,
                    paymentTerms: order.paymentTerms || paymentTerms,
                    salesRep: order.salesRep || salesRep,
                },
            });
            if (!res.success) {
                return;
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(246026, scriptName, err).stamp());
            return { success: false, error: err } as ExxComResponse;
        }
    }

    /**
     * @function createLine
     * @description Enables creating a line on an order.
     */
    async createLine({ order, productId, quantity }: { order: Order; productId: string; quantity: number }): Promise<OrderResponse> {
        try {
            const res = await this.update({
                order,
                action: 'createLine',
                values: { productId, quantity },
            });
            if (!res.success) {
                return;
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(246026, scriptName, err).stamp());
            return { success: false, error: err } as ExxComResponse;
        }
    }

    /**
     * @function deleteLine
     * @description Enables deleting a line from an order.
     */
    async deleteLine({ order, productId }: { order: Order; productId: string }): Promise<OrderResponse> {
        try {
            const res = await this.update({
                order,
                action: 'deleteLine',
                values: { productId },
            });
            if (!res.success) {
                return;
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(133971, scriptName, err).stamp());
            return { success: false, error: err } as ExxComResponse;
        }
    }

    /**
     * @function setLineRate
     * @description Enables setting a custom price on a line item.
     */
    async setLineRate({ order, productId, rate }: { order: Order; productId: string; rate: number }): Promise<OrderResponse> {
        try {
            const res = await this.update({
                order,
                action: 'setLineRate',
                values: { productId, rate },
            });
            if (!res.success) {
                return;
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(691661, scriptName, err).stamp());
            return { success: false, error: err } as ExxComResponse;
        }
    }

    /**
     * @function addQuantity
     * @description Increases the quantity of products on a line.
     */
    async addQuantity({ order, productId, quantity }: { order: Order; productId: string; quantity: number }): Promise<OrderResponse> {
        try {
            const res = await this.update({
                order,
                action: 'addQuantity',
                values: { productId, quantity },
            });
            if (!res.success) {
                return;
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(520376, scriptName, err).stamp());
            return { success: false, error: err } as ExxComResponse;
        }
    }

    /**
     * @function subtractQuantity
     * @description Decreases the quantity of products on a line.
     */
    async subtractQuantity({ order, productId, quantity }: { order: Order; productId: string; quantity: number }): Promise<OrderResponse> {
        try {
            const res = await this.update({
                order,
                action: 'subtractQuantity',
                values: { productId, quantity },
            });
            if (!res.success) {
                return;
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(340771, scriptName, err).stamp());
            return { success: false, error: err } as ExxComResponse;
        }
    }

    /**
     * @function setQuantity
     * @description Sets the quantity of products on a line to a specific number.
     */
    async setQuantity({ order, productId, quantity }: { order: Order; productId: string; quantity: number }): Promise<OrderResponse> {
        try {
            const res = await this.update({
                order,
                action: 'setQuantity',
                values: { productId, quantity },
            });
            if (!res.success) {
                return;
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(340771, scriptName, err).stamp());
            return { success: false, error: err } as ExxComResponse;
        }
    }

    /**
     * @function setAddress
     * @description Sets the order shipping or billing address.
     */
    async setAddress({ order, type, address }: { order: Order; type: string; address: AddressData }): Promise<OrderResponse> {
        try {
            const res = await this.update({
                order,
                action: 'setAddress',
                values: { type, address },
            });
            if (!res.success) {
                return;
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(420376, scriptName, err).stamp());
            return { success: false, error: err } as ExxComResponse;
        }
    }

    /**
     * @function setShippingMethod
     * @param {Order} order - An Order instance
     * @param {Number} [amountShipping] - The shipping method rate returned from easyPostShipping.get
     * @param {Boolean} [retrieveShipping] - Indicates whether the shipping rate should be retrieved. Default: false
     * @param {OrderShippingMethod} [shippingMethod] - A shipping method object with deliveryDate (from easyPostShipping.get) and nsid
     * @description Sets the shipping method and shipping amount on an order.
     * Usually shipping methods are retrieved on the client-side, so it is not
     * necessary to retrieve them again on the server-side.
     *
     * Use Case 1: Shipping methods retrieved on the client-side, do not retrieve
     * again on the server
     * - Pass in order
     * - Pass in amountShipping
     * - Pass in shippingMethod.deliveryDate
     * - Pass in shippingMethod.nsid
     *
     * Use Case 2: Shipping methods not retrieved on the client-side, retrieve on
     * the server
     * - Pass in order
     * - Pass in retrieveShipping: true
     * - Pass in shippingMethod.nsid
     */
    async setShippingMethod({
        order,
        amountShipping,
        retrieveShipping,
        shippingMethod: { deliveryDate, nsid },
    }: {
        order: Order;
        amountShipping?: number;
        retrieveShipping?: boolean;
        shippingMethod: OrderShippingMethod;
    }): Promise<OrderResponse> {
        try {
            const res = await this.update({
                order,
                action: 'setShippingMethod',
                values: {
                    amountShipping,
                    retrieveShipping,
                    shippingMethod: { deliveryDate, nsid },
                },
            });
            if (!res.success) {
                return;
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(682774, scriptName, err).stamp());
            return { success: false, error: err } as ExxComResponse;
        }
    }

    /**
     * @function setAmountShipping
     * @description Enables setting a custom shipping amount.
     */
    async setAmountShipping({ order, amountShipping }: { order: Order; amountShipping: number }): Promise<OrderResponse> {
        try {
            const res = await this.update({
                order,
                action: 'setAmountShipping',
                values: { amountShipping },
            });
            if (!res.success) {
                return;
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(420476, scriptName, err).stamp());
            return { success: false, error: err } as ExxComResponse;
        }
    }

    /**
     * @function setBillingMethod
     * @param {Order} order - An Order instance
     * @param {StripeCardData} [card]
     * @description Sets stripePayment card data on an order.
     */
    async setBillingMethod({
        order,
        card: { brand, id, expMonth, expYear, last4, name },
        clientSecret,
        intentId,
    }: {
        order: Order;
        card?: StripeCardData;
        clientSecret?: string;
        intentId?: string;
    }): Promise<OrderResponse> {
        try {
            const res = await this.update({
                order,
                action: 'setBillingMethod',
                values: {
                    stripePayment: {
                        card: { brand, id, expMonth, expYear, last4, name },
                        clientSecret,
                        intentId,
                    },
                },
            });
            if (!res.success) {
                return;
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(420366, scriptName, err).stamp());
            return { success: false, error: err } as ExxComResponse;
        }
    }

    // Create API

    /**
     * @function start
     * @description Starts an order with basic information to prepare it for
     * additional user-specified data.
     */
    async start({ userId, webstore }: { userId: string; webstore: string }): Promise<OrderResponse> {
        try {
            const res: OrderResponse = await this.create({
                shipEarly: false,
                signatureRequired: false,
                status: 'Started',
                type: 'ui',
                user: userId,
                webstore,
            } as OrderStartedData);
            return res;
        } catch (err) {
            console.error(...new ExxComError(423903, scriptName, err).stamp());
            return { success: false, error: err } as ExxComResponse;
        }
    }
}
