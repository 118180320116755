<div id="cms-blog-header-search">
  <div id="cms-blog-header-search-input-container" #searchInputContainer>
    <input
      id="cms-blog-header-search-input"
      #searchInput
      placeholder="Search"
      [class.mat-elevation-z2]="searchInputHasFocus"
      [class.show-magnifier]="!isNarrow"
      (focus)="onFocus()"
      (keyup)="onKeyup($event)"
      (keydown.enter)="onKeydownEnter()"
      data-cy="cms-blog-header-search-wide"
    />
    <div *ngIf="!displayMagnifier && isNarrow" id="cms-blog-header-search-narrow-close" (click)="navAway()">
      <div></div>
      <div></div>
    </div>
  </div>

  <div id="cms-blog-header-search-menu" *ngIf="isActive" #searchMenu>
    <div *ngIf="suggestedPosts.length == 0" id="cms-blog-header-search-no-results">
      <h5 class="cms-blog-header-search-menu-title">No results match your search query.</h5>
    </div>

    <div *ngIf="suggestedPosts.length > 0" id="cms-blog-header-search-menu-posts">
      <div *ngFor="let post of suggestedPosts" class="cms-blog-header-search-menu-posts-links">
        <a class="fx-row fx-row-h-left fx-row-v-center fx-wrap" [routerLink]="post.url" (click)="navAway()">
          <span class="mx-3 mt-1" [innerHTML]="post.title"></span>
          <p class="mx-3 mb-1 mt-base">{{ post.date }} &#x2022; {{ calculateReadingTime(post) }}</p>
        </a>
      </div>
    </div>

    <div *ngIf="suggestedPosts.length > 0" id="cms-blog-header-search-menu-see-more-results">
      <button
        class="button-primary see-more-button"
        id="cms-blog-header-search-menu-see-more-results-link"
        data-cy="cms-blog-search-menu-see-more-results"
        [routerLink]="'/blog/result/input/' + searchValue"
        (click)="navAway()"
      >
        See all results
      </button>
    </div>
  </div>
</div>
