import { Injectable } from '@angular/core';

import { ApiService } from 'lib/services/api.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { ExxComResponse } from 'lib/interfaces/exxcom-response.interface';
import { Product } from 'lib/services/product/product.class';
import { ProductData, ProductResponse } from 'lib/services/product/product.interface';

const scriptName = 'product.service';

let apiService: ApiService;

@Injectable()
export class ProductService {
    constructor(a: ApiService) {
        try {
            apiService = a;
        } catch (err) {
            console.error(...new ExxComError(249277, scriptName, err).stamp());
        }
    }

    // Core API

    /**
     * @function get
     * @description Enables retrieving one or more product document(s) by _id or
     * by name.
     */
    private async get({
        _id,
        name,
        query,
        limit,
    }: {
        _id?: string;
        name?: string;
        query?: any;
        limit?: number;
    } = {}): Promise<ProductResponse> {
        try {
            let filters: any = {};
            if (_id) {
                filters._id = _id;
            } else if (name) {
                filters.email = name;
            }
            if (query) {
                filters = Object.assign(filters, query);
            }
            const url = ['products/find', `?filters=${JSON.stringify(filters)}`];
            if (limit) {
                url.push(`&limit=${limit}`);
            }
            const res: ProductResponse = await apiService.get(url);
            if (!res.success) {
                throw res;
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(420377, scriptName, err).stamp());
            return { success: false, error: err } as ExxComResponse;
        }
    }

    /**
     * @function update
     * @description Enables updating a given product.
     * *** Must be done in STEP. ***
     */
    // async update(
    //   { product, values, action }:
    //   { product: Product, values: any, action?: string }
    // ): Promise<ProductResponse> {
    //   try {
    //     const url = [`products/update/${product._id}`];
    //     if (action) { url.push(`/${action}`); }
    //     const res: ProductResponse = await apiService.post(url, values);
    //     if (!res.success) { throw res; }
    //     product.init(res.data as ProductData);
    //     return res;
    //   } catch(err) {
    //     console.error(...new ExxComError(340276, scriptName, err).stamp());
    //     return { success: false, error: err } as ExxComResponse;
    //   }
    // }

    /**
     * @function create
     * @description Enables creating new products.
     * *** Must be done in STEP. ***
     */
    // async create(values: ProductData): Promise<ProductResponse> {
    //   try {
    //     const res: ProductResponse = await apiService.post('products/create', values);
    //     if (!res.success) { throw res; }
    //     return res;
    //   } catch(err) {
    //     const message = get(err, 'error.message');
    //     if (message != 'Already exists') { console.error(...new ExxComError(420377, scriptName, err).stamp()); }
    //     return err as ExxComResponse;
    //   }
    // }

    /**
     * @function delete
     * @description Deletes a customer from the database.
     * *** Must be done in STEP. ***
     */
    // async delete(_id: string): Promise<ExxComResponse> {
    //   try {
    //     const res: ExxComResponse = await apiService.delete(`products/delete/${_id}`);
    //     if (!res.success) { throw res; }
    //     return res;
    //   } catch(err) {
    //     console.error(...new ExxComError(240737, scriptName, err).stamp());
    //     return { success: false, error: err } as ExxComResponse;
    //   }
    // }

    // Get API

    /**
     * @function getDetached
     * @description For retrieving instantiated and initialized customer or
     * customers.
     */
    async getDetached({
        _id,
        name,
        query,
        limit,
    }: {
        _id?: string;
        name?: string;
        query?: any;
        limit?: number;
    } = {}): Promise<Product[]> {
        try {
            const res: ProductResponse = await this.get({
                _id,
                name,
                query,
                limit,
            });
            if (res.error) {
                throw res;
            }
            const product: Product[] = ((res.data as ProductData[]) || []).map((data: ProductData) => new Product(data));
            return product;
        } catch (err) {
            console.error(...new ExxComError(629477, scriptName, err).stamp());
            return [];
        }
    }
}
