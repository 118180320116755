import { Component, OnInit } from '@angular/core';

import { CmsService } from 'lib/services/cms.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { isBrowser } from 'lib/tools';
import { MetaService } from 'lib/services/meta.service';
import { WebpService } from 'lib/services/webp.service';

const scriptName = 'cms-policies.component';

@Component({
    selector: 'app-cms-policies',
    templateUrl: './cms-policies.component.html',
    styleUrls: ['./cms-policies.component.scss'],
})
export class CmsPoliciesComponent implements OnInit {
    entry: any = {};
    title: string;
    getWebpImg: (src: string) => string;

    constructor(
        private cmsService: CmsService,
        private metaService: MetaService,
        webpService: WebpService
    ) {
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
    }

    async ngOnInit() {
        try {
            this.entry = await this.cmsService.getPage('policy_page');
            this.title = this.getTitle(this.entry.title);
        } catch (err) {
            console.error(...new ExxComError(794391, scriptName, err).stamp());
        }
    }

    getTitle(title: string) {
        try {
            const splitTitle = title.split(' ');
            return splitTitle[0] + '-' + splitTitle[1];
        } catch (err) {
            console.error(...new ExxComError(793222, scriptName, err).stamp());
        }
    }

    getSectionId(sectionRowTitle: string) {
        try {
            const splitSectionRowTitle = sectionRowTitle.split(' ');
            return splitSectionRowTitle[0];
        } catch (err) {
            console.error(...new ExxComError(709221, scriptName, err).stamp());
        }
    }

    onClick(title: string): void {
        try {
            if (!isBrowser()) {
                return;
            }
            const id = this.getSectionId(title);
            const el: HTMLElement | null = document.getElementById(id);
            if (el) {
                el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest',
                });
            }
        } catch (err) {
            console.error(...new ExxComError(892881, scriptName, err).stamp());
        }
    }
}
