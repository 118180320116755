<div *ngIf="isInitialized" id="cms-blog-results">
  <div class="fx-row-container fx-wrap" data-cy="search-results-text">
    <h5 *ngIf="type == 'input'" class="col-sm-7 mb-0 mt-4">Search Result for:</h5>
    <p *ngIf="type == 'tag'" class="col-sm-7 mb-0">Articles related to:</p>
    <h4 *ngIf="type == 'input'" class="col-sm-7 mb-4 mt-base">{{ value }}</h4>
    <h2 *ngIf="type == 'tag'" class="col-sm-7 pb-2">{{ value }}</h2>
  </div>

  <div *ngIf="results.length == 0" class="fx-row-container no-result-image pt-3">
    <img class="col-sm-10" src="https://images.sabrepc.com/spc-cms/blog/NoSearchResults.svg" alt="No Search Results" />
  </div>

  <div class="ul fx-row-container fx-row-h-center fx-wrap">
    <div *ngFor="let post of results" class="cms-blog-results-posts col-sm-7">
      <h5 class="text-left cms-blog-results-posts-category mb-base">{{ get(post, "blog_category[0].title", "") }}</h5>
      <a [routerLink]="post.url">
        <h4 class="m-0">{{ post.title }}</h4>
        <p class="mt-0 date">{{ reformatDate(post) }} &#x2022; {{ calculateReadingTime(post) }}</p>
      </a>
    </div>
  </div>
</div>
