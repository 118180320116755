<div
  (window:resize)="onWindowResize()"
  [class]="'swiper ' + mainClass"
  [ngStyle]="{ visibility: isHidden ? 'hidden' : '', 'max-width': maxWidth ? maxWidth : '' }"
>
  <div *ngIf="mode == 'multi'" [id]="selector" [class]="'swiper-container swiper-multi-container'">
    <h3 *ngIf="sectionHeading" [class]="headingStyle" [class.section-heading]="checkEnviroment">
      {{ sectionHeading }}
    </h3>

    <!-- recommendations and recently viewed -->
    <div class="swiper-wrapper py-3">
      <div *ngFor="let slide of slides" class="swiper-slide swiper-multi-slide fx-row fx-row-h-center">
        <a [routerLink]="slide.linkUrl" class="fx-col fx-col-v-center fx-col-h-center">
          <div class="swiper-multi-image-wrapper fx-col fx-col-v-center fx-col-h-center">
            <img [class]="imageStyle" [src]="slide.imageUrl" />
          </div>
          <span [class]="slideTitleStyle" [innerHTML]="slide.heading | safe : 'html'"> </span>
        </a>
      </div>
    </div>

    <div class="swiper-arrows">
      <div *ngIf="!arrowPosition && options.loop" class="swiper-arrows-default">
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>

      <div
        *ngIf="arrowPosition == 'upper-right' && options.loop"
        [class]="'swiper-arrows-upper-right fx-row-v-center ' + buttonStyle"
        (click)="swiper.slideNext()"
      >
        <span>{{ arrowText }}</span>
        <mat-icon *ngIf="hasCaret" class="fx-row fx-row-v-center fx-row-h-center">chevron_right</mat-icon>
      </div>
    </div>
  </div>

  <div *ngIf="mode == 'single'" [id]="selector" [class]="'swiper-container swiper-single-container hero-banner-mobile'">
    <h4 *ngIf="sectionHeading" [class]="headingStyle">{{ sectionHeading }}</h4>

    <div class="swiper-wrapper">
      <div *ngFor="let slide of slides" class="swiper-slide swiper-single-slide">
        <div
          class="swiper-single-image-wrapper fx-row fx-wrap fx-row-v-center"
          [ngStyle]="{ 'background-image': 'url(' + slide?.imageUrl + ')' }"
        >
          <div *ngIf="name == 'desktop-page'" class="col-sm-8 col-md-4 spc-data-margin">
            <h1 [class.light]="slide.lightTheme">{{ slide?.heading }}</h1>
            <a class="button-primary button-base" [routerLink]="slide?.linkUrl">
              {{ slide?.secondHeading }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="slides?.length > 1" class="swiper-pagination"></div>
  </div>

  <div *ngIf="mode == 'mobile'" [id]="selector" [class]="'swiper-container swiper-mobile-container hero-banner-mobile'">
    <h4 *ngIf="sectionHeading" [class]="headingStyle">{{ sectionHeading }}</h4>

    <div class="swiper-wrapper">
      <div *ngFor="let slide of slides" class="swiper-slide swiper-mobile-slide">
        <div *ngIf="!isRelatedContent">
          <div
            class="swiper-mobile-image-wrapper fx-row fx-wrap fx-row-v-center"
            [ngStyle]="{ 'background-image': 'url(' + slide?.imageUrl + ')' }"
          ></div>

          <div *ngIf="name == 'mobile-page'" class="col-sm-8 col-md-4 mt-2">
            <h1>{{ slide?.heading }}</h1>
            <a class="button-primary button-base" [routerLink]="slide?.linkUrl">
              {{ slide?.secondHeading }}
            </a>
          </div>
        </div>
        <div *ngIf="isRelatedContent">
          <a [routerLink]="slide?.linkUrl" class="card fx-col px-2">
            <div class="card-image-container">
              <img [src]="slide?.imageUrl" [alt]="slide?.secondHeading" />
            </div>

            <div class="card-body fx-col">
              <div>
                <h5 class="mb-1">
                  {{ slide.category }}
                </h5>
                <h3>
                  {{ slide?.heading }}
                </h3>
              </div>

              <div class="date-time fx-row fx-row-v-center">
                <span>{{ slide.date | date : "longDate" }}</span>
                <div class="ml-4 fx-row fx-row-v-center">
                  <mat-icon *ngIf="slide.readTime" svgIcon="clock" class="mr-1"></mat-icon>
                  {{ slide.readTime }}
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>

    <span *ngIf="slides?.length > 1" class="swiper-pagination spacing-2"></span>
  </div>
</div>
