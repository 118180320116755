import { Component, EventEmitter, Output } from '@angular/core';

import { ExxComError } from 'lib/classes/exxcom-error.class';
import { GtmService } from 'lib/services/google/gtm.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

const scriptName = 'marketo-dialog.component';

@Component({
    selector: 'app-marketo-dialog',
    templateUrl: './marketo-dialog.component.html',
    styleUrls: ['./marketo-dialog.component.scss'],
})
export class MarketoDialogComponent {
    data: any = this.options.initialState;
    @Output() formSubmittedEmitter: any = new EventEmitter<any>();
    @Output() reloadEventEmitter: any = new EventEmitter<any>();

    constructor(
        private gtmService: GtmService,
        private bsModalService: BsModalService,
        public bsModalRef: BsModalRef,
        public options: ModalOptions
    ) {}

    initForm(form: any) {}

    marketoFormSubmitted(customerData: object, formId: string) {
        try {
            this.gtmService.trackMarketoFormSubmission(formId);
            this.formSubmittedEmitter.emit({ success: true, customerData });
        } catch (err) {
            console.error(...new ExxComError(888883, scriptName, err).stamp());
        }
    }

    onReload() {
        this.reloadEventEmitter.emit();
    }
}
