import { Component, Input, Inject, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { ExxComError } from 'lib/classes/exxcom-error.class';
import { get, isEmpty } from 'lodash';
import { isBrowser } from 'lib/tools';
import { SwiperSlide } from 'lib/classes/swiper-slide.class';

const scriptName = 'swiper.component';

declare let Swiper: any;

@Component({
    selector: 'lib-swiper',
    templateUrl: './swiper.component.html',
    styleUrls: ['./swiper.component.scss'],
})
export class SwiperComponent implements OnChanges, OnInit {
    @Input() arrowPosition: string = 'default'; // Options: default, upper-right
    @Input() arrowText: string = '';
    @Input() buttonStyle: string = 'link-purple';
    @Input() hasCaret: boolean = false;
    @Input() headingStyle: string = 'text-center heading-exx';
    @Input() imageStyle: string = 'swiper-multi-slide-image';
    @Input() isRelatedContent: boolean = false;
    @Input() maxWidth: string = '';
    @Input() mode: string = ''; // Options: multi, single, mobile
    @Input() name: string = '';
    @Input() entry: string = ''; // entry is pulled from homepage.component
    @Input() sectionHeading: string = '';
    @Input() slides: SwiperSlide[] = [];
    @Input() mobileSlides: SwiperSlide[] = [];
    @Input() slideTitleStyle: string = 'swiper-multi-slide-heading';
    @Input() transitionAsGroup: boolean = false;
    @Input() loop: string = 'true';

    private breakpoints: any = { sm: 0, md: 577, lg: 769 };

    isHidden: boolean = true;
    mainClass: string = '';
    options: any = {
        breakpoints: {}, // Only enabled in multi mode
        loop: '',
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        on: {
            init: () => (this.isHidden = false),
            click: (e: any) => {
                // Mobile pagination
                const className: string = get(e, 'target.className', '');
                if (className.indexOf('disabled') != -1) {
                    return;
                }
                if (className.indexOf('arrow ') != -1) {
                    const isLeft = className.indexOf('left-arrow') != -1;
                    return isLeft ? this.swiper.slidePrev() : this.swiper.slideNext();
                }
            },
        },
        pagination: {
            // Pagination will only be enabled on the carousel if a div with class = swiper-pagination is created
            el: '.swiper-pagination',
            type: 'custom',
            clickable: true,
            renderCustom: function (_: any, current: number, total: number) {
                let prevArrow = 'arrow left-arrow swiper-button-prev';
                let nextArrow = 'arrow right-arrow swiper-button-next';
                if (current == 1) {
                    prevArrow += ' disabled';
                } else if (current == total) {
                    nextArrow += ' disabled';
                }
                return `
          <span class='${prevArrow}'></span>
          <span>${current} of ${total}</span>
          <span class='${nextArrow}'></span>
        `;
            },
        },
        zoom: true,
        preventsClicks: false,
    };
    selector: string = '';
    swiper: any;

    constructor(@Inject('environment') private environment: any) {}

    // Required because sometimes the properties are not quite ready when the component loads
    ngOnChanges(changes: SimpleChanges) {
        try {
            if (!isBrowser()) {
                return;
            }
            this.maxWidth = (changes.maxWidth && changes.maxWidth.currentValue) || this.maxWidth;
            this.mode = (changes.mode && changes.mode.currentValue) || this.mode;
            this.slides = (changes.slides && changes.slides.currentValue) || [];
            this.mobileSlides = (changes.mobileSlides && changes.mobileSlides.currentValue) || [];
            this.arrowPosition = (changes.arrowPosition && changes.arrowPosition.currentValue) || this.arrowPosition;
            this.arrowText = (changes.arrowText && changes.arrowText.currentValue) || this.arrowText;
            this.loop = (changes.loop && changes.loop.currentValue) || this.loop;
            this.entry = (changes.entry && changes.entry.currentValue) || this.entry;
            if (this.slides.length > 0 || this.mobileSlides.length > 0) {
                this.initOnChanges();
            }
        } catch (err) {
            console.error(...new ExxComError(399391, scriptName, err).stamp());
        }
    }

    // dynamically sets class container for our swiper based on type/name
    ngOnInit() {
        try {
            this.mainClass = `swiper-${this.mode}`;
            this.selector = `swiper-${this.name}-container`;
        } catch (err) {
            console.error(...new ExxComError(266848, scriptName, err).stamp());
        }
    }

    private initOnChanges() {
        try {
            this.isHidden = true;
            if (this.mode == 'multi') {
                this.initMulti(); // breaks up how many slides/screen shown depending on screen size
                this.initMultiMaxWidth(); // sets max-width depending on how many slides/screen
            } else {
                // other cases are type = single or type = mobile in <lib-swapper>, currently for desktop/mobile banner
                this.options.slidesPerView = 1;
            }
            setTimeout(() => (this.swiper = new Swiper(`#${this.selector}`, this.options))); // creates the swiper with given options
        } catch (err) {
            console.error(...new ExxComError(582775, scriptName, err).stamp());
        }
    }

    private initMulti() {
        try {
            const totalSlides = this.slides.length;
            const smSlidesPerView = totalSlides <= 2 ? totalSlides : 2.5;
            const mdSlidesPerView = totalSlides < 3 ? totalSlides : 4;
            const lgSlidesPerView = totalSlides < 4 ? totalSlides : 5;
            this.options.breakpoints[this.breakpoints.sm] = {
                // when window width is >=   0px
                maxSlidesPerView: 2.5,
                slidesPerView: smSlidesPerView,
                slidesPerGroup: this.transitionAsGroup ? smSlidesPerView : 1,
                spaceBetween: 10,
                watchSlidesVisibility: true,
            };
            this.options.breakpoints[this.breakpoints.md] = {
                // when window width is >= 577px
                maxSlidesPerView: 4,
                slidesPerView: mdSlidesPerView,
                slidesPerGroup: this.transitionAsGroup ? mdSlidesPerView : 1,
                spaceBetween: 25,
            };
            this.options.breakpoints[this.breakpoints.lg] = {
                // when window width is >= 769px
                maxSlidesPerView: 5,
                slidesPerView: lgSlidesPerView,
                slidesPerGroup: this.transitionAsGroup ? lgSlidesPerView : 1,
                spaceBetween: 40,
                watchSlidesVisibility: false,
            };
            this.setLoopOption();
            if (!this.swiper) {
                return;
            }
            const deleteInstance = false;
            const cleanStyles = true;
            this.swiper.destroy(deleteInstance, cleanStyles);
        } catch (err) {
            console.error(...new ExxComError(587277, scriptName, err).stamp());
        }
    }

    private initMultiMaxWidth() {
        try {
            if (!this.maxWidth) {
                return;
            }
            const currentBreakpoint = this.getCurrentBreakpoint();
            const breakpointOptions = this.options.breakpoints[currentBreakpoint];
            const maxWidth = parseInt(this.maxWidth.replace('px', ''));
            const multiplier = breakpointOptions.slidesPerView / breakpointOptions.maxSlidesPerView;
            this.maxWidth = Math.round(maxWidth * multiplier) + 'px';
        } catch (err) {
            console.error(...new ExxComError(229901, scriptName, err).stamp());
        }
    }

    get checkEnviroment() {
        return this.environment.siteAbbr == 'exx';
    }

    private getCurrentBreakpoint() {
        try {
            const windowWidth = window.innerWidth;
            let currentBreakpoint: number;
            if (windowWidth >= this.breakpoints.sm && windowWidth < this.breakpoints.md) {
                currentBreakpoint = this.breakpoints.sm;
            } else if (windowWidth >= this.breakpoints.md && windowWidth < this.breakpoints.lg) {
                currentBreakpoint = this.breakpoints.md;
            } else if (windowWidth >= this.breakpoints.lg) {
                currentBreakpoint = this.breakpoints.lg;
            }
            return currentBreakpoint;
        } catch (err) {
            console.error(...new ExxComError(682883, scriptName, err).stamp());
        }
    }

    private setLoopOption() {
        try {
            if (isEmpty(this.options.breakpoints)) {
                return;
            }
            this.options.loop = this.slides.length > this.options.breakpoints[this.getCurrentBreakpoint()].maxSlidesPerView;
        } catch (err) {
            console.error(...new ExxComError(794786, scriptName, err).stamp());
        }
    }

    // Dynamically changes how many slides are looped through when a user swipes
    onWindowResize() {
        try {
            if (this.mode == 'multi') {
                this.setLoopOption();
            }
        } catch (err) {
            console.error(...new ExxComError(978723, scriptName, err).stamp());
        }
    }
}
