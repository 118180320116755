import { Inject, Injectable } from '@angular/core';

import { ExxComError } from 'lib/classes/exxcom-error.class';
import { isBrowser } from 'lib/tools';

const scriptName = 'disqus.service';
let environment: any;

@Injectable()
export class DisqusService {
    private scriptElem: any;
    public active: Boolean;

    constructor(@Inject('environment') e: any) {
        try {
            environment = e;
        } catch (err) {
            console.error(...new ExxComError(535594, scriptName, err).stamp());
        }
    }

    init() {
        try {
            if (!isBrowser()) {
                return;
            }
            this.active = true;
            this.scriptElem = document.createElement('script');
            const siteName = environment.siteAbbr == 'spc' ? 'sabre-blog' : 'exxact-blog';
            this.scriptElem.innerHTML = `(function() {
        let d = document, s = d.createElement('script');
        s.src = 'https://${siteName}.disqus.com/embed.js';
        s.setAttribute('data-timestamp', +new Date());
        (d.head || d.body).appendChild(s);
      })();`;
            document.head.appendChild(this.scriptElem);
        } catch (err) {
            console.error(...new ExxComError(109387, scriptName, err).stamp());
        }
    }

    removeScript() {
        try {
            if (!isBrowser()) {
                return;
            }
            this.scriptElem.remove();
        } catch (err) {
            console.error(...new ExxComError(520939, scriptName, err).stamp());
        }
    }
}
