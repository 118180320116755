import { Component, Input, AfterViewInit, Output, EventEmitter, ChangeDetectorRef, Inject } from '@angular/core';
import { Swiper, Autoplay } from 'swiper';

import { isBrowser } from 'lib/tools';
import { ExxComError } from 'lib/classes/exxcom-error.class';

const scriptName = 'mod-swiper.component';

Swiper.use([Autoplay]);

@Component({
    selector: 'mod-swiper[containerClass]',
    templateUrl: './mod-swiper.component.html',
    styleUrls: ['./mod-swiper.component.scss'],
})
export class ModSwiperComponent implements AfterViewInit {
    @Input() swiperTitle: string = '';
    @Input() slidesPerView: number = 3;
    @Input() slidesPerViewBelow: number = 0;
    @Input() spaceBetween: number = 0;
    @Input() centeredSlides: boolean = false;
    @Input() initialSlide: number = 0;
    @Input() breakpoints: any = {
        // disables touch drag
        100: {
            touchRatio: 0,
        },
    };
    @Input() loop: boolean = false;
    @Input() autoplay: any = false;
    @Input() productImages: string[];
    // IMPORTANT: required for swiper to function properly
    @Input() containerClass: string = 'swiper-container';
    @Input() allowNextArrow: boolean = true;
    @Input() allowPrevArrow: boolean = true;
    @Input() bottomNav: boolean = false;
    @Input() navType: string = 'normal';
    @Input() isSolution: boolean;

    @Output() onNumberChange = new EventEmitter<number>();

    @Output() onSlideChange = new EventEmitter<number>();

    swiper: any;
    showNextArrow: boolean = true;
    showPrevArrow: boolean = true;
    slideCounter: number = 0;
    currentSlideCounter: number = 0;
    prevSlideCounter: number;
    nextSlideCounter: number = 0;
    belowSlideLocation: number = 0;
    belowSlideTranslateAmount: number = 0;
    desiredIndex: number = 0;
    widthPerSwiperImage: number = 66;

    private _activeIndex: number;

    @Input() set activeIndex(value: number) {
        this._activeIndex = value;
        this.slideSwiper(this._activeIndex);
    }

    get activeIndex(): number {
        return this._activeIndex;
    }

    constructor(
        private cdr: ChangeDetectorRef,
        @Inject('environment') public environment: any
    ) {}

    ngAfterViewInit() {
        const options = {
            autoplay: this.autoplay,
            breakpoints: this.breakpoints,
            centeredSlides: this.centeredSlides,
            init: true,
            initialSlide: this.initialSlide,
            loop: this.loop,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            observer: true,
            observeParents: true,
            slidesPerView: this.slidesPerView,
            spaceBetween: this.spaceBetween,
        };
        if (this.bottomNav) {
            options.navigation = {
                nextEl: '.swiper-button-next-unique',
                prevEl: '.swiper-button-prev-unique',
            };
        }
        try {
            if (!isBrowser()) {
                return;
            }
            this.swiper = new Swiper(`.${this.containerClass}`, options);
            this.determineArrowVisibility();
            this.slideCounter = this.swiper.activeIndex + 1;
            if (!this.swiper.on) {
                return;
            }
            this.swiper.on('breakpoint', () => {
                this.determineArrowVisibility();
            });
            this.swiper.on('slideChange', () => {
                this.determineArrowVisibility();
                this.slideCounter = this.swiper.activeIndex + 1;
                this.emitSlideIndex();
                if (this.bottomNav) {
                    this.swapImage(this.swiper.activeIndex);
                }
            });
            this.belowSlideTranslateAmount = this.slidesPerViewBelow * this.widthPerSwiperImage;
        } catch (err) {
            console.error(...new ExxComError(438041, scriptName, err).stamp());
        }
        this.cdr.detectChanges();
    }

    slideSwiper(value) {
        this.swiper?.slideTo(value);
    }

    swapImage(index: number) {
        this.prevSlideCounter = this.currentSlideCounter;
        this.currentSlideCounter = index;
        document
            .querySelector<HTMLElement>(`#image-swiper-container :nth-child(${this.currentSlideCounter + 1})`)
            .classList.toggle('active-swiper-product');
        document
            .querySelector<HTMLElement>(`#image-swiper-container :nth-child(${this.prevSlideCounter + 1})`)
            .classList.toggle('active-swiper-product');
        this.swiper.slideTo(index);
        this.onNumberChange.emit(this.currentSlideCounter);
    }

    emitSlideIndex() {
        this.onSlideChange.emit(this.swiper.activeIndex);
    }

    determineArrowVisibility() {
        this.showPrevArrow = !this.swiper.isBeginning && this.allowPrevArrow;
        this.showNextArrow = !this.swiper.isEnd && this.allowNextArrow;
    }

    prevSlide() {
        if (this.swiper) {
            if (this.navType === 'bottom') {
                if (this.currentSlideCounter === 0) {
                    return;
                }
                if (this.currentSlideCounter % this.slidesPerViewBelow == 0) {
                    document.querySelector<HTMLElement>('#image-swiper-container').style.transform = `translateX(${
                        this.belowSlideLocation + this.belowSlideTranslateAmount
                    }px)`;
                    this.belowSlideLocation += this.belowSlideTranslateAmount;
                }
                if (this.currentSlideCounter !== 0) {
                    this.prevSlideCounter = this.currentSlideCounter;
                    document
                        .querySelector<HTMLElement>(`#image-swiper-container :nth-child(${this.currentSlideCounter})`)
                        .classList.toggle('active-swiper-product');
                    document
                        .querySelector<HTMLElement>(`#image-swiper-container :nth-child(${this.currentSlideCounter + 1})`)
                        .classList.toggle('active-swiper-product');
                    this.currentSlideCounter -= 1;
                    this.onNumberChange.emit(this.currentSlideCounter);
                }
            }

            const currentSlide = this.swiper.activeIndex;
            const { slidesPerView } = this.swiper.params;
            this.swiper.slideTo(currentSlide - slidesPerView);
        }
    }

    prevSlideSPC() {
        if (this.swiper) {
            const currentSlide = this.swiper.activeIndex;
            const { slidesPerView } = this.swiper.params;
            this.swiper.slideTo(currentSlide - slidesPerView);
        } else {
            return;
        }
    }

    nextSlideSPC() {
        if (this.swiper) {
            const currentSlide = this.swiper.activeIndex;
            const { slidesPerView } = this.swiper.params;
            this.swiper.slideTo(currentSlide + slidesPerView);
        } else {
            return;
        }
    }

    nextSlide() {
        if (this.swiper) {
            if (this.navType === 'bottom') {
                if (this.currentSlideCounter === this.productImages.length - 1) {
                    return;
                }

                if ((this.currentSlideCounter + 1) % this.slidesPerViewBelow == 0) {
                    document.querySelector<HTMLElement>('#image-swiper-container').style.transform = `translateX(-${
                        this.belowSlideLocation + this.belowSlideTranslateAmount
                    }px)`;
                    this.belowSlideLocation -= this.belowSlideTranslateAmount;
                }
                if (this.currentSlideCounter !== this.productImages.length - 1) {
                    this.currentSlideCounter += 1;
                    this.onNumberChange.emit(this.currentSlideCounter);
                    document
                        .querySelector<HTMLElement>(`#image-swiper-container :nth-child(${this.currentSlideCounter + 1})`)
                        .classList.toggle('active-swiper-product');
                    document
                        .querySelector<HTMLElement>(`#image-swiper-container :nth-child(${this.currentSlideCounter})`)
                        .classList.toggle('active-swiper-product');
                }
            }
            const currentSlide = this.swiper.activeIndex;
            const { slidesPerView } = this.swiper.params;
            this.swiper.slideTo(currentSlide + slidesPerView);
        } else {
            return;
        }
    }
}
