<ng-container *ngIf="!isEmpty(get(leadGenRow, 'lead_gen', ''))">
  <div *ngFor="let row of get(leadGenRow, 'lead_gen.lead_gen', []); let i = index">
    <section id="rows" class="lead-gen" [class.gray-bg]="get(leadGenRow, 'lead_gen.gray_background', false)">
      <div class="d-flex flex-wrap container-padless justify-content-center">
        <div class="category-top-title d-flex flex-row justify-content-center">
          <h3 class="col-md-7 text-center">{{ get(row, "lead_gen_title", "") }}</h3>
        </div>
        <p class="text-center px-3">{{ get(row, "lead_gen_copy", "") }}</p>
        <div class="col-xs-12 text-center">
          <button
            *ngIf="get(row, 'lead_gen_link.title', '')"
            class="button-secondary button-base"
            (click)="
              bootstrapMarketoService.openBootstrapMarketoDialog(get(row, 'marketo_form.id', ''), 'edit', {
                url: dataUrl,
                title: dataTitle
              })
            "
          >
            {{ get(row, "lead_gen_link.title", "") }}
          </button>
        </div>
      </div>
    </section>
  </div>
</ng-container>
<hr *ngIf="get(leadGenRow, 'lead_gen.split_line', '')" class="m-0" />
