import { Location } from '@angular/common';

import { CategoryFacets } from 'lib/services/category/category-facets.class';
import { CategoryPagination } from 'lib/services/category/category-pagination.class';
import { CategorySorting } from 'lib/services/category/category-sorting.class';
import { contains, queryParams } from 'lib/tools';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { get, pick } from 'lodash';
import { WebstoreProduct } from 'lib/services/webstore-product/webstore-product.class';
import { RouterService } from 'lib/services/router.service';

const scriptName = 'category.class';

let environment: any;
let routerService: RouterService;
let location: Location;

export function parseCategoryUrlComponent(name: string) {
    try {
        if (!name) {
            return '';
        }
        return name
            .replace(/\//g, '')
            .replace(/(\s&|\sand)/g, '')
            .replace(/\s/g, '-')
            .replace(/--/g, '-')
            .replace(/\+/g, '');
    } catch (err) {
        console.error(...new ExxComError(789923, scriptName, err).stamp());
    }
}

export class Category {
    banners: any = { header: '', banner: '', left: '', footer: '' };
    breadcrumb: any = {};
    didYouMean: string = null;
    facets: CategoryFacets;
    isFiltered: boolean = false;
    isInitialized: boolean = false;
    sorting: CategorySorting;
    pagination: CategoryPagination;
    products: WebstoreProduct[] = [];
    searchTerm: string = null;
    subtype: string = null;
    totalProducts: number = 0;
    type: string = null;
    websitePageType: string = '';

    constructor(dependencies: any) {
        try {
            environment = dependencies.environment;
            location = dependencies.location;
            routerService = dependencies.routerService;
            this.facets = new CategoryFacets({ routerService });
            this.sorting = new CategorySorting();
            this.pagination = new CategoryPagination();
        } catch (err) {
            console.error(...new ExxComError(890929, scriptName, err).stamp());
        }
    }

    init(params: any, type: string, breadcrumb: string, res: any) {
        try {
            params = this.initParams(params);
            this.didYouMean = (res.didYouMean && res.didYouMean.query) || null;
            this.searchTerm = params.q ? params.q : null;
            this.totalProducts = get(res, 'pagination.totalResults', 0);
            this.initBanners(get(res, 'merchandising.content'));
            this.initBreadcrumb(type, breadcrumb);
            this.initProducts(get(res, 'results'));
            this.initType(type, breadcrumb);
            this.facets.init(get(res, 'facets'));
            this.pagination.init(get(res, 'pagination'), params);
            this.sorting.init(get(res, 'sorting'), params);
            this.isFiltered = this.facets.active.length > 0;
            this.isInitialized = true;
            this.websitePageType = get(res, 'websitePageType', '');
        } catch (err) {
            console.error(...new ExxComError(109491, scriptName, err).stamp());
        }
    }

    initParams(params) {
        if (params.q) {
            params.q = decodeURIComponent(params.q);
        }
        if (params.filters) {
            params.filters = decodeURIComponent(params.filters);
        }
        return params;
    }

    // SearchSpring UI merchandising banners to ExxCom key
    // Placement: Top,       Primary   -> category.banners.header
    // Placement: Top,       Secondary -> category.banners.banner
    // Placement: Left Side, Left      -> category.banners.left
    // Placement: Bottom,    Footer    -> category.banners.footer
    private initBanners(merchContent: any) {
        try {
            if (!merchContent) {
                return;
            }
            const removeScript = (content: string) => content.replace(/\n|\t/g, '').replace(/<script(.*)<\/script>/g, '');
            this.banners.header = removeScript((merchContent.header && merchContent.header[0]) || '');
            this.banners.banner = removeScript((merchContent.banner && merchContent.banner[0]) || '');
            this.banners.left = removeScript((merchContent.left && merchContent.left[0]) || '');
            this.banners.footer = removeScript((merchContent.footer && merchContent.footer[0]) || '');
        } catch (err) {
            console.error(...new ExxComError(789290, scriptName, err).stamp());
        }
    }

    private initBreadcrumb(type: string, breadcrumb: string) {
        try {
            if (!breadcrumb) {
                return (this.breadcrumb = {});
            }
            const formatBreadcrumb = (breadcrumb: string) => {
                const parts = breadcrumb.split('>');
                const breadcrumbObject = {};
                parts.forEach((part, index) => {
                    breadcrumbObject[`part${index + 1}`] = {
                        name: part,
                        href: `/${parseCategoryUrlComponent(part)}`,
                    };
                });
                return breadcrumbObject;
            };
            this.breadcrumb = type == 'category' ? formatBreadcrumb(breadcrumb) : {};
        } catch (err) {
            console.error(...new ExxComError(998822, scriptName, err).stamp());
        }
    }

    private initProducts(results: any[]) {
        try {
            if (!results) {
                return;
            }
            this.products = [];
            results.forEach((product: any) => {
                product.isSearchSpring = true;
                this.products.push(new WebstoreProduct(product, { environment: environment }));
            });
        } catch (err) {
            console.error(...new ExxComError(628833, scriptName, err).stamp());
        }
    }

    private initType(type: string, breadcrumb: string) {
        try {
            this.type = type || '';
            if (!breadcrumb || type != 'category') {
                return;
            }
            const subtype = breadcrumb.split('>')[0];
            this.subtype = subtype && subtype.toLowerCase();
        } catch (err) {
            console.error(...new ExxComError(399442, scriptName, err).stamp());
        }
    }

    // SORT

    sort(selectedIndex: any) {
        try {
            if (selectedIndex === undefined) {
                return;
            }
            this.sorting.selectedIndex = selectedIndex;
            this.sorting.setSelectedLabel();
            const selectedOption = this.sorting.options[selectedIndex];
            const url = this.getSortUrl(selectedOption);
            location.go(url);
            // routerService.router.navigateByUrl(url);
        } catch (err) {
            console.error(...new ExxComError(899292, scriptName, err).stamp());
        }
    }
    private getSortUrl(selectedOption: any) {
        try {
            const pathUrl = window.location.pathname + window.location.search;
            const params = queryParams.get(pathUrl);
            const qParam = pick(params, 'q');
            const sortParam = {};
            sortParam[`sort.${selectedOption.field}`] = selectedOption.direction;
            const filterParam = pick(params, 'filters');
            const pageParam = pick(params, 'page');
            let url = pathUrl.split('?')[0];
            url = queryParams.add(url, qParam);
            url = queryParams.add(url, sortParam);
            url = queryParams.add(url, filterParam);
            url = queryParams.add(url, pageParam);
            return url;
        } catch (err) {
            console.error(...new ExxComError(104882, scriptName, err).stamp());
        }
    }

    // FILTER

    filter(facetGroup: any, facet: any) {
        try {
            const url = this.getFilterUrl(facetGroup, facet);
            location.go(url);
        } catch (err) {
            console.error(...new ExxComError(662553, scriptName, err).stamp());
        }
    }
    private getFilterUrl(facetGroup: any, facet: any) {
        try {
            const params = queryParams.get(window.location.pathname + window.location.search);
            const qParam = pick(params, 'q');
            const sortParam = this.sorting.getParam(params);
            const filtersParam = pick(params, 'filters');
            let url = window.location.pathname;
            url = queryParams.add(url, qParam);
            url = queryParams.add(url, filtersParam);
            url = !facet.isActive ? this.addFilter(facetGroup, facet, url) : this.removeFilter(facetGroup, facet, url);
            url = url.replace(/(\?|&)filters=$/, '');
            url = queryParams.add(url, sortParam);
            return url;
        } catch (err) {
            console.error(...new ExxComError(888277, scriptName, err).stamp());
        }
    }
    private addFilter(facetGroup: any, facet: string, url: string) {
        try {
            if (!contains(url, facetGroup.field)) {
                url = this.addNewFacet(facetGroup, facet, url);
            } else {
                url = this.modifyExistingFacet(facetGroup, facet, url);
            }
            return url;
        } catch (err) {
            console.error(...new ExxComError(897723, scriptName, err).stamp());
        }
    }
    private addNewFacet(facetGroup: any, facet: any, url: string) {
        try {
            const urlParts = url.split('filters=');
            url = !contains(urlParts[0], '?') ? `${urlParts[0]}?` : urlParts[0];
            if (contains(url, 'search?q=') && !/&$/.test(url)) {
                url += '&';
            }
            if (facet.type == 'value') {
                url += `filters=${facetGroup.field}::${encodeURIComponent(facet.value)}`;
            } else if (facet.type == 'range') {
                url += `filters=${facetGroup.field}::${facet.low}-${facet.high}`;
            }
            if (urlParts[1]) {
                url += `;${urlParts[1]}`;
            }
            return url;
        } catch (err) {
            console.error(...new ExxComError(681883, scriptName, err).stamp());
        }
    }
    private modifyExistingFacet(facetGroup: any, facet: any, url: string) {
        try {
            const urlParts = url.split(`${facetGroup.field}::`);
            if (facet.type == 'value') {
                url = urlParts[0] + `${facetGroup.field}::${encodeURIComponent(facet.value)},` + urlParts[1];
            } else if (facet.type == 'range') {
                url = urlParts[0] + `${facetGroup.field}::${facet.low}-${facet.high},` + urlParts[1];
            }
            return url;
        } catch (err) {
            console.error(...new ExxComError(939922, scriptName, err).stamp());
        }
    }
    private removeFilter(facetGroup: any, facet: any, url: string) {
        try {
            if (facet.type == 'value') {
                const keyIndex = url.indexOf(`${facetGroup.field}`);
                if (keyIndex == -1) {
                    return url;
                }

                // begin the process of properly getting and modifying the filter string filter_value::filter_value:key1,key2;

                // detect the semicolon and make a substring from url/filter?=filter_key=val1,val2  to ::filter_value:key1,key2
                let firstSemi = url.indexOf(';', keyIndex + 1);
                firstSemi = firstSemi == -1 ? url.length : firstSemi;
                let facetStr = url.substring(keyIndex, firstSemi);

                if (facetStr.indexOf('?filters') !== -1) {
                    return url;
                }

                // remove ONLY the value from the specific facet
                const split = url.split(facetStr);
                if (split.length < 2) {
                    return url;
                }
                // facetStr = facetStr.replace(new RegExp(`(${facetGroup.field}::[\\s\\S]*)${encodeURIComponent(facet.value)},?`), '$1');
                if (facetStr.indexOf(encodeURIComponent(facet.value) + ',') !== -1) {
                    facetStr = facetStr.replace(encodeURIComponent(facet.value) + ',', '');
                } else {
                    facetStr = facetStr.replace(encodeURIComponent(facet.value), '');
                }
                // EDGE CASES DO NOT REMOVE / LOOK HERE FOR BUGS FIRST

                // facets with spaces which are replace with '+' signs
                const formattedFacet = facet.value.replaceAll(' ', '+');
                if (facetStr.includes(formattedFacet)) {
                    if (facetStr.indexOf(formattedFacet + ',') !== -1) {
                        facetStr = facetStr.replace(formattedFacet + ',', '');
                    } else {
                        facetStr = facetStr.replace(formattedFacet, '');
                    }
                }
                // there's no value for the key anymore and the facet should be removed
                if (facetStr.lastIndexOf(':') == facetStr.length - 1) {
                    facetStr = '';
                }
                // if the key has multiple values and the value has been removed, remove the comma which separated it
                if (facetStr.lastIndexOf(',') == facetStr.length - 1) {
                    facetStr = facetStr.substring(0, facetStr.length - 1);
                }
                // add semicolon to split[1] so the addition of facetStr works properly
                if (split[1] != '' && split[1][-1] != ';') {
                    split[1] += ';';
                }
                // trim the name of the facet split (semicolons should only be added above or line below, regex is weird)
                while (split[1][0] == ';') {
                    split[1] = split[1].substring(1, split[1].length);
                }
                // trim semicolons from filter=; after a facet has been deleted
                if (split[1] == '' && split[0][-1] == ';') {
                    split[0] = split[0].substring(0, split[0].length - 1);
                }

                // END OF EDGE CASES

                url = split[0] + split[1] + facetStr;
            } else if (facet.type == 'range') {
                if (facet.low == '*') {
                    facet.low = '\\*';
                }
                if (facet.high == '*') {
                    facet.high = '\\*';
                }
                url = url.replace(new RegExp(`${facet.low}-${facet.high},?`), '');
            }
            url = url.replace(/,$/, '');
            url = url.replace(new RegExp(`${facetGroup.field}::$`), '');
            url = url.replace(new RegExp(`${facetGroup.field}::;`), '');
            url = url.replace(new RegExp(`;$`), '');
            return url;
        } catch (err) {
            console.error(...new ExxComError(389781, scriptName, err).stamp());
        }
    }

    clearAllFilters() {
        try {
            const urlComponent = window.location.pathname;
            const params = queryParams.get(routerService.router.url);
            const url = !params.q ? urlComponent : queryParams.getUrl(urlComponent, { q: params.q });
            this.facets.clearAll();
            location.go(url);
        } catch (err) {
            console.error(...new ExxComError(892755, scriptName, err).stamp());
        }
    }
}
