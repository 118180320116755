import { BreakpointObserver } from '@angular/cdk/layout';
import { ElementRef, HostListener, ViewChild, Directive, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { decode } from 'html-entities';
import { get, debounce } from 'lodash';

import { CategoryQuickViewDialogComponent } from 'lib/components/category/category-quick-view-dialog/category-quick-view-dialog.component';
import { CategoryService } from 'lib/services/category/category.service';
import { CmsService } from 'lib/services/cms.service';
import { ExxComComponentClass } from 'lib/components/exxcom-component.class';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { isBrowser } from 'lib/tools';
import { MetaService } from 'lib/services/meta.service';
import { RouterService } from 'lib/services/router.service';
import { WebstoreProductService } from 'lib/services/webstore-product/webstore-product.service';
import { MarketoService } from 'lib/services/marketo.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BootstrapCategoryQuickViewDialogComponent } from './bootstrap-category-quick-view-dialog/bootstrap-category-quick-view-dialog.component';

const scriptName = 'category-component.class';

@Directive()
export class CategoryComponentClass extends ExxComComponentClass implements OnDestroy {
    @HostListener('document:click', ['$event']) click(event: any) {
        try {
            this.closeSortMenuOnClickAway(event);
        } catch (err) {
            console.error(...new ExxComError(394992, scriptName, err).stamp());
        }
    }

    @ViewChild('facets') facets: ElementRef;
    @ViewChild('facetsNarrowMenu') facetsNarrowMenu: ElementRef;
    @ViewChild('facetsNarrowMenuToggle') facetsNarrowMenuToggle: ElementRef;
    @ViewChild('sortMenu') sortMenu: ElementRef;

    // Dependencies

    breakpointObserver: BreakpointObserver;
    categoryService: CategoryService;
    cmsService: CmsService;
    dialog: MatDialog;
    environment: any;
    isLandingPage: Boolean;
    marketoService: MarketoService;
    metaService: MetaService;
    productService: WebstoreProductService;
    routerService: RouterService;
    activatedRoute: ActivatedRoute;
    location: Location;
    bsModalRef?: BsModalRef;
    modalService: BsModalService;
    // Properties: public

    cmsPartialCategoryTopData: any = { rows: [], rowSets: [] };
    filters: any;
    filtersOpen: boolean = false;
    filterQueryString: string;
    grayBackgroundRows: boolean[];
    isNarrow: boolean = false;
    partials: { top: any } = { top: {} };
    sortMenuOpen: boolean = false;
    numberOfColumns: number;
    facetsStatus: any = {};
    facetsMaximized: any = {};

    constructor({ dependencies }) {
        super({ dependencies });
        if (!isBrowser()) {
            return;
        }
        this.openProductQuickView = debounce(this.openProductQuickView, 500, {
            leading: true,
            trailing: false,
        });
        const pathParts = window.location.pathname.split('/');
        const category = pathParts.length > 2 ? pathParts[2] : '';
        if (!this.categoryService.paths.includes(category) && !window.location.pathname.includes('Services/')) {
            this.routerService.navigate(['/404']);
        }
    }
    ngOnDestroy(): void {
        this.cleanUpBootstrapModals();
    }

    async init() {
        try {
            await this.initCmsData();
            super.initBreakpointListener();
            if (!isBrowser()) {
                return;
            }
            window.scroll(0, 0);
        } catch (err) {
            console.error(...new ExxComError(203989, scriptName, err).stamp());
        }
    }
    /**
   * Removes residual bootstrap class and styling from body
  // occurs when navigating to a new page after closing a nested modal
   */
    cleanUpBootstrapModals() {
        try {
            if (this.modalService.getModalsCount() > 0) {
                const body = document.querySelector('body');
                document.querySelector('modal-container').remove();
                document.querySelector('bs-modal-backdrop').remove();
                body.style.overflowY = null;
                body.classList.remove('modal-open');
            }
        } catch (err) {
            console.error(...new ExxComError(802328, scriptName, err).stamp());
        }
    }

    private async initCmsData() {
        try {
            this.initCmsTopPartial(); // Without await so they all run asynchronously
            const res = await Promise.all([
                await this.cmsService.getPage('category_page', {
                    fields: ['url', 'seo_meta_data', 'default_number_of_columns', 'islandingpage'],
                    searchParams: this.routerService.url.params,
                }),
                await this.categoryService.getCategory({
                    fields: ['-_id', 'urlComponent', 'pageTitle', 'metaDescription', 'metaKeywords', 'websitePageType'],
                }),
            ]);

            const cmsRes = res[0];
            const excRes = res[1];
            this.isLandingPage = cmsRes.islandingpage;
            this.initCmsMetaData(get(cmsRes, 'seo_meta_data'), excRes);
            let cachedNumberOfColumns;
            if (!isBrowser()) {
                return {};
            }
            if (sessionStorage) {
                if (sessionStorage.getItem('columnCacheUrl') == this.routerService.url.component) {
                    cachedNumberOfColumns = parseInt(sessionStorage.getItem('columns'));
                }
            }
            this.numberOfColumns = cachedNumberOfColumns || get(cmsRes, 'default_number_of_columns', 4);
            if (!this.numberOfColumns) {
                this.numberOfColumns = 4;
            }
            await this.marketoService.init();
            this.marketoService.initLoaded();
        } catch (err) {
            console.error(...new ExxComError(620588, scriptName, err).stamp());
        }
    }

    private async initCmsMetaData(cms: any, exc: any) {
        try {
            if (!cms) {
                cms = {};
            }
            if (!exc) {
                exc = {};
            }
            let title: string;
            if (cms.title) {
                title = cms.title;
            } else if (get(this.routerService, 'url.params.page', '')) {
                if (this.environment.siteAbbr == 'spc' || this.environment.siteAbbr == 'exx') {
                    title = `${this.environment.siteName} - Page ${get(this.routerService, 'url.params.page', '')}`;
                } else {
                    title = `${exc.pageTitle} - Page ${get(this.routerService, 'url.params.page', '')}`;
                }
            } else {
                title = exc.pageTitle;
            }
            // if the title is undefined, we're most likely on a search as we dont have meta titles set for that, so we set the title accordingly.
            if (cms.title == undefined) {
                this.metaService.add({
                    title: `Search - Page ${get(this.routerService, 'url.params.page', '')}`,
                    description: cms.description || exc.metaDescription || '', // in cms, description is called Meta Description
                    keywords: cms.keywords || exc.metaKeywords || '',
                    og_description: cms.og_description || '',
                    og_image: cms.og_image || '',
                    og_title: cms.og_title || '',
                    og_url: cms.og_url || '',
                    collection: cms.collection || '',
                });
            } else {
                this.metaService.add({
                    title: title,
                    description: cms.description || exc.metaDescription || '', // in cms, description is called Meta Description
                    keywords: cms.keywords || exc.metaKeywords || '',
                    og_description: cms.og_description || '',
                    og_image: cms.og_image || '',
                    og_title: cms.og_title || '',
                    og_url: cms.og_url || '',
                    collection: cms.collection || '',
                });
            }
        } catch (err) {
            console.error(...new ExxComError(103982, scriptName, err).stamp());
        }
    }

    private async initCmsTopPartial() {
        try {
            const references = [
                'category_top_partial_rows.category_top_banner.category_top_banner',
                'category_top_partial_rows.feature_solutions.feature_solutions',
                'category_top_partial_rows.lead_gen.lead_gen',
                'category_top_partial_rows.logos.logos',
                'category_top_partial_rows.subcategory_links.subcategory_links',
                'category_top_partial_row_sets.products.category_top_facets',
            ];
            if (this.environment.siteAbbr == 'spc') {
                references.push(
                    'category_top_partial_rows.cards.cards',
                    'category_top_partial_rows.colored_block.colored_block',
                    'category_top_partial_rows.image_and_text.image_and_text',
                    'category_top_partial_rows.software_stack.software_stack',
                    'category_top_partial_rows.tables.tables',
                    'category_top_partial_rows.multi_columns.multi_columns'
                );
            } else if (this.environment.siteAbbr == 'exx') {
                references.push(
                    'category_top_partial_rows.colored_block.colored_block',
                    'category_top_partial_rows.image_and_text.image_and_text',
                    'category_top_partial_rows.image_and_text.text_block',
                    'category_top_partial_rows.internal_links.internal_link',
                    'category_top_partial_rows.multi_columns.multi_columns',
                    'category_top_partial_rows.multi_column_v2.multi_column_v2',
                    'category_top_partial_rows.related_content.related_content.case_studies.gated_content_case_studies',
                    'category_top_partial_rows.related_content.related_content.ebooks.gated_content_ebooks',
                    'category_top_partial_rows.related_content.related_content.ebooks.gated_content_ebooks.type',
                    'category_top_partial_rows.related_content.related_content.case_studies.gated_content_case_studies.type',
                    'category_top_partial_rows.related_content.related_content.whitepapers.gated_content_whitepapers',
                    'category_top_partial_rows.related_content.related_content.whitepapers.gated_content_whitepapers.type',
                    'category_top_partial_rows.value_props.value_prop_set.value_prop',
                    'category_top_partial_rows.product_options.product',
                    'category_top_partial_rows.product_options.see_all_options',
                    'category_top_partial_rows.tables.tables',
                    'category_top_partial_rows.tables.tables.new_table_format_2.table_group.tables.table_reference',
                    'category_top_partial_rows.related_content.related_content.blog.blog_category',
                    'category_top_partial_rows.full_width_block.full_width_block',
                    'category_top_partial_rows.image_and_text.full_width_image_and_text',
                    'category_top_partial_rows.step_by_step.step_by_step',
                    'category_top_partial_rows.accordion.accordion',
                    'category_top_partial_rows.product_options_2.product_options', // the first version is not defined in its own content model, this version is
                    'category_top_partial_rows.product_options_2.product_options.feature_product',
                    'category_top_partial_rows.product_options_2.product_options.tables',
                    'category_top_partial_rows.product_options_2.product_options.tables.new_table_format_2.table_group.tables.table_reference'
                );
            }
            const cmsPartialCategoryTopEntry = (
                await this.cmsService.initCache({
                    contentTypeId: 'category_page',
                    references,
                    searchParams: this.routerService.url.params,
                })
            )[0];
            this.cmsPartialCategoryTopData = {
                title: get(cmsPartialCategoryTopEntry, 'title', ''),
                url: get(cmsPartialCategoryTopEntry, 'url', ''),
                rows: get(cmsPartialCategoryTopEntry, 'category_top_partial_rows', []),
                rowSets: get(cmsPartialCategoryTopEntry, 'category_top_partial_row_sets', []),
            };
            const rows = get(cmsPartialCategoryTopEntry, 'category_top_partial_rows', []);
            this.grayBackgroundRows = new Array(rows.length).fill(false);
            rows.forEach((row: any, i: number) => {
                if (
                    get(row, 'cards.gray_background', false) ||
                    get(row, 'image_and_text.gray_background', false) ||
                    get(row, 'paragraph.gray_background', false) ||
                    get(row, 'lead_gen.gray_background', false)
                ) {
                    this.grayBackgroundRows[i] = true;
                }
            });
        } catch (err) {
            console.error(...new ExxComError(204898, scriptName, err).stamp());
        }
    }

    get currentCategoryBreadcrumbPart() {
        const parts = this.category.breadcrumb;
        const keys = Object.keys(parts);
        const size = keys.length;
        return this.category && parts && size > 0 ? get(parts[keys[size - 1]], 'name') : '';
    }
    get category() {
        return this.categoryService.category;
    }
    get subtype() {
        return get(this.category, 'subtype');
    }

    private closeSortMenuOnClickAway(event: MouseEvent) {
        try {
            if (!this.sortMenu) {
                return;
            }
            const sortMenu = this.sortMenu.nativeElement;
            if (event.target != sortMenu && !sortMenu.contains(event.target)) {
                setTimeout(() => (this.sortMenuOpen = false), 50);
            }
        } catch (err) {
            console.error(...new ExxComError(394488, scriptName, err).stamp());
        }
    }

    toggleNarrowFilterMenu() {
        try {
            if (!isBrowser()) {
                return;
            }
            if (!this.category.facets.menuOpen) {
                document.body.style.overflow = 'hidden';
                document.body.style.touchAction = 'none';
                // quick hack to get element selected for IOS and remove momentum scroll
                const el = document.getElementById('category-facets');
                if (el) {
                    el.click();
                }
            } else {
                document.body.style.overflow = 'inherit';
                document.body.style.touchAction = 'inherit';
            }
            this.category.facets.menuOpen = !this.category.facets.menuOpen;
        } catch (err) {
            console.error(...new ExxComError(839842, scriptName, err).stamp());
        }
    }

    filter(group: any, facet: any) {
        try {
            if (!isBrowser()) {
                return;
            }
            const groupElem = document.getElementById(`category-facet-group-container-${group.index}`);
            if (groupElem) {
                group.scrollTop = groupElem.scrollTop;
            }
            this.category.filter(group, facet);
            this.categoryService.loadPage();
        } catch (err) {
            console.error(...new ExxComError(592884, scriptName, err).stamp());
        }
    }

    toggleFacetGroupCollapsed(groupIndex: number) {
        try {
            if (!isBrowser()) {
                return;
            }
            const facetsStatus = this.facetsStatus;
            if (facetsStatus[groupIndex] === false || !facetsStatus[groupIndex]) {
                facetsStatus[groupIndex] = true;
            } else {
                facetsStatus[groupIndex] = false;
            }
            this.facetsStatus = facetsStatus;
        } catch (err) {
            console.error(...new ExxComError(828784, scriptName, err).stamp());
        }
    }

    toggleFacetGroupMaximized(groupIndex: number) {
        try {
            if (!isBrowser()) {
                return;
            }
            const facetsMaximized = this.facetsMaximized;
            if (facetsMaximized[groupIndex] === false || !facetsMaximized[groupIndex]) {
                facetsMaximized[groupIndex] = true;
            } else {
                facetsMaximized[groupIndex] = false;
            }
            this.facetsMaximized = facetsMaximized;
        } catch (err) {
            console.error(...new ExxComError(839842, scriptName, err).stamp());
        }
    }

    getFacetCollapsed(groupIndex: number) {
        return this.facetsStatus[groupIndex] === true ? true : false;
    }

    getFacetMaximized(groupIndex: number) {
        return this.facetsMaximized[groupIndex] === true ? true : false;
    }

    onResize() {
        if (!this.isNarrow) {
            this.category.facets.menuOpen = false;
        }
    }

    toggleActive(columns: number) {
        if (!isBrowser()) {
            return {};
        }
        sessionStorage.setItem('columnCacheUrl', get(this.routerService, 'url.component', ''));
        sessionStorage.setItem('columns', columns.toString());
        this.numberOfColumns = columns;
    }

    getHighlight(input: any) {
        try {
            if (!isBrowser()) {
                return;
            }
            return decode(input).replace('<ul>', `<ul style ='font-size:.875em'>`);
        } catch (err) {
            console.error(...new ExxComError(901235, scriptName, err).stamp());
        }
    }

    preventMobileDefaultScroll($event) {
        if (this.isNarrow && $event && $event.preventDefault && $event.stopPropagation) {
            // $event.stopPropagation();
            if ($event.cancelable) {
                $event.preventDefault();
            }
        }
    }

    async openQuickView(urlComponent: string) {
        try {
            const product = await this.productService.getProductByUrlComponent(urlComponent);
            if (!product) {
                return;
            }
            this.dialog.open(CategoryQuickViewDialogComponent, {
                height: 'auto',
                maxHeight: '750px',
                data: {
                    action: 'display',
                    type: 'quickView',
                    product: product,
                },
            });
        } catch (err) {
            console.error(...new ExxComError(901900, scriptName, err).stamp());
        }
    }

    // Multi facet options
    getContainerClassFromColumns: Function = (numColumns = 1, index = 0, numProducts = 0) => {
        const exxDict = {
            4: `category-product category-grid-item pb-2`,
            3: `category-product col-lg-4 col-xl-4 pb-2`,
            1: `category-product col-lg-12 col-xl-12 pb-2 category-product-column-1`,
        };
        const spcDict = {
            4: `category-product col-lg-3 col-xl-3`,
            3: `category-product col-lg-4 col-xl-4`,
            1: `category-product col-lg-12 col-xl-12 category-product-column-1 fx-row`,
        };
        const classDict = this.environment.siteAbbr == 'spc' ? spcDict : exxDict;
        const borderVals = {
            4: 'remove-last-four',
            3: 'remove-last-three',
            2: 'remove-last-two',
            1: 'remove-last-one',
        };
        let tempClass = '';
        if (numProducts % numColumns) {
            tempClass = borderVals[numProducts % numColumns];
        } else {
            tempClass = borderVals[numColumns];
        }
        return get(classDict, this.numberOfColumns, 'category-product') + ' ' + tempClass;
    };

    getBorderClassFromColumns: Function = (numColumns = 1, index = 0) => {
        let specialCase = '';
        // right side
        if (index % numColumns == numColumns - 1) {
            specialCase = 'mr-0 pr-0 ml-2';
        } else if (index % numColumns == 0) {
            specialCase = 'ml-0 pl-0 mr-2';
        } else {
            specialCase = 'ml-1 mr-1';
        }
        const borderClass = {
            4: ``,
            3: `border-cards ${specialCase}`,
            1: 'border-cards list-container fx-row ml-0 pt-2 pb-2 mt-0 mb-0',
        };
        return get(borderClass, numColumns, 'border-cards');
    };

    clearAllFilters() {
        this.category.clearAllFilters();
        this.categoryService.loadPage();
    }

    sort(i: any) {
        this.category.sort(i);
        this.categoryService.loadPage();
    }

    hideIfBackdropClick(event: any) {
        try {
            if (event.target.classList.contains('modal') || event.target.getAttribute('id') === 'product-view-full-details') {
                this.bsModalRef.hide();
            }
        } catch (err) {
            console.error(...new ExxComError(922211, scriptName, err).stamp());
        }
    }

    //  Ngx-Bootstrap modal
    async openProductQuickView(urlComponent: string) {
        try {
            const product = await this.productService.getProductByUrlComponent(urlComponent);
            const initialState = {
                data: {
                    action: 'display',
                    type: 'quickView',
                    product: product,
                },
            };

            this.bsModalRef = this.modalService.show(BootstrapCategoryQuickViewDialogComponent, {
                animated: true,
                class: 'modal-lg modal-quick-view modal-dialog-centered',
                initialState,
            });
            this.bsModalRef.content.closeBtnName = 'Close';
        } catch (err) {
            console.error(...new ExxComError(923101, scriptName, err).stamp());
        }
    }
}
