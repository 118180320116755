<div id="popup-dialog-overflow-wrapper">
  <div id="popup-dialog" class="dialog popup-dialog-container">
    <div class="dialog-top-row">
      <i-feather
        name="x"
        (click)="modalRef.hide()"
        [ngStyle]="{ color: site == 'exx' ? '#687aa0' : '#4c5f7f' }"
      ></i-feather>
    </div>

    <ng-template [ngTemplateOutlet]="inputTemplate"></ng-template>
  </div>
</div>
