import { Inject, Injectable } from '@angular/core';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MarketoDialogComponent } from 'lib/components/marketo-dialog/marketo-dialog.component';

const scriptName = 'bootstrap-marketo.service';

type productInquiryParams = {
    title?: string;
    url?: string;
    partNumber?: string;
    productInfo?: {
        mpn?: string;
        solutionTitle?: string;
    };
};

@Injectable()
export class BootstrapMarketoService {
    constructor(
        @Inject('environment') private environment: any,
        public bsModalService: BsModalService,
        public bsModalRef: BsModalRef
    ) {}

    openBootstrapMarketoDialog(formId?: string, action?: string, { title, url, partNumber, productInfo }: productInquiryParams = {}, product?: any) {
        try {
            let interest;
            if (partNumber) {
                interest = `${title} - ${partNumber} | ${this.environment.urls.base}${url}`;
            } else if (product) {
                interest = `${product.description} | ${this.environment.urls.base}/${product.urlComponent}`;
            } else if (productInfo?.mpn && productInfo?.solutionTitle) {
                interest = `${productInfo?.mpn} | ${productInfo?.solutionTitle} | ${this.environment.urls.base}${url}`;
            } else {
                interest = `${title} | ${this.environment.urls.base}${url}`;
            }
            const initialState = {
                data: {
                    marketoFormId: `mktoForm_${formId}`,
                    action,
                    interest: interest,
                },
            };
            this.bsModalRef = this.bsModalService.show(
                MarketoDialogComponent,

                {
                    animated: true,
                    class: 'modal-marketo-form modal-dialog-centered',
                    initialState,
                }
            );

            this.bsModalRef.content.closeBtnName = 'Close';
        } catch (err) {
            console.error(...new ExxComError(969696, scriptName, err).stamp());
        }
    }
}
