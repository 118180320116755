import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AccountService } from 'lib/services/account/account.service';
import { CartService } from 'lib/services/cart/cart.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { GtmService } from 'lib/services/google/gtm.service';
import { MetaService } from 'lib/services/meta.service';
import { WebstoreProduct } from 'lib/services/webstore-product/webstore-product.class';
import { ProductListingComponentClass } from 'lib/components/product/product-listing-component.class';
import { SessionService } from 'lib/services/session.service';
import { WebstoreProductService } from 'lib/services/webstore-product/webstore-product.service';
import { isBrowser } from 'lib/tools';
import { WebpService } from 'lib/services/webp.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RouterService } from 'lib/services/router.service';

const scriptName = 'product-listing.component';

@Component({
    selector: 'app-product-listing',
    templateUrl: './product-listing.component.html',
    styleUrls: ['./product-listing.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ProductListingComponent extends ProductListingComponentClass implements OnInit {
    @Input() product: WebstoreProduct;
    bsModalRef?: BsModalRef;
    selectedQuantity: number = 1;
    defaultSelectValue: number = 1;
    initialSlide: number = 0;
    getWebpImg: (src: string) => string;
    modalStatus: string;
    amount: number = 0;

    translateAmount: number = 0;

    currentImageIndex: number = 0;

    constructor(
        @Inject('environment') environment: any,
        accountService: AccountService,
        breakpointObserver: BreakpointObserver,
        bsModalRef: BsModalRef,
        bsModalService: BsModalService,
        cartService: CartService,
        dialog: MatDialog,
        gtmService: GtmService,
        metaService: MetaService,
        productService: WebstoreProductService,
        private routerService: RouterService,
        sessionService: SessionService,
        webpService: WebpService
    ) {
        super({
            dependencies: {
                accountService,
                bsModalService,
                bsModalRef,
                breakpointObserver,
                cartService,
                dialog,
                environment,
                gtmService,
                productService,
                sessionService,
                metaService,
            },
        });
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
    }
    ngOnInit() {
        try {
            this.init();
        } catch (err) {
            console.error(...new ExxComError(546432, scriptName, err).stamp());
        }
    }

    updateQuantity(quantity: number) {
        try {
            this.selectedQuantity = quantity;
        } catch (err) {
            console.error(...new ExxComError(546430, scriptName, err).stamp());
        }
    }

    updateSlideIndex(index: number) {
        try {
            if (!isBrowser()) {
                return;
            }
            this.initialSlide = index;
        } catch (err) {
            console.error(...new ExxComError(854932, scriptName, err).stamp());
        }
    }

    closeDialog() {
        this.dialog.closeAll();
    }

    setImageIndex(value: number) {
        this.currentImageIndex = value;
    }
}
