import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { ExxComComponentClass } from 'lib/components/exxcom-component.class';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

const scriptName = 'bootstrap-category-quick-view-dialog.component';

@Component({
    selector: 'lib-bootstrap-category-quick-view-dialog',
    templateUrl: './bootstrap-category-quick-view-dialog.component.html',
    styleUrls: ['./bootstrap-category-quick-view-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class BootstrapCategoryQuickViewDialogComponent extends ExxComComponentClass implements OnInit {
    data: any;
    subscription: Subscription;
    productType: string;

    constructor(
        public bsModalRef: BsModalRef,
        @Inject('environment') environment: any
    ) {
        super({ dependencies: { environment, bsModalRef } });
    }
    ngOnInit(): void {
        if (this.environment?.siteAbbr == 'spc' && this.data) {
            this.productType =
                this.data?.product?.manufacturer == 'SabrePC' || this.data?.product?.topCategory == 'Supermicro' ? 'Solution' : 'Component';
        }
    }

    closeModal() {
        try {
            this.bsModalRef.hide();
        } catch (err) {
            console.error(...new ExxComError(622224, scriptName, err).stamp());
        }
    }
}
