<div id="product-spec-dialog-close" [mat-dialog-close]="true">
  <mat-icon>close</mat-icon>
</div>
<div id="product-spec-dialog" class="dialog">
  <div [class]="data?.specs ? 'fx-row fx-row-h-space-between' : 'fx-row fx-row-h-right'">
  
    <div *ngIf="data?.specs" id="product-spec-dialog-content" class="fx-row fx-row-v-center">
      <h3 id="product-spec-dialog-title">Specifications Comparison</h3>
    </div>
  </div>

  <div
    class="fx-row fx-row-v-center options-tab mx-auto"
    [class.invisible]="data?.options && data.options.length <= 1"
  >
    <button
      *ngFor="let data of data?.options; index as k"
      class="option-tab mb-3"
      (click)="this.selectedTable = k"
      [class.active]="selectedTable == k"
    >
      <span>{{ data?.tab_name }}</span>
    </button>
  </div>

  <div class="product-spec-dialog-table fx-row">
    <div *ngIf="data?.specs" class="fx-col">
      <div *ngFor="let spec of get(data, 'specs', [])">
        <div class="cell-left fx-row fx-row-v-center">
          <p class="ml-2 mr-2">{{ spec }}</p>
        </div>
      </div>
    </div>

    <div *ngIf="data?.specs" class="fx-row">
      <div *ngFor="let values of get(data, 'values', []); index as i">
        <div *ngFor="let value of values; index as j" class="cell-right fx-row fx-row-v-center">
          <p *ngIf="j != 1" class="ml-2 mr-2 truncate-ellipsis">{{ value }}</p>
          <a *ngIf="j == 1" class="link-blue ml-2 mr-2 truncate-ellipsis" [href]="(data?.links)[i]" target="_blank">{{
            value
          }}</a>
        </div>
      </div>
    </div>

    <div *ngIf="data?.options" class="options-table">
      <div [innerHTML]="(data?.options)[selectedTable]?.table_content | safe: 'html'"></div>
    </div>
  </div>
</div>
