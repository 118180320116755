import { Injectable } from '@angular/core';

import { ApiService } from 'lib/services/api.service';
import { AuthService } from 'lib/services/auth.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { ExxComResponse } from 'lib/interfaces/exxcom-response.interface';
import { get } from 'lodash';
import { getEmailFromBrowser, wait } from 'lib/tools';
import { User } from 'lib/services/user/user.class';
import { UserData, UserResponse } from 'lib/services/user/user.interface';
import { SessionService } from 'lib/services/session.service';

const scriptName = 'user.service';

let apiService: ApiService;
let authService: AuthService;
let sessionService: SessionService;

@Injectable()
export class UserService {
    user: User = null;
    users: User[] = [];

    constructor(ap: ApiService, au: AuthService, s: SessionService) {
        try {
            apiService = ap;
            authService = au;
            sessionService = s;
        } catch (err) {
            console.error(...new ExxComError(521018, scriptName, err).stamp());
        }
    }

    // Initializers

    /**
     * @function init
     * @description Initializes the login and logout listeners, and initializes
     * logged in if the session is valid.
     */
    async init(): Promise<void> {
        try {
            if (await sessionService.isValidSession()) {
                await this.initLoggedIn();
                await this.initCollection();
            }
            authService.login$.subscribe(() => this.onLogin());
            authService.logout$.subscribe(() => this.onLogout());
        } catch (err) {
            console.error(...new ExxComError(429871, scriptName, err).stamp());
        }
    }

    /**
     * @function initCollection
     * @description Retrieves users when the app loads and when users are
     * changed.
     *
     * Called in
     * - user.service
     * - user.component
     */
    async initCollection(): Promise<void> {
        try {
            this.users = (await this.getDetached()) as User[];
        } catch (err) {
            console.error(...new ExxComError(240287, scriptName, err).stamp());
        }
    }

    /**
     * @function initLoggedIn
     * @description Retrieves the email from the browser and the document from the
     * database, and then initializes the values on the User instance.
     */
    async initLoggedIn(): Promise<void> {
        try {
            this.user = new User();
            const email: string = getEmailFromBrowser();
            const res: UserResponse = await this.get({ email });
            if (!res.success) {
                throw res;
            }
            this.user.init(res.data[0]);
        } catch (err) {
            console.error(...new ExxComError(510731, scriptName, err).stamp());
        }
    }

    /**
     * @function initLoggedOut
     * @description Deletes the user instance on logout.
     */
    initLoggedOut(): void {
        try {
            this.user = null;
        } catch (err) {
            console.error(...new ExxComError(130261, scriptName, err).stamp());
        }
    }

    /**
     * @function onLogin
     * @description
     */
    async onLogin(): Promise<void> {
        try {
            await wait('');
            await this.initLoggedIn();
        } catch (err) {
            console.error(...new ExxComError(413071, scriptName, err).stamp());
        }
    }

    /**
     * @function onLogout
     * @description
     */
    onLogout(): void {
        try {
            this.initLoggedOut();
        } catch (err) {
            console.error(...new ExxComError(510376, scriptName, err).stamp());
        }
    }

    // Core API

    /**
     * @function get
     * @description Enables retrieving one or more user document(s) by _id or
     * email. If it is necessary to retrieve a user document for a user other than
     * the one currently logged in, use getDetached instead. create() also
     * returns a detached instance.
     */
    private async get({
        _id,
        email,
        query,
        limit,
    }: {
        _id?: string;
        email?: string;
        query?: any;
        limit?: number;
    } = {}): Promise<UserResponse> {
        try {
            let filters: any = {};
            if (_id) {
                filters._id = _id;
            } else if (email) {
                filters.email = email;
            }
            if (query) {
                filters = Object.assign(filters, query);
            }
            const url = ['users/find', `?filters=${JSON.stringify(filters)}`];
            if (limit) {
                url.push(`&limit=${limit || 1}`);
            }
            const res: UserResponse = await apiService.get(url);
            if (!res.success) {
                throw res;
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(520347, scriptName, err).stamp());
            return { success: false, error: err } as ExxComResponse;
        }
    }

    /**
     * @function update
     * @description Enables updating the current user, or an existing user if a
     * User instance is passed in.
     */
    async update({ values, user, action }: { values: any; user?: User; action?: string }): Promise<UserResponse> {
        try {
            const url = [`users/update/${user._id || this.user._id}`];
            if (action) {
                url.push(`/${action}`);
            }
            const res: UserResponse = await apiService.post(url, values);
            if (!res.success) {
                throw res;
            }
            const data = res.data as UserData;
            user ? user.init(data) : this.user.init(data);
            return res;
        } catch (err) {
            console.error(...new ExxComError(620377, scriptName, err).stamp());
            return { success: false, error: err } as ExxComResponse;
        }
    }

    /**
     * @function create
     * @description Enables creating new users. The newly created user is returned
     * and can be passed to update() to update the user's document.
     */
    async create(values: any): Promise<UserResponse> {
        try {
            const res: UserResponse = await apiService.post('users/create', values);
            if (!res.success) {
                throw res;
            }
            return res;
        } catch (err) {
            if (get(err, 'error.message') != 'Already registered') {
                console.error(...new ExxComError(219477, scriptName, err).stamp());
            }
            return err as ExxComResponse;
        }
    }

    /**
     * @function delete
     * @description Deletes a user from the database.
     */
    async delete(_id: string): Promise<ExxComResponse> {
        try {
            const res: ExxComResponse = await apiService.delete(`users/delete/:${_id}`);
            if (!res.success) {
                throw res;
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(219477, scriptName, err).stamp());
            return { success: false, error: err } as ExxComResponse;
        }
    }

    // Get API

    /**
     * @function getDetached
     * @description For working with other users' documents, as in a manager
     * assigning a role to a user. After the detached instance is created, it can
     * be passed to update() in order to update it.
     */
    async getDetached({
        _id,
        email,
        query,
        limit,
    }: {
        _id?: string;
        email?: string;
        query?: any;
        limit?: number;
    } = {}): Promise<User | User[]> {
        try {
            const res: UserResponse = await this.get({
                _id,
                email,
                query,
                limit,
            });
            if (res.error) {
                throw res;
            }
            const users = ((res.data as UserData[]) || []).map((data: UserData) => new User(data));
            return users.length == 1 ? users[0] : users;
        } catch (err) {
            console.error(...new ExxComError(502983, scriptName, err).stamp());
            return null;
        }
    }
}
