import { Directive, OnDestroy } from '@angular/core';
import { CmsService } from 'lib/services/cms.service';
import { ExxComComponentClass } from 'lib/components/exxcom-component.class';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { GtmService } from 'lib/services/google/gtm.service';
import { isBrowser } from 'lib/tools';
import { MatDialog } from '@angular/material/dialog';
import { MetaService } from 'lib/services/meta.service';
import { parseCategoryUrlComponent } from 'lib/services/category/category.class';
import { RouterService } from 'lib/services/router.service';
import { SwiperSlide } from 'lib/classes/swiper-slide.class';
import { WebstoreProductService } from 'lib/services/webstore-product/webstore-product.service';
import { MarketoService } from 'lib/services/marketo.service';
import { ActivatedRoute } from '@angular/router';

const scriptName = 'product-component.class';

// TODO: Add Angular decorator.
@Directive()
export class ProductComponentClass extends ExxComComponentClass implements OnDestroy {
    // Dependencies

    cmsService: CmsService;
    dialog: MatDialog;
    environment: any;
    gtmService: GtmService;
    metaService: MetaService;
    productService: WebstoreProductService;
    routerService: RouterService;
    marketoService: MarketoService;
    activatedRoute: ActivatedRoute;

    // Properties: public

    breadcrumb: { name: string; urlComponent: string }[] = [];
    isVisible: boolean = false;
    product: any = {};
    premium_content: any = [];
    recommendationSlides: SwiperSlide[] = [];
    recentlyViewedSlides: SwiperSlide[] = [];
    productType: string;

    constructor({ dependencies }) {
        super({ dependencies });
    }

    async init() {
        try {
            this.product = this.activatedRoute.snapshot.data['productData'];
            this.productType = this.product?.manufacturer == 'SabrePC' || this.product?.topCategory == 'Supermicro' ? 'Solution' : 'Component';
            if (this.product && this.product.cmsGroupId && this.environment.siteAbbr == 'exx') {
                const references = [
                    'product_page_partial_rows.image_and_text.image_and_text',
                    'product_page_partial_rows.image_and_text.text_block',
                    'product_page_partial_rows.image_and_text.full_width_image_and_text',
                    'product_page_partial_rows.multi_columns.multi_columns',
                    'product_page_partial_rows.tables.tables',
                    'product_page_partial_rows.colored_block.colored_block',
                    'product_page_partial_rows.product_options.product',
                    'product_page_partial_rows.product_options.see_all_options',
                    'product_page_partial_rows.accordion.accordion',
                    'product_page_partial_rows.product_options_2.product_options', // the first version is not defined in its own content model, this version is
                    'product_page_partial_rows.product_options_2.product_options.feature_product',
                    'product_page_partial_rows.product_options_2.product_options.tables',
                    'product_page_partial_rows.product_options_2.product_options.tables.new_table_format_2.table_group.tables.table_reference',
                ];
                const groupIDs = this.product.cmsGroupId.replace(/\s/g, '').split(';'); // remove spaces from cmsGroupId and parse into an array by delimiter
                this.premium_content = await this.cmsService.getEntries('c_product_page_overview', {
                    references: references,
                    query: { group_id: { $in: groupIDs } },
                });
                this.premium_content.sort(function (a, b) {
                    // make sure entries are same order as PIM, contentstack sends them in different order
                    return groupIDs.indexOf(a.group_id) - groupIDs.indexOf(b.group_id);
                });
            }
            if (!this.product) {
                if (this.product === null) {
                    this.routerService.router.navigateByUrl('/not-found');
                }
                return; // There was probably an error, and the case is not necessarily that the product was not found
            }
            this.metaService.add({
                title: `${this.product.description} | ${this.environment.siteName}`,
                description: `${this.product.metaDescription}`,
                keywords: `${this.product.subcategory}, ${this.product.category}`,
                og_description: `${this.product.metaDescription}`,
                og_image: `${this.product.imageUrl}`, // Leave empty to use the website logo
                og_title: `${this.product.description} | ${this.environment.siteName}`,
                og_url: `${this.environment.urls.base}${this.product.urlComponent}`,
            });

            this.gtmService.trackProductView(this.product);
            this.parseBreadcrumb();
            this.isVisible = true;
            this.initRecommendationSwiper(this.product.nsid);
            this.initRecentlyViewedSwiper();
            this.metaService.addProductMetadata(this.product);
            if (!isBrowser()) {
                return;
            }
            await this.marketoService.init();
            await this.marketoService.initLoaded();
        } catch (err) {
            console.error(...new ExxComError(830959, scriptName, err).stamp());
        }
    }

    private parseBreadcrumb() {
        try {
            const breadcrumbParts = this.product.breadcrumb ? this.product.breadcrumb.split('>') : [];
            this.breadcrumb = breadcrumbParts.map((part: string) => {
                return {
                    name: part,
                    urlComponent: parseCategoryUrlComponent(part),
                };
            });
        } catch (err) {
            console.error(...new ExxComError(730021, scriptName, err).stamp());
        }
    }

    private async initRecentlyViewedSwiper() {
        try {
            this.recentlyViewedSlides = await this.productService.getRecentlyViewed();
        } catch (err) {
            console.error(...new ExxComError(204001, scriptName, err).stamp());
        }
    }

    private async initRecommendationSwiper(nsid: string | number) {
        try {
            this.recommendationSlides = await this.productService.getRecommendations(nsid);
        } catch (err) {
            console.error(...new ExxComError(835532, scriptName, err).stamp());
        }
    }

    toggleMenu(index: number) {
        try {
            if (!isBrowser()) {
                return;
            }
            const footerMenuLevel1 = document.getElementsByClassName('menu-level1')[index];
            footerMenuLevel1.classList.toggle('open');
        } catch (err) {
            console.error(...new ExxComError(692882, scriptName, err).stamp());
        }
    }

    displayLeft(displayType: string) {
        try {
            return displayType == 'Image on the left';
        } catch (err) {
            console.error(...new ExxComError(919933, scriptName, err).stamp());
        }
    }

    ngOnDestroy() {
        this.metaService.removeProductMetadata();
    }
}
