<div id="header-account">
  <div id="header-account-wide-container">
    <div
      id="header-account-icon"
      #icon
      class="fx-row fx-row-h-center fx-row-v-center"
      [class.active]="isActive"
      (click)="toggleMenu()"
    >
      <!-- See docs/clients/click-null.txt for an explanation of the reason for (click)="null" -->
      <mat-icon svgIcon="user" (click)="(null)"></mat-icon>
    </div>

    <div id="header-account-menu" #menu [class]="isActive ? 'active mat-elevation-z2' : ''">
      <div *ngIf="isValidLocalSessionData && !isGuestSession" class="fx-col fx-col-h-left" (click)="closeMenu()">
        <a routerLink="/account">Overview</a>
        <a routerLink="/account/orders">Orders</a>
        <a routerLink="/account/wishlist">Wishlist</a>
        <a routerLink="/account/profile">Profile</a>
        <a routerLink="/" (click)="logout()">Log Out</a>
      </div>

      <div *ngIf="!isValidLocalSessionData || isGuestSession" class="fx-col fx-col-h-left">
        <a routerLink="/account/login" (click)="closeMenu()">Log In</a>
      </div>
    </div>
  </div>

  <div id="header-account-narrow-container" class="fx-row fx-row-v-center">
    <a routerLink="/account" class="fx-row fx-row-v-center fx-row-h-space-between">
      <span>Account</span>
      <mat-icon svgIcon="user" #icon id="header-account-icon"></mat-icon>
    </a>
  </div>
</div>
