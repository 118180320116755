import { ExxComError } from 'lib/classes/exxcom-error.class';
import { WebstoreProduct } from 'lib/services/webstore-product/webstore-product.class';

const scriptName = 'webstore-product-pricing.class';

const actionToButtonTextMap = {
    addToCart: 'Add to Cart',
    inquire: 'Send Inquiry',
    seeInCart: 'See Price in Cart',
};

interface Availability {
    longText: string;
    shortText: string;
    color: string;
}

export class WebstoreProductPricing {
    action: string;
    availability: Availability;
    buttonText: string;
    condition: string;
    freeShipping: boolean = false;
    hasPrice: boolean;
    isInStock: boolean;
    isNcnr: boolean;
    isPreOrder: boolean;
    isSpecialOrder: boolean;
    leadTime: string;
    leadTimeMessage: string;
    preOrderDate: Date = null;
    schemaOrgAvailabilityUrl: any = '';
    showAvailability: boolean;
    shortAvailability: string;
    showCondition: boolean;
    showPrice: boolean;
    showPriceInCart: boolean;

    constructor(product: WebstoreProduct) {
        try {
            // The order in which these are defined is important for some subsequent definitions
            // The commented lines are for testing
            this.condition = product.condition;
            this.showCondition = this.condition != 'New';
            this.hasPrice = product.price > 0; // Price should be on the Webstore Product Pricing instance
            this.isInStock = product.inventory > 0;
            this.isPreOrder = new Date(product.preOrderDate || 0) > new Date();
            this.preOrderDate = product.preOrderDate;
            this.isSpecialOrder = product.specialOrder;
            this.leadTime = product.leadTime;
            this.leadTimeMessage = product.leadTime == 'Call for availability' ? product.leadTime : `Ships in ${product.leadTime}`;
            this.isNcnr = product.ncnr;
            this.showPriceInCart = product.showPriceInCart;
            this.showPrice = this.getShowPrice(product.showPrice);
            this.action = this.getAction();
            this.buttonText = actionToButtonTextMap[this.action];
            this.availability = this.getAvailability(product);
            this.schemaOrgAvailabilityUrl = this.getSchemaOrgAvailabilityUrl();
        } catch (err) {
            console.error(...new ExxComError(285772, scriptName, err).stamp());
        }
    }

    private getAction() {
        try {
            if (!this.hasPrice || (!this.isInStock && !this.isSpecialOrder) || !this.showPrice) {
                return 'inquire';
            } else if (this.showPriceInCart) {
                return 'seeInCart';
            } else {
                return 'addToCart';
            }
        } catch (err) {
            console.error(...new ExxComError(692994, scriptName, err).stamp());
        }
    }

    private getShowPrice(productShowPrice: any) {
        try {
            if (productShowPrice && this.hasPrice && !this.showPriceInCart && (this.isSpecialOrder || this.isPreOrder)) {
                return true; // Show price
            } else if (!productShowPrice || !this.hasPrice || this.showPriceInCart) {
                return false; // Hide price
            } else {
                return true; // Show price
            }
        } catch (err) {
            console.error(...new ExxComError(254982, scriptName, err).stamp());
        }
    }

    private getAvailability(product: any) {
        try {
            const { inventory = 0 } = product;
            const preDate = new Date(product.preOrderDate || 0);
            if (preDate >= new Date() && this.isPreOrder) {
                return {
                    longText: `Pre-order, Expected to ship ${preDate.toLocaleDateString('en-US', { day: 'numeric', month: 'numeric' })}`,
                    shortText: 'Pre-order',
                    color: 'orange',
                };
            } else if (inventory > 0) {
                return {
                    longText: 'In Stock',
                    shortText: 'In Stock',
                    color: 'green',
                };
            } else if (product.specialOrder) {
                return {
                    longText: 'Special Order',
                    shortText: 'Special Order',
                    color: 'blue',
                };
            } else {
                return null;
            }
        } catch (err) {
            console.error(...new ExxComError(892881, scriptName, err).stamp());
        }
    }

    private getSchemaOrgAvailabilityUrl() {
        try {
            let url: string = '';
            if (this.isPreOrder) {
                url = 'https://schema.org/PreOrder';
            } else if (this.isInStock) {
                url = 'https://schema.org/InStock';
            } else if (!this.isInStock) {
                url = 'https://schema.org/OutOfStock';
            }
            return url;
        } catch (err) {
            console.error(...new ExxComError(802999, scriptName, err).stamp());
        }
    }
}
