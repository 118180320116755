import { Component, ElementRef, EventEmitter, OnInit, QueryList, ViewChild, ViewChildren, Input, Output } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/core';
import { isEmpty, size } from 'lodash';

import { ApiService } from 'lib/services/api.service';
import { contains, isBrowser } from 'lib/tools';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { HeaderMenuComponent as HeaderMenu } from 'lib/components/header-menu.component.class';
import { HeaderMenuStaticItems } from './header-menu-static-items.collection';
import { HeaderMenuNarrow } from './header-menu-narrow.class';
import { CategoryService } from 'lib/services/category/category.service';

const scriptName = 'header-menu.component';

const MENU_STATE_KEY = makeStateKey<Array<object>>('menu');

@Component({
    selector: 'app-header-menu',
    templateUrl: './header-menu.component.html',
    styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent extends HeaderMenu implements OnInit {
    @Input('isValidLocalSessionData') isValidLocalSessionData: boolean = false;
    @Input('isNarrow') isNarrow: boolean = false;
    @Output() isActiveChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild('headerMenuNarrowToggle', { static: true })
    headerMenuNarrowToggle: any;
    @ViewChild('headerMenuNarrowLevel1', { static: true })
    headerMenuNarrowLevel1: any;
    @ViewChildren('headerMenuNarrowLevel2')
    headerMenuNarrowLevel2: QueryList<ElementRef>;
    @ViewChildren('headerMenuNarrowLevel3')
    headerMenuNarrowLevel3: QueryList<ElementRef>;

    currentNarrowMenuLevel: number = 0;
    isNarrowMenuOpen: boolean = false;
    level2LabelWidth: number = 0.5;
    level2MenuHeight: number | null;
    level2MenuWidth: number | null;
    level3MenuOpen: boolean = false;
    menu: object[];
    narrowMenu: HeaderMenuNarrow;
    wasActive: boolean = false;

    constructor(
        private apiService: ApiService,
        categoryService: CategoryService,
        private transferState: TransferState
    ) {
        super(categoryService);
    }

    async ngOnInit() {
        try {
            this.getMenu();
            if (!isBrowser()) {
                return;
            }
            setTimeout(() => {
                this.narrowMenu = new HeaderMenuNarrow(this.isNarrow, {
                    menuOpen: this.isNarrowMenuOpen,
                    toggle: this.headerMenuNarrowToggle,
                    level1: this.headerMenuNarrowLevel1,
                    level2: this.headerMenuNarrowLevel2,
                    level3: this.headerMenuNarrowLevel3,
                });
                this.narrowMenu.init();
            }, 500);
        } catch (err) {
            console.error(...new ExxComError(444268, scriptName, err).stamp());
        }
    }

    private getMenu() {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise<void>(async (resolve) => {
            try {
                this.menu = this.transferState.get(MENU_STATE_KEY, []);
                if (!isEmpty(this.menu)) {
                    return;
                }
                const res = await this.apiService.get('menu');
                if (!res.success) {
                    throw res;
                } else {
                    this.menu = res.data.concat(HeaderMenuStaticItems);
                    this.transferState.set(MENU_STATE_KEY, this.menu);
                    resolve();
                }
            } catch (err) {
                const suppress = ['Unknown Error', 'cannotconnect'];
                if (err && contains(suppress, err.statusText)) {
                    return;
                }
                console.error(...new ExxComError(230992, scriptName, err).stamp());
            }
        });
    }

    mouseEnter(level1Category: any, level2Category: any) {
        try {
            const level1Categories = size(level1Category.categories);
            const level2Categories = size(level2Category.categories);
            if (level2Categories) {
                this.level2MenuWidth = 325 * 2;
                this.level3MenuOpen = true;
            }
            // this.level2MenuHeight = level1Categories * (!level2Categories ? 42 : 44) + 15*2 + 2;
            if (level2Categories > level1Categories) {
                // 44 is the height of header-menu-wide-level2/3-label, 15 is the of padding of level div
                this.level2MenuHeight = level2Categories * 39 + 16 * 2;
            }
        } catch (err) {
            console.error(...new ExxComError(239388, scriptName, err).stamp());
        }
    }

    mouseLeave() {
        try {
            this.level2MenuHeight = null;
            this.level2MenuWidth = null;
            this.level3MenuOpen = false;
        } catch (err) {
            console.error(...new ExxComError(320199, scriptName, err).stamp());
        }
    }

    setIsActive(active: boolean) {
        try {
            if (active == this.wasActive) {
                return;
            }
            this.wasActive = active;
            this.isActiveChange.emit(active);
        } catch (err) {
            console.error(...new ExxComError(120982, scriptName, err).stamp());
        }
    }

    showCategoryChildren(target: HTMLElement, categoryId: string) {
        try {
            const childCategories = target.parentElement.children[1];
            const categoryToggleButton = target.children[0].children[0];
            if (childCategories.classList.contains('show-category-children')) {
                childCategories.classList.remove('show-category-children');
                categoryToggleButton.classList.remove('header-menu-narrow-collapse-icon');
                target.classList.remove('selected-category');
            } else {
                childCategories.classList.add('show-category-children');
                categoryToggleButton.classList.add('header-menu-narrow-collapse-icon');
                target.classList.add('selected-category');
            }
        } catch (err) {
            if (err.statusText === 'Unknown Error') {
                return;
            }
            console.error(...new ExxComError(183497, scriptName, err).stamp());
        }
    }

    collapseCategories() {
        const openCategoryIcons = document.querySelectorAll('.header-menu-narrow-collapse-icon');
        const openCategories = document.querySelectorAll('.show-category-children');
        openCategoryIcons.forEach((icon) => {
            icon.classList.remove('header-menu-narrow-collapse-icon');
        });
        openCategories.forEach((category) => {
            category.classList.remove('show-category-children');
            category.parentElement.children[0].classList.remove('selected-category');
        });
    }

    goToLevelOne() {
        if (this.narrowMenu) {
            this.narrowMenu.prev();
            this.collapseCategories();
        } else {
            return;
        }
    }

    closeNarrowMenu() {
        if (this.narrowMenu) {
            this.narrowMenu.close();
            this.collapseCategories();
        } else {
            return;
        }
    }
}
