import { Component, OnInit } from '@angular/core';

import { CmsBlogComponentClass } from 'lib/components/cms/blog/cms-blog-component.class';
import { RouterService } from 'lib/services/router.service';
import { currentYear } from 'lib/tools';

@Component({
    selector: 'app-cms-blog-footer',
    templateUrl: './cms-blog-footer.component.html',
    styleUrls: ['./cms-blog-footer.component.scss'],
})
export class CmsBlogFooterComponent extends CmsBlogComponentClass implements OnInit {
    currentYear: number = currentYear();
    constructor(routerService: RouterService) {
        super({ dependencies: { routerService } });
    }

    ngOnInit() {}
}
