import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, FormGroup, ValidationErrors } from '@angular/forms';

import { EasyPostValidationService } from 'lib/services/easypost/easypost-validation.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { get, isEmpty } from 'lodash';

const scriptName = 'address.validator';

@Injectable()
export class AddressValidator implements AsyncValidator {
    private enabled: boolean = false;
    private wasValid: boolean = true;

    formGroup: FormGroup;
    isValid: boolean = true;

    constructor(private easypostValidationService: EasyPostValidationService) {}

    async validate(formGroup: FormGroup): Promise<ValidationErrors> {
        try {
            if (formGroup && !this.formGroup) {
                this.formGroup = formGroup;
            }
            const values = get(formGroup, 'value');
            if (!this.enabled || isEmpty(values)) {
                return this.isValid ? null : { invalid: true };
            }
            this.isValid = values.isConfirmed || (await this.easypostValidationService.isValidAddress(values));
            this.wasValid = this.isValid;
            return this.isValid ? null : { invalid: true };
        } catch (err) {
            console.error(...new ExxComError(402991, scriptName, err).stamp());
        }
    }

    validateIsConfirmed(isConfirmed: AbstractControl, formGroup: FormGroup) {
        try {
            if (isConfirmed.pristine || !formGroup) {
                return null;
            }
            if (isConfirmed.value) {
                this.isValid = true;
                formGroup.setErrors(null);
            } else {
                this.isValid = this.wasValid;
                formGroup.setErrors(this.isValid ? null : { invalid: true });
            }
            return null;
        } catch (err) {
            console.error(...new ExxComError(502994, scriptName, err).stamp());
        }
    }

    triggerValidation() {
        return new Promise<void>((resolve) => {
            try {
                this.enabled = true;
                const subscription = this.formGroup.statusChanges.subscribe((status: any) => {
                    if (status == 'PENDING') {
                        return;
                    }
                    subscription.unsubscribe();
                    resolve();
                });
                this.formGroup.updateValueAndValidity();
                this.enabled = false;
            } catch (err) {
                console.error(...new ExxComError(720963, scriptName, err).stamp());
            }
        });
    }

    reset() {
        try {
            this.formGroup = null;
            this.isValid = true;
            this.wasValid = true;
        } catch (err) {
            console.error(...new ExxComError(402988, scriptName, err).stamp());
        }
    }
}
