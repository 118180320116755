<div id="product" *ngIf="isVisible">
    <header id="product-header" class="non-flex-container px-3">
        <div id="product-breadcrumb" *ngIf="!isNarrow" data-cy="breadcrumb">
            <span *ngFor="let breadcrumbPart of breadcrumb; let i = index">
                <a *ngIf="i != 0" [routerLink]="'/category/' + breadcrumbPart.urlComponent" data-cy="breadcrumb-link">{{ breadcrumbPart.name }}</a>
                <span *ngIf="i == 0">{{ breadcrumbPart.name }}</span>
                <span *ngIf="i < breadcrumb?.length - 1" class="product-breadcrumb-separator"> / </span>
            </span>
        </div>

        <h1 id="product-description" *ngIf="productType !== 'Solution'">{{ product?.description }}</h1>

        <div id="product-numbers" *ngIf="productType !== 'Solution'">
            <span id="product-mpn">MPN: {{ product.mpn }}</span>
        </div>
    </header>

    <app-product-listing class="fx-col-container" [product]="product" *ngIf="productType !== 'Solution'"></app-product-listing>
    <app-product-listing-solution [product]="product" isSingleSearchView="true" *ngIf="productType === 'Solution'"></app-product-listing-solution>
    <div class="fx-col-container" [class.pt-3]="!isNarrow">
        <mat-tab-group mat-align-tabs="start" dynamicHeight [disableRipple]="true">
            <mat-tab *ngIf="product.marketingInfo" label="Overview">
                <div
                    *ngIf="product.marketingInfo"
                    id="product-marketing-information-wide"
                    class="product-tab-content px-3"
                    [innerHTML]="product.marketingInfo"
                ></div>
            </mat-tab>
            <mat-tab *ngIf="product.specifications" label="Specifications">
                <div
                    *ngIf="product.specifications"
                    id="product-specifications-wide"
                    class="product-tab-content px-3"
                    [innerHTML]="product.specifications"
                ></div>
            </mat-tab>
        </mat-tab-group>
    </div>

    <div id="product-marketingInfo-specifications" class="px-3" [class.pt-2]="productType !== 'Solution'" data-cy="marketing-info-narrow">
        <div class="menu-level1">
            <div
                *ngIf="product.marketingInfo"
                (click)="toggleMenu(0)"
                class="title fx-row fx-row-h-space-between fx-row-v-center"
                data-cy="product-about-btn-narrow"
            >
                <h3 class="about">Overview</h3>
                <i-feather name="plus-circle" class="plus"></i-feather>
                <i-feather name="minus-circle" class="minus"> </i-feather>
            </div>
            <div class="menu-level2" data-cy="product-about-body" id="product-marketing-information-narrow" [innerHTML]="product.marketingInfo"></div>
        </div>
        <div class="menu-level1">
            <div *ngIf="product.specifications" (click)="toggleMenu(1)" class="title fx-row fx-row-h-space-between fx-row-v-center mb-0">
                <h3 class="specifications">Specifications</h3>
                <i-feather name="plus-circle" class="plus"></i-feather>
                <i-feather name="minus-circle" class="minus"> </i-feather>
            </div>
            <div class="menu-level2" id="product-specifications-narrow" [innerHTML]="product.specifications"></div>
        </div>
    </div>

    <div class="fx-col-container px-3">
        <div *ngIf="recommendationSlides" id="product-recommendations">
            <mod-swiper
                *ngIf="recommendationSlides?.length > 0"
                class="normal-swiper-nav"
                [slidesPerView]="slidesPerView"
                [spaceBetween]="spaceBetween"
                [breakpoints]="breakpoints"
                [loop]="false"
                containerClass="recommended-swiper"
                swiperTitle="People Also Shopped For"
                data-cy="product-recommendations-container"
            >
                <product-recommended
                    class="swiper-slide"
                    *ngFor="let slide of recommendationSlides"
                    [imageUrl]="slide?.imageUrl"
                    [heading]="slide?.heading"
                    [linkUrl]="slide?.linkUrl"
                    data-cy="product-recommendation-item"
                >
                </product-recommended>
            </mod-swiper>
        </div>
    </div>

    <div class="fx-col-container px-3">
        <div *ngIf="recentlyViewedSlides" id="product-recently-viewed">
            <mod-swiper
                *ngIf="recentlyViewedSlides?.length > 0"
                class="normal-swiper-nav"
                [slidesPerView]="slidesPerView"
                [spaceBetween]="spaceBetween"
                [breakpoints]="breakpoints"
                [loop]="false"
                containerClass="recently-viewed-swiper"
                swiperTitle="You Recently Viewed"
            >
                <product-recommended
                    class="swiper-slide"
                    *ngFor="let slide of recentlyViewedSlides"
                    [imageUrl]="slide?.imageUrl"
                    [heading]="slide?.heading"
                    [linkUrl]="slide?.linkUrl"
                >
                </product-recommended>
            </mod-swiper>
        </div>
    </div>
</div>
