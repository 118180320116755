import { SimpleChanges } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { ConfigurationProductChanges, ConfigurationProductData } from 'lib/services/configuration-product/configuration-product.interface';

const scriptName = 'ConfigurationProduct.class';

export class ConfigurationProductLine {
    // Schema

    _id?: string = null;
    changes?: ConfigurationProductChanges[] = [];
    createdAt?: string | Date = null;
    images?: string[] = [];
    manufacturer?: string = null;
    mpn?: string = null;
    name?: string = null;
    preOrderDate?: string | Date = null;
    urlComponent?: string = null;
    type?: string = null;
    // Client-side only

    changed$: BehaviorSubject<SimpleChanges> = new BehaviorSubject<SimpleChanges>(null);

    constructor(data?: ConfigurationProductData) {
        if (data) {
            this.init(data);
        }
    }

    init(data: ConfigurationProductData) {
        try {
            const changes: SimpleChanges = {};
            this._id = data._id || this._id || null;
            this.changes = data.changes || this.changes || [];
            this.createdAt = data.createdAt ? new Date(data.createdAt) : this.createdAt || null;
            this.manufacturer = data.manufacturer || this.manufacturer || null;
            this.mpn = data.mpn || this.mpn || null;
            this.name = data.name || this.name || null;
            this.type = data.type || this.type || null;
            this.urlComponent = data.urlComponent || this.urlComponent || null;

            this.changed$.next(changes);
        } catch (err) {
            console.error(...new ExxComError(429177, scriptName, err).stamp());
        }
    }
}
