<div id="header-cart">
  <!-- CART DROPDOWN TOGGLE -->

  <div
    *ngIf="cart"
    id="header-cart-icon"
    data-cy="header-cart-icon"
    #icon
    class="fx-row fx-row-h-center fx-row-v-center"
    data-cy="spc-cart"
    [class.active]="isActive"
    (click)="toggleMenu()"
  >
    <!-- See docs/clients/click-null.txt for an explanation of the reason for (click)="null" -->
    <mat-icon [ngClass]="cart?.isEmpty ? '' : 'small-icon'" svgIcon="shopping-bag" (click)="(null)"></mat-icon>
    <div *ngIf="cart?.totalItems > 0" id="header-cart-icon-count" (click)="(null)" data-cy="spc-cart-item-count">
      {{ cart?.totalItems }}
    </div>
  </div>

  <div
    *ngIf="!cart"
    id="header-cart-icon"
    #icon
    class="fx-row fx-row-h-center fx-row-v-center"
    [class.active]="isActive"
    (click)="toggleMenu()"
  >
    <!-- See docs/clients/click-null.txt for an explanation of the reason for (click)="null" -->
    <mat-icon svgIcon="shopping-bag" (click)="(null)"></mat-icon>
    <div *ngIf="cart?.totalItems > 0" id="header-cart-icon-count" (click)="(null)">{{ cart?.totalItems }}</div>
  </div>

  <!-- EMPTY CART MESSAGE DROPDOWN -->

  <div *ngIf="cart?.isEmpty" id="header-cart-menu" #menu [class]="isActive ? 'active mat-elevation-z2' : ''">
    <span class="header-cart-menu-message">Your cart is empty</span>
  </div>

  <!-- CART MENU DROPDOWN -->

  <div *ngIf="!cart?.isEmpty" id="header-cart-menu" #menu [class]="isActive ? 'active mat-elevation-z2' : ''">
    <h3 id="header-cart-menu-total-items">{{ cart?.totalItems }} ITEMS</h3>

    <div id="header-cart-menu-items" (scroll)="showScrollMessage = false" #menuItems data-cy="spc-cart-item-list">
      <div *ngFor="let item of items" class="header-cart-menu-item fx-row fx-row-h-center">
        <img
          *ngIf="item?.imageUrl"
          class="header-menu-cart-item-image"
          [src]="getWebpImg(item.imageUrl)"
          [alt]="item.description"
        />

        <div class="header-cart-menu-item-text fx-fill-remaining">
          <div class="header-cart-menu-item-text-description">
            <a class="link-gray truncate-ellipsis" [routerLink]="'/' + item.urlComponent" (click)="closeMenu()">{{
              item.description
            }}</a>
          </div>
          <span class="header-cart-menu-item-text-price">
            {{ item.quantity }} @ {{ item.price | currency }} each |
            <span class="header-cart-menu-item-text-total">{{ item.totalPrice | currency }}</span>
          </span>
        </div>
      </div>
    </div>

    <h4 id="header-cart-menu-subtotal" class="fx-row fx-row-h-space-between">
      <span id="header-cart-scroll-message" [class.show]="showScrollMessage">
        Scroll for More <mat-icon>double_arrow</mat-icon>
      </span>
      <span>SUBTOTAL:</span>
      <span>{{ cart?.summary.subtotal | currency }}</span>
    </h4>

    <div id="header-cart-menu-buttons" class="mt-2">
      <a
        data-cy="header-cart-menu-proceed-btn"
        class="header-cart-menu-button button-secondary button-base px-1 mr-1"
        routerLink="/checkout/cart"
        (click)="closeMenu()"
        >{{ isCheckout ? "Edit Your Cart" : isCart ? "View Your Cart" : "Proceed to Cart" }}</a
      >
      <a
        class="header-cart-menu-button button-primary px-1 button-base"
        data-cy="spc-checkout-button"
        (click)="openAuthDialog(); closeMenu()"
        >Checkout</a
      >
    </div>
  </div>
</div>
