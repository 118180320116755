import { ExxComError } from 'lib/classes/exxcom-error.class';

const scriptName = 'exxcom.class';

export class ExxCom {
    constructor() {}

    set(k: string, v: any) {
        try {
            this[k] = v;
        } catch (err) {
            console.error(...new ExxComError(792883, scriptName, err).stamp());
        }
    }

    setMany(o: any) {
        try {
            for (const k in o) {
                if (Object.prototype.hasOwnProperty.call(o, k)) {
                    this[k] = o[k];
                }
            }
        } catch (err) {
            console.error(...new ExxComError(898282, scriptName, err).stamp());
        }
    }
}
