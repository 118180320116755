import { ElementRef, HostListener, Input, ViewChild, Directive } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AccountService } from 'lib/services/account/account.service';
import { AddressValidator } from 'lib/validators/address.validator';
import { CheckoutService } from 'lib/services/checkout.service';
import { countries, countriesByNameLower, countriesByCode } from 'lib/lists/countries';
import { ExxComComponentClass } from 'lib/components/exxcom-component.class';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { GrecaptchaService } from 'lib/services/google/grecaptcha.service';
import { each, get, isEmpty, startCase } from 'lodash';
import { MessageService } from 'lib/services/message.service';
import { SessionService } from 'lib/services/session.service';
import { states, statesByCodeLower } from 'lib/lists/states';
import { validateCountry } from 'lib/validators/country.validator';
import { validatePhone } from 'lib/validators/phone.validator';
import { validateState } from 'lib/validators/state.validator';
import { wait } from 'lib/tools';

const scriptName = 'address-component.class';

@Directive()
export class AddressComponentClass extends ExxComComponentClass {
    // Host listeners

    @HostListener('document:click', ['$event.target']) click(target: any) {
        this.closeOnClickAway(target);
    }

    // Inputs

    @Input() address: any;
    @Input() isBrowser: boolean;
    @Input() mode: string; // create, update, readOnly

    // View children

    @ViewChild('countryInput') countryInput: ElementRef;
    @ViewChild('countrySelect') countrySelect: ElementRef;
    @ViewChild('stateInput') stateInput: ElementRef;
    @ViewChild('stateSelect') stateSelect: ElementRef;
    @ViewChild('submitButton') submitButton: ElementRef;

    // Dependencies

    accountService: AccountService;
    addressValidator: AddressValidator;
    checkoutService: CheckoutService;
    formBuilder: FormBuilder;
    grecaptchaService: GrecaptchaService;
    messageService: MessageService;
    sessionService: SessionService;

    // Properties: public

    countries: any = countries;
    countryMenuOpen: boolean = false;
    form: FormGroup;
    isPending: boolean = false;
    showSpinner: boolean;
    states: any = states;
    stateMenuOpen: boolean = false;
    statesByCodeLowerArray = Object.keys(statesByCodeLower).map((k: string) => statesByCodeLower[k]);
    tempAddresses: any = [];

    // Properties: public getters

    get addresses() {
        return get(this.accountService.account, 'addresses.all', []);
    }
    get isCreateMode() {
        return this.mode == 'create';
    }
    get isUpdateMode() {
        return this.mode == 'update';
    }

    constructor({ dependencies }) {
        super({ dependencies });
    }

    // Form

    initForm({ empty }: { empty?: boolean } = { empty: false }) {
        try {
            const initValue = (controlName: string) => {
                let defaultValue: string | boolean = '';
                if (controlName == 'country') {
                    defaultValue = 'United States';
                } else if (controlName.indexOf('is') == 0) {
                    defaultValue = false;
                }
                const addressValue = get(this, ['address', controlName]);
                return empty || !addressValue ? defaultValue : addressValue;
            };
            this.form = this.formBuilder.group(
                {
                    title: [initValue('title')],
                    addressee: [initValue('addressee'), { validators: Validators.required }],
                    phone: [
                        initValue('phone'),
                        {
                            validators: [Validators.required, validatePhone],
                            updateOn: 'blur',
                        },
                    ],
                    email: [initValue('email'), { validators: Validators.email }],
                    address1: [initValue('address1'), { validators: Validators.required }],
                    address2: [initValue('address2')],
                    city: [initValue('city'), { validators: Validators.required }],
                    state: [
                        initValue('state'),
                        {
                            validators: [Validators.required, () => validateState(this.form)],
                        },
                    ],
                    zip: [initValue('zip'), { validators: Validators.required }],
                    country: [initValue('country') || 'United States', { validators: [Validators.required, validateCountry] }],
                    isDefaultShipping: [initValue('isDefaultShipping'), { validators: Validators.required }],
                    isDefaultBilling: [initValue('isDefaultBilling'), { validators: Validators.required }],
                    isConfirmed: [
                        false,
                        {
                            validators: (fc: AbstractControl) => this.addressValidator.validateIsConfirmed(fc, this.form),
                        },
                    ],
                },
                {
                    asyncValidators: (fg: FormGroup) => this.addressValidator.validate(fg),
                    updateOn: 'change',
                }
            );
        } catch (err) {
            console.error(...new ExxComError(539834, scriptName, err).stamp());
        }
    }

    // Form getters

    get addressee() {
        return this.form.get('addressee');
    }
    get phone() {
        return this.form.get('phone');
    }
    get address1() {
        return this.form.get('address1');
    }
    get address2() {
        return this.form.get('address2');
    }
    get city() {
        return this.form.get('city');
    }
    get state() {
        return this.form.get('state');
    }
    get zip() {
        return this.form.get('zip');
    }
    get country() {
        return this.form.get('country');
    }
    get isDefaultShipping() {
        return this.form.get('isDefaultShipping');
    }
    get isDefaultBilling() {
        return this.form.get('isDefaultBilling');
    }
    get isConfirmed() {
        return this.form.get('isConfirmed');
    }

    // Helpers: errors

    hasError(control: any) {
        return control.invalid && (control.dirty || control.touched);
    }
    hasErrorRequired(control: any) {
        return control.errors && control.errors.required;
    }
    hasErrorInvalid(control: any) {
        return control.errors && control.errors.invalid;
    }
    hasErrorPhoneInvalid(control: any) {
        return control.errors && control.errors.phoneInvalid;
    }
    hasErrorAddressInvalid() {
        return this.addressValidator.isValid === false;
    }

    // Helpers: checkers

    hasDefaultAddress(type: string) {
        return this.addresses.find((a: any) => a[`isDefault${startCase(type)}`]);
    }
    hasMultipleAddresses() {
        return get(this, 'addresses.length', 0) > 1;
    }
    hasOnlyOneAddress() {
        return get(this, 'addresses.length', 0) == 1;
    }
    hasEmptyOrOneAddress() {
        return isEmpty(this.addresses) || (this.isPending && this.addresses.length == 1);
    }

    // Helpers: dropdowns

    scrollToOption(menu: HTMLElement, value: string) {
        try {
            const search = new RegExp(`^${value}`, 'i');
            let foundIndex = 0;
            let found = false;
            each(menu.children, (child: HTMLElement, i: number) => {
                if (search.test(child.innerHTML.trim()) && !found) {
                    foundIndex = i;
                    found = true;
                    return;
                }
            });
            menu.scrollTop = (menu.scrollHeight / menu.children.length) * foundIndex;
        } catch (err) {
            console.error(...new ExxComError(893994, scriptName, err).stamp());
        }
    }

    setSelectValue(controlName: string, value: any) {
        try {
            this.form.controls[controlName].setValue(value);
        } catch (err) {
            console.error(...new ExxComError(692883, scriptName, err).stamp());
        }
    }

    formatSelectValue(controlName: string) {
        try {
            const control = this[controlName];
            if (!control || this.hasError(control)) {
                return;
            }
            const value = control.value;
            if (controlName == 'state') {
                const states = statesByCodeLower[value.toLowerCase()];
                states && control.setValue(states.code);
            } else if (controlName == 'country') {
                const countryByName = countriesByNameLower[value.toLowerCase()];
                const countryByCode = countriesByCode[value.toUpperCase()];
                if (countryByName) {
                    control.setValue(countryByName.name);
                } else if (countryByCode) {
                    control.setValue(countryByCode.name);
                }
            }
        } catch (err) {
            console.error(...new ExxComError(300992, scriptName, err).stamp());
        }
    }

    async toggleSelectMenu(type: string, action?: string, selectContent?: boolean) {
        try {
            await wait('100ms');
            const menuOpen = `${type}MenuOpen`;
            if (!action || action == 'toggle') {
                this[menuOpen] = !this[menuOpen];
            } else {
                this[menuOpen] = action == 'open';
            }
            if (action == 'open' && selectContent !== false) {
                this[`${type}Select`].nativeElement.children[0].select();
            }
        } catch (err) {
            console.error(...new ExxComError(109292, scriptName, err).stamp());
        }
    }

    closeOnClickAway(target: any) {
        try {
            const countrySelect = this.countrySelect && this.countrySelect.nativeElement;
            if (countrySelect && target != countrySelect && !countrySelect.contains(target)) {
                this.countryMenuOpen = false;
            }
            const stateSelect = this.stateSelect && this.stateSelect.nativeElement;
            if (stateSelect && target != stateSelect && !stateSelect.contains(target)) {
                this.stateMenuOpen = false;
            }
        } catch (err) {
            console.error(...new ExxComError(729931, scriptName, err).stamp());
        }
    }

    // Helpers: misc

    getValues() {
        try {
            const values: any = {};
            if (this.isUpdateMode) {
                values._id = this.address._id;
            }
            each(this.form.controls, (control: any, name: string) => {
                let value = control.value;
                if (name == 'country') {
                    value = countriesByNameLower[control.value.toLowerCase()].code;
                }
                values[name] = value;
            });
            return values;
        } catch (err) {
            console.error(...new ExxComError(239838, scriptName, err).stamp());
        }
    }

    getAddresses() {
        try {
            if (this.showSpinner) {
                return this.tempAddresses;
            } else {
                this.tempAddresses = this.addresses;
                return this.addresses;
            }
        } catch (err) {
            console.error(...new ExxComError(339338, scriptName, err).stamp());
        }
    }
}
