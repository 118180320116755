import { FormGroup } from '@angular/forms';

import { statesByCodeLower } from 'lib/lists/states';

export function validateState(formGroup: FormGroup): { [key: string]: any } | null {
    if (!formGroup) {
        return;
    }
    const state = formGroup.get('state').value;
    const country = formGroup.get('country').value;
    if (!state || !country) {
        return null;
    }
    const countryLower = country.toLowerCase();
    if (countryLower != 'us' && countryLower != 'united states') {
        return null;
    }
    const isValid = !!statesByCodeLower[state.toLowerCase()];
    return !isValid ? { invalid: true } : null;
}
