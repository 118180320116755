import { Injectable, Inject } from '@angular/core';
import { ExxComError } from 'lib/classes/exxcom-error.class';

import { SavedConfigurationResponse, SavedConfigurationData, CustomerData } from './saved-configuration.interface';

import { ApiService } from '../api.service';

const scriptName = 'saved-configuration.service';

let apiService: ApiService;

@Injectable()
export class SavedConfigurationService {
    constructor(@Inject('environment') e: any, a: ApiService) {
        try {
            apiService = a;
        } catch (err) {
            console.error(...new ExxComError(334477, scriptName, err).stamp());
        }
    }

    async findSavedConfiguration(configurationId: String, key: String): Promise<any> {
        try {
            const res: SavedConfigurationResponse = await apiService.get(`saved/configurations/find/${configurationId}/${key}`);
            if (!res.success) {
                throw res;
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(334478, scriptName, err).stamp());
        }
    }

    async createSavedConfiguration(configurationId: String, selectedSpecs: Object): Promise<any> {
        try {
            const body = { configurationId, selectedSpecs };
            const res: SavedConfigurationResponse = await apiService.post('saved/configurations/create', body);
            if (!res.success) {
                throw res;
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(334479, scriptName, err).stamp());
        }
    }

    async addCustomerDataToSavedConfiguration(configurationToUpdate: SavedConfigurationData, customerData: CustomerData): Promise<any> {
        try {
            const newSavedConfiguration = configurationToUpdate;
            newSavedConfiguration.customerData = customerData;
            return newSavedConfiguration;
        } catch (err) {
            console.error(...new ExxComError(334480, scriptName, err).stamp());
        }
    }

    async saveSavedConfiguration(configurationToSave: Object): Promise<any> {
        try {
            const res: SavedConfigurationResponse = await apiService.post('saved/configurations/save', configurationToSave);
            if (!res.success) {
                throw res;
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(334481, scriptName, err).stamp());
        }
    }
}
