import { SimpleChanges } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { RoleData } from './role.interface';

const scriptName = 'role.class';

export class Role {
    // Schema

    _id: string = null;
    name: string = null;
    permissions: string[] = [];
    sortOrder: number = null;

    // Client-side only

    changed$: BehaviorSubject<SimpleChanges> = new BehaviorSubject<SimpleChanges>(null);

    constructor(data?: RoleData) {
        if (data) {
            this.init(data);
        }
    }

    init(data: RoleData) {
        try {
            const changes: SimpleChanges = {};

            this._id = data._id || this._id || null;
            this.name = data.name || this.name || null;
            this.permissions = data.permissions || this.permissions || [];
            this.sortOrder = data.sortOrder || this.sortOrder || null;

            this.changed$.next(changes);
        } catch (err) {
            console.error(...new ExxComError(620377, scriptName, err).stamp());
        }
    }
}
