import { ElementRef, EventEmitter, HostListener, OnInit, Output, SimpleChanges, ViewChild, Input, Directive } from '@angular/core';

import { CmsBlogComponentClass } from 'lib/components/cms/blog/cms-blog-component.class';
import { each, get } from 'lodash';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { getStyleIntValue, isBrowser, wait } from 'lib/tools';

const scriptName = 'cms-blog-header-search-component.class';

@Directive()
export class CmsBlogHeaderSearchComponentClass extends CmsBlogComponentClass implements OnInit {
    // Host listeners

    @HostListener('document:mouseover', ['$event.target']) onHover(target: any) {
        this.onHoverMenu(target);
    }
    @HostListener('document:click', ['$event.target']) click(target: any) {
        this.onClickAway(target);
    }

    // Inputs

    @Input() isNarrow: boolean;
    @Input() displayMagnifier: boolean;
    @Output() showMagnifier: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() isActiveChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    // View children

    @ViewChild('searchInput') searchInput: ElementRef;
    @ViewChild('searchInputContainer') searchInputContainer: ElementRef;
    @ViewChild('searchMenu') searchMenu: ElementRef;

    // Dependencies (defined in parent)

    // Properties: public

    isActive: boolean = false;
    isSearching: boolean = false;
    suggestedPosts: any = [];
    wasActive: boolean = false;

    constructor({ dependencies }) {
        super({ dependencies });
    }

    async ngOnChanges(changes: SimpleChanges) {
        try {
            if (changes.isNarrow && changes.isNarrow.previousValue != changes.isNarrow.currentValue) {
                this.clearInput();
                this.setIsActive();
            }
        } catch (err) {
            console.error(...new ExxComError(444091, scriptName, err).stamp());
        }
    }

    ngOnInit() {
        try {
            if (!isBrowser()) {
                return;
            }
            this.routerService.onRouteChange('cmsBlogHeaderSearchComponent', async (event: any) => {
                this.setOverflowSubstitue(true);
                this.clearInput();
                this.setIsActive();
            });
        } catch (err) {
            console.error(...new ExxComError(720931, scriptName, err).stamp());
        }
    }

    get router() {
        return this.routerService.router;
    }
    get menuElement() {
        return this.searchMenu && this.searchMenu.nativeElement;
    }
    get inputElement() {
        return this.searchInput && this.searchInput.nativeElement;
    }
    get searchValue() {
        return this.inputElement && this.inputElement.value;
    }
    set searchValue(value: string) {
        if (this.inputElement) {
            this.inputElement.value = value;
        }
    }
    get searchInputHasFocus() {
        if (isBrowser()) {
            return this.inputElement && document.activeElement == this.inputElement;
        } else {
            return false;
        }
    }
    get searchInputHasValue() {
        return this.searchValue != '';
    }

    async setIsActive() {
        try {
            await wait('');
            this.isActive = this.searchInputHasFocus && this.searchInputHasValue;
            this.isActiveChange.emit(this.isActive);
            this.triggerOverflow(!this.isActive);
        } catch (err) {
            console.error(...new ExxComError(799992, scriptName, err).stamp());
        }
    }

    clearInput() {
        try {
            this.searchValue = '';
            this.inputElement && this.inputElement.blur();
        } catch (err) {
            console.error(...new ExxComError(934411, scriptName, err).stamp());
        }
    }

    navAway() {
        try {
            this.setIsActive();
            if (!this.isNarrow) {
                return;
            }
            this.showMagnifier.emit(true);
        } catch (err) {
            console.error(...new ExxComError(209911, scriptName, err).stamp());
        }
    }

    private async setMenuHeight() {
        try {
            await wait('');
            const menu = this.menuElement;
            if (!menu) {
                return;
            }
            menu.style.height = '';
            const menuContentHeight = getStyleIntValue(menu, 'height');
            const windowHeight = window.innerHeight;
            const header = document.getElementById('cms-blog-header');
            const headerHeight = header && getStyleIntValue(header, 'height');
            const calculatedMenuHeight = windowHeight - headerHeight;
            if (menuContentHeight < calculatedMenuHeight) {
                menu.style.height = `${menuContentHeight}px`;
            } else {
                menu.style.height = `${calculatedMenuHeight}px`;
            }
        } catch (err) {
            console.error(...new ExxComError(998811, scriptName, err).stamp());
        }
    }

    private getBlogPost(p: any) {
        try {
            const category = get(p, 'blog_category[0].title');
            return {
                title: p.title,
                url: p.url,
                category: category,
                date: this.reformatDate(p),
                article: p.article,
            };
        } catch (err) {
            console.error(...new ExxComError(298378, scriptName, err).stamp());
        }
    }

    async onFocus() {
        try {
            await this.setIsActive();
            this.setMenuHeight();
        } catch (err) {
            console.error(...new ExxComError(620942, scriptName, err).stamp());
        }
    }

    private triggerOverflow(set: boolean) {
        try {
            const infiniteScroll = document.getElementById('infinite-scroll-global-container').style.overflow;
            if (!infiniteScroll) {
                return;
            }
            this.setOverflowSubstitue(set);
        } catch (err) {
            console.error(...new ExxComError(3888914, scriptName, err).stamp());
        }
    }

    private setOverflowSubstitue(set: boolean) {
        try {
            if (!isBrowser() || this.isNarrow) {
                return;
            }
            const cmsHeader = document.getElementById('cms-blog-header');
            const mai = document.getElementsByTagName('main')[0];
            if (!mai || !cmsHeader) {
                return;
            }
            cmsHeader.style.borderRight = set ? '' : '5px solid #A3B0C4';
            cmsHeader.style.position = set ? '' : 'fixed';
            mai.style.position = set ? '' : 'relative';
            mai.style.top = set ? '' : '70px';
        } catch (err) {
            console.error(...new ExxComError(302009, scriptName, err).stamp());
        }
    }

    async onKeyup(event: KeyboardEvent) {
        try {
            if (!isBrowser() || event.code == 'Enter') {
                return;
            }
            const searchValue = this.searchValue;
            this.isSearching = true;
            const entries = await this.cmsService.searchBlogPostsByTitle(searchValue, 5);
            this.suggestedPosts = [];
            each(entries, (post: any) => this.suggestedPosts.push(this.getBlogPost(post)));
            this.isSearching = false;
            await this.setIsActive();
            this.setMenuHeight();
        } catch (err) {
            console.error(...new ExxComError(209841, scriptName, err).stamp());
        }
    }

    async onKeydownEnter() {
        try {
            if (!isBrowser()) {
                return;
            }
            this.router.navigateByUrl(`/blog/result/input/${this.searchValue}`);
            this.inputElement && this.inputElement.blur();
            this.navAway();
        } catch (err) {
            console.error(...new ExxComError(610921, scriptName, err).stamp());
        }
    }

    private async onHoverMenu(target: any) {
        try {
            if (target.id == 'cms-blog-header-menu-wide-level1-label' || target.id == 'cms-blog-header-menu-wide-level1-label-icon') {
                this.inputElement && this.inputElement.blur();
                await this.setIsActive();
            }
        } catch (err) {
            console.error(...new ExxComError(102981, scriptName, err).stamp());
        }
    }

    private menuWasClicked(target: HTMLElement) {
        try {
            const menu = this.menuElement;
            return menu && (menu == target || menu.contains(target));
        } catch (err) {
            console.error(...new ExxComError(633904, scriptName, err).stamp());
        }
    }

    private inputWasClicked(target: HTMLElement) {
        try {
            return this.inputElement == target;
        } catch (err) {
            console.error(...new ExxComError(606002, scriptName, err).stamp());
        }
    }

    private async onClickAway(target: any) {
        try {
            if (this.menuWasClicked(target) || this.inputWasClicked(target)) {
                return;
            }
            if (this.isNarrow && target.id == 'overlay') {
                this.showMagnifier.emit(true);
            }
            await this.setIsActive();
        } catch (err) {
            console.error(...new ExxComError(209945, scriptName, err).stamp());
        }
    }
}
