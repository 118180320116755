// https://gist.github.com/zmmbreeze/9408172
const stringifyCircularJson = (o: any, spaces: number) => {
    try {
        let cache = [];
        const jsonString = JSON.stringify(
            o,
            function (key, value) {
                if (typeof value === 'object' && value !== null) {
                    if (cache.indexOf(value) !== -1) {
                        return;
                    } // Circular reference found, discard key
                    cache.push(value); // Store value in our collection
                }
                return value;
            },
            spaces
        );
        cache = null; // Garbage collection
        return jsonString;
    } catch (err) {
        console.error(429833, 'Error in exxcom-error.class', err);
    }
};

let lastLogTime: number;

export class ExxComError {
    error: any = null;
    number: number = null;
    thrownIn: string = null;

    constructor(number: number, thrownIn: string, error: any) {
        this.thrownIn = thrownIn;
        this.number = number;
        this.error = error;
    }

    stamp(): string[] {
        try {
            // Prevent logging too many errors

            const nowTime: number = new Date().getTime();
            if (lastLogTime && lastLogTime + 3000 > nowTime) {
                return [];
            }
            lastLogTime = new Date().getTime();

            // Parse error

            const toStamp = [`Error file: "${this.thrownIn}" at [${this.number}]:\n`];
            if (this.error instanceof Error) {
                // adding comment to remove lint error.
            } else if (!(this.error instanceof Error) && typeof this.error == 'object') {
                const errorString = stringifyCircularJson(this.error, 2);
                this.error = new Error(`${this.error.message ? this.error.message : 'ExxComError from object:'}\r\n${errorString}`);
            } else if (typeof this.error == 'string') {
                this.error = new Error(this.error);
            } else {
                this.error = new Error(`Unknown error type:\r\n${this.error}`);
            }

            toStamp.push(this.error);
            toStamp.push('\r\n<------->'); // Delimiter required for log parsing

            return toStamp;
        } catch (err) {
            console.error(529834, 'Error in exxcom-error.class', err);
        }
    }
}
