import { ExxComError } from 'lib/classes/exxcom-error.class';
import { WebstoreProduct } from 'lib/services/webstore-product/webstore-product.class';

const scriptName = 'account-wishlist-line.class';

export class AccountWishlistLine {
    _id: string = null;
    dateAdded: Date = null;
    dateUpdated: Date = null;
    notes: string = '';
    priority: number = 0;
    product: WebstoreProduct;
    quantity: number = 0;

    constructor(data: any, dependencies: any) {
        try {
            this._id = data._id;
            this.dateAdded = new Date(data.dateAdded);
            this.dateUpdated = new Date(data.dateUpdated);
            this.notes = data.notes;
            this.priority = data.priority;
            this.product = new WebstoreProduct(data.product, {
                environment: dependencies.environment,
            });
            this.quantity = data.quantity;
        } catch (err) {
            console.error(...new ExxComError(520393, scriptName, err).stamp());
        }
    }
}
