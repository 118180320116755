import { ApiService } from 'lib/services/api.service';

import { arrayOfObjectsEqual, duration } from 'lib/tools';
import { countriesByNameLower, isCountryCode } from 'lib/lists/countries';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { get } from 'lodash';
import { Subject } from 'rxjs';

const scriptName = 'easypost.class';

let apiService: ApiService;

export class EasyPostShipping {
    private previous: any = {
        country: '',
        state: '',
        zip: '',
        items: [],
        res: { success: false, data: [] },
    };

    isRetrieving: boolean = false;
    retrieving$: Subject<boolean> = new Subject<boolean>();

    constructor(dependencies: any) {
        try {
            apiService = dependencies.apiService;
        } catch (err) {
            console.error(...new ExxComError(620394, scriptName, err).stamp());
        }
    }

    async get({
        address1,
        city,
        state,
        zip,
        country,
        items,
    }: {
        address1: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        items: any[];
    }) {
        try {
            if (
                this.isPreviousSame({
                    address1,
                    city,
                    state,
                    zip,
                    country,
                    items,
                })
            ) {
                return this.previous.res;
            }
            this.previous = { address1, city, state, zip, country, items };
            this.isRetrieving = true;
            this.retrieving$.next(true);
            const countryCode = isCountryCode(country) ? country : get(countriesByNameLower[country.toLowerCase()], 'code');
            duration.start();
            const res: any = await this.request({
                address1,
                city,
                state,
                zip,
                country: countryCode,
                items,
            });
            this.previous.res = res;
            this.isRetrieving = false;
            this.retrieving$.next(false);
            return res;
        } catch (err) {
            console.error(...new ExxComError(520394, scriptName, err).stamp());
        }
    }

    isPreviousSame({ address1, city, state, country, zip, items }) {
        try {
            const sameAddress1 = address1 == this.previous.address1;
            const sameCity = city == this.previous.city;
            const sameState = state == this.previous.state;
            const sameZip = zip == this.previous.zip;
            const sameCountry = country == this.previous.country;
            const sameItems = arrayOfObjectsEqual(this.previous.items, items);
            return sameAddress1 && sameCity && sameState && sameZip && sameCountry && sameItems;
        } catch (err) {
            console.error(...new ExxComError(523093, scriptName, err).stamp());
        }
    }

    private async request({ address1, city, state, zip, country, items }) {
        try {
            const req = { address1, city, state, zip, country, items };
            duration.start();
            const res = await apiService.post('easypost/shipping', req);
            if (!res.success || res.name == 'HttpErrorResponse') {
                res.success = false;
            }
            res.duration = duration.get();
            return res;
        } catch (err) {
            console.error(...new ExxComError(520394, scriptName, err).stamp());
            return { success: false };
        }
    }
}
