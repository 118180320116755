import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';

import { contains } from 'lib/tools';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { Observable } from 'rxjs';
const scriptName = 'universal.interceptor';

@Injectable()
export class UniversalInterceptor implements HttpInterceptor {
    constructor(
        @Optional() @Inject(REQUEST) protected request: Request,
        @Inject('environment') private environment: any
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        try {
            let newReq: HttpRequest<any> = req;
            if (contains(req.url, '.svg')) {
                const splitURL = req.url.split('assets')[1];
                const remoteURLexx = 'https://resources.exxactcorp.com/resources/exx/assets';
                const remoteURLspc = 'https://resources.sabrepc.com/resources/spc/assets';
                const remoteURLexc = 'https://resources.exxactcorp.com/resources/exc/assets';
                const remoteURL =
                    this.environment.siteAbbr === 'exx' ? remoteURLexx : this.environment.siteAbbr === 'spc' ? remoteURLspc : remoteURLexc;
                const newUrl = remoteURL.concat(splitURL);
                newReq = req.clone({ url: newUrl });
                return next.handle(newReq);
            }
            const isRelativeUrl = !contains(req.url, '//'); // There may be additional cases; currently ../assets/ is the only one.
            if (this.request && isRelativeUrl) {
                let newUrl = `${this.request.protocol}://${this.request.get('host')}`;
                if (!req.url.startsWith('/')) {
                    newUrl += '/';
                }
                newUrl += req.url.startsWith('../') ? req.url.slice(3) : req.url;
                newReq = req.clone({ url: newUrl });
            }
            return next.handle(newReq);
        } catch (err) {
            console.error(...new ExxComError(698822, scriptName, err).stamp());
        }
    }
}

// https://angular.io/guide/universal#using-absolute-urls-for-server-requests
