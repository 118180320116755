import { ExxComError } from 'lib/classes/exxcom-error.class';
import { contains, isBrowser } from 'lib/tools';
import { keyBy, isNil, isArray } from 'lodash';
import { RouterService } from 'lib/services/router.service';

const scriptName = 'category-facets.class';

let routerService: RouterService;

class CategoryFacet {
    isActive: boolean;
    count: number;
    label: string;
    type: string;
    value: string;
    low: string;
    high: string;

    constructor(props: any) {
        try {
            this.isActive = props.active;
            this.count = props.count;
            this.label = props.label;
            this.type = props.type;
            this.value = props.value;
            this.low = props.low;
            this.high = props.high;
        } catch (err) {
            console.error(...new ExxComError(520482, scriptName, err).stamp());
        }
    }
}

class CategoryGroup {
    field: string;
    facets: CategoryFacet[] = [];
    index: number;
    isCollapsed: boolean;
    isMaximized: boolean;
    label: string;
    scrollTop: number = 0;
    totalFacets: number;

    constructor(index: number, field: string, label: string, values: any) {
        try {
            this.field = field;
            this.index = index;
            this.label = label;
            this.totalFacets = values.length;
            if (isArray(values)) {
                this.facets = values.map((props: any) => new CategoryFacet(props));
            }
        } catch (err) {
            console.error(...new ExxComError(820396, scriptName, err).stamp());
        }
    }
}

export class CategoryFacets {
    active: any[] = [];
    groups: CategoryGroup[] = [];
    params: any[] = [];
    maximizedGroups: string[] = [];
    menuOpen: boolean = false;

    constructor(dependencies: any) {
        try {
            routerService = dependencies.routerService;
        } catch (err) {
            console.error(...new ExxComError(120932, scriptName, err).stamp());
        }
    }

    init(resFacets: any[]) {
        try {
            if (!resFacets || !Array.isArray(resFacets)) {
                return;
            }

            const prevComp = routerService.url.componentPrev;
            const currComp = routerService.url.component;
            if (!isNil(prevComp) && prevComp != currComp) {
                this.groups = [];
            }

            this.active = [];
            const newGroups = resFacets.map((group: any, i: number) => {
                const newGroup = new CategoryGroup(i, group.field, group.label, group.values);
                newGroup.facets.forEach((facet: any) => {
                    if (facet.isActive) {
                        this.active.push({ group: group, facet: facet });
                    }
                });
                return newGroup;
            });

            if (this.groups.length != 0) {
                const newGroupsKeyed = keyBy(newGroups, 'field');

                this.groups.forEach((oldGroup: CategoryGroup) => {
                    if (isBrowser()) {
                        const groupElem = document.getElementById(`category-facet-group-container-${oldGroup.index}`);
                        if (groupElem) {
                            groupElem.scrollTop = oldGroup.scrollTop;
                        }
                    }
                    const newGroup = newGroupsKeyed[oldGroup.field];
                    if (!newGroup) {
                        return;
                    }
                    newGroup.isMaximized = oldGroup.isMaximized;
                    newGroup.isCollapsed = oldGroup.isCollapsed;
                    Object.keys(oldGroup).forEach((oldKey: string) => (oldGroup[oldKey] = newGroup[oldKey]));
                });
            }
            this.groups = newGroups;
            // get filters from URL in case there's no results but still active facets
            if (isBrowser && this.active.length == 0) {
                const filters = window.location.search.split('?filters=')[1];
                const split = this.parseFilters(filters).split('&filter.');
                split.forEach((filter, idx) => {
                    if (filter != '') {
                        const newSplit = filter.split('=');
                        const facet = {
                            isActive: true,
                            count: 1,
                            label: newSplit[1],
                            type: 'value',
                            value: newSplit[1],
                        };
                        const group = new CategoryGroup(
                            idx,
                            newSplit[0],
                            newSplit[0][0].toUpperCase() + newSplit[0].substring(1, newSplit[0].length).split('_').join(' '),
                            facet
                        );
                        this.active.push({ facet, group });
                    }
                });
            }
        } catch (err) {
            console.error(...new ExxComError(789284, scriptName, err).stamp());
        }
    }

    toggleGroupCollapsed(index: number) {
        try {
            const group = this.groups[index];
            group.isCollapsed = !group.isCollapsed;
        } catch (err) {
            console.error(...new ExxComError(261672, scriptName, err).stamp());
        }
    }

    toggleGroupMaximized(index: number) {
        try {
            const group = this.groups[index];
            group.isMaximized = !group.isMaximized;
        } catch (err) {
            console.error(...new ExxComError(343415, scriptName, err).stamp());
        }
    }

    clearAll() {
        try {
            this.groups.forEach((group: any) => group.facets.forEach((facet: any) => (facet.isActive = false)));
        } catch (err) {
            console.error(...new ExxComError(204021, scriptName, err).stamp());
        }
    }

    // YANKED FROM CATEGORY-SERVICE, can't import the category service without import (this) through the dependencies
    /**
     * @function parseFilters
     * @param {String} filters A string list of the filters from the URL.
     * @description Parses the URL filters, which are generated by category.class
     * filter() when a filter is clicked. A previously generated URL can also be
     * passed directly into the browser address bar. This function parses the URL
     * filters that were generated in category.class, in order to prepare them to
     * be passed into the searchspring.service search function, which concatenates
     * the full URL for the API request.
     */
    private parseFilters(filters: any): string {
        try {
            if (!filters) {
                return '';
            }
            filters = decodeURIComponent(filters);
            filters = filters.split(';');
            let filterQueryString = '';
            filters.forEach((filter: any) => {
                if (!contains(filter, '::')) {
                    return;
                }
                filter = filter.split('::');
                const filterName = filter[0];
                const filterValues = filter[1].split(',').filter(Boolean);
                filterValues.forEach((value: string) => {
                    if (/^(\*?|0?\.?[0-9]{1,6})-(0?\.?[0-9]{1,6}|\*)$/.test(value)) {
                        const range = value.split('-');
                        filterQueryString += `&filter.${filterName}.low=${range[0]}&filter.${filterName}.high=${range[1]}`;
                    } else {
                        filterQueryString += `&filter.${filterName}=${value}`;
                    }
                });
            });
            return filterQueryString;
        } catch (err) {
            console.error(...new ExxComError(429093, scriptName, err).stamp());
        }
    }
}
