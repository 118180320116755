import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, ResolveFn, ActivatedRoute } from '@angular/router';
import { WebstoreProductService } from './webstore-product.service';

@Injectable()
export class ProductResolver {
    constructor(private productService: WebstoreProductService) {}

    resolve: ResolveFn<any> = (route: ActivatedRouteSnapshot) => {
        const currentUrlComponent = route.url[0].path;
        return this.productService.getProductByUrlComponent(currentUrlComponent, true);
    };
}
