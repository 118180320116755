import { Inject, Injectable } from '@angular/core';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { CmsService } from './cms.service';

const scriptName = 'cmsMarketoIDs.service';

@Injectable()
export class cmsMarketoIDsService {
    constructor(
        @Inject('environment') e: any,
        public cmsService: CmsService
    ) {}

    async getFormIDs() {
        try {
            return await this.cmsService.getEntry('marketo_forms');
        } catch (err) {
            console.error(...new ExxComError(321331, scriptName, err).stamp());
        }
    }
}
