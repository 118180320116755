<div id="auth-dialog" class="dialog" data-cy="spc-auth-dialog">
  <div class="dialog-top-row">
    <mat-icon class="dialog-close" [mat-dialog-close]="">close</mat-icon>
  </div>

  <div id="auth-dialog-content" class="fx-col fx-col-h-center pt-3">
    <img src="../../assets/images/logo-icon.svg" alt="logo icon" />

    <div *ngIf="type == 'customer'" id="auth-dialog-customer">
      <div *ngIf="isInitialized">
        <!-- Enables setting min-height on parent while initializing -->
        <h3 class="my-3 text-center">Welcome back.</h3>
        <app-auth-login
          authType="checkout"
          formClass="form-2"
          [dialogRef]="dialogRef"
          [showFormHeading]="false"
          [showLabels]="true"
        ></app-auth-login>
        <div class="mt-3 text-center font-xs">
          <span class="auth-dialog-member-question">Not a member? </span>
          <a class="link-purple" routerLink="/account/register" (click)="dialogRef.close()">Join now.</a>
        </div>
        <hr class="divider mt-3 mb-3" />
        <button class="button-secondary button-base" data-cy="spc-guest-checkout-button" (click)="type = 'guest'">
          Guest checkout
        </button>
      </div>
    </div>

    <div *ngIf="isInitialized && type == 'guest'" id="auth-dialog-guest">
      <!-- min-height not required because not displayed until button click -->
      <h3 *ngIf="isInitialized" class="my-3 text-center">Nice to meet you.</h3>
      <app-auth-register
        authType="checkout"
        formClass="form-2"
        registrationType="guest"
        [dialogRef]="dialogRef"
        [showFormHeading]="false"
        [showLabels]="true"
      ></app-auth-register>
      <div class="mt-3 mb-1 text-center font-xs">
        <span class="auth-dialog-member-question">Already a member? </span>
        <span class="link-purple" (click)="type = 'customer'">Sign in.</span>
      </div>
    </div>
  </div>
</div>
