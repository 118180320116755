import { Inject, Injectable } from '@angular/core';

import { ApiService } from 'lib/services/api.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { isBrowser, timestamp } from 'lib/tools';

const scriptName = 'grecaptcha.service';

declare let grecaptcha: any;
// https://developers.google.com/recaptcha/docs/v3

@Injectable()
export class GrecaptchaService {
    consoleErrorMessage: string = 'reCAPTCHA verification failed';
    uiErrorMessage: string = 'Validation error: Please refresh the page and try again.';

    constructor(
        @Inject('environment') private environment,
        private apiService: ApiService
    ) {}

    async getToken(action: string): Promise<string> {
        try {
            if (!isBrowser()) {
                return;
            }
            const subdomain = window.location.hostname.split('.')[0];
            action = `${this.environment.siteAbbr}_${subdomain}_${action}`;
            return grecaptcha.execute(this.environment.google.grecaptchaSiteKey, { action: action });
        } catch (err) {
            console.error(...new ExxComError(698822, scriptName, err).stamp());
        }
    }

    async isValid(action: string) {
        try {
            const token = await this.getToken(action);
            const res = await this.verify(token);
            return res.isValid;
        } catch (err) {
            console.error(...new ExxComError(304998, scriptName, err).stamp());
        }
    }

    private async verify(token: string) {
        try {
            const res = await this.apiService.get(`grecaptcha/verify/${token}/${this.environment.siteAbbr}`);
            if (!res.success) {
                console.error(timestamp(), scriptName, 293495, res);
            } else {
                return res.data;
            }
        } catch (err) {
            console.error(...new ExxComError(823994, scriptName, err).stamp());
        }
    }
}
