<div id="cms-applications-forms">
  <section class="fx-row-container fx-wrap pt-3 pb-2">
    <h4 id="app-forms-top-label" class="col-sm-12 col-lg-12">{{ entry?.top_label }}</h4>
    <h1 class="col-sm-12 col-lg-12 pl-0">{{ entry?.title }}</h1>
  </section>

  <hr class="fx-row-container fx-wrap" />

  <section id="applications-forms-app-form-item-section" class="fx-row-container fx-wrap pt-2">
    <div *ngFor="let appFormItem of entry.list" class="applications-forms-app-form-item fx-row-h-left">
      <p class="applications-forms-item-header">{{ appFormItem?.name }}</p>
      <a class="applications-forms-item-link" aria-label="Download" download [href]="get(appFormItem, 'link.href', '')">
        Download
      </a>
    </div>
  </section>
</div>
