import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-popup-dialog',
    templateUrl: './popup-dialog.component.html',
    styleUrls: ['./popup-dialog.component.scss'],
})
export class PopupDialogComponent {
    @ContentChild(TemplateRef) inputTemplate: TemplateRef<any>;
    @Input() modalRef;
    @Input() site: 'exx' | 'spc' = 'exx';

    constructor(public bsModalRef: BsModalRef) {}
}
