import { Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { BreakpointObserver } from '@angular/cdk/layout';
import { CmsPartialComponentClass } from 'lib/components/cms/cms-partial-component.class';
import { RouterService } from 'lib/services/router.service';
import { WebpService } from 'lib/services/webp.service';
import { CategoryService } from 'lib/services/category/category.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';

const scriptName = 'cms-partial-category-top-image-and-text';
@Component({
    selector: 'cms-partial-category-top-image-and-text',
    templateUrl: './image-and-text.component.html',
    styleUrls: ['./image-and-text.component.scss'],
})
export class ImageAndTextComponent extends CmsPartialComponentClass {
    @ViewChild('buttonRowImageElement') buttonRowImageElement: ElementRef;
    @Input() imageAndTextRow: any;
    @Input() grayBackgroundRows: any = [];
    activeButtonId: string = '';
    currentButtonRowImage: string = '';
    currentButtonRowImageAlt: string = '';
    @Input() index: number;
    getWebpImg: (src: string) => string;

    constructor(
        @Inject('environment') environment: any,
        breakpointObserver: BreakpointObserver,
        routerService: RouterService,
        webpService: WebpService,
        location: Location,
        categoryService: CategoryService
    ) {
        super({
            dependencies: {
                breakpointObserver,
                environment,
                routerService,
                location,
                categoryService,
            },
        });
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
    }
    /**
     *
     * @param i index from ngFor directive. Each index represents a new button with different text/image
     * @param buttonRowImage the image associated with the button prcess
     * @returns new image and active button
     */
    setActiveButtonAndImage(i: number, buttonRowImage: string) {
        try {
            const activeButtonPressed = `button-${i}`;
            this.activeButtonId = activeButtonPressed;

            if (buttonRowImage === this.currentButtonRowImage || '') {
                return;
            } else {
                this.buttonRowImageElement.nativeElement.className = 'opacity-on';
                setTimeout(() => {
                    this.currentButtonRowImage = buttonRowImage;
                    this.currentButtonRowImageAlt = this.imageAndTextRow?.image_and_text?.image_and_text[0]?.button_row[i]?.button_image?.title;
                    this.buttonRowImageElement.nativeElement.className = 'opacity-off';
                }, 250);
            }
        } catch (err) {
            console.error(...new ExxComError(923111, scriptName, err).stamp());
        }
    }
}
