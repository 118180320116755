<div *ngIf="pagination.totalPages > 1" class="category-pagination fx-row fx-row-h-center fx-row-v-center">
  <div *ngFor="let control of pagination.controls" class="category-pagination-item fx-col fx-col-v-center">
    <a
      *ngIf="control.isArrow"
      class="category-pagination-arrow fx-row fx-row-v-center fx-row-h-center"
      [class.disabled]="!control.isActive"
      (click)="
        control.direction == 'right' ? paginate(pagination.next.queryParams) : paginate(pagination.previous.queryParams)
      "
    >
      <mat-icon>{{ "chevron_" + control.direction }}</mat-icon>
    </a>

    <span *ngIf="control.isEllipsis" class="category-pagination-ellipsis fx-row fx-row-h-center fx-row-v-center">
      &hellip;
    </span>

    <a
      *ngIf="control.isNumber"
      class="category-pagination-number fx-row fx-row-h-center fx-row-v-center"
      [class.active]="control.isActive"
      (click)="paginate(control.queryParams)"
    >
      <span>{{ control.number }}</span>
    </a>
  </div>
</div>
