<ng-container *ngIf="!isEmpty(get(tableRow, 'tables', ''))">
  <div *ngFor="let row of get(tableRow, 'tables.tables', []); let i = index">
    <section id="rows" class="d-flex flex-column align-items-center container-gridless">
      <div class="category-top-title d-flex flex-row justify-content-center pb-2">
        <h2 *ngIf="get(row, 'headline', '')" class="col-md-7 text-center">
          {{ get(row, "headline", "") }}
        </h2>
      </div>
      <div class="px-2" id="tables-partial" [innerHTML]="get(row, 'table_content', '') | safe: 'html'"></div>
    </section>
  </div>
</ng-container>
<hr *ngIf="get(tableRow, 'tables.split_line', '')" class="m-0" />
