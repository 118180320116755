<ng-container *ngIf="!isEmpty(get(multipleColumnsRow, 'multi_columns', ''))">
    <div *ngFor="let row of get(multipleColumnsRow, 'multi_columns.multi_columns', [])">
        <section id="rows" *ngIf="!isEmpty(get(row, 'columns'))" class="container">
            <div class="multi-column-container">
                <div class="multi-column-title">
                    <h2 class="text-center title">
                        {{ get(row, 'multicolumn_content_title', '') }}
                    </h2>
                    <p class="multi-column-subtitle my-0 text-center">
                        {{ get(row, 'subtitle', '') }}
                    </p>
                </div>
                <div class="multi-columns-sub-container" [ngClass]="'column-' + get(row, 'columns_number')">
                    <div *ngFor="let column of get(row, 'columns', '')" class="multi-column" [class.card]="get(column, 'enable_card', '')">
                        <div class="image-container">
                            <img
                                *ngIf="!get(column, 'image_enlargement') && !get(column, 'image_link.routerLink', '')"
                                [src]="getWebpImg(get(column, 'image.href', ''))"
                                [alt]="get(column, 'image.title', '')"
                            />

                            <a
                                *ngIf="get(column, 'image_link.routerLink', '') && !get(column, 'image_enlargement')"
                                [href]="get(column, 'image_link.routerLink', '')"
                            >
                                <img [src]="getWebpImg(get(column, 'image.href', ''))" [alt]="get(column, 'image.title', '')" />
                            </a>
                        </div>

                        <app-image-zoom
                            [image]="getWebpImg(get(column, 'image.href', ''))"
                            [altText]="'test'"
                            style="width: 100%"
                            *ngIf="get(column, 'image_enlargement')"
                        >
                            <div class="image-container">
                                <img
                                    *ngIf="get(column, 'image_enlargement')"
                                    [src]="getWebpImg(get(column, 'image.href', ''))"
                                    [alt]="get(column, 'image.title', '')"
                                />
                            </div>
                        </app-image-zoom>

                        <div class="text-components-container">
                            <h3
                                *ngIf="get(column, 'column_title_group', '')"
                                class="column-title w-100"
                                [ngStyle]="{
                                    'text-align': get(column, 'column_title_group.column_title_alignment', '')
                                }"
                            >
                                {{ get(column, 'column_title_group.column_title', '') }}
                            </h3>
                            <p class="column-copy" *ngIf="get(column, 'column_copy_group.enable_copy', '')">
                                {{ get(column, 'column_copy_group.column_copy', '') }}
                            </p>
                            <button
                                *ngIf="get(column, 'column_button.enable_button', '')"
                                class="button-primary button-base d-flex align-items-center w-100 learn-more-button"
                                [routerLink]="get(column, 'column_button.routerLink', '')"
                            >
                                {{ get(column, 'column_button.link.title', '') }}
                                <mat-icon>chevron_right</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</ng-container>
<hr *ngIf="get(multipleColumnsRow, 'multi_columns.split_line', '')" class="m-0" />
