<div id="footer">
    <section *ngIf="!isNarrow" id="footer-row-1" class="accordion" data-cy="spc-footer-desktop">
        <div class="footer-link-menu-container accordion-item" *ngFor="let group of entry.sections; index as i">
            <div class="title accordion-header">
                <span>{{ group.section_title }}</span>
                <mat-icon svgIcon="triangle"></mat-icon>
            </div>

            <div>
                <div class="footer-links-container" *ngFor="let subsection of group.group">
                    <a data-cy="footer-internal-link-desktop" *ngIf="!subsection.is_external_link" [routerLink]="subsection.link.href">{{
                        subsection.link?.title
                    }}</a>

                    <a data-cy="footer-external-link-desktop" *ngIf="subsection.is_external_link" [href]="get(subsection, 'link.href', '/')">{{
                        subsection.link?.title
                    }}</a>
                </div>
            </div>
        </div>
    </section>

    <section *ngIf="isNarrow" id="footer-row-1" data-cy="spc-footer-mobile">
        <div class="accordion">
            <div class="accordion-item" *ngFor="let group of entry.sections; index as i">
                <h2 class="accordion-header" id="{{ 'heading-' + i }}">
                    <div
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#collapse-' + i"
                        aria-expanded="false"
                        [attr.aria-controls]="'collapse-' + i"
                    >
                        <span>{{ group.section_title }}</span>
                    </div>
                </h2>
                <div id="{{ 'collapse-' + i }}" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading-' + i">
                    <div class="accordion-body">
                        <ng-container *ngFor="let subsection of group.group">
                            <a data-cy="footer-internal-link-desktop" *ngIf="!subsection.is_external_link" [routerLink]="subsection.link.href">{{
                                subsection.link?.title
                            }}</a>

                            <a
                                data-cy="footer-external-link-desktop"
                                *ngIf="subsection.is_external_link"
                                [href]="get(subsection, 'link.href', '/')"
                                >{{ subsection.link?.title }}</a
                            >
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Row 2 is call, times, social media, sign up for news letter -->
    <div id="footer-row-2">
        <div class="col-lg-8 info p-0">
            <div class="contact-info">
                <span class="pr-1">Call</span>
                <span>408.914.8196</span>
            </div>
            <span class="vertical-bar">|</span>
            <div class="contact-info">
                <span class="pr-1">Open</span>
                <span>Mon - Fri 9am - 5:30pm PST</span>
            </div>
            <span class="vertical-bar">|</span>
            <div class="contact-info">
                <a class="mx-1" routerLink="/Contact-Us">
                    <i data-feather="mail"></i>
                </a>
                <a class="mx-1" href="https://twitter.com/sabrepc">
                    <mat-icon class="filled-icon" svgIcon="x-logo"></mat-icon>
                </a>
                <a class="mr-1 ml-base" href="https://www.facebook.com/SabrePC/">
                    <i data-feather="facebook"></i>
                </a>
            </div>
            <span class="vertical-bar">|</span>
        </div>
        <div class="fx-row fx-row-h-center">
            <a class="button-5 button-base" (click)="bootstrapMarketoService.openBootstrapMarketoDialog(marketoFormId, 'edit')">
                <i data-feather="send"></i>
                <span>Sign up to get the best deals.</span>
            </a>
        </div>
    </div>

    <section id="footer-row-3">
        <div class="privacy-policy">
            <span>Copyright © {{ currentYear }} SabrePC Inc.</span>
            <span>All rights reserved.</span>

            <span class="vertical-bar mx-1">|</span>
            <a routerLink="/privacy" class="link-gray-inverted">Privacy</a>
            <span class="vertical-bar mx-1">|</span>
            <a routerLink="/cookies" class="link-gray-inverted">Cookies</a>
            <span class="vertical-bar mx-1">|</span>
            <a class="link-gray-inverted" href="#" onclick="window.displayPreferenceModal();return false;" id="termly-consent-preferences"
                >Consent Preferences</a
            >
        </div>
        <img src="../../assets/images/godaddy-en-ssl.png" alt="godaddy-en-ssl" />
    </section>

    <section id="footer-row-4" class="fx-col fx-col-h-center fx-col-v-center" data-cy="spc-footer-scroll-top" (click)="scrollToTop()">
        <mat-icon>chevron_right</mat-icon>
        <span>BACK TO TOP</span>
    </section>
</div>
