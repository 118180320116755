import { Inject, Injectable, Optional } from '@angular/core';

import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';

import { ApiService } from 'lib/services/api.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { isBrowser } from 'lib/tools';
import { RouterService } from 'lib/services/router.service';
import { Subject } from 'rxjs';

const scriptName = 'auth.service';

let apiService: ApiService;

@Injectable()
export class AuthService {
    private baseUrl: string = '';

    login$: Subject<void> = new Subject<void>();
    logout$: Subject<void> = new Subject<void>();

    constructor(
        @Optional() @Inject(REQUEST) protected req: Request,
        a: ApiService,
        r: RouterService
    ) {
        try {
            apiService = a;
            this.baseUrl = `${r.baseUrl}/auth`;
        } catch (err) {
            console.error(...new ExxComError(738774, scriptName, err).stamp());
        }
    }

    async register(values: any): Promise<any> {
        try {
            if (!isBrowser()) {
                return { success: false, isServer: true };
            }
            return await apiService.post(`${this.baseUrl}/register`, values, {
                useFullUrl: true,
            });
        } catch (err) {
            console.error(...new ExxComError(520948, scriptName, err).stamp());
        }
    }

    async login(values: any): Promise<any> {
        try {
            if (!isBrowser()) {
                return { success: false, isServer: true };
            }
            const res = await apiService.post(`${this.baseUrl}/login`, values, {
                useFullUrl: true,
            });
            if (res.success) {
                this.login$.next();
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(120032, scriptName, err).stamp());
        }
    }

    async password(values: any): Promise<any> {
        try {
            if (!isBrowser()) {
                return { success: false, isServer: true };
            }
            return await apiService.post(`${this.baseUrl}/reset-password`, values, { useFullUrl: true });
        } catch (err) {
            console.error(...new ExxComError(723094, scriptName, err).stamp());
        }
    }

    async comparePassword(values: any): Promise<any> {
        try {
            if (!isBrowser()) {
                return { success: false, isServer: true };
            }
            return await apiService.post(`${this.baseUrl}/compare-password`, values, { useFullUrl: true });
        } catch (err) {
            console.error(...new ExxComError(623094, scriptName, err).stamp());
        }
    }

    logout(): void {
        try {
            this.logout$.next();
        } catch (err) {
            console.error(...new ExxComError(902984, scriptName, err).stamp());
        }
    }
}
