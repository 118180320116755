import { Inject, Injectable, Optional } from '@angular/core';

import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';
import { get } from 'lodash';

import * as moment from 'moment';
import { ApiService } from 'lib/services/api.service';
import { AuthService } from 'lib/services/auth.service';
import { cookies, getEmailFromBrowser, isBrowser, saveEmailInBrowser } from 'lib/tools';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { RouterService } from './router.service';

const scriptName = 'session.service';

let apiService: ApiService;
let authService: AuthService;
let routerService: RouterService;
let self: SessionService;

const reset = function (event: Event) {
    event.stopPropagation();
    self.reset();
};

@Injectable()
export class SessionService {
    private monitor: any;
    private monitoring: boolean = false;
    private monitorInterval: number = 5; // seconds
    private resetOnAction: boolean = true;
    private resetOnActionPause: number = 5; // seconds
    private resetOnActionTimeout: any;

    authBaseUrl: string = ''; // Set in auth.service constructor

    constructor(
        @Optional() @Inject(REQUEST) protected req: Request,
        @Inject('environment') private environment: any,
        ap: ApiService,
        au: AuthService,
        r: RouterService
    ) {
        try {
            apiService = ap;
            authService = au;
            routerService = r;
            self = this;
            this.authBaseUrl = `${r.baseUrl}/auth`;
        } catch (err) {
            console.error(...new ExxComError(520934, scriptName, err).stamp());
        }
    }

    async init() {
        try {
            authService.logout$.subscribe(() => this.stop());
            if (await this.isValidSession()) {
                this.start();
            }
        } catch (err) {
            console.error(...new ExxComError(203988, scriptName, err).stamp());
        }
    }

    // Main actions

    set(idToken: string, expiresIn: any, email?: string) {
        try {
            if (isBrowser()) {
                const expiresAt: any = this.calcExpiry(expiresIn);
                localStorage.setItem('idToken', idToken);
                localStorage.setItem('expiresAt', expiresAt);
                cookies.set('idToken', idToken, 1);
                cookies.set('expiresAt', expiresAt, 1);
                if (email) {
                    saveEmailInBrowser(email);
                }
            }
            return { start: () => this.start() };
        } catch (err) {
            console.error(...new ExxComError(520938, scriptName, err).stamp());
        }
    }

    start() {
        try {
            if (!isBrowser() || !this.isValidLocalSessionData() || this.monitoring) {
                return;
            }
            this.monitoring = true;
            document.addEventListener('click', reset);
            this.monitor = setInterval(async () => {
                const isValid = await this.isValidSession();
                if (isValid) {
                    return;
                }
                this.stop();

                if (this.environment.siteAbbr == 'exc') {
                    // allows the user to be navigated to the URL they were last out before timeout
                    localStorage.setItem('cachedUrl', routerService.url.current);
                    routerService.router.navigateByUrl('/login');
                } else {
                    routerService.router.navigateByUrl('/account/login');
                }
            }, this.monitorInterval * 1000);
        } catch (err) {
            console.error(...new ExxComError(320938, scriptName, err).stamp());
        }
    }

    async reset() {
        try {
            if (!isBrowser() || this.resetOnAction) {
                return;
            }
            const res = await this.resetSession();
            this.set(res.data.expiresIn, res.data.idToken);
            this.resetOnAction = false;
            this.resetOnActionTimeout = setTimeout(() => (this.resetOnAction = true), this.resetOnActionPause * 1000);
        } catch (err) {
            console.error(...new ExxComError(509383, scriptName, err).stamp());
        }
    }

    stop() {
        try {
            if (isBrowser()) {
                clearTimeout(this.resetOnActionTimeout);
                clearInterval(this.monitor);
                document.removeEventListener('click', reset);
                this.monitoring = false;
                localStorage.removeItem('idToken');
                localStorage.removeItem('expiresAt');
                localStorage.removeItem('isGuestSession');
                cookies.remove('idToken');
                cookies.remove('expiresAt');
                cookies.remove('isGuestSesion');
            } else {
                if (get(this.req, 'cookies')) {
                    delete this.req.cookies.idToken;
                    delete this.req.cookies.expiresAt;
                    delete this.req.cookies.email;
                }
            }
        } catch (err) {
            console.error(...new ExxComError(302988, scriptName, err).stamp());
        }
    }

    // Expiry

    private calcExpiry(expiresIn: any) {
        try {
            const time = expiresIn.slice(0, -1);
            const unit = expiresIn.slice(-1);
            return JSON.stringify(moment().add(time, unit).valueOf());
        } catch (err) {
            console.error(...new ExxComError(792883, scriptName, err).stamp());
        }
    }

    private getExpiry() {
        try {
            const expiresAt = isBrowser() ? localStorage.getItem('expiresAt') : get(this.req, 'cookies.expiresAt');
            return (expiresAt && moment(parseInt(expiresAt))) || null;
        } catch (err) {
            if (err.message != 'The operation is insecure.') {
                console.error(...new ExxComError(310299, scriptName, err).stamp());
            }
            return null;
        }
    }

    // Validity

    async isValidSession() {
        try {
            const isAuthorized = await this.isAuthorized();
            const isValid = this.isValidLocalSessionData() && isAuthorized;
            return isValid;
        } catch (err) {
            console.error(...new ExxComError(634949, scriptName, err).stamp());
            return false;
        }
    }

    isValidLocalSessionData() {
        try {
            let hasEmail: boolean;
            let hasIdToken: boolean;
            if (isBrowser()) {
                hasEmail = !!localStorage.getItem('email');
                hasIdToken = !!localStorage.getItem('idToken');
            } else {
                hasEmail = get(this.req, 'cookies.email');
                hasIdToken = get(this.req, 'cookies.idToken');
            }
            const isValidLocalSessionData = this.isValidExpiry() && hasEmail && hasIdToken;
            return isValidLocalSessionData;
        } catch (err) {
            console.error(...new ExxComError(329838, scriptName, err).stamp());
        }
    }

    isValidExpiry() {
        try {
            const expiry = this.getExpiry();
            const isValidExpiry = !!expiry && moment().isBefore(expiry);
            return isValidExpiry;
        } catch (err) {
            console.error(...new ExxComError(520753, scriptName, err).stamp());
        }
    }

    // Endpoint accessors

    private async resetSession(): Promise<any> {
        try {
            if (!isBrowser()) {
                return false;
            }
            const values = { email: getEmailFromBrowser() };
            return await apiService.post(`${this.authBaseUrl}/reset-session`, values, { useFullUrl: true });
        } catch (err) {
            console.error(...new ExxComError(902984, scriptName, err).stamp());
            return false;
        }
    }

    private async isAuthorized(): Promise<boolean> {
        try {
            if (!isBrowser()) {
                return false;
            }
            const res: any = await apiService.get(`${this.authBaseUrl}/authorize`, { useFullUrl: true });
            if (!res.success) {
                throw res;
            }
            const isAuthorized = res.message != 'Unauthorized';
            return isAuthorized;
        } catch (err) {
            console.error(...new ExxComError(732888, scriptName, err).stamp());
            return false;
        }
    }

    // Guest

    setIsGuestSession(isGuest: boolean) {
        try {
            if (!isBrowser()) {
                return;
            }
            localStorage.setItem('isGuestSession', isGuest.toString());
            cookies.set('isGuestSession', isGuest.toString(), 1);
        } catch (err) {
            console.error(...new ExxComError(692944, scriptName, err).stamp());
        }
    }

    isGuestSession() {
        try {
            if (isBrowser()) {
                return localStorage.getItem('isGuestSession') == 'true';
            } else {
                get(this.req, 'cookies.isGuestSession') == 'true';
            }
        } catch (err) {
            console.error(...new ExxComError(902883, scriptName, err).stamp());
        }
    }
}
