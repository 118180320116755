import { debounce, get } from 'lodash';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { getStyleIntValue, isBrowser, wait } from 'lib/tools';

const scriptName = 'header-menu-narrow.class';

export class HeaderMenuNarrow {
    currentCategory: any;
    currentLevel: number = 0;
    previousCategory: any;
    menuOpen: boolean = false;

    constructor(
        private isNarrow: boolean,
        private elems: any
    ) {}

    init() {
        try {
            if (!isBrowser()) {
                return;
            }
            this.calculateHeight();
            // Debounce to prevent too many function calls if a user clicks excessively
            this.open = debounce(this.open, 500, { leading: true });
            this.next = debounce(this.next, 500, { leading: true });
            this.prev = debounce(this.prev, 500, { leading: true });
            this.close = debounce(this.close, 500, { leading: true });
            // Close on click away from menu
            document.addEventListener('click', (event: MouseEvent) => this.closeOnClickAway(event));
        } catch (err) {
            console.error(...new ExxComError(444268, scriptName, err).stamp());
        }
    }

    /**
     * closeOnClickAway() has to be triggered before prev(), which is why prev()
     * is wrapped in a setTimeout. If closeOnClickAway() is triggered after
     * prev(), then, because prev() changes the currentLevel, the Angular *ngIf
     * has already removed the elements, so the JavaScript contains cannot find
     * them.
     */
    private closeOnClickAway(event: MouseEvent) {
        try {
            if (!this.isNarrow || this.currentLevel == 0) {
                return;
            }
            const target: any = event.target;
            const menu = document.getElementById('header-menu-narrow');
            if (menu != target && !menu.contains(target)) {
                this.menuOpen = false;
                this.close();
            }
        } catch (err) {
            console.error(...new ExxComError(836799, scriptName, err).stamp());
        }
    }

    toggle() {
        try {
            this.currentLevel == 0 ? this.open() : this.close();
            // this.menuOpen = !this.menuOpen;
        } catch (err) {
            console.error(...new ExxComError(904004, scriptName, err).stamp());
        }
    }

    open() {
        try {
            this.menuOpen = true;
            this.currentLevel = 1;
            this.elems.toggle.nativeElement.classList.add('open');
            this.elems.level1.nativeElement.classList.add('open');
            document.getElementsByTagName('html')[0].style.overflow = 'hidden';
            document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        } catch (err) {
            console.error(...new ExxComError(247432, scriptName, err).stamp());
        }
    }

    next(target: HTMLElement, currentCategory: any) {
        try {
            this.reset();
            if (this.currentLevel < 3) {
                this.currentLevel++;
            }
            this.setLevelsOverflowY();
            let classList;
            if (target.tagName == 'svg') {
                classList = get(target, 'parentElement.parentElement.parentElement.children[1].classList');
            } else if (target.tagName == 'polygon') {
                classList = get(target, 'parentElement.parentElement.parentElement.parentElement.children[1].classList');
            } else {
                classList = get(target, 'parentElement.children[1].classList');
            }
            if (!classList) {
                return;
            }
            classList.add('open');
            target.parentElement.parentElement.parentElement.children[1].classList.add('open');
            this.previousCategory = this.currentCategory;
            this.currentCategory = currentCategory;
        } catch (err) {
            console.error(...new ExxComError(145266, scriptName, err).stamp());
        }
    }

    async prev() {
        try {
            await wait('');
            if (this.currentLevel == 1) {
                return;
            }
            const openElem = document.getElementsByClassName(`header-menu-narrow-level${this.currentLevel} open`)[0];
            openElem.classList.remove('open');
            this.currentLevel--;
            this.setLevelsOverflowY();
            this.currentCategory = this.previousCategory;
        } catch (err) {
            console.error(...new ExxComError(743331, scriptName, err).stamp());
        }
    }

    async close() {
        try {
            this.menuOpen = false;
            this.elems.toggle.nativeElement.classList.remove('open');
            if (this.currentLevel == 1) {
                const level1Elem = this.getOpenElemAtLevel(1);
                level1Elem.classList.remove('open');
            } else if (this.currentLevel == 2) {
                // Get the elements first, and then remove open from level 1 in order to trigger the transition
                const level1Elem = this.getOpenElemAtLevel(1);
                const level2Elem = this.getOpenElemAtLevel(2);
                level1Elem.classList.remove('open');
                await wait('400ms');
                // Hide the elements after the level 1 transition, turn off their
                // transition, remove .open from them, and then reset their transition and visibility
                level2Elem.style.visibility = 'hidden';
                level2Elem.style.transition = 'none';
                level2Elem.classList.remove('open');
                level2Elem.style.visibility = '';
                level2Elem.style.transition = '';
            } else if (this.currentLevel == 3) {
                const level1Elem = this.getOpenElemAtLevel(1);
                const level2Elem = this.getOpenElemAtLevel(2);
                const level3Elem = this.getOpenElemAtLevel(3);
                level1Elem.classList.remove('open');
                await wait('400ms');
                level2Elem.style.visibility = 'hidden';
                level3Elem.style.visibility = 'hidden';
                level2Elem.style.transition = 'none';
                level3Elem.style.transition = 'none';
                level2Elem.classList.remove('open');
                level3Elem.classList.remove('open');
                level2Elem.style.visibility = '';
                level3Elem.style.visibility = '';
                level2Elem.style.transition = '';
                level3Elem.style.transition = '';
            }
            this.currentLevel = 0;
            document.getElementsByTagName('html')[0].style.overflow = '';
            document.getElementsByTagName('body')[0].style.overflow = '';
        } catch (err) {
            console.error(...new ExxComError(200929, scriptName, err).stamp());
        }
    }

    private getElemsAtLevel(level: number) {
        try {
            if (level == 1) {
                return this.elems.level1.nativeElement;
            } else {
                return this.elems[`level${level}`].toArray().map((elem: any) => elem.nativeElement);
            }
        } catch (err) {
            console.error(...new ExxComError(456784, scriptName, err).stamp());
        }
    }

    private getOpenElemAtLevel(level: number) {
        try {
            if (level == 1) {
                return this.elems.level1.nativeElement;
            } else {
                const elem = this.getElemsAtLevel(level).find((elem: HTMLElement) => elem.classList.contains('open'));
                return elem;
            }
        } catch (err) {
            console.error(...new ExxComError(294993, scriptName, err).stamp());
        }
    }

    private reset() {
        try {
            const menuLevel1 = document.querySelector('.header-menu-narrow-level1');
            menuLevel1.scrollTop = 0;
        } catch (err) {
            console.error(...new ExxComError(500503, scriptName, err).stamp());
        }
    }

    private setLevelsOverflowY() {
        try {
            if (this.currentLevel == 1) {
                this.elems.level1.nativeElement.style.overflowY = 'scroll';
                this.getElemsAtLevel(2).forEach((elem: any) => (elem.style.overflowY = 'hidden'));
                this.getElemsAtLevel(3).forEach((elem: any) => (elem.style.overflowY = 'hidden'));
            } else if (this.currentLevel == 2) {
                this.elems.level1.nativeElement.style.overflowY = 'hidden';
                this.getElemsAtLevel(2).forEach((elem: any) => (elem.style.overflowY = 'scroll'));
                this.getElemsAtLevel(3).forEach((elem: any) => (elem.style.overflowY = 'hidden'));
            } else if (this.currentLevel == 3) {
                this.elems.level1.nativeElement.style.overflowY = 'hidden';
                this.getElemsAtLevel(2).forEach((elem: any) => (elem.style.overflowY = 'hidden'));
                this.getElemsAtLevel(3).forEach((elem: any) => (elem.style.overflowY = 'scroll'));
            }
        } catch (err) {
            console.error(...new ExxComError(945300, scriptName, err).stamp());
        }
    }

    calculateHeight() {
        try {
            const windowHeight = window.innerHeight;

            const header = document.getElementById('header');
            const headerHeight = header.offsetHeight;

            const headerRow2 = document.getElementById('header-row2');
            const headerRow2BorderBottomHeight = getStyleIntValue(headerRow2, 'border-bottom-width');

            const calculatedHeight = windowHeight - headerHeight - headerRow2BorderBottomHeight + 2 + 'px';

            const level1 = this.getElemsAtLevel(1);
            level1.style.height = calculatedHeight;

            const level3 = this.getElemsAtLevel(3);
            level3.forEach((elem: any) => (elem.style.height = calculatedHeight));
        } catch (err) {
            console.error(...new ExxComError(492998, scriptName, err).stamp());
        }
    }
}
