<head>
  <!-- Marketo Munchkin -->
  <script src="https://munchkin.marketo.net/munchkin.js"></script>
  <!-- End Marketo Munchkin -->
</head>
<div id="category" (window:resize)="onResize()" data-cy="category">
  <app-cms-partial-category-top
    [data]="cmsPartialCategoryTopData"
    [grayBackgroundRows]="grayBackgroundRows"
    data-cy="cms-partial-category-top"
  >
  </app-cms-partial-category-top>

  <div *ngIf="!isLandingPage" data-cy="search-spring-listings">
    <div id="category-header">
      <!-- BREADCRUMB -->

      <div id="category-breadcrumb" data-cy="spc-breadcrumb">
        <span *ngIf="category.breadcrumb.part1">
          <span> {{ category.breadcrumb.part1.name }} </span>
        </span>
        <span *ngIf="category.breadcrumb.part2">
          &nbsp;/&nbsp;
          <a
            [routerLink]="'/category/' + category.breadcrumb.part2.href"
            class="link-gray-inverted"
            [class.highlight]="currentCategoryBreadcrumbPart == category.breadcrumb.part2.name"
          >
            {{ category.breadcrumb.part2.name }}
          </a>
        </span>
        <span *ngIf="category.breadcrumb.part3">
          &nbsp;/&nbsp;
          <a
            [routerLink]="'/category/' + category.breadcrumb.part3.href"
            class="link-gray-inverted"
            [class.highlight]="currentCategoryBreadcrumbPart == category.breadcrumb.part3.name"
          >
            {{ category.breadcrumb.part3.name }}
          </a>
        </span>
      </div>
    </div>

    <section id="category-body" class="fx-row fx-wrap">
      <app-category-no-results
        *ngIf="category.isInitialized && category.products.length == 0 && category.facets.active.length == 0"
        [category]="category"
      ></app-category-no-results>

      <!-- FACETS -->

      <div
        *ngIf="category.products.length > 0 || category.facets.active.length != 0"
        id="category-body-column-1"
        class="col-lg-1_5"
        #facetsNarrowMenu
        [class.open]="category.facets.menuOpen"
        (wheel)="preventMobileDefaultScroll($event)"
        data-cy="category-filters"
      >
        <div id="category-facets-container">
          <!-- FACETS HEADER -->

          <div id="category-facets-header" class="fx-row fx-row-v-bottom">Refine your results.</div>

          <!-- FACETS CONTROLS ACTIVE -->

          <div *ngIf="category.isFiltered" id="category-facets-controls" data-cy="sidebar-filter-container">
            <div
              *ngFor="let activeFacet of category.facets.active"
              class="category-facets-controls-facet tag-gray fx-row fx-row-h-space-between fx-row-v-center"
              (click)="filter(activeFacet.group, activeFacet.facet); $event.stopPropagation()"
              data-cy="sidebar-filter-item"
            >
              <span>{{ activeFacet.group.label }}: {{ activeFacet.facet.label }}</span>
              <mat-icon>close</mat-icon>
            </div>

            <button
              class="tag-purple"
              (click)="clearAllFilters(); $event.stopPropagation()"
              data-cy="sidebar-clear-all-button"
            >
              Clear All
            </button>
          </div>

          <!-- FACETS -->

          <div id="category-facets" data-cy="spc-category-facet-group-parent">
            <div class="category-facet-group" *ngFor="let facetGroup of category.facets.groups">
              <div
                class="category-facet-header fx-row fx-row-h-space-between fx-row-v-center"
                (click)="toggleFacetGroupCollapsed(facetGroup.index)"
                data-cy="spc-category-facet-filter-header"
              >
                <div class="category-facet-label">{{ facetGroup.label }}</div>
                <mat-icon class="category-facet-filter-expander" [class.collapsed]="getFacetCollapsed(facetGroup.index)"
                  >expand_more</mat-icon
                >
              </div>

              <div
                [id]="'category-facet-group-container-' + facetGroup.index"
                [attr.data-cy-is-facet-collapsed]="getFacetCollapsed(facetGroup.index)"
                class="fx-col category-facet-group-container"
                [class.collapsed]="getFacetCollapsed(facetGroup.index)"
                [class.maximized]="getFacetMaximized(facetGroup.index)"
              >
                <div
                  *ngFor="let facet of facetGroup.facets"
                  class="category-facet fx-row fx-row-v-center pb-1"
                  data-cy="category-facet-filter-item"
                  (click)="filter(facetGroup, facet)"
                  [class.active-facet]="facet.isActive"
                >
                  <div class="category-facet-checkbox image-checkbox-1" [class.checked]="facet.isActive"></div>
                  <span class="category-facet-text" [class.active]="facet.isActive"
                    >{{ facet.label }} ({{ facet.count }})</span
                  >
                </div>
              </div>

              <!--
              Changing the Max and Min Number of Displayed Filters
              - Set the number next to facetGroup.totalFacets to the minimum
                number of filters to display.
              - In the Sass file, near .category-facet-maximizer-container, set
                the $number-facets-minimized variable to the same.
              - Also in the Sass file, set the $number-facets-maximized value to
                the maximum number of filters to display.
            --->
              <div
                class="category-facet-maximizer-container fx-row fx-row-h-center"
                [class.show]="facetGroup.totalFacets > 5 && !getFacetCollapsed(facetGroup.index)"
                [class.pad-bottom]="facetGroup.totalFacets <= 5 && !getFacetCollapsed(facetGroup.index)"
              >
                <div
                  class="category-facet-maximizer"
                  [class.show]="
                    facetGroup.totalFacets > 5 &&
                    !getFacetMaximized(facetGroup.index) &&
                    !getFacetCollapsed(facetGroup.index)
                  "
                  (click)="toggleFacetGroupMaximized(facetGroup.index)"
                >
                  See all
                </div>
                <div
                  class="category-facet-maximizer"
                  [class.show]="
                    facetGroup.totalFacets > 5 &&
                    getFacetMaximized(facetGroup.index) &&
                    !getFacetCollapsed(facetGroup.index)
                  "
                  (click)="toggleFacetGroupMaximized(facetGroup.index)"
                >
                  See less
                </div>
              </div>
            </div>

            <span
              class="facets-footer fx-row"
              [class.show]="category?.facets.menuOpen"
              (click)="toggleNarrowFilterMenu()"
              (wheel)="preventMobileDefaultScroll($event)"
            >
              <a class="button-primary" data-cy="category-filters-close-btn"> Done </a>
              <a class="button-secondary" (click)="clearAllFilters(); $event.stopPropagation()"> Clear All </a>
            </span>
          </div>
        </div>

        <div *ngIf="this.isNarrow" id="category-body-column-1-narrow-spacer"></div>

        <!-- BANNER LEFT -->

        <div *ngIf="category.banners.left" id="category-banners-left" [innerHTML]="category.banners.left"></div>
      </div>

      <!-- PRODUCTS -->

      <div
        *ngIf="category.products.length > 0"
        id="category-body-column-2"
        class="col-lg-4_5"
        data-cy="spc-category-products-container"
      >
        <div id="category-products-container" class="fx-col">
          <!-- PRODUCTS HEADER -->

          <div id="category-products-header" class="fx-row fx-row-h-space-between fx-row-v-bottom">
            <!-- PRODUCTS TOTAL -->

            <div id="category-count">
              {{ category.totalProducts.toLocaleString() }} Product{{ category.totalProducts == 1 ? "" : "s" }}
              {{ category.searchTerm ? ' for "' + category.searchTerm + '"' : "" }}
            </div>

            <!-- FACETS NARROW MENU TOGGLE -->

            <div
              id="category-facets-narrow-menu-toggle"
              #facetsNarrowMenuToggle
              class="col-xs-6 pl-0 pr-1"
              data-cy="category-filters-mobile"
            >
              <button class="button fx-row fx-row-h-space-between fx-row-v-center" (click)="toggleNarrowFilterMenu()">
                <span class="fx-row fx-row-h-space-between fx-row-v-center">
                  <mat-icon svgIcon="filter"></mat-icon>
                  Filter
                </span>
              </button>
            </div>

            <!-- SORT -->

            <div id="category-sort" class="fx-row fx-row-v-center col-xs-6 pl-1 pr-0" #sortMenu>
              <!-- <label id="category-sort-label">Sort by</label> -->
              <div id="category-sort-menu">
                <button
                  id="category-sort-button"
                  (click)="sortMenuOpen = !sortMenuOpen"
                  [class]="sortMenuOpen ? 'open button mat-elevation-z2 fx-row-v-center' : 'button fx-row-v-center'"
                  data-cy="category-sort-btn"
                >
                  <div class="fx-row fx-row-h-center fx-row-v-center">{{ category.sorting.selectedLabel }}</div>
                  <mat-icon>expand_more</mat-icon>
                </button>
                <div
                  id="category-sort-menu-options"
                  class="fx-col"
                  [class]="sortMenuOpen ? 'open fx-col mat-elevation-z2' : 'fx-col'"
                >
                  <a
                    *ngFor="let option of category.sorting.options; let i = index"
                    class="category-sort-menu-option"
                    (click)="sort(i); sortMenuOpen = false"
                  >
                    {{ option.label }}
                  </a>
                </div>
              </div>

              <div id="sort-view-buttons" class="fx-row fx-row-v-center fx-row-h-center">
                <div class="list-view-icon">
                  <mat-icon
                    id="mat-icon-list"
                    [class.active]="numberOfColumns == 1"
                    (click)="toggleActive(1)"
                    svgIcon="list"
                  ></mat-icon>
                </div>
                <div class="details-view-icon">
                  <mat-icon
                    id="mat-icon-details"
                    [class.active]="numberOfColumns == 3"
                    (click)="toggleActive(3)"
                    svgIcon="grid"
                  ></mat-icon>
                </div>
                <div class="grid-view-icon">
                  <mat-icon
                    id="mat-icon-grid"
                    [class.active]="numberOfColumns == 4"
                    (click)="toggleActive(4)"
                    svgIcon="grid-9"
                  ></mat-icon>
                </div>
              </div>
            </div>
          </div>

          <!-- PRODUCTS -->

          <!-- category roduct view multiple columns -->
          <div
            *ngIf="numberOfColumns != 1 || isNarrow"
            id="category-products"
            class="fx-row fx-wrap"
            data-cy="category-products"
          >
            <div
              *ngFor="let product of category.products; index as i"
              data-cy="spc-product"
              [class]="getContainerClassFromColumns(numberOfColumns, i, category?.products?.length)"
              data-cy="category-product"
            >
              <!-- category product image multi-column / mobile -->
              <div class="category-product-image-container fx-row fx-row-h-center fx-row-v-center">
                <a
                  class="category-product-image-narrow"
                  [routerLink]="product.urlComponent"
                  data-cy="category-product-anchor"
                >
                  <img
                    *ngIf="product?.imageUrl"
                    [src]="getWebpImg(product.imageUrl)"
                    class="category-product-image"
                    [alt]="product.name"
                  />
                </a>
                <img
                  *ngIf="product?.imageUrl"
                  class="category-product-image category-product-image-wide"
                  [src]="getWebpImg(product.imageUrl)"
                  [alt]="product.name"
                />
                <button
                  class="category-product-quick-view button-secondary"
                  (click)="openProductQuickView(product.urlComponent)"
                  data-cy="spc-open-product-quick-view-button"
                >
                  Quick View
                </button>
              </div>

              <!-- category product name and specs -->
              <div [class]="'category-product-text-container'">
                <div
                  [class]="
                    numberOfColumns == 4
                      ? 'category-product-name-small truncate-ellipsis my-1'
                      : 'category-product-name truncate-ellipsis my-1'
                  "
                >
                  <a
                    [class]="numberOfColumns < 4 ? 'large' : 'small'"
                    [routerLink]="product?.urlComponent"
                    [innerHTML]="product?.name"
                    data-cy="spc-product-link-desktop-multi-view"
                  ></a>
                </div>

                <!-- category product pricing and availability -->
                <div class="product-list-add-to-cart col-lg-4 col-xl-4">
                  <div
                    *ngIf="
                      product?.topCategory != 'Solutions' &&
                      product?.topCategory != 'Systems' &&
                      product?.topCategory != 'Supermicro'
                    "
                    class="category-product-availability"
                  >
                    <span
                      *ngIf="product?.pricing?.availability"
                      [class.orange]="product?.pricing?.availability?.color == 'orange'"
                      [class.blue]="product?.pricing?.availability?.color == 'blue'"
                      [class.green]="product?.pricing?.availability?.color == 'green'"
                    >
                      {{ product?.pricing?.availability?.shortText }}
                    </span>
                    <span *ngIf="!product.specialOrder && product?.inventory == 0 && product?.showPrice" class="orange">
                      Out of Stock
                    </span>
                  </div>
                  <div
                    *ngIf="product?.pricing?.showPrice"
                    class="category-product-price"
                    [class.pb-3]="!product?.freeShipping"
                  >
                    <meta content="https://www.sabrepc.com/{{ product.urlComponent }}" />
                    <meta content="USD" />
                    <meta *ngIf="product?.price" [content]="product.price" />
                    <link [routerLink]="product.pricing.schemaOrgAvailabilityUrl | safe : 'resourceUrl'" />
                    {{ product.price | currency }}
                  </div>
                  <div
                    *ngIf="!product.pricing.showPrice"
                    class="category-product-sales-message"
                    [class.pb-3]="!product.freeShipping"
                  >
                    Contact sales for pricing
                  </div>
                  <div *ngIf="product.freeShipping" class="category-product-free-shipping pb-3">
                    <span>Free Shipping</span>
                  </div>

                  <div *ngIf="!isNarrow && numberOfColumns < 4 && product.highlights" class="fx-row fx-row-h-left">
                    <div
                      class="highlight-section pl-2 text-left"
                      [innerHTML]="getHighlight(product?.highlights) | safe : 'html'"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- category products view singular columns -->
          <div *ngIf="numberOfColumns == 1 && !isNarrow" id="category-products" class="fx-row fx-wrap">
            <div
              *ngFor="let product of category.products; index as i"
              [class]="getContainerClassFromColumns(numberOfColumns, i, category?.products?.length)"
            >
              <app-product-listing [product]="product" isSingleSearchView="true"></app-product-listing>
            </div>
          </div>

          <!-- PRODUCTS FOOTER -->

          <div id="category-products-footer" class="fx-row fx-row-h-center">
            <!-- PAGINATION -->

            <app-category-pagination></app-category-pagination>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div id="category-footer" class="fx-row">
    <div *ngIf="category.banners.bottom" id="category-banners-bottom" [innerHTML]="category.banners.bottom"></div>
  </div>

  <lib-overlay [isVisible]="category.facets.menuOpen" [zIndex]="99"></lib-overlay>
</div>
