import { ModuleWithProviders, NgModule } from '@angular/core';

import { SharedModule } from 'lib/modules/shared.module';

import { AccountService } from 'lib/services/account/account.service';
import { CartService } from 'lib/services/cart/cart.service';
import { CategoryService } from 'lib/services/category/category.service';
import { ConfiguratorService } from 'lib/services/configuration/configurator.service';
import { ConfiguratorSharedRuleService } from 'lib/services/configuration/configurator-shared-rule.service';
import { CheckoutService } from 'lib/services/checkout.service';
import { DisqusService } from 'lib/services/disqus.service';
import { MarketoService } from 'lib/services/marketo.service';
import { SearchSpringService } from 'lib/services/searchspring.service';
import { StripeService } from 'lib/services/stripe/stripe.service';
import { WebstoreProductService } from 'lib/services/webstore-product/webstore-product.service';
import { VoucherifyService } from 'lib/services/voucherify/voucherify.service';

import { AddressComponent } from 'lib/components/address/address.component';
import { ProductImageDialogComponent } from 'lib/components/product/product-image-dialog/product-image-dialog.component';
import { ProductSpecDialogComponent } from 'lib/components/product-spec-dialog/product-spec-dialog.component';
import { SpecialModule } from './special.module';
import { ProductResolver } from 'lib/services/webstore-product/webstore-product-resolver';
import { MetaService } from 'lib/services/meta.service';
import { RedirectService } from 'lib/services/redirect.service';
import { WebpService } from 'lib/services/webp.service';
import { CustomerService } from 'lib/services/customer/customer.service';
import { EasyPostValidationService } from 'lib/services/easypost/easypost-validation.service';
import { OrderService } from 'lib/services/order/order.service';
import { ProductService } from 'lib/services/product/product.service';
import { SavedConfigurationService } from 'lib/services/saved-configuration/saved-configuration.service';
import { UserService } from 'lib/services/user/user.service';

@NgModule({
    declarations: [AddressComponent, ProductImageDialogComponent, ProductSpecDialogComponent],
    imports: [SharedModule.forRoot(), SpecialModule.forRoot()],
    exports: [AddressComponent, ProductImageDialogComponent, ProductSpecDialogComponent, SharedModule],
})
export class SharedWebstoreModule {
    static forRoot(environment: any, appPageTitles: any): ModuleWithProviders<SharedWebstoreModule> {
        return {
            ngModule: SharedWebstoreModule,
            providers: [
                AccountService,
                CartService,
                CategoryService,
                ConfiguratorService,
                ConfiguratorSharedRuleService,
                CheckoutService,
                DisqusService,
                MarketoService,
                WebstoreProductService,
                ProductResolver,
                SearchSpringService,
                StripeService,
                MetaService,
                RedirectService,
                WebpService,
                CustomerService,
                EasyPostValidationService,
                OrderService,
                ProductService,
                SavedConfigurationService,
                UserService,
                VoucherifyService,
                { provide: 'environment', useValue: environment },
                { provide: 'appPageTitles', useValue: appPageTitles },
            ],
        };
    }
}
