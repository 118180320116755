import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { AccountService } from 'lib/services/account/account.service';
import { AuthDialogComponent } from '../auth-dialog/auth-dialog.component';
import { AuthService } from 'lib/services/auth.service';
import { CartService } from 'lib/services/cart/cart.service';
import { contains, isBrowser, saveEmailInBrowser } from 'lib/tools';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { get } from 'lodash';
import { GrecaptchaService } from 'lib/services/google/grecaptcha.service';
import { MessageService } from 'lib/services/message.service';
import { SessionService } from 'lib/services/session.service';

const scriptName = 'auth-login.component';

@Component({
    selector: 'app-auth-login',
    templateUrl: './auth-login.component.html',
    styleUrls: ['./auth-login.component.scss'],
})
export class AuthLoginComponent implements OnInit {
    @Input() authType: string = 'account'; // Required. Default is account. Options: account, checkout
    @Input() dialogRef: MatDialogRef<AuthDialogComponent>;
    @Input() formClass: string; // Required.
    @Input() showFormHeading: boolean = false; // Optional. Default is false. Shows or hides heading inside the form
    @Input() showLabels: boolean = false; // Optional. Default is false. Shows or
    // hides input labels. If labels are shown, then placeholders are not shown.

    actionPending: boolean = false;
    form: FormGroup;
    grecaptchaToken: string = '';
    showV2Captcha: boolean = false;

    constructor(
        private accountService: AccountService,
        private authService: AuthService,
        private cartService: CartService,
        private formBuilder: FormBuilder,
        private grecaptchaService: GrecaptchaService,
        private router: Router,
        private sessionService: SessionService,
        public messageService: MessageService
    ) {}

    async ngOnInit() {
        try {
            this.defineForm();
        } catch (err) {
            console.error(...new ExxComError(638774, scriptName, err).stamp());
        }
    }

    private defineForm() {
        try {
            const captchaV2Validator = this.showV2Captcha ? ['', { validators: [Validators.required], updateOn: 'change' }] : [];
            const kaptchaKey = this.showV2Captcha ? { captcha: captchaV2Validator } : {};
            this.form = this.formBuilder.group({
                email: [
                    '',
                    {
                        validators: [Validators.required, Validators.email],
                        updateOn: 'blur',
                    },
                ],
                password: ['', { validators: [Validators.required], updateOn: 'change' }],
                ...kaptchaKey,
            });
        } catch (err) {
            console.error(...new ExxComError(798812, scriptName, err).stamp());
        }
    }

    get email() {
        return this.form.get('email');
    }
    get password() {
        return this.form.get('password');
    }

    hasError(control: any) {
        return control.invalid && (control.dirty || control.touched);
    }
    hasErrorRequired(control: any) {
        return this.hasError(control) && control.errors && control.errors.required;
    }
    hasErrorInvalid(control: any) {
        return this.hasError(control) && control.errors && control.errors.email;
    }

    async onSubmit() {
        try {
            if (!isBrowser()) {
                return;
            }
            if (!this.form.valid || (this.showV2Captcha && this.form.controls.captcha.status != 'VALID')) {
                return this.form.markAllAsTouched();
            }
            this.actionPending = true;
            await this.login();
            this.actionPending = false;
        } catch (err) {
            console.error(...new ExxComError(839847, scriptName, err).stamp());
        }
    }

    private async login() {
        try {
            const values: any = Object.assign(
                {
                    grecaptchaToken: await this.grecaptchaService.getToken('auth_login'),
                },
                this.form.value
            );
            values.email = values.email.toLowerCase();
            const res = await this.authService.login(values);
            res && res.success ? await this.onSuccess(res) : this.onError(res);
        } catch (err) {
            console.error(...new ExxComError(523332, scriptName, err).stamp());
        }
    }

    private async onSuccess(res: any) {
        try {
            if (this.dialogRef) {
                this.dialogRef.close();
            }
            saveEmailInBrowser(this.form.value.email.toLowerCase());
            this.sessionService.setIsGuestSession(false);
            this.sessionService.set(res.data.idToken, res.data.expiresIn).start();
            await this.accountService.init(true);
            await this.cartService.onLogin();
            this.router.navigateByUrl(this.authType == 'checkout' ? '/checkout' : '/account');
        } catch (err) {
            console.error(...new ExxComError(687472, scriptName, err).stamp());
        }
    }

    private onError(res: any) {
        try {
            if (res.isServer) {
                return;
            }
            if (!res) {
                throw new Error('No response when logging in');
            }
            const message = get(res, 'error.message');
            const messages = ['Not registered', 'Incorrect email or password'];
            const details = get(res, 'error.details');
            if (message == 'crg' || details == 'crg') {
                this.showV2Captcha = true;
                return;
            } // Ignore invalid reCAPTCHA response
            if (contains(messages, message)) {
                this.messageService.addLocal('login', message).clearOnTimeout(5);
            } else {
                this.messageService.addLocal('login', res.message).clearOnTimeout(5);
                throw res;
            }
        } catch (err) {
            console.error(...new ExxComError(293943, scriptName, err).stamp());
        }
    }
}
