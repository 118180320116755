import { contains } from 'lib/tools';
import { each, isEmpty, pickBy } from 'lodash';
import { ExxComError } from 'lib/classes/exxcom-error.class';

const scriptName = 'category-pagination.class';

class CategoryPaginationQueryParams {
    q: string;
    page: number;
    filters: string;
    constructor() {}
}

class CategoryPaginationNode {
    queryParams = new CategoryPaginationQueryParams();
    number: number;
    isActive: boolean;
    constructor() {}
}

export class CategoryPagination {
    previous = new CategoryPaginationNode();
    next = new CategoryPaginationNode();
    first = new CategoryPaginationNode();
    last = new CategoryPaginationNode();

    previousPage: number;
    currentPage: number;
    nextPage: number;
    totalPages: number;
    pages: any = [];
    controls: any = [];

    constructor() {}

    init(resPagination: any, params: any) {
        try {
            if (!resPagination) {
                return;
            }

            this.reset();

            this.currentPage = resPagination.currentPage;
            this.nextPage = resPagination.nextPage;
            this.totalPages = resPagination.totalPages;

            // Next, last queryParams.page

            this.next.queryParams.page = this.nextPage == 0 ? this.currentPage : this.nextPage;
            this.last.queryParams.page = this.totalPages;

            // Last numer, isActive

            this.last.number = this.totalPages;
            this.last.isActive = this.currentPage == this.totalPages;

            // Previous number, queryParams.page

            this.previous.number = resPagination.previousPage == 0 ? 1 : resPagination.previousPage;

            if (this.previous.number != 0 && this.previous.number != 1) {
                this.previous.queryParams.page = this.previous.number;
            }
            if (this.previous.number == 1) {
                delete this.previous.queryParams.page;
            }

            // All queryParams q

            if (params.q) {
                this.previous.queryParams.q = params.q;
                this.next.queryParams.q = params.q;
                this.first.queryParams.q = params.q;
                this.last.queryParams.q = params.q;
            }

            // All queryParams filters

            if (params.filters) {
                this.previous.queryParams.filters = params.filters;
                this.next.queryParams.filters = params.filters;
                this.first.queryParams.filters = params.filters;
                this.last.queryParams.filters = params.filters;
            }

            this.parseControls(params);
        } catch (err) {
            console.error(...new ExxComError(890992, scriptName, err).stamp());
        }
    }

    private reset() {
        try {
            this.previous = new CategoryPaginationNode();
            this.next = new CategoryPaginationNode();
            this.first = new CategoryPaginationNode();
            this.last = new CategoryPaginationNode();
        } catch (err) {
            console.error(...new ExxComError(299383, scriptName, err).stamp());
        }
    }

    private removeSort(queryParams: any) {
        try {
            each(queryParams, (v: string, k: string) => {
                if (contains(k, 'sort.')) {
                    delete queryParams[k];
                }
            });
        } catch (err) {
            console.error(...new ExxComError(802004, scriptName, err).stamp());
        }
    }

    private parseSort(params: any) {
        try {
            const param = pickBy(params, (v: string, k: string) => contains(k, 'sort.'));
            const field = Object.keys(param)[0];
            const direction = param[field];
            if (!isEmpty(param)) {
                this.removeSort(this.previous.queryParams);
                this.removeSort(this.next.queryParams);
                this.removeSort(this.first.queryParams);
                this.removeSort(this.last.queryParams);
                this.previous.queryParams[field] = direction;
                this.next.queryParams[field] = direction;
                this.first.queryParams[field] = direction;
                this.last.queryParams[field] = direction;
            }
            return { field: field, direction: direction };
        } catch (err) {
            console.error(...new ExxComError(500030, scriptName, err).stamp());
        }
    }

    private parseControls(params: any) {
        try {
            this.controls = [];

            if (this.totalPages == 1) {
                return;
            }

            const sortParam = this.parseSort(params);

            const getEllipsis = () => {
                return { isEllipsis: true };
            };
            const getArrow = (direction: string, isActive: boolean) => {
                return {
                    isArrow: true,
                    direction: direction,
                    isActive: isActive,
                };
            };
            const getPage = (number: number) => {
                const data: any = {
                    isNumber: true,
                    number: number,
                    isActive: number == this.currentPage,
                    isEllipsis: false,
                    queryParams: {},
                };
                if (params.q) {
                    data.queryParams.q = params.q;
                }
                if (sortParam.field) {
                    this.removeSort(data.queryParams);
                    data.queryParams[sortParam.field] = sortParam.direction;
                }
                if (params.filters) {
                    data.queryParams.filters = params.filters;
                }
                if (number != 0) {
                    data.queryParams.page = number;
                }
                return data;
            };

            const maxVisible = 7;

            this.controls.push(getArrow('left', this.currentPage > 1));

            if (this.totalPages <= maxVisible) {
                for (let i = 0; i < this.totalPages; i++) {
                    const pageNumber = i + 1;
                    const data = getPage(pageNumber);
                    this.controls.push(data);
                }
            } else if (this.totalPages > maxVisible) {
                const leftBreak = maxVisible - 2;
                const rightBreak = this.totalPages - 3;

                let data: any;
                for (let i = 1; i <= maxVisible; i++) {
                    if (i == 1) {
                        // First page
                        data = getPage(1);
                    } else if (i == maxVisible) {
                        // Last page
                        data = getPage(this.totalPages);
                    } else if (this.currentPage < leftBreak) {
                        if (i <= leftBreak) {
                            data = getPage(i);
                        } else if (i > leftBreak) {
                            data = getEllipsis();
                        }
                    } else if (this.currentPage >= leftBreak && this.currentPage < rightBreak) {
                        if (i == 2 || i == maxVisible - 1) {
                            data = getEllipsis();
                        } else {
                            data = getPage(this.currentPage + i - 4); // 4, because the active control is the 4th
                        }
                    } else if (this.currentPage >= rightBreak) {
                        if (i == 2) {
                            data = getEllipsis();
                        } else {
                            data = getPage(this.totalPages - maxVisible + i);
                        }
                    }

                    this.controls.push(data);
                }
            }

            this.controls.push(getArrow('right', this.currentPage < this.totalPages));
        } catch (err) {
            console.error(...new ExxComError(499942, scriptName, err).stamp());
        }
    }
}
