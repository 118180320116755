import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';

import { CmsBlogHeaderComponentClass } from 'lib/components/cms/blog/cms-blog-header-component.class';

@Component({
    selector: 'app-cms-blog-header',
    templateUrl: './cms-blog-header.component.html',
    styleUrls: ['./cms-blog-header.component.scss'],
})
export class CmsBlogHeaderComponent extends CmsBlogHeaderComponentClass implements OnInit {
    constructor(breakpointObserver: BreakpointObserver) {
        super({ dependencies: { breakpointObserver } });
    }
}
