import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { SharedWebstoreModule } from 'lib/modules/shared-webstore.module';
import { AuthModule } from './auth/auth.module';

import { AppComponent } from './app.component';
import { CmsApplicationsFormsComponent } from './cms/pages/cms-applications-forms/cms-applications-forms.component';
import { CmsB2bServiceComponent } from './cms/pages/cms-b2b-service/cms-b2b-service.component';
import { CmsBlogFooterComponent } from './cms/blog/cms-blog-footer/cms-blog-footer.component';
import { CmsBlogHeaderComponent } from './cms/blog/cms-blog-header/cms-blog-header.component';
import { CmsBlogHeaderMenuComponent } from './cms/blog/cms-blog-header/cms-blog-header-menu/cms-blog-header-menu.component';
import { CmsBlogHeaderSearchComponent } from './cms/blog/cms-blog-header/cms-blog-header-search/cms-blog-header-search.component';
import { CmsBlogSearchComponent } from './cms/blog/cms-blog-search/cms-blog-search.component';
import { CmsContactUsComponent } from './cms/pages/cms-contact-us/cms-contact-us.component';
import { CmsImgComponent } from 'lib/components/cms/cms-img/cms-img.component';
import { CmsPoliciesComponent } from './cms/pages/cms-policies/cms-policies.component';
import { CmsWarrantyPolicyComponent } from './cms/pages/cms-warranty-policy/cms-warranty-policy.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderAccountComponent } from './header/header-account/header-account.component';
import { HeaderCartComponent } from './header/header-cart/header-cart.component';
import { HeaderComponent } from './header/header.component';
import { HeaderMenuComponent } from './header/header-menu/header-menu.component';
import { HeaderSearchComponent } from './header/header-search/header-search.component';
import { NotFoundComponent } from './not-found/not-found.component';

import { ServicesInitializer } from './app.initializers';
import { ReCaptchaDirective } from 'lib/validators/recaptcha.directive';

import { appPageTitles } from './app-page-titles';
import { environment } from '../environments/environment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SpecialModule } from 'lib/modules/special.module';
import { PrivacyComponent } from './privacy/privacy.component';
import { CookiesComponent } from './privacy/cookies/cookies.component';

@NgModule({
    declarations: [
        AppComponent,
        CmsB2bServiceComponent,
        CmsBlogFooterComponent,
        CmsBlogHeaderComponent,
        CmsBlogHeaderMenuComponent,
        CmsBlogHeaderSearchComponent,
        CmsBlogSearchComponent,
        CmsContactUsComponent,
        CmsImgComponent,
        CmsPoliciesComponent,
        CmsWarrantyPolicyComponent,
        FooterComponent,
        HeaderAccountComponent,
        HeaderCartComponent,
        HeaderComponent,
        HeaderMenuComponent,
        HeaderSearchComponent,
        NotFoundComponent,
        CmsApplicationsFormsComponent,
        ReCaptchaDirective,
        PrivacyComponent,
        CookiesComponent,
    ],
    imports: [AppRoutingModule, AuthModule, SpecialModule.forRoot(), SharedWebstoreModule.forRoot(environment, appPageTitles)],
    providers: [ServicesInitializer, BsModalRef],
    bootstrap: [AppComponent],
})
export class AppModule {}
