import { Component, Inject } from '@angular/core';

import { CmsBlogHeaderSearchComponentClass } from 'lib/components/cms/blog/cms-blog-header-menu-component.class';
import { CmsService } from 'lib/services/cms.service';

@Component({
    selector: 'app-cms-blog-header-menu',
    templateUrl: './cms-blog-header-menu.component.html',
    styleUrls: ['./cms-blog-header-menu.component.scss'],
})
export class CmsBlogHeaderMenuComponent extends CmsBlogHeaderSearchComponentClass {
    constructor(@Inject('environment') environment: any, cmsService: CmsService) {
        super({ dependencies: { cmsService, environment } });
    }
}
