import { CmsService } from 'lib/services/cms.service';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { ExxComComponentClass } from 'lib/components/exxcom-component.class';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { RouterService } from 'lib/services/router.service';
import { CategoryService } from 'lib/services/category/category.service';
import { isBrowser } from 'lib/tools';
import { startsWith } from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BootstrapMarketoService } from 'lib/services/bootstrap/bootstrap-marketo.service';

const scriptName = 'cms-partial-component.class';

export class CmsPartialComponentClass extends ExxComComponentClass {
    // Dependencies
    bootstrapMarketoService: BootstrapMarketoService;
    bsModalRef?: BsModalRef;
    bsModalService: BsModalService;
    cmsService: CmsService;
    dialog: MatDialog;
    environment: any;
    routerService: RouterService;
    location: Location;
    categoryService: CategoryService;

    constructor({ dependencies }: { dependencies?: any } = {}) {
        super({ dependencies });
    }

    displayLeft(displayType: string) {
        try {
            return displayType == 'Image on the left';
        } catch (err) {
            console.error(...new ExxComError(629933, scriptName, err).stamp());
        }
    }

    navigateByUrl(link: string) {
        try {
            this.routerService.router.navigateByUrl(`/${link}`);
        } catch (err) {
            console.error(...new ExxComError(522221, scriptName, err).stamp());
        }
    }

    buttonLinkRouter(link: string) {
        try {
            if (startsWith(link, '/')) {
                this.routerService.router.navigateByUrl(link);
                window.scrollTo({ top: 0 });
            } else {
                window.open(link, '_self');
            }
        } catch (err) {
            console.error(...new ExxComError(421395, scriptName, err).stamp());
        }
    }

    filter(pathname: string, paramObj: any) {
        try {
            if (!isBrowser()) {
                return;
            }
            const params = decodeURIComponent(new URLSearchParams(paramObj).toString());
            const baseUrl = this.categoryService.paths.includes(pathname.replace('/', '')) ? `/category${pathname}` : pathname;
            const url = `${baseUrl}?${params}`;
            const currentPath = window.location.pathname;
            if (currentPath === baseUrl) {
                this.location.go(url);
                this.categoryService.loadPage();
                const categoryBody = document.getElementById('category-body');
                categoryBody.scrollIntoView({
                    behavior: 'smooth',
                });
            } else {
                this.routerService.router.navigateByUrl(url);
            }
        } catch (err) {
            console.error(...new ExxComError(982347, scriptName, err).stamp());
        }
    }
}
