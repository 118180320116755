<ng-container>
  <div *ngIf="!isEmpty(get(paragraphRow, 'paragraph', ''))">
    <section id="rows" class="paragraph" [class.gray-bg]="get(paragraphRow, 'paragraph.gray_background', false)">
      <div class="fx-wrap fx-row-container">
        <div class="category-top-title fx-row fx-row-h-center pb-2">
          <h2 class="col-md-7 text-center">{{ get(paragraphRow, "paragraph.title", "") }}</h2>
        </div>
        <div class="col-xs-12 text-center" [innerHTML]="get(paragraphRow, 'paragraph.copy', '') | safe: 'html'"></div>
      </div>
    </section>
  </div>
</ng-container>
<hr *ngIf="get(paragraphRow, 'paragraph.split_line', '')" class="m-0" />
