<div id="auth-login" class="auth-container">
  <!--
    As a workaround, *ngIf="true" resolves an issue (SD-819) with CSS
    transitions firing on the login form errors when the component first loads
    on production after navigating to the login page by clicking "Log in" from
    the header account dropdown.
  -->
  <form *ngIf="true" [class]="formClass" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div *ngIf="showFormHeading">
      <h3>Returning Customer</h3>
      <h4>Sign in for the fastest checkout</h4>
    </div>

    <div class="form-field">
      <label *ngIf="showLabels" for="auth-login-email" class="form-label">Email</label>
      <input
        type="text"
        formControlName="email"
        #emailInput
        id="auth-login-email"
        data-cy="spc-login-form-email"
        [placeholder]="!showLabels ? 'Email' : ''"
        [class.error]="hasError(email)"
        (keyup.enter)="emailInput.blur()"
      />
      <div class="form-error" [class.show]="hasError(email)">
        <div class="form-error" [class.show]="hasErrorRequired(email)">Email is required.</div>
        <div class="form-error" [class.show]="hasErrorInvalid(email)">Email is not valid.</div>
      </div>
    </div>

    <div class="form-field">
      <label *ngIf="showLabels" for="auth-login-password" class="form-label">Password</label>
      <input
        type="password"
        formControlName="password"
        #passwordInput
        id="auth-login-password"
        data-cy="spc-login-form-password"
        [placeholder]="!showLabels ? 'Password' : ''"
        [class.error]="hasError(password)"
      />
      <div class="form-error" [class.show]="hasError(password)">
        <div class="form-error" [class.show]="hasErrorRequired(password)">Password is required.</div>
      </div>
    </div>

    <a
      class="link-gray-inverted fx-col-push-right font-xs"
      href="/account/reset-password"
      (click)="dialogRef && dialogRef.close()"
    >
      Forgot password?
    </a>

    <span class="form-messages">{{ messageService.locals.login }}</span>

    <div
      *ngIf="showV2Captcha"
      nbRecaptcha
      key="6Ldu_sQaAAAAAMfA5w7xAU8KJ2bWkXQBjrYlRX5_"
      formControlName="captcha"
    ></div>

    <button class="button-primary button-base" data-cy="spc-form-login-button" type="submit">
      <mat-spinner *ngIf="actionPending" diameter="18" class="white"></mat-spinner>
      <span *ngIf="!actionPending">Sign in</span>
    </button>
  </form>
</div>
