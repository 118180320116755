<div
  id="marketo-dialog"
  #bootstrapDialog
  class="dialog marketo-dialog-container"
  data-cy="exx-marketo-dialog-container"
>
  <div class="dialog-top-row">
    <mat-icon class="dialog-close" (click)="bsModalRef.hide()" data-cy="exx-marketo-close-button">close</mat-icon>
  </div>

  <lib-marketo-form
    [formId]="data.marketoFormId"
    [interest]="data.interest"
    [quantity]="data.quantity"
    [configuratorData]="data.configuratorData"
    [saveConfiguratorInformation]="data.saveConfiguratorInformation"
    [configuratorSystemPrice]="data.configuratorSystemPrice"
    (formEmitter)="initForm($event)"
    (formSubmittedEmitter)="marketoFormSubmitted($event, data.marketoFormId)"
    (reloadEventEmitter)="onReload()"
    class="d-flex justify-content-center"
  ></lib-marketo-form>
</div>
