<section
    id="product-listing-wide"
    *ngIf="!isSingleSearchView"
    data-cy="spc-product-listing-desktop"
    [class.solution-product]="product?.manufacturer == 'SabrePC'"
>
    <div class="fx-row fx-wrap" [class.pt-3]="!isQuickView">
        <div class="image-container" [ngClass]="isQuickView ? 'col-lg-5 col-xl-5 p-0' : 'col-lg-4 col-xl-4'">
            <img *ngIf="!isBrowser && product.imageUrl" [src]="getWebpImg(product.imageUrl)" [alt]="product.description" />
            <mod-swiper
                *ngIf="productImgSlides?.length > 0 && isBrowser && productType !== 'Solution'"
                (onNumberChange)="setImageIndex($event)"
                class="bottom-swiper-nav container p-0"
                [slidesPerView]="1"
                [loop]="false"
                containerClass="preview-swiper-narrow"
                [allowPrevArrow]="false"
                [allowNextArrow]="false"
                [productImages]="product?.imageUrls"
                navType="bottom"
                [isSolution]="true"
                [activeIndex]="amount"
            >
                <div class="fx-row fx-row-h-center fx-row-v-center swiper-slide product-preview" *ngFor="let slide of productImgSlides">
                    <img
                        [src]="getWebpImg(slide?.imageUrl)"
                        [alt]="slide?.heading"
                        (click)="openProductImageSwiperDialog(productImgSlides, initialSlide)"
                    />
                </div>
            </mod-swiper>
            <div *ngIf="product?.imageUrls.length > 0 && isBrowser && productType == 'Solution'">
                <mod-swiper
                    (onNumberChange)="setImageIndex($event)"
                    class="bottom-swiper-nav container p-0"
                    [slidesPerView]="1"
                    [loop]="false"
                    containerClass="preview-swiper-narrow"
                    [allowPrevArrow]="false"
                    [allowNextArrow]="false"
                    [productImages]="product?.imageUrls"
                    navType="bottom"
                    [activeIndex]="amount"
                >
                    <div class="fx-row fx-row-h-center fx-row-v-center swiper-slide" *ngFor="let slide of productImgSlides">
                        <img [src]="getWebpImg(slide?.imageUrl)" [alt]="slide?.heading" (click)="!isNarrow ? openModal(templateModal) : []" />
                    </div>
                </mod-swiper>
                <app-product-page-image-tile-spc
                    *ngIf="product?.imageUrls.length > 1"
                    [(amount)]="amount"
                    [slidesPerView]="4"
                    [productImages]="product?.imageUrls"
                    [(translateAmount)]="translateAmount"
                    [widthPerSlideImage]="66"
                >
                </app-product-page-image-tile-spc>
            </div>
        </div>

        <div id="product-listing-main" [ngClass]="isQuickView ? 'fx-col col-lg-7 col-xl-7' : 'fx-row fx-wrap col-lg-8 col-xl-8 p-0'">
            <div *ngIf="isQuickView" id="product-listing-title">
                <h3>{{ product?.description }}</h3>
                <a id="product-view-full-details" [routerLink]="product.urlComponent" (click)="bsModalRef.hide()"> View full details</a>
            </div>

            <div id="product-listing-details" [ngClass]="isQuickView ? '' : 'col-lg-6 col-xl-6'">
                <div
                    *ngIf="
                        product?.topCategory != 'Solutions' &&
                        product?.topCategory != 'Systems' &&
                        product?.topCategory != 'Supermicro' &&
                        (product?.pricing?.availability ||
                            product?.pricing?.showCondition ||
                            (product?.leadTime && product?.inventory != 0) ||
                            (product?.inventory == 0 && product?.showPrice))
                    "
                    id="product-listing-availability"
                >
                    <div *ngIf="product?.pricing?.availability" class="availability fx-row fx-row-v-center">
                        <mat-icon svgIcon="tag"></mat-icon>
                        <span>{{ product?.pricing?.availability?.longText }}</span>
                    </div>
                    <div *ngIf="!product?.specialOrder && product?.inventory == 0 && product?.showPrice" class="availability fx-row fx-row-v-center">
                        <mat-icon svgIcon="box-off"></mat-icon>
                        <span>Out of Stock</span>
                    </div>
                    <div *ngIf="product?.leadTime && (product?.inventory != 0 || product?.specialOrder)" class="availability fx-row fx-row-v-center">
                        <mat-icon svgIcon="truck"></mat-icon>
                        <span>{{ product?.pricing?.leadTimeMessage }}</span>
                    </div>
                    <div *ngIf="product?.pricing?.showCondition" class="availability fx-row fx-row-v-center">
                        <mat-icon svgIcon="box"></mat-icon>
                        <span>{{ product?.pricing?.condition }}</span>
                    </div>
                </div>

                <div *ngIf="product.highlights" id="product-listing-highlights">
                    <strong>Highlights</strong>
                    <div [innerHTML]="product.highlights" class="my-1"></div>
                </div>

                <div *ngIf="!isQuickView" id="product-listing-social-media" class="fx-row fx-row-h-left">
                    <a href="mailto:?subject={{ product.description }}" target="_blank">
                        <mat-icon svgIcon="mail"></mat-icon>
                    </a>
                    <a
                        href="https://www.facebook.com/sharer/sharer.php?u=https://www.sabrepc.com{{ product.urlComponent }}"
                        class="fb-xfbml-parse-ignore"
                        target="_blank"
                    >
                        <mat-icon svgIcon="facebook"></mat-icon>
                    </a>
                    <a
                        class="twitter-share-button"
                        href="https://twitter.com/intent/tweet?text=https://www.sabrepc.com{{ product.urlComponent }}"
                        target="_blank"
                    >
                        <mat-icon class="twitter-icon" svgIcon="x-logo"></mat-icon>
                    </a>
                    <a
                        href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https://www.sabrepc.com{{ product.urlComponent }}"
                        target="_blank"
                    >
                        <mat-icon svgIcon="linkedin"></mat-icon>
                    </a>
                </div>
            </div>

            <div id="product-listing-sales" [ngClass]="isQuickView ? '' : 'col-lg-6 col-xl-6'">
                <div id="product-listing-actions" class="fx-col">
                    <div class="fx-col">
                        <div
                            id="product-listing-price-wishlist"
                            class="fx-row fx-row-v-center fx-row-h-space-between"
                            [class.mb-2]="product.pricing.buttonText == 'Send Inquiry'"
                        >
                            <span *ngIf="product.pricing.showPrice" id="product-listing-price-text">
                                <link [routerLink]="product.pricing.schemaOrgAvailabilityUrl | safe: 'resourceUrl'" />
                                {{ product.price | currency }}
                            </span>
                            <div id="product-listing-pricing-message">
                                <span *ngIf="!product.pricing.showPrice && !product.pricing.showPriceInCart"> Contact sales for pricing </span>
                            </div>
                            <div id="product-listing-on-wishlist-wide" class="fx-row fx-row-v-center">
                                <mat-icon *ngIf="!isQuickView && !isOnWishlist" (click)="toggleWishlist()">favorite_border</mat-icon>
                                <mat-icon *ngIf="!isQuickView && isOnWishlist" class="active" (click)="toggleWishlist()">favorite</mat-icon>
                                <span id="product-listing-on-wishlist-message-wide" class="mat-elevation-z5" [class.show]="showWishlistMessage">
                                    Please
                                    <a routerLink="/account/login" class="link-amber">log in</a>
                                    to add an item to your wishlist.
                                </span>
                            </div>
                        </div>

                        <div class="fx-row fx-row-h-space-between" id="product-listing-shipping-info">
                            <div id="free-shipping">
                                <span *ngIf="product.freeShipping" class="pb-2">Free Shipping</span>
                            </div>
                            <div *ngIf="product.pricing.isNcnr">
                                <div id="product-listing-ncnr">
                                    <mat-icon svgIcon="alert-triangle"> </mat-icon>
                                    <span class="ncnr-highlighted">Non-cancelable and non-returnable</span>
                                </div>
                            </div>
                        </div>

                        <div id="product-list-add-to-cart" class="fx-row fx-wrap fx-row-h-space-between">
                            <span>
                                <input #wideQuantity value="{{ selectedQuantity }}" type="hidden" />
                                <mat-select [(value)]="defaultSelectValue" disableOptionCentering panelClass="test1234">
                                    <mat-option *ngFor="let n of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" [value]="n" (click)="updateQuantity(n)">
                                        {{ n }}
                                    </mat-option>
                                    <mat-option> <a [routerLink]="'/Contact-Us'">Bulk</a></mat-option>
                                </mat-select>
                            </span>
                            <a
                                *ngIf="product.pricing.buttonText == 'Add to Cart'"
                                class="button-primary button-base col-md-8"
                                data-cy="spc-desktop-product-add-to-cart"
                                (click)="addToCart(product, wideQuantity)"
                            >
                                {{ showAddedToCartMessage ? '+' + wideQuantity.value + ' to cart' : product.pricing.buttonText }}
                            </a>
                            <a
                                *ngIf="product.pricing.buttonText == 'See Price in Cart'"
                                class="button-primary button-base col-md-8"
                                routerLink="/checkout/cart"
                                >{{ product.pricing.buttonText }}</a
                            >
                            <a
                                *ngIf="product.pricing.buttonText == 'Send Inquiry'"
                                class="button-primary button-base col-md-8"
                                (click)="openBootstrapMarketoDialog('mktoForm_1490', product)"
                                data-cy="spc-send-inquiry-product-button"
                                >{{ product.pricing.buttonText }}</a
                            >
                        </div>
                    </div>

                    <div id="product-listing-b2b-pricing-message" class="fx-row fx-row-v-center py-2">
                        <span>B2B pricing options available.</span>
                        <mat-icon svgIcon="info"></mat-icon>
                        <div id="product-listing-b2b-pricing-help" class="mat-elevation-z3">
                            <h4>SabrePC B2B Account Services</h4>
                            <p>
                                Save instantly and shop with assurance knowing that you have a dedicated account team a phone call or email away to
                                help answer any of your questions with a B2B account.
                            </p>
                            <ul>
                                <li>Business-Only Pricing</li>
                                <li>Personalized Quotes</li>
                                <li>Fast Delivery</li>
                                <li>Products and Support</li>
                            </ul>
                        </div>
                    </div>

                    <div id="product-listing-b2b-pricing-button" data-cy="spc-get-better-pricing-button">
                        <button class="button-secondary button-base" (click)="openBootstrapMarketoDialog('mktoForm_2061', product)">
                            Get Better Pricing
                        </button>
                    </div>
                </div>

                <div *ngIf="!isQuickView" id="product-listing-sales-message">
                    <div><strong>Need Help?</strong> Let's talk about it.</div>
                    <div>
                        <a href="mailto:sales@sabrepc.com" class="link-gray">sales@sabrepc.com</a>
                        |
                        <a href="tel:1-408-914-8169" class="link-gray">408.914.8196</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="product-listing-narrow" *ngIf="!isSingleSearchView" class="pt-0" [class.isSolutionProduct]="product?.manufacturer == 'SabrePC'">
    <div class="fx-row fx-row-h-center product-listing-image">
        <div class="image-container text-center pb-2">
            <div id="product-listing-on-wishlist-narrow" class="fx-row fx-row-v-center">
                <mat-icon *ngIf="!isQuickView && !isOnWishlist" (click)="toggleWishlist()">favorite_border</mat-icon>
                <mat-icon *ngIf="!isQuickView && isOnWishlist" class="active" (click)="toggleWishlist()">favorite</mat-icon>
                <span id="product-listing-on-wishlist-message-narrow" class="mat-elevation-z5" [class.show]="showWishlistMessage">
                    Please
                    <a routerLink="/account/login" class="link-amber">log in</a>
                    to add an item to your wishlist.
                </span>
            </div>
            <img *ngIf="!isBrowser && product.imageUrl" [src]="getWebpImg(product.imageUrl)" [alt]="product.description" />
            <mod-swiper
                *ngIf="productImgSlides?.length > 0 && isBrowser"
                class="bottom-swiper-nav"
                [slidesPerView]="1"
                [loop]="false"
                containerClass="preview-swiper-wide"
                navType="bottom"
                (onSlideChange)="updateSlideIndex($event)"
            >
                <img
                    class="swiper-slide product-preview-image"
                    *ngFor="let slide of productImgSlides"
                    [src]="getWebpImg(slide?.imageUrl)"
                    [alt]="slide?.heading"
                />
            </mod-swiper>

            <div *ngIf="product?.imageUrls.length > 0 && isBrowser && product?.manufacturer == 'SabrePC'">
                <mod-swiper
                    (onNumberChange)="setImageIndex($event)"
                    class="bottom-swiper-nav container p-0"
                    [slidesPerView]="1"
                    [loop]="false"
                    containerClass="preview-swiper-narrow"
                    [allowPrevArrow]="false"
                    [allowNextArrow]="false"
                    [productImages]="product?.imageUrls"
                    navType="bottom"
                    [activeIndex]="amount"
                >
                    <div class="fx-row fx-row-h-center fx-row-v-center swiper-slide" *ngFor="let slide of productImgSlides">
                        <img [src]="getWebpImg(slide?.imageUrl)" [alt]="slide?.heading" (click)="!isNarrow ? openModal(templateModal) : []" />
                    </div>
                </mod-swiper>
                <app-product-page-image-tile-spc
                    *ngIf="product?.imageUrls.length > 1"
                    [(amount)]="amount"
                    [slidesPerView]="4"
                    [productImages]="product?.imageUrls"
                    [(translateAmount)]="translateAmount"
                    [widthPerSlideImage]="66"
                >
                </app-product-page-image-tile-spc>
            </div>
        </div>
    </div>

    <div id="product-listing-main" [ngClass]="isQuickView ? 'fx-col col-lg-7 col-xl-7' : 'fx-row fx-wrap col-lg-8 col-xl-8 px-3'">
        <div id="product-listing-details" [ngClass]="isQuickView ? '' : 'col-lg-6 col-xl-6 px-0'">
            <div class="fx-row fx-row-h-space-between fx-row-v-center">
                <div id="product-numbers">MPN: {{ product.mpn }}</div>
                <div *ngIf="product.freeShipping" id="free-shipping">Free shipping</div>
            </div>

            <div id="product-listing-price" class="fx-row fx-row-v-center fx-row-h-space-between pb-0 mb-0 pt-1">
                <h3 *ngIf="product.pricing.showPrice" id="product-listing-price-text">
                    <link [routerLink]="product.pricing.schemaOrgAvailabilityUrl | safe: 'resourceUrl'" />
                    {{ product.price | currency }}
                </h3>
                <span id="product-listing-pricing-message">
                    <span *ngIf="!product.pricing.showPrice && !product.pricing.showPriceInCart" class="pb-2"> Contact sales for pricing </span>
                </span>
            </div>
            <div class="fx-row fx-row-v-center p-0 m-0">
                <div *ngIf="product.pricing.isNcnr" id="product-listing-ncnr">
                    <mat-icon svgIcon="info"></mat-icon>
                    <span class="ncnr-highlighted">Non-cancelable and non-returnable</span>
                </div>
            </div>

            <h1 id="product-description">{{ product?.description }}</h1>

            <div
                *ngIf="
                    product?.topCategory != 'Solutions' &&
                    product?.topCategory != 'Systems' &&
                    product?.topCategory != 'Supermicro' &&
                    (product?.pricing?.availability ||
                        product?.pricing?.showCondition ||
                        (product?.leadTime && product?.inventory != 0) ||
                        (product?.inventory == 0 && product?.showPrice))
                "
                id="product-listing-availability"
            >
                <div *ngIf="product?.pricing?.availability" class="availability fx-row fx-row-v-center">
                    <mat-icon svgIcon="tag"></mat-icon>
                    <span>{{ product?.pricing?.availability?.longText }}</span>
                </div>
                <div *ngIf="!product?.specialOrder && product?.inventory == 0 && product?.showPrice" class="availability fx-row fx-row-v-center">
                    <mat-icon svgIcon="box-off"></mat-icon>
                    <span>Out of Stock</span>
                </div>
                <div *ngIf="product?.leadTime && (product?.inventory != 0 || product?.specialOrder)" class="availability fx-row fx-row-v-center">
                    <mat-icon svgIcon="truck"></mat-icon>
                    <span>{{ product?.pricing?.leadTimeMessage }}</span>
                </div>
                <div *ngIf="product?.pricing?.showCondition" class="availability fx-row fx-row-v-center">
                    <mat-icon svgIcon="box"></mat-icon>
                    <span>{{ product?.pricing?.condition }}</span>
                </div>
            </div>

            <div *ngIf="product.highlights" id="product-listing-highlights" class="py-2 mt-2">
                <strong>Highlights</strong>
                <div [innerHTML]="product.highlights" class="my-1"></div>
            </div>

            <div
                *ngIf="product.pricing.buttonText != 'Send Inquiry'"
                id="product-listing-b2b-pricing-message-narrow"
                class="fx-row fx-row-v-center py-2"
            >
                <span>B2B pricing options available.</span>
                <mat-icon svgIcon="info"></mat-icon>
                <div id="product-listing-b2b-pricing-help-narrow" class="mat-elevation-z3">
                    <h4>SabrePC B2B Account Services</h4>
                    <p>
                        Save instantly and shop with assurance knowing that you have a dedicated account team a phone call or email away to help
                        answer any of your questions with a B2B account.
                    </p>
                    <ul>
                        <li>Business-Only Pricing</li>
                        <li>Personalized Quotes</li>
                        <li>Fast Delivery</li>
                        <li>Products and Support</li>
                    </ul>
                </div>
            </div>

            <div id="product-listing-b2b-pricing-button" data-cy="spc-get-better-pricing-button-mobile">
                <button class="button-secondary button-base" (click)="openBootstrapMarketoDialog('mktoForm_2061', product)">
                    Get Better Pricing
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="isVisible" id="floating-text-button" class="fx-row fx-row-v-center fx-wrap fx-row-h-space-between">
        <span>
            <input #narrowQuantity value="{{ selectedQuantity }}" type="hidden" />
            <mat-select [(value)]="defaultSelectValue" disableOptionCentering>
                <mat-option *ngFor="let n of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" [value]="n" (click)="updateQuantity(n)">
                    {{ n }}
                </mat-option>
                <mat-option class="dialog-close" [mat-dialog-close]=""> <a class="dialog-close" routerLink="/Contact-Us">Bulk</a></mat-option>
            </mat-select>
        </span>
        <a
            *ngIf="product.pricing.buttonText == 'Add to Cart'"
            class="button-primary button-base col-xs-8 col-sm-8"
            (click)="addToCart(product, narrowQuantity)"
        >
            {{ showAddedToCartMessage ? '+' + narrowQuantity.value + ' to cart' : product.pricing.buttonText }}
        </a>
        <a *ngIf="product.pricing.buttonText == 'See Price in Cart'" class="button-primary button-base col-xs-8 col-sm-8" routerLink="/checkout/cart">
            {{ product.pricing.buttonText }}
        </a>
        <button
            *ngIf="product.pricing.buttonText == 'Send Inquiry'"
            class="button-primary button-base col-xs-8 col-sm-8"
            data-cy="spc-product-send-inquiry-button"
            (click)="openBootstrapMarketoDialog('mktoForm_1490', product)"
        >
            {{ product.pricing.buttonText }}
        </button>
    </div>
</section>

<section *ngIf="isSingleSearchView" class="product-listing-single-search col-lg-12 col-xl-12 fx-row p-0">
    <!-- category product image single -->
    <div class="col-lg-4 col-xl-4 image-outer category-product-image-container-wide fx-row fx-row-h-center fx-row-v-center">
        <img
            *ngIf="product?.imageUrl"
            class="category-product-image category-product-image-wide"
            [src]="getWebpImg(product.imageUrl)"
            [alt]="product.description"
        />
        <button class="category-product-quick-view button" (click)="openProductQuickView(product?.urlComponent)">Quick View</button>
    </div>

    <!-- category product specs and name -->
    <div class="category-product-text-container col-lg-4 col-xl-4 p-0 mx-3">
        <div class="category-product-name truncate-ellipsis">
            <a [class]="'large'" [routerLink]="product.urlComponent" [innerHTML]="product.name" data-cy="spc-product-link"></a>
        </div>

        <div *ngIf="product.highlights" class="fx-row fx-row-h-left">
            <div class="highlight-section pl-2 pt-3 text-left" [innerHTML]="getHighlight(product?.highlights) | safe: 'html'"></div>
        </div>
    </div>

    <!-- category product pricing and availability -->
    <div class="col-lg-4 col-xl-4 p-0">
        <div class="category-product-availability">
            <span
                *ngIf="product?.pricing?.availability"
                [class.orange]="product?.pricing?.availability?.color == 'orange'"
                [class.blue]="product?.pricing?.availability?.color == 'blue'"
                [class.green]="product?.pricing?.availability?.color == 'green'"
            >
                {{ product?.pricing?.availability?.shortText }}
            </span>
            <span *ngIf="!product.specialOrder && product?.inventory == 0 && product?.showPrice" class="orange"> Out of Stock </span>

            <div *ngIf="product.pricing.showPrice" class="category-product-price" [class.pb-2]="!product.freeShipping">
                <link [routerLink]="product.pricing.schemaOrgAvailabilityUrl | safe: 'resourceUrl'" />
                <h3 class="price-header">{{ product.price | currency }}</h3>
            </div>

            <div *ngIf="!product.pricing.showPrice" class="category-product-sales-message" [class.pb-2]="!product.freeShipping">
                Contact sales for pricing
            </div>
            <div *ngIf="product.freeShipping" class="category-product-free-shipping pb-2">
                <span>Free Shipping</span>
            </div>

            <div class="product-list-add-to-cart fx-row fx-row-h-space-between">
                <span class="mr-1">
                    <input #wideQuantity value="{{ selectedQuantity }}" type="hidden" />
                    <mat-select [(value)]="defaultSelectValue" disableOptionCentering>
                        <mat-option *ngFor="let n of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" [value]="n" (click)="updateQuantity(n)">
                            {{ n }}
                        </mat-option>
                        <mat-option class="dialog-close" [mat-dialog-close]=""> <a class="dialog-close" routerLink="/Contact-Us">Bulk</a></mat-option>
                    </mat-select>
                </span>
                <a
                    *ngIf="product.pricing.buttonText == 'Add to Cart'"
                    class="button-primary button-base col-md-8"
                    (click)="addToCart(product, wideQuantity)"
                >
                    {{ showAddedToCartMessage ? '+' + wideQuantity.value + ' to cart' : product.pricing.buttonText }}
                </a>
                <a
                    *ngIf="product.pricing.buttonText == 'See Price in Cart'"
                    class="button-primary button-base col-md-8"
                    routerLink="/checkout/cart"
                    >{{ product.pricing.buttonText }}</a
                >
                <a
                    *ngIf="product.pricing.buttonText == 'Send Inquiry'"
                    class="button-primary button-base col-md-8"
                    (click)="openBootstrapMarketoDialog('mktoForm_1490', product)"
                    >{{ product.pricing.buttonText }}</a
                >
            </div>

            <p class="single-search-contact-us m-0">
                Looking for Business Pricing?
                <a class="link-purple" [routerLink]="'/Contact-Us'">Contact Us</a>
            </p>
        </div>
    </div>
</section>

<ng-template #templateModal>
    <div id="modal-parent">
        <div class="close-icon" aria-label="Close" (click)="bsModalRef.hide()">
            <span class="d-flex align-items-center">
                <i-feather name="x"></i-feather>
            </span>
        </div>
        <div id="main-image-frame-container">
            <div class="test">
                <div id="main-image-frame">
                    <div
                        class="slider-container"
                        [ngStyle]="{
                            transform: 'translateX(-' + amount * 650 + 'px)'
                        }"
                    >
                        <div *ngFor="let imageSlide of product?.imageUrls" class="img-container">
                            <img [src]="imageSlide" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-product-page-image-tile-spc
            [(amount)]="amount"
            [slidesPerView]="4"
            [productImages]="product?.imageUrls"
            [(translateAmount)]="translateAmount"
            [widthPerSlideImage]="66"
        ></app-product-page-image-tile-spc>
    </div>
</ng-template>
