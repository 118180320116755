import { Injectable } from '@angular/core';

import { ExxComError } from 'lib/classes/exxcom-error.class';
import { isBrowser } from 'lib/tools';

const scriptName = 'message.service';

@Injectable()
export class MessageService {
    private actionPending: boolean = false;

    globals: string[] = [];
    locals: any = {};

    constructor() {
        try {
            this.initEventListeners();
        } catch (err) {
            console.error(...new ExxComError(520938, scriptName, err).stamp());
        }
    }

    private initEventListeners() {
        try {
            if (!isBrowser()) {
                return;
            }
            document.addEventListener('click', () => this.clearOnEvent());
            document.addEventListener('keydown', () => this.clearOnEvent());
        } catch (err) {
            console.error(...new ExxComError(203984, scriptName, err).stamp());
        }
    }

    private clearOnEvent() {
        try {
            if (!this.actionPending) {
                return;
            }
            this.clear();
            this.actionPending = false;
        } catch (err) {
            console.error(...new ExxComError(830934, scriptName, err).stamp());
        }
    }

    clear() {
        try {
            this.globals = [];
            this.locals = {};
        } catch (err) {
            console.error(...new ExxComError(732039, scriptName, err).stamp());
        }
    }

    addGlobal(message: string) {
        try {
            this.globals.push(message);
            return {
                clearOnAction: () => (this.actionPending = true),
                clearOnTimeout: (seconds: number) => this.clearOnTimeout(seconds),
            };
        } catch (err) {
            console.error(...new ExxComError(830394, scriptName, err).stamp());
        }
    }

    addLocal(location: string, message: string) {
        try {
            this.locals[location] = message;
            return {
                clearOnAction: () => (this.actionPending = true),
                clearOnTimeout: (seconds: number) => this.clearOnTimeout(seconds),
            };
        } catch (err) {
            console.error(...new ExxComError(783209, scriptName, err).stamp());
        }
    }

    clearOnTimeout(seconds: number) {
        try {
            if (!isBrowser()) {
                return;
            }
            setTimeout(() => this.clear(), seconds * 1000);
        } catch (err) {
            console.error(...new ExxComError(830933, scriptName, err).stamp());
        }
    }
}
