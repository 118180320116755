<div id="header-menu" data-cy="spc-header-menu">
  <nav id="header-menu-wide" class="header-row" (mouseover)="setIsActive(true)" (mouseout)="setIsActive(false)">
    <div class="header-menu-wide-level1 fx-row fx-row-h-center fx-row-v-center">
      <div
        *ngFor="let level1Category of menu"
        class="header-menu-wide-level1-item"
        data-cy="desktop-header-link-parent"
      >
        <div class="header-menu-wide-level1-label header-menu-label fx-row fx-row-h-center fx-row-v-center">
          {{ level1Category.name }}
          <mat-icon svgIcon="triangle"></mat-icon>
        </div>

        <div class="header-menu-wide-level2" [ngStyle]="{ 'height.px': level2MenuHeight, 'width.px': level2MenuWidth }">
          <div class="py-2 level2-content" data-cy="navbar-link-container">
            <div
              *ngFor="let level2Category of level1Category.categories"
              class="header-menu-wide-level2-item"
              (mouseenter)="mouseEnter(level1Category, level2Category)"
              (mouseleave)="mouseLeave()"
              data-cy="mouseover-link-animate-level2"
            >
              <a
                class="header-menu-wide-level2-label header-menu-label fx-row fx-row-h-space-between fx-row-v-center"
                [routerLink]="getLinkUrl(level2Category.href)"
              >
                {{ level2Category.name }}
                <div *ngIf="level2Category.categories" class="fx-row fx-row-h-space-between fx-row-v-center">
                  <mat-icon class="m-0" svgIcon="triangle"></mat-icon>
                </div>
              </a>

              <div
                *ngIf="level2Category.categories"
                class="py-2 header-menu-wide-level3"
                data-cy="mouseover-link-animate-level3"
              >
                <div
                  *ngFor="let level3Category of level2Category.categories"
                  class="header-menu-wide-level3-item"
                  data-cy="header-menu-wide-level3-item"
                >
                  <a
                    class="header-menu-wide-level3-label header-menu-label"
                    [routerLink]="getLinkUrl(level3Category.href)"
                    data-cy="mouseover-link-animate-level3-link"
                  >
                    {{ level3Category.name }}
                  </a>
                </div>
                <div [class.divider]="level3MenuOpen"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>

  <nav id="header-menu-narrow" (window:resize)="narrowMenu?.calculateHeight()">
    <div class="header-menu-narrow-level1" #headerMenuNarrowLevel1>
      <div
        class="header-menu-horizontal-padding header-menu-narrow-level1-top fx-row fx-row-h-space-between fx-row-v-center"
      >
        <span>Explore</span>
        <button class="header-menu-close-button" (click)="closeNarrowMenu()">
          <img src="https://assets.sabrepc.com/img/spc/misc/close-menu.svg" alt="close menu" />
        </button>
      </div>

      <div *ngFor="let level1Category of menu; index as i" [class.pt-1]="i == 0">
        <div
          class="header-menu-horizontal-padding header-menu-label header-menu-narrow-navigate-next fx-row fx-row-h-space-between"
          (click)="narrowMenu?.next($event.target, level1Category)"
          data-cy="header-content-narrow-level1-item"
        >
          {{ level1Category.name }}
          <mat-icon class="mr-base" *ngIf="level1Category.categories" svgIcon="triangle"></mat-icon>
        </div>

        <div class="header-menu-narrow-level2" #headerMenuNarrowLevel2>
          <div
            class="header-menu-narrow-level2-bar header-menu-horizontal-padding fx-row fx-row-h-space-between fx-row-v-center pt-2"
          >
            <a
              *ngIf="narrowMenu?.currentLevel != 1"
              class="link-purple fx-row fx-row-h-space-between fx-row-v-center header-menu-back"
              (click)="goToLevelOne()"
            >
              <mat-icon svgIcon="triangle"></mat-icon>
              <span>Back</span>
            </a>
            <div class="header-menu-narrow-level2-bar-title">
              <span>{{ level1Category.name }}</span>
            </div>
            <button class="header-menu-close-button" (click)="closeNarrowMenu()">
              <img src="https://assets.sabrepc.com/img/spc/misc/close-menu.svg" alt="close menu" />
            </button>
          </div>

          <div id="header-menu-level2-category-list">
            <div
              [id]="level2Category.name"
              *ngFor="let level2Category of level1Category.categories"
              data-cy="header-content-narrow-level2-item"
            >
              <a
                *ngIf="!level2Category.categories"
                class="header-menu-horizontal-padding header-menu-label"
                (click)="closeNarrowMenu()"
                [routerLink]="getLinkUrl(level2Category.href)"
              >
                {{ level2Category.name }}
              </a>
              <div>
                <div
                  *ngIf="level2Category.categories"
                  class="header-menu-horizontal-padding header-menu-label fx-row fx-row-h-space-between"
                  (click)="showCategoryChildren($event.currentTarget, level2Category.name)"
                >
                  {{ level2Category.name }}
                  <div class="header-menu-category-toggle fx-row fx-row-v-center">
                    <div class="header-menu-category-toggle-button">
                      <div class="horizontal"></div>
                      <div class="vertical"></div>
                    </div>
                  </div>
                </div>

                <div
                  class="header-menu-horizontal-padding header-menu-narrow-level2-children"
                  data-cy="header-content-narrow-category-children"
                >
                  <a
                    class="header-menu-label all-category all"
                    (click)="closeNarrowMenu()"
                    [routerLink]="getLinkUrl(level2Category.href)"
                  >
                    All {{ level2Category.name }}
                  </a>
                  <div *ngFor="let level3Category of level2Category.categories">
                    <a
                      class="header-menu-label"
                      (click)="closeNarrowMenu()"
                      [routerLink]="getLinkUrl(level3Category.href)"
                    >
                      {{ level3Category.name }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="header-menu-narrow-spacer"></div>
          </div>
        </div>
      </div>

      <div class="header-menu-narrow-spacer"></div>
      <div class="fx-col fx-col-h-left header-menu-label header-menu-horizontal-padding">
        <div class="header-menu-narrow-spacer">
          <a
            routerLink="/account"
            (click)="narrowMenu?.toggle()"
            class="button-primary fx-row fx-row-v-center fx-row-h-space-between"
          >
            <span class="pr-1 menu-account">Account</span> <mat-icon class="m-0" svgIcon="user"></mat-icon
          ></a>
        </div>
        <div class="header-menu-narrow-button-spacer"></div>
        <div class="header-menu-narrow-spacer header-menu-label header-menu-horizontal-padding">
          <a routerLink="/Contact-Us" (click)="narrowMenu?.toggle()" class="contact-us link-purple">Contact Us</a>
        </div>
      </div>
    </div>

    <div
      id="header-menu-narrow-toggle"
      #headerMenuNarrowToggle
      (click)="narrowMenu?.toggle()"
      data-cy="header-menu-narrow-toggle"
    >
      <div *ngIf="narrowMenu">
        <mat-icon [ngClass]="narrowMenu.menuOpen ? 'hide' : ' '" svgIcon="menu"></mat-icon>
        <mat-icon [ngClass]="!narrowMenu.menuOpen ? 'hide' : ' '" svgIcon="x"></mat-icon>
      </div>
    </div>
  </nav>
</div>
