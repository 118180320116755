import { SimpleChanges } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { ConfigurationProductChanges, ConfigurationProductData } from 'lib/services/configuration-product/configuration-product.interface';
import { isBoolean } from 'lodash';

const scriptName = 'ConfigurationProduct.class';

export class ConfigurationProduct {
    // Schema

    _id?: string = null;
    changes?: ConfigurationProductChanges[] = [];
    createdAt?: string | Date = null;
    images?: string[] = [];
    manufacturer?: string = null;
    mpn?: string = null;
    name?: string = null;
    ncnr?: boolean = null;
    preOrderDate?: string | Date = null;
    urlComponent?: string = null;
    type?: string = null;
    quantity?: number = 1;
    cost?: number = 0.0;
    price?: number = 0.0;
    productLine?: string = '';
    wattage?: number = 0.0;
    memory?: number = 0;
    slotSpace?: number = 0;
    nvLinkSupported?: boolean = false;
    nvLinksPerPair?: number = 0;
    numPorts?: number = null;
    connectorType?: string = null;
    // Client-side only

    changed$?: BehaviorSubject<SimpleChanges> = new BehaviorSubject<SimpleChanges>(null);

    constructor(data?: ConfigurationProductData) {
        if (data) {
            this.init(data);
        }
    }

    init(data: ConfigurationProductData) {
        try {
            const changes: SimpleChanges = {};
            // const isFirstChange = !this._id;
            this._id = data._id || this._id || null;
            this.changes = data.changes || this.changes || [];
            this.createdAt = data.createdAt ? new Date(data.createdAt) : this.createdAt || null;
            this.manufacturer = data.manufacturer || this.manufacturer || null;
            this.mpn = data.mpn || this.mpn || null;
            this.name = data.name || this.name || null;
            this.productLine = data.productLine || this.productLine || null;
            this.type = data.type || this.type || null;
            this.urlComponent = data.urlComponent || this.urlComponent || null;
            this.price = data.price || this.price || null;
            this.quantity = data.quantity || this.quantity || null;
            this.cost = data.cost || this.cost || null;
            this.wattage = data.wattage || this.wattage || null;
            this.memory = data.memory || this.memory || null;
            this.slotSpace = data.slotSpace || this.slotSpace || null;
            this.nvLinkSupported = isBoolean(data.nvLinkSupported) ? data.nvLinkSupported : null;
            this.nvLinksPerPair = data.nvLinksPerPair || this.nvLinksPerPair || null;
            this.numPorts = data.numPorts || this.numPorts || null;
            this.connectorType = data.connectorType || this.connectorType || null;
            this.changed$.next(changes);
        } catch (err) {
            console.error(...new ExxComError(429177, scriptName, err).stamp());
        }
    }
}
