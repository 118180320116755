import { Injectable } from '@angular/core';

import { Address } from 'lib/services/address/address.class';
import { AddressData, AddressResponse } from 'lib/services/address/address.interface';
import { ApiService } from 'lib/services/api.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { ExxComResponse } from 'lib/interfaces/exxcom-response.interface';
import { get } from 'lodash';

const scriptName = 'address.service';

let apiService: ApiService;

@Injectable()
export class AddressService {
    constructor(a: ApiService) {
        try {
            apiService = a;
        } catch (err) {
            console.error(...new ExxComError(420476, scriptName, err).stamp());
        }
    }

    // Core API

    /**
     * @function get
     * @description Enables retrieving one or more address document(s) by _id,
     * email.
     */
    private async get({
        _id,
        email,
        query,
        limit,
    }: {
        _id?: string;
        email?: string;
        query?: any;
        limit?: number;
    } = {}): Promise<AddressResponse> {
        try {
            let filters: any = {};
            if (_id) {
                filters._id = _id;
            } else if (email) {
                filters.email = email;
            }
            if (query) {
                filters = Object.assign(filters, query);
            }
            const url = ['addresses/find', `?filters=${JSON.stringify(filters)}`];
            if (limit) {
                url.push(`&limit=${limit}`);
            }
            const res: AddressResponse = await apiService.get(url);
            if (!res.success) {
                throw res;
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(304736, scriptName, err).stamp());
            return { success: false, error: err } as AddressResponse;
        }
    }

    /**
     * @function update
     * @description Enables updating a given address.
     */
    async update({ values, action, address }: { values: any; action?: string; address?: Address }): Promise<AddressResponse> {
        try {
            const url = [`addresses/update/${address._id}`];
            if (action) {
                url.push(`/${action}`);
            }
            const res: AddressResponse = await apiService.post(url, values);
            if (!res.success) {
                throw res;
            }
            address.init(res.data as AddressData);
            return res;
        } catch (err) {
            console.error(...new ExxComError(503277, scriptName, err).stamp());
            return { success: false, error: err } as ExxComResponse;
        }
    }

    /**
     * @function create
     * @description Enables creating new addresses.
     */
    async create(values: AddressData): Promise<AddressResponse> {
        try {
            const res: AddressResponse = await apiService.post('addresses/create', values);
            if (!res.success) {
                throw res;
            }
            return res;
        } catch (err) {
            const message = get(err, 'error.message');
            if (message != 'Already exists') {
                console.error(...new ExxComError(336993, scriptName, err).stamp());
            }
            return err as ExxComResponse;
        }
    }

    /**
     * @function delete
     * @description Deletes a address from the database.
     */
    async delete(_id: string): Promise<ExxComResponse> {
        try {
            const res: ExxComResponse = await apiService.delete(`addresses/delete/${_id}`);
            if (!res.success) {
                throw res;
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(257967, scriptName, err).stamp());
            return { success: false, error: err } as ExxComResponse;
        }
    }

    // Get API

    /**
     * @function getDetached
     * @description For retrieving instantiated and initialized address or
     * addresses.
     */
    async getDetached({
        _id,
        email,
        query,
        limit,
    }: {
        _id?: string;
        email?: string;
        query?: any;
        limit?: number;
    } = {}): Promise<Address[]> {
        try {
            const res: AddressResponse = await this.get({
                _id,
                email,
                query,
                limit,
            });
            if (res.error) {
                throw res;
            }
            const addresses: Address[] = ((res.data as AddressData[]) || []).map((data: AddressData) => new Address(data));
            return addresses;
        } catch (err) {
            console.error(...new ExxComError(535308, scriptName, err).stamp());
            return [];
        }
    }
}
