import { Injectable, Inject } from '@angular/core';
import { ExxComError } from 'lib/classes/exxcom-error.class';

import { ApiService } from '../api.service';
import { CartService } from 'lib/services/cart/cart.service';

import {
    VoucherifyValidationData,
    VoucherifyValidationResponse,
    VoucherifyRedemptionData,
    VoucherifyRedemptionResponse,
    VoucherifyValidationStackableData,
    VoucherifyValidationStackableResponse,
    VoucherifyRedemptionStackableData,
    VoucherifyRedemptionStackableResponse,
    VoucherifyValidationRuleResponse,
    VouchersResponse,
} from './voucherify.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { DiscountData } from '../order/order.interface';
import { StackableRedeemableParams } from '@voucherify/sdk';

const scriptName = 'voucherify-service';

let apiService: ApiService;

@Injectable({
    providedIn: 'root',
})

// https://voucherifyio.github.io/voucherify-js-sdk/index.html
export class VoucherifyService {
    private couponData = new BehaviorSubject<Map<string, object>>(new Map());
    couponData$: Observable<Map<string, object>> = this.couponData.asObservable();

    private discountedItems = new BehaviorSubject<Map<string, object>>(new Map());
    discountedItems$: Observable<Map<string, object>> = this.discountedItems.asObservable();

    private discountTotal = new BehaviorSubject<number>(0);
    discountTotal$: Observable<number> = this.discountTotal.asObservable();

    private freeShipping = new BehaviorSubject<boolean>(false);
    freeShipping$: Observable<boolean> = this.freeShipping.asObservable();

    private freeShippingMethod = new BehaviorSubject<string>('');
    freeShippingMethod$: Observable<string> = this.freeShippingMethod.asObservable();

    private shippingMethod = new BehaviorSubject<string>('');
    shippingMethod$: Observable<string> = this.shippingMethod.asObservable();

    constructor(
        @Inject('environment') _e: any,
        a: ApiService,
        private cartService: CartService
    ) {
        try {
            apiService = a;
        } catch (err) {
            throw new ExxComError(888000, scriptName, err).stamp();
        }
    }

    // async validateCoupon(code: string, params: VoucherifyValidationData['params']): Promise<VoucherifyValidationResponse> {
    async validateCoupon(code: string): Promise<VoucherifyValidationResponse> {
        try {
            const tempAmount = this.cartService.cart.summary.subtotal * 100;
            const tempEmail = this.cartService.cart.email;
            const tempItems = [];
            this.cartService.cart.items.forEach((item) => {
                tempItems.push({
                    source_id: item.product.name,
                    related_object: 'product',
                    quantity: item.quantity,
                    price: item.price * 100,
                });
            });

            tempItems.push({
                source_id: '5h1pp1ng',
                related_object: 'product',
                price: this.cartService.cart.summary.shipping * 100,
            });

            const body: VoucherifyValidationData = {
                code,
                params: {
                    order: {
                        amount: tempAmount,
                        items: tempItems,
                    },
                    customer: {
                        id: tempEmail,
                        source_id: tempEmail,
                        email: tempEmail,
                    },
                },
            };
            const res: VoucherifyValidationResponse = await apiService.post(`voucherify/validate`, body);
            return res;
        } catch (err) {
            throw new ExxComError(888011, scriptName, err).stamp();
        }
    }

    async redeemCoupon(code: string, params: VoucherifyRedemptionData['params']): Promise<VoucherifyRedemptionResponse> {
        try {
            const body: VoucherifyRedemptionData = { code, params };
            const res: VoucherifyRedemptionResponse = await apiService.post(`voucherify/redeem`, body);
            return res;
        } catch (err) {
            throw new ExxComError(888012, scriptName, err).stamp();
        }
    }

    async validateCouponStackable(params: VoucherifyValidationStackableData['params']): Promise<VoucherifyValidationStackableResponse> {
        try {
            const body: VoucherifyValidationStackableData = { params };
            const res: VoucherifyValidationStackableResponse = await apiService.post(`voucherify/validatestackable`, body);
            return res;
        } catch (err) {
            throw new ExxComError(888001, scriptName, err).stamp();
        }
    }

    // async redeemCouponStackable(params: VoucherifyRedemptionStackableData['params']): Promise<VoucherifyRedemptionStackableResponse> {
    async redeemCouponStackable(): Promise<VoucherifyRedemptionStackableResponse> {
        try {
            if (this.cartService.cart.discountedSummary.couponData) {
                const couponData = new Map(Object.entries(this.cartService.cart.discountedSummary.couponData));

                const tempStackableRedeemableParams: StackableRedeemableParams[] = [];

                couponData.forEach((value, key) => {
                    const tempRedeemable: StackableRedeemableParams = {
                        object: 'voucher',
                        id: key,
                    };

                    tempStackableRedeemableParams.push(tempRedeemable);
                });

                const tempAmount = this.cartService.cart.summary.subtotal * 100;
                const tempEmail = this.cartService.cart.email;
                const tempItems = [];
                this.cartService.cart.items.forEach((item) => {
                    tempItems.push({
                        source_id: item.product.name,
                        related_object: 'product',
                        quantity: item.quantity,
                        price: item.price * 100,
                    });
                });

                tempItems.push({
                    source_id: '5h1pp1ng',
                    related_object: 'product',
                    price: this.cartService.cart.summary.shipping * 100,
                });

                const reqBody: VoucherifyRedemptionStackableData['params'] = {
                    redeemables: tempStackableRedeemableParams,
                    customer: {
                        id: tempEmail,
                        source_id: tempEmail,
                        email: tempEmail,
                    },
                    order: {
                        amount: tempAmount,
                        items: tempItems,
                    },
                };
                const res: VoucherifyRedemptionStackableResponse = await apiService.post(`voucherify/redeemstackable`, reqBody);
                return res;
            }
        } catch (err) {
            throw new ExxComError(888002, scriptName, err).stamp();
        }
    }

    async getValidationRule(validationRuleId: string): Promise<VoucherifyValidationRuleResponse> {
        try {
            const res: VoucherifyValidationRuleResponse = await apiService.get(`voucherify/validation/rule/${validationRuleId}`);
            return res;
        } catch (err) {
            throw new ExxComError(888003, scriptName, err).stamp();
        }
    }

    async getVoucher(code: string): Promise<VouchersResponse> {
        try {
            const res: VouchersResponse = await apiService.get(`voucherify/voucher/${code}`);
            return res;
        } catch (err) {
            throw new ExxComError(888004, scriptName, err).stamp();
        }
    }

    getDiscountOrderData() {
        const cart = this.cartService.cart;
        const discountData: DiscountData = {
            couponData: cart.discountedSummary.couponData,
            discountedItems: cart.discountedSummary.discountedItems,
            freeShipping: cart.discountedSummary.freeShipping,
            freeShippingMethod: cart.discountedSummary.freeShippingMethod,
            freeShippingMethodPrice: cart.discountedSummary.freeShippingMethodPrice,
            summary: {
                shipping: cart.discountedSummary?.summary?.shipping || 0,
                subtotal: cart.discountedSummary?.summary?.subtotal || 0,
                tax: cart.discountedSummary?.summary?.tax || 0,
                total: cart.discountedSummary?.summary?.total || 0,
            },
        };

        return discountData;
    }

    resetValues(): void {
        this.couponData.next(new Map());
        this.discountedItems.next(new Map());
        this.discountTotal.next(0);
        this.freeShipping.next(false);
        this.freeShippingMethod.next('');
        this.cartService.updateDiscounts({
            couponData: null,
            discountedItems: null,
            discountTotal: 0,
            freeShipping: null,
            freeShippingMethod: null,
        });
    }

    updateCouponData(data: Map<string, object>) {
        this.couponData.next(data);
    }

    updateDiscountedItems(data: Map<string, object>) {
        this.discountedItems.next(data);
    }

    updateDiscountTotal(newValue: number) {
        this.discountTotal.next(newValue);
    }

    updateFreeShipping(newVal: boolean) {
        this.freeShipping.next(newVal);
    }

    updateFreeShippingMethod(newVal: string) {
        this.freeShippingMethod.next(newVal);
    }

    updateShippingMethod(newVal: string) {
        this.shippingMethod.next(newVal);
    }
}
