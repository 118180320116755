<div id="product-page-slider" *ngIf="productImages.length > 1">
  <div class="swiper-bottom-navigation fx-row fx-row-v-center fx-row-h-center">
    <div (click)="slideChange(null, 'left')" class="arrow-container-circle">
      <div class="arrow-container">
        <i-feather name="chevron-left" class="chevron-left"></i-feather>
      </div>
    </div>

    <div id="product-swiper-image-container-preview" [style.maxWidth.px]="widthPerSwiperImage * slidesPerView">
      <div
        id="image-swiper-container"
        class="d-flex flex-row justify-content-center"
        [ngStyle]="{
          width: widthPerSwiperImage * productImages.length + 'px',
          transform: 'translateX(' + translateAmount + 'px)'
        }"
      >
        <div
          *ngFor="let swiperSlide of productImages; let i = index"
          (click)="slideChange(i)"
          class="product-swiper-image-container product-swiper-image"
          [class.active-swiper-product]="i == amount"
        >
          <img [src]="swiperSlide" />
        </div>
      </div>
    </div>

    <div (click)="slideChange(null, 'right')" class="arrow-container-circle">
      <div class="arrow-container">
        <i-feather name="chevron-right" class="chevron-right"></i-feather>
      </div>
    </div>
  </div>
</div>
