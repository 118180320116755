import { AbstractControl } from '@angular/forms';

import { countries } from 'lib/lists/countries';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export function validatePhone(control: AbstractControl): { [key: string]: boolean } | null {
    let isValid: boolean = false;
    if (!control.value) {
        return null;
    }
    if (control.value.length < 7) {
        return { phoneInvalid: true };
    }
    countries.forEach((country: any) => {
        const phoneNumber = parsePhoneNumberFromString(control.value, country.code);
        if (phoneNumber && phoneNumber.isValid()) {
            isValid = true;
            return false;
        }
    });
    return isValid ? null : { phoneInvalid: true };
}
