import { Inject, Injectable } from '@angular/core';

import { ExxComError } from 'lib/classes/exxcom-error.class';
import { isBrowser } from 'lib/tools';
import { RouterService } from 'lib/services/router.service';

const scriptName = 'gtm.service';

declare let dataLayer: any;

let environment: any;

// https://support.google.com/tagmanager/topic/6333310?hl=en&ref_topic=3002579

@Injectable()
export class GtmService {
    constructor(@Inject('environment') e: any, r: RouterService) {
        try {
            environment = e;
        } catch (err) {
            console.error(...new ExxComError(732888, scriptName, err).stamp());
        }
    }

    init() {
        try {
            this.loadScript();
        } catch (err) {
            console.error(...new ExxComError(839984, scriptName, err).stamp());
        }
    }

    private loadScript() {
        try {
            if (!isBrowser()) {
                return;
            }
            const trackingId = environment.google[environment.envAbbr].gtmId;
            const script = document.createElement('script');
            script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${trackingId}');`;
            document.head.appendChild(script);
        } catch (err) {
            console.error(...new ExxComError(442398, scriptName, err).stamp());
        }
    }

    trackAddToCart(item: any, quantity: number | string) {
        try {
            if (!isBrowser()) {
                return;
            }
            dataLayer.push({
                event: 'addToCart',
                data: {
                    name: item.description,
                    id: item.mpn,
                    price: item.price,
                    quantity: quantity,
                },
            });
        } catch (err) {
            console.error(...new ExxComError(839484, scriptName, err).stamp());
        }
    }

    trackCheckOutPages(path: string) {
        try {
            if (!isBrowser()) {
                return;
            }
            dataLayer.push({
                event: path,
            });
        } catch (err) {
            console.error(...new ExxComError(839777, scriptName, err).stamp());
        }
    }

    trackGoals(formId: string) {
        try {
            if (!isBrowser()) {
                return;
            }
            dataLayer.push({
                event: 'goalsTracking',
                data: {
                    category: 'marketoforms',
                    action: formId,
                    label: formId,
                },
            });
        } catch (err) {
            console.error(...new ExxComError(884532, scriptName, err).stamp());
        }
    }

    trackMarketoFormSubmission(formId: string) {
        try {
            if (!isBrowser()) {
                return;
            }
            dataLayer.push({
                event: 'marketoFormTracking',
                data: {
                    action: formId,
                },
            });
        } catch (err) {
            console.error(...new ExxComError(293992, scriptName, err).stamp());
        }
    }

    trackProductView(item: any) {
        try {
            if (!isBrowser()) {
                return;
            }
            dataLayer.push({
                event: 'productView',
                data: {
                    id: item.mpn,
                    name: item.description,
                    variant: '',
                    price: item.price ? item.price.toFixed(2) : '',
                    category: item.category,
                    list: '',
                },
            });
        } catch (err) {
            console.error(...new ExxComError(938842, scriptName, err).stamp());
        }
    }

    trackTransaction(transaction: any) {
        try {
            if (!isBrowser()) {
                return;
            }
            dataLayer.push({
                event: 'transaction',
                data: {
                    action: 'Transaction id: ' + transaction.refNumber,
                    transactionId: transaction.refNumber,
                    transactionAffiliation: '',
                    transactionSubTotal: '',
                    transactionTotal: transaction.amountTotal,
                    transactionTax: transaction.amountTax,
                    transactionShipping: transaction.amountShipping,
                    transactionCurrency: 'USD',
                    transactionProducts: transaction.lines.map((product: any) => {
                        return {
                            sku: product.item,
                            name: product.product.description,
                            category: product.product.category,
                            price: product.product.price,
                            quantity: product.quantity,
                            variant: '',
                        };
                    }),
                },
            });
        } catch (err) {
            console.error(...new ExxComError(439843, scriptName, err).stamp());
        }
    }
}
