<ng-container *ngIf="!isEmpty(get(subcategoryLinksRow, 'subcategory_links', ''))">
  <div *ngFor="let row of get(subcategoryLinksRow, 'subcategory_links.subcategory_links', []); let i = index">
    <section id="rows" class="d-flex flex-column container align-items-center subcategory">
      <div class="category-top-title d-flex flex-row justify-content-center pb-2">
        <h2 *ngIf="get(row, 'headline', '')" class="col-md-7 text-center p-0">
          {{ row.headline }}
        </h2>
      </div>
      <div class="grid partial-grid link-container">
        <div
          class="d-flex flex-row subcategory-link g-col-12 g-col-lg-4 g-col-md-6"
          (click)="navigateByUrl(subcategory?.link?.href)"
          *ngFor="let subcategory of get(row, 'subcategory_links', [])"
        >
          <div
            [style.background-image]="
              'url(' + getWebpImg('https://assets.sabrepc.com/img/spc/misc/partial/Subcat-bg.jpg') + ')'
            "
            class="subcategory-background d-flex flex-row align-items-center"
          >
            <div #systemImage>
              <img
                *ngIf="subcategory?.system_image?.href"
                [src]="getWebpImg(subcategory.system_image.href)"
                [alt]="subcategory.system_image.title"
              />
            </div>
          </div>
          <div class="subcategory-text p-2">
            <span *ngIf="subcategory?.category_name" class="bold font-color">{{ subcategory?.category_name }}</span>
            <p *ngIf="subcategory?.short_description" class="truncate-ellipsis">
              {{ subcategory?.short_description }}
            </p>
            <a *ngIf="subcategory?.link" class="link-purple" [routerLink]="subcategory?.link?.href">Learn More</a>
          </div>
        </div>
      </div>
    </section>
  </div>
</ng-container>
<hr *ngIf="get(subcategoryLinksRow, 'subcategory_links.split_line', '')" class="m-0" />
