import { contains, isBrowser } from 'lib/tools';
import { ExxCom } from './exxcom.class';
import { ExxComError } from 'lib/classes/exxcom-error.class';

const scriptName = 'swiper-slide.class';

export class SwiperSlide extends ExxCom {
    category: string = '';
    date: string = '';
    heading: string = '';
    imageUrl: string = '';
    lightTheme: boolean = false;
    linkUrl: string = '';
    paragraph: string = ''; // used for quote copy
    readTime: string = '';
    secondHeading: string = '';

    constructor(properties?: any) {
        super();
        try {
            this.setMany(properties);
            this.parseLinkUrl();
        } catch (err) {
            console.error(...new ExxComError(798823, scriptName, err).stamp());
        }
    }

    /**
     * @function parseLinkUrl
     * @description Parses a link URL from various formats into a standadized
     * format: /Url-Component, which routerLink expects. If there is no link or
     * there is an error, then the link points to the current page.
     */
    private parseLinkUrl() {
        try {
            if (!isBrowser()) {
                return;
            }

            if (!this.linkUrl || typeof this.linkUrl != 'string') {
                this.linkUrl = window.location.pathname;
                return;
            } // Stays on current page

            if (contains(this.linkUrl, ['https', 'http'])) {
                const urlParts = this.linkUrl.split('/') || [];
                const path = urlParts[3];
                this.linkUrl = `/${path ? path : ''}`;
            } else if (this.linkUrl.indexOf('/') != 1) {
                this.linkUrl = `/${this.linkUrl}`;
            }
        } catch (err) {
            this.linkUrl = window.location.pathname;
            console.error(...new ExxComError(629985, scriptName, err).stamp());
        }
    }
}
