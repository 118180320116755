import { SafePipe } from 'lib/pipes/safe.pipe';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from 'lib/interceptors/auth.interceptor';
import { UniversalInterceptor } from 'lib/interceptors/universal.interceptor';
import { ModSwiperComponent } from 'lib/components/mod-swiper/mod-swiper.component';
import { OverlayComponent } from 'lib/components/overlay/overlay.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ProductRecommendedComponent } from 'lib/components/mod-swiper/swiper-slide-templates/product-recommended/product-recommended.component';
import { MaterialModule } from './material.module';
import { RouterModule } from '@angular/router';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { IconsModule } from './feather-icons.module';
import { environment } from '../../spc/src/environments/environment';

@NgModule({
    declarations: [SafePipe, ModSwiperComponent, OverlayComponent, ProductRecommendedComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MaterialModule,
        RouterModule,
        IconsModule,
        NgxGpAutocompleteModule.forRoot({
            loaderOptions: {
                apiKey: environment.google.api_key,
                libraries: ['places'],
            },
        }),
    ],
    exports: [
        SafePipe,
        ModSwiperComponent,
        CommonModule,
        MatIconModule,
        MaterialModule,
        RouterModule,
        OverlayComponent,
        ProductRecommendedComponent,
        IconsModule,
        NgxGpAutocompleteModule,
    ],
})
export class SpecialModule {
    static forRoot(environment?: any): ModuleWithProviders<SpecialModule> {
        return {
            ngModule: SpecialModule,
            providers: [
                { provide: 'environment', useValue: environment || {} },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: UniversalInterceptor,
                    multi: true,
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: AuthInterceptor,
                    multi: true,
                },
            ],
        };
    }
}
