import { ExxComError } from 'lib/classes/exxcom-error.class';
import { WebstoreProductPricing } from 'lib/services/webstore-product/webstore-product-pricing.class';

import * as moment from 'moment';
import { contains, isValidDate } from 'lib/tools';
import { invert, isEmpty, last } from 'lodash';

const scriptName = 'webstore-product.class';

/**
 * PRODUCT PROPERTY CONSISTENCY
 * ============================
 *
 * For consistency, Webstore Product fields here (client-side) must reflect
 * those in the datahase schema (server-side) at
 * severs/lib/schemas/webstore/product.js. This ensures the same field names are
 * used throughout the application, rather than having a property which
 * represents one field have multiple names.
 *
 * Also, if an attribute is added to STEP, or a field is added to SearchSpring,
 * and if they must be used in ExxCom, then those must be defined in the
 * database schema. Again, this ensures the same field name can be referenced
 * anywhere within the application, and provides one point of entry where the
 * field names are maintained. It also encourages thoughtful field creation and
 * standardized naming practices.
 *
 * The STEP attributes are converted by Exxtools before the products are added
 * to the database. However, because the fields are retrieved dynamically from
 * SearchSpring, where the names are also different, they have to be converted
 * here with maps. Also, if any parsing needs to be done on the field, then they
 * have to be defined in the maps. If the names are the same, and the value does
 * not need to be parsed, then there is no need to define them in the map.
 */

/**
 * @namespace ssToExc
 * @description Provides a SearchSpring to ExxCom property map that is used by
 * the Webstore Product class to turn SearchSpring property names into ExxCom
 * property names, which makes accessing the properties everywhere throughout
 * the ExxCom clients consistent.
 */
const ssToExc = {
    shared: {
        meta_description: 'metaDescription',
        ncnr: 'ncnr', // Must be from "Yes"/"No" converted to boolean
        netsuite_id: 'nsid',
        pre_order_date: 'preOrderDate', // Must be a JavaScript Date
        show_price_in_cart: 'showPriceInCart', // Must be from "Yes"/"No" converted to boolean
        special_order: 'specialOrder',
        searchspring_image: 'images',
        thumbnailImageUrl: 'images',
        url: 'urlComponent',
    },
    site: {
        exx: {
            don_t_show_price: 'showPrice',
            sales_description: 'description',
            url_component: 'urlComponent',
        },
        spc: {
            don_t_show_sabrepc_price: 'showPrice', // Must be from "Yes"/"No" converted to boolean and inverted
            sabrepc_breadcrumb: 'breadcrumb',
            sabrepc_description: 'description',
            sabrepc_free_shipping: 'freeShipping',
            sabrepc_inventory: 'inventory', // Must be a number
            sabrepc_price: 'price', // Must be a number
            sabrepc_url_component: 'urlComponent',
        },
    },
};

/**
 * @function getProductImageUrl
 * @param {String} imageUrlPart The part of the image URL that contains the image file name.
 * @param {Object} environmentUrls The URLs on the site-specific environment.
 * @description The purpose of this function is to provide consistent parsing of
 * the product image URLs. It is not part of the Webstore Product class becasue
 * it is not necessary to have an instance of the Webstore Product class to use
 * it.
 */
export function getProductImageUrl(imageUrlPart: string, environmentUrls: any) {
    try {
        let filename: string;
        if (imageUrlPart) {
            if (contains(imageUrlPart, '/ajax_search/img/') || contains(imageUrlPart, 'no-image')) {
                filename = '';
            } else {
                filename = parseFilename(imageUrlPart);
            }
        }
        const imageUrl = filename ? `${environmentUrls.image.productBase}${filename}.jpg` : environmentUrls.image.noImage;
        return imageUrl;
    } catch (err) {
        console.error(...new ExxComError(829094, scriptName, err).stamp());
    }
}

/**
 * @function parseBreadcrumb
 * @param {String} value - A breadcrumb from SearchSpring
 * @param {WebstoreProduct} product - A WebstoreProduct instance
 * @description Parses a SearchSpring breadcrumb into categories. This function
 * does essentially the same as the the lines in
 * webstore-sync.products > getStep > parseValue, except there the purpose is
 * to put the values in the ExxCom databse, whereas here it is to parse the
 * SearchSpring breadcrumb (which also comes from STEP).
 */
function parseBreadcrumb(value: string, product: WebstoreProduct) {
    try {
        const categories = value && value.split('&gt;');
        product.topCategory = (value && categories[0]) || '';
        product.category = (value && categories[1]) || '';
        product.subcategory = (value && categories[2]) || '';
        return value;
    } catch (err) {
        console.error(...new ExxComError(492388, scriptName, err).stamp());
    }
}

/**
 * @function parseDate
 * @param {String} value
 * @description Parses a date in the expected format (YYYY-MM-DD) to a
 * JavaScript Date object.
 */
function parseDate(value: any) {
    try {
        if (isValidDate(value)) {
            return value;
        } else if (!value || typeof value != 'string') {
            return null;
            // eslint-disable-next-line no-useless-escape
        } else if (!/^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/.test(value)) {
            console.error(
                382005,
                [
                    'Invalid product date format. Check the SearchSpring results object by',
                    'going to a SearchSpring page where you know a product has a date on it',
                    'and then logging the "data" object that gets passed into the',
                    'constructor, and check what the PIM is exporting to SearchSpring.',
                ].join(' ')
            );
        }
        return moment(value, 'YYYY-MM-DD').toDate();
    } catch (err) {
        console.error(...new ExxComError(329993, scriptName, err).stamp());
    }
}

/**
 * @function parseFilename
 * @param {String} value
 * @description Parses the filename only from a path. It does not include the
 * directories or file extension.
 */
function parseFilename(value: string) {
    try {
        if (!value) {
            return null;
        }
        return last(value.split('/')).split('.')[0];
    } catch (err) {
        console.error(...new ExxComError(298833, scriptName, err).stamp());
    }
}

/**
 * @function parseUrlComponent
 * @param {String} urlComponent
 * @description Ensures there is always a leading / for routerLink.
 */
function parseUrlComponent(value: string): string {
    try {
        return value.indexOf('/') == 0 ? value : `/${value}`;
    } catch (err) {
        console.error(...new ExxComError(520958, scriptName, err).stamp());
    }
}

let environment: any;

export class WebstoreProduct {
    // Database properties

    /**
     * Properties defined on the databse schema must be defined here as well. The
     * site-specific properties defined on the schema are not put into a separate
     * "sites" property here, however, because the products-find.endpoint parses
     * the product so its site-specific properties are flattened onto the main
     * object.
     */

    private images: string[] = []; // Parsed into imageUrls

    _id: string = '';
    breadcrumb: string = '';
    category: string = '';
    cmsGroupId: string = '';
    condition: string = '';
    description: string = '';
    freeShipping: boolean = false;
    height: number = 0;
    highlights: string = '';
    inventory: number = 0;
    leadTime: string = '';
    length: number = 0;
    manufacturer: string = '';
    marketingInfo: string = '';
    metaDescription: string = '';
    mpn: string = '';
    name: string = '';
    ncnr: boolean = false;
    nsid: number = 0;
    preOrderDate: Date = null;
    price: number = 0;
    showPrice: boolean = false;
    showPriceInCart: boolean = false;
    specialOrder: boolean = false;
    specifications: string = '';
    subcategory: string = '';
    topCategory: string = '';
    upc: string = '';
    urlComponent: string = '';
    weight: number = 0;
    width: number = 0;

    // Client-side only properties

    imageUrl: string = '';
    imageUrls: string[] = [];
    pricing: WebstoreProductPricing;
    // quantityInCart: number = 0;

    constructor(data: any, dependencies: any) {
        try {
            if (isEmpty(data)) {
                return;
            }
            environment = dependencies.environment;
            this.initProperties(data);
        } catch (err) {
            console.error(...new ExxComError(298844, scriptName, err).stamp());
        }
    }

    private initProperties(data: any) {
        try {
            const isSearchSpring = !!data.isSearchSpring;
            delete data.isSearchSpring; // Set in ExxCom immediately before passing data to Webstore Product class, not needed afterward
            delete data.imageUrl; // Parsed in SearchSpring > Data Settings > Core Mappings, not needed
            let ssToExcAll;
            let ssToExcAllInverted;
            if (isSearchSpring) {
                ssToExcAll = Object.assign(ssToExc.shared, ssToExc.site[environment.siteAbbr]);
                ssToExcAllInverted = Object.keys(invert(ssToExcAll));
            }

            for (const k in data) {
                if (Object.prototype.hasOwnProperty.call(data, k)) {
                    let v = data[k];
                    let excKey = '';

                    if (isSearchSpring) {
                        if (v == 'Yes') {
                            v = true;
                        } else if (v == 'No') {
                            v = false;
                        }
                        excKey = ssToExcAll[k];
                        if (excKey) {
                            if (excKey == 'showPrice') {
                                v = !v;
                            } else if (excKey == 'images') {
                                v = isEmpty(this.images) ? [parseFilename(v)] : this.images;
                            } else if (excKey == 'inventory') {
                                v = parseInt(v);
                            } else if (excKey == 'price') {
                                v = parseFloat(v);
                            } else if (excKey == 'preOrderDate') {
                                v = parseDate(v);
                            } else if (excKey == 'breadcrumb') {
                                v = parseBreadcrumb(v, this);
                            }
                            this[excKey] = v;
                        }
                    }

                    // eslint-disable-next-line no-prototype-builtins
                    if (
                        // eslint-disable-next-line no-prototype-builtins
                        this.hasOwnProperty(k) &&
                        !contains(ssToExcAllInverted || [], k)
                    ) {
                        this[k] = v;
                    }
                }
            }

            // Some SearchSpring products are returned without any image properties, so they are not mapped above
            this.imageUrls = isEmpty(this.images)
                ? [getProductImageUrl(null, environment.urls)]
                : this.images.map((i: string) => getProductImageUrl(i || null, environment.urls));
            this.imageUrl = this.imageUrls[0];
            this.pricing = new WebstoreProductPricing(this);
            this.urlComponent = parseUrlComponent(this.urlComponent);
        } catch (err) {
            console.error(...new ExxComError(892773, scriptName, err).stamp());
        }
    }
}
