<div id="cms-blog-header-menu">
  <nav id="cms-blog-header-menu-wide" class="header-row m-0">
    <div class="cms-blog-header-menu-wide-level1 fx-row fx-row-h-center fx-row-v-center">
      <div
        id="cms-blog-header-menu-wide-level1-all"
        class="cms-blog-header-menu-wide-level1-item"
        (mouseover)="setIsActive(menu && menu.length > 0)"
        (mouseout)="setIsActive(false)"
        data-cy="cms-blog-header-menu-wide"
      >
        <div id="cms-blog-header-menu-wide-level1-label" class="cms-blog-header-menu-label">
          Categories
          <mat-icon id="cms-blog-header-menu-wide-level1-label-icon">arrow_drop_down_right</mat-icon>
        </div>

        <div class="cms-blog-header-menu-wide-level2 mat-elevation-dropdown" data-cy="cms-blog-header-menu-level2-wide">
          <div *ngFor="let category of menu" class="cms-blog-header-menu-wide-level2-item">
            <a
              class="cms-blog-header-menu-wide-level2-label cms-blog-header-menu-label"
              [routerLink]="category.routerLink"
            >
              {{ category.title }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>

  <nav id="cms-blog-header-menu-narrow" (window:resize)="narrowMenu?.calculateHeight()">
    <div
      id="cms-blog-header-menu-narrow-toggle"
      #headerMenuNarrowToggle
      (click)="narrowMenu?.toggle()"
      data-cy="cms-blog-header-menu-narrow"
    >
      <span></span>
      <span></span><span></span><span></span><span></span>
      <span></span>
    </div>

    <div
      class="cms-blog-header-menu-narrow-level1"
      #headerMenuNarrowLevel1
      data-cy="cms-blog-header-menu-level2-narrow"
    >
      <div *ngFor="let category of menu">
        <a [routerLink]="category.routerLink" (click)="narrowMenu?.toggle()">
          <div class="cms-blog-header-menu-label cms-blog-header-menu-narrow-navigate-next">
            {{ category.title }}
          </div>
        </a>
      </div>
    </div>
  </nav>
</div>
