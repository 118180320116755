import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation, OnChanges } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ExxComComponentClass } from 'lib/components/exxcom-component.class';
import { isBrowser } from 'lib/tools';

type PaneType = 'left' | 'right';
@Component({
    selector: 'app-slide-panel',
    templateUrl: './slide-panel.component.html',
    styleUrls: ['./slide-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('slide', [
            state('left', style({ transform: 'translateX(0)', height: 'fit-content' })),
            state('right', style({ transform: 'translateX(-50%)' })),
            transition('* => *', animate(300)),
        ]),
    ],
})
export class SlidePanelComponent extends ExxComComponentClass implements OnChanges {
    @Input() activePane: PaneType = 'left';
    transitioning: boolean = false;

    constructor(breakpointObserver: BreakpointObserver) {
        super({ dependencies: { breakpointObserver } });
    }

    // TODO:cleanup styling, we should not have 70 lines of hacky TS to fix styling
    ngOnChanges() {
        this.togglePane();
        if (!isBrowser()) {
            return;
        }
        const parent = document.getElementById('parent');
        const rightPane = document.getElementById('rightPane');
        const leftPane = document.getElementById('leftPane');
        const scrollDiv = document.getElementById('scroll-div');
        const contactContainer = document.getElementById('contact-container');
        scrollDiv.scrollTop = 0;
        if (this.activePane == 'right') {
            rightPane.style.maxHeight = 'fit-content';
            rightPane.style.height = '100%';
            rightPane.style.overflowY = 'visible';
            setTimeout(() => {
                leftPane.style.maxHeight = '0';
                leftPane.style.overflowY = 'hidden';
                if (this.isNarrow !== true) {
                    parent.style.height = '100vh';
                    contactContainer.style.height = 'fit-content';
                }
            }, 300);
        } else {
            if (leftPane) {
                leftPane.style.maxHeight = 'fit-content';
                leftPane.style.height = '100%';
                leftPane.style.overflowY = 'visible';
            }
            setTimeout(() => {
                if (rightPane) {
                    rightPane.style.maxHeight = '0';
                    rightPane.style.overflowY = 'hidden';
                }
            }, 300);
        }
    }

    togglePane() {
        this.transitioning = true;
        setTimeout(() => {
            this.transitioning = false;
        }, 300);
    }
}
