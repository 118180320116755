import { Component, OnInit } from '@angular/core';

import { CmsService } from 'lib/services/cms.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { isBrowser } from 'lib/tools';
import { MetaService } from 'lib/services/meta.service';

const scriptName = 'cms-warranty-policy.component';

@Component({
    selector: 'app-cms-warranty-policy',
    templateUrl: './cms-warranty-policy.component.html',
    styleUrls: ['./cms-warranty-policy.component.scss'],
})
export class CmsWarrantyPolicyComponent implements OnInit {
    entry: any = {};

    constructor(
        private cmsService: CmsService,
        private metaService: MetaService
    ) {}

    async ngOnInit() {
        try {
            this.entry = await this.cmsService.getPage('policy_page');
        } catch (err) {
            console.error(...new ExxComError(798891, scriptName, err).stamp());
        }
    }

    getSectionId(sectionRowTitle: string) {
        try {
            const splitSectionRowTitle = sectionRowTitle.split(' ');
            return splitSectionRowTitle[0];
        } catch (err) {
            console.error(...new ExxComError(798892, scriptName, err).stamp());
        }
    }

    onClick(title: string): void {
        try {
            if (!isBrowser()) {
                return;
            }
            const id = this.getSectionId(title);
            const el: HTMLElement | null = document.getElementById(id);
            if (el) {
                el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest',
                });
            }
        } catch (err) {
            console.error(...new ExxComError(798893, scriptName, err).stamp());
        }
    }
}
