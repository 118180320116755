import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// const scriptName = 'category-quick-view-dialog.compnent';

@Component({
    selector: 'lib-category-quick-view-dialog',
    templateUrl: './category-quick-view-dialog.component.html',
    styleUrls: ['./category-quick-view-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CategoryQuickViewDialogComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<CategoryQuickViewDialogComponent>
    ) {}

    ngOnInit() {}
}
