import { EventEmitter, OnInit, ViewChild, Input, Output, Directive } from '@angular/core';

import { CmsBlogComponentClass } from 'lib/components/cms/blog/cms-blog-component.class';
import { CmsBlogHeaderMenuNarrow } from 'lib/components/cms/blog/cms-blog-header-menu-narrow.class';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { isBrowser, wait } from 'lib/tools';

const scriptName = 'cms-blog-header-search-component.class';

@Directive()
export class CmsBlogHeaderSearchComponentClass extends CmsBlogComponentClass implements OnInit {
    // Inputs

    @Input('isNarrow') isNarrow: boolean = false;

    // Outputs

    @Output() isActiveChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    // View children

    @ViewChild('headerMenuNarrowToggle', { static: true })
    headerMenuNarrowToggle: any;
    @ViewChild('headerMenuNarrowLevel1', { static: true })
    headerMenuNarrowLevel1: any;

    // Dependencies (defined in parent)

    // Properties: public

    currentNarrowMenuLevel: number = 0;
    isNarrowMenuOpen: boolean = false;
    menu: any = [];
    narrowMenu: CmsBlogHeaderMenuNarrow;
    wasActive: boolean = false;

    constructor({ dependencies }) {
        super({ dependencies });
    }

    async ngOnInit() {
        await this.getMenu();
        if (!isBrowser()) {
            return;
        }
        await wait('500ms');
        this.narrowMenu = new CmsBlogHeaderMenuNarrow(this.isNarrow, {
            toggle: this.headerMenuNarrowToggle,
            level1: this.headerMenuNarrowLevel1,
        });
        this.narrowMenu.init();
    }

    private async getMenu() {
        try {
            const categories: any = await this.cmsService.getBlogCategories(`${this.environment.siteAbbr}_blog_category`);
            if (categories && categories.length == 0) {
                return;
            }
            categories.forEach((category: any) =>
                this.menu.push({
                    title: category.title,
                    routerLink: category.routerLink,
                })
            );
        } catch (err) {
            console.error(...new ExxComError(554992, scriptName, err).stamp());
        }
    }

    setIsActive(active: boolean) {
        try {
            if (active == this.wasActive) {
                return;
            }
            this.wasActive = active;
            this.isActiveChange.emit(active);
        } catch (err) {
            console.error(...new ExxComError(203991, scriptName, err).stamp());
        }
    }
}
