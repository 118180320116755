import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WebpService } from 'lib/services/webp.service';
import { ExxComComponentClass } from 'lib/components/exxcom-component.class';

// const scriptName = 'product-image-dialog.component';

@Component({
    selector: 'product-image-dialog',
    templateUrl: './product-image-dialog.component.html',
    styleUrls: ['./product-image-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ProductImageDialogComponent extends ExxComComponentClass implements OnInit {
    isNarrow: boolean = false;
    mode: string = 'single';
    breakpointObserver: BreakpointObserver;
    initialSlide: number;
    getWebpImg: (src: string) => string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        b: BreakpointObserver,
        webpService: WebpService
    ) {
        super({
            dependencies: {
                breakpointObserver: b,
            },
        });
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
    }

    ngOnInit() {
        super.initBreakpointListener();
        if (this.isNarrow) {
            this.mode = 'mobile';
        }
        this.initialSlide = this.data.initialSlide;
    }
}
