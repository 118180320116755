import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';

import { ExxComError } from 'lib/classes/exxcom-error.class';
import { GtmService } from 'lib/services/google/gtm.service';
import { MarketoService } from 'lib/services/marketo.service';
import { MetaService } from 'lib/services/meta.service';
import { ProductComponentClass } from 'lib/components/product/product-component.class';
import { RouterService } from 'lib/services/router.service';
import { WebstoreProductService } from 'lib/services/webstore-product/webstore-product.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ActivatedRoute } from '@angular/router';

const scriptName = 'product.component';

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ProductComponent extends ProductComponentClass implements OnInit {
    slidesPerView: number = 2.5;
    spaceBetween: number = 10;
    breakpoints: object = {
        576: {
            slidesPerView: 3.5,
            spaceBetween: 25,
        },
        768: {
            slidesPerView: 4,
            spaceBetween: 40,
        },
        1100: {
            slidesPerView: 5,
            spaceBetween: 40,
        },
    };
    constructor(
        @Inject('environment') environment: any,
        gtmService: GtmService,
        marketoService: MarketoService,
        metaService: MetaService,
        productService: WebstoreProductService,
        routerService: RouterService,
        breakpointObserver: BreakpointObserver,
        activatedRoute: ActivatedRoute
    ) {
        super({
            dependencies: {
                environment,
                gtmService,
                marketoService,
                metaService,
                productService,
                routerService,
                breakpointObserver,
                activatedRoute,
            },
        });
    }

    async ngOnInit() {
        try {
            await this.init();
        } catch (err) {
            console.error(...new ExxComError(830322, scriptName, err).stamp());
        }
    }
}
