import { Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AuthDialogComponent } from '../../auth/auth-dialog/auth-dialog.component';
import { CartService } from 'lib/services/cart/cart.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { isBrowser, multiply, wait } from 'lib/tools';
import { RouterService } from 'lib/services/router.service';
import { SessionService } from 'lib/services/session.service';
import { WebpService } from 'lib/services/webp.service';

const scriptName = 'header-cart.component';

@Component({
    selector: 'app-header-cart',
    templateUrl: './header-cart.component.html',
    styleUrls: ['./header-cart.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HeaderCartComponent implements OnDestroy, OnInit {
    @HostListener('document:click', ['$event']) click($event: any) {
        this.closeOnClickAway($event.target);
    }

    @Input() isNarrow: boolean;

    @Output() isActiveChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('icon') icon: ElementRef;
    @ViewChild('menu') menu: ElementRef;
    @ViewChild('menuItems') menuItems: ElementRef;

    private cartSubscription: any;

    isActive: boolean = false;
    items: any[] = [];
    menuClicked: boolean = false;
    showScrollMessage: boolean;
    totalItems: number = 0;
    wasActive: boolean = false;
    getWebpImg: (src: string) => string;

    constructor(
        private cartService: CartService,
        private routerService: RouterService,
        private sessionService: SessionService,
        public dialog: MatDialog,
        webpService: WebpService
    ) {
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
    }

    /**
     * The header-cart component is re-initialized if the browser is resized past
     * the breakpoint observer in header.component.ts. The reason is because
     * header-cart is located in two different areas of the header, one for wide
     * and one for narrow screen views. For that to be possible, the one that is
     * not displayed is removed from the DOM. It is not sufficient to set
     * display:none because of the document click listener that gets applied when
     * this component is initalized. If not removed from the DOM, then the
     * document:click event is triggered twice, once for each instance of the
     * component.
     */

    ngOnInit() {
        try {
            if (!isBrowser()) {
                return;
            }
            this.cartSubscription = this.cartService.cart.changed$.subscribe(() => this.parseMenu());
        } catch (err) {
            console.error(...new ExxComError(346753, scriptName, err).stamp());
        }
    }

    ngOnDestroy() {
        try {
            this.cartSubscription && this.cartSubscription.unsubscribe();
        } catch (err) {
            console.error(...new ExxComError(824388, scriptName, err).stamp());
        }
    }

    get cart() {
        return this.cartService.cart;
    }
    get isCart() {
        return this.routerService.isCart;
    }
    get isCheckout() {
        return this.routerService.isCheckout;
    }

    private parseMenu() {
        try {
            this.items = this.cart.items.map((item: any) => {
                return {
                    description: item.product.description,
                    imageUrl: item.product.imageUrl,
                    quantity: item.quantity,
                    price: item.price,
                    totalPrice: multiply(item.quantity, item.price),
                    urlComponent: item.product.urlComponent,
                };
            });
            this.setScrollMessageVisibility();
        } catch (err) {
            console.error(...new ExxComError(925733, scriptName, err).stamp());
        }
    }

    private async setScrollMessageVisibility() {
        try {
            await wait('');
            this.showScrollMessage = this.isNarrow ? this.items.length > 2 : this.items.length > 3;
        } catch (err) {
            console.error(...new ExxComError(936343, scriptName, err).stamp());
        }
    }

    private async setIsActive(active: boolean) {
        try {
            if (active == this.wasActive) {
                return;
            }
            this.wasActive = active;
            this.isActive = active;
            this.isActiveChange.emit(active);
        } catch (err) {
            console.error(...new ExxComError(436433, scriptName, err).stamp());
        }
    }

    closeMenu() {
        try {
            this.setIsActive(false);
            if (!this.menuItems) {
                return;
            }
            this.menuItems.nativeElement.scrollTop = 0;
            this.setScrollMessageVisibility();
        } catch (err) {
            console.error(...new ExxComError(934634, scriptName, err).stamp());
        }
    }

    async toggleMenu() {
        try {
            if (!isBrowser()) {
                return;
            }
            if (!this.isActive) {
                await this.cartService.reinit({ callOnFirstRoute: true }); // On open, in case price changed
                this.parseMenu();
            }
            const wasActive = this.isActive;
            this.setIsActive(!this.isActive);
            if (wasActive && !this.isCart && !this.cart.isEmpty) {
                // Reset scrollTop and show scroll message immediately after opening menu
                this.menuItems.nativeElement.scrollTop = 0;
                this.setScrollMessageVisibility();
            }
        } catch (err) {
            console.error(...new ExxComError(792885, scriptName, err).stamp());
        }
    }

    closeOnClickAway(target: HTMLElement) {
        try {
            const icon = this.icon.nativeElement;
            const menu = this.menu.nativeElement;
            if (this.isActive && target != icon && !icon.contains(target) && !menu.contains(target)) {
                this.closeMenu();
            }
        } catch (err) {
            console.error(...new ExxComError(293991, scriptName, err).stamp());
        }
    }

    openAuthDialog() {
        try {
            if (this.sessionService.isValidLocalSessionData()) {
                this.routerService.router.navigateByUrl('/checkout');
            } else {
                this.dialog.open(AuthDialogComponent, {
                    width: '380px',
                    height: '95vh',
                    maxHeight: '615px',
                    autoFocus: false,
                    panelClass: 'dialog-border-radius-0',
                });
            }
        } catch (err) {
            console.error(...new ExxComError(835743, scriptName, err).stamp());
        }
    }
}
