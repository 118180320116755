import { Component, Inject, Input, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { CmsPartialComponentClass } from 'lib/components/cms/cms-partial-component.class';
import { RouterService } from 'lib/services/router.service';
import { WebpService } from 'lib/services/webp.service';
import { CategoryService } from 'lib/services/category/category.service';

@Component({
    selector: 'app-cms-partial-category-top',
    templateUrl: './cms-partial-category-top.component.html',
    styleUrls: ['./cms-partial-category-top.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CmsPartialCategoryTopComponent extends CmsPartialComponentClass {
    @Input() data: any = {};
    @Input() grayBackgroundRows: any = [];
    getWebpImg: (src: string) => string;
    constructor(
        @Inject('environment') environment: any,
        breakpointObserver: BreakpointObserver,
        dialog: MatDialog,
        routerService: RouterService,
        webpService: WebpService,
        location: Location,
        categoryService: CategoryService
    ) {
        super({
            dependencies: {
                breakpointObserver,
                dialog,
                environment,
                routerService,
                location,
                categoryService,
            },
        });
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
    }
}
