import { SimpleChanges } from '@angular/core';

import { AddressData } from 'lib/services/address/address.interface';
import { BehaviorSubject } from 'rxjs';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { initServiceClassBoolean } from 'lib/tools';

const scriptName = 'address.class';

export class Address {
    // Schema

    _id: string = null;
    address1: string = null;
    address2: string = null;
    address3: string = null;
    addressee: string = null;
    attention: string = null;
    city: string = null;
    country: string = null;
    createdAt: string | Date = null;
    customerNsid: string = null;
    isDefaultBilling: boolean = null;
    isDefaultShipping: boolean = null;
    isResidential: boolean = null;
    email: string = null;
    nsid: string = null;
    phone: string = null;
    state: string = null;
    title: string = null;
    updated: string[] = null;
    updatedAt: string | Date = null;
    zip: string = null;

    // Client-side only

    changed$: BehaviorSubject<SimpleChanges> = new BehaviorSubject<SimpleChanges>(null);

    constructor(data?: AddressData) {
        if (data) {
            this.init(data);
        }
    }

    init(data: AddressData) {
        try {
            const changes: SimpleChanges = {};

            this._id = data._id || this._id || null;
            this.address1 = data.address1 || this.address1 || null;
            this.address2 = data.address2 || this.address2 || null;
            this.address3 = data.address3 || this.address3 || null;
            this.addressee = data.addressee || this.addressee || null;
            this.attention = data.attention || this.attention || null;
            this.city = data.city || this.city || null;
            this.country = data.country || this.country || null;
            this.createdAt = data.createdAt ? new Date(data.createdAt) : this.createdAt || null;
            this.customerNsid = data.customerNsid || this.customerNsid || null;
            this.isDefaultBilling = initServiceClassBoolean('isDefaultBilling', data.isDefaultBilling, this);
            this.isDefaultShipping = initServiceClassBoolean('isDefaultShipping', data.isDefaultShipping, this);
            this.isResidential = data.isResidential || this.isResidential || null;
            this.email = data.email || this.email || null;
            this.nsid = data.nsid || this.nsid || null;
            this.phone = data.phone || this.phone || null;
            this.state = data.state || this.state || null;
            this.title = data.title || this.title || null;
            this.updated = data.updated || this.updated || [];
            this.updatedAt = data.updatedAt ? new Date(data.updatedAt) : this.updatedAt || null;
            this.zip = data.zip || this.zip || null;

            this.changed$.next(changes);
        } catch (err) {
            console.error(...new ExxComError(309277, scriptName, err).stamp());
        }
    }
}
