import { AccountWishlistLine } from './account-wishlist-line.class';
import { ApiService } from 'lib/services/api.service';
import { each, isEmpty } from 'lodash';
import { ExxComError } from 'lib/classes/exxcom-error.class';

const scriptName = 'account-wishlist.class';

let apiService: ApiService;
let environment: any;

export class AccountWishlist {
    customerId: string;
    email: string;
    lastUpdated: Date | string;
    lines: AccountWishlistLine[] = [];
    totalLines: number;
    webstore: string;

    constructor(dependencies: any) {
        try {
            environment = dependencies.environment;
            apiService = dependencies.apiService;
        } catch (err) {
            console.error(...new ExxComError(789929, scriptName, err).stamp());
        }
    }

    init(webstore: string, email: string, customerId: string, wishlist: any) {
        try {
            this.webstore = webstore;
            this.email = email;
            this.customerId = customerId;
            this.totalLines = wishlist.length;
            this.lastUpdated =
                wishlist.length > 0
                    ? new Date(
                          Math.max.apply(
                              null,
                              wishlist.map((line: any) => new Date(line.dateAdded))
                          )
                      )
                    : '';
            this.lines = wishlist.map((line: any) => new AccountWishlistLine(line, { environment: environment }));
        } catch (err) {
            console.error(...new ExxComError(590293, scriptName, err).stamp());
        }
    }

    private async get() {
        try {
            const url = `customers/find/${this.webstore}/${this.email}?populate=wishlist.product`;
            const res = await apiService.get(url);
            if (!res.success) {
                throw res;
            } else {
                this.init(this.webstore, this.email, this.customerId, res.data.wishlist);
            }
        } catch (err) {
            console.error(...new ExxComError(203911, scriptName, err).stamp());
        }
    }

    private async post(type: string, values: any, lineId?: string) {
        try {
            const url = `customers/update/${this.customerId}?action=modifyWishlist&type=${type}&wishlistLineId=${lineId}`;
            const res = await apiService.post(url, values);
            if (!res.success) {
                throw res;
            } else {
                this.init(this.webstore, this.email, this.customerId, res.data.wishlist);
            }
        } catch (err) {
            console.error(...new ExxComError(105791, scriptName, err).stamp());
        }
    }

    async add(productId: string) {
        try {
            if (!productId) {
                throw new Error('"productId" is required to add a product');
            }
            const alreadyOnList = !!this.lines.find((line: any) => line.product._id == productId);
            if (alreadyOnList) {
                return;
            }
            const values = { product: productId };
            await this.post('add', values);
        } catch (err) {
            console.error(...new ExxComError(320933, scriptName, err).stamp());
        }
    }

    async remove(productId: string) {
        try {
            if (!productId) {
                throw new Error('"productId" is required to remove a product');
            }
            await this.get();
            const notOnList = !this.lines.find((line: any) => line.product._id == productId);
            if (notOnList) {
                return;
            }
            const values = { product: productId };
            await this.post('remove', values);
        } catch (err) {
            console.error(...new ExxComError(294873, scriptName, err).stamp());
        }
    }

    async update(lineId: string, values: any) {
        try {
            if (!lineId || isEmpty(values)) {
                throw new Error('"lineId", "field", and "value" are to update a product');
            }
            each(values, (v: any, k: string) => (values[k] = isNaN(v) ? v : parseInt(v)));
            await this.post('update', values, lineId);
        } catch (err) {
            console.error(...new ExxComError(430944, scriptName, err).stamp());
        }
    }
}
