export const appPageTitles = {
    '': 'Welcome to SabrePC',
    'About-Us': 'About Us | SabrePC',
    account: 'Account - Overview | SabrePC',
    'account/overview': 'Overview - Account | SabrePC',
    'account/profile': 'Profile - Account | SabrePC',
    'account/orders': 'Orders - Account | SabrePC',
    'account/order-history': 'Orders - History | SabrePC',
    'account/order-returns': 'Orders - Returns | SabrePC',
    'account/profile-information': 'Orders - Profile Information | SabrePC',
    'account/profile-credit-cards': 'Orders - Profile Credit Cards | SabrePC',
    'account/profile-update-password': 'Orders - Profile Update Password | SabrePC',
    'account/address-book': 'Orders - Address Book | SabrePC',
    'account/wishlist': 'Wishlist - Account | SabrePC',
    'B2B-Services': 'B2B Services | SabrePC',
    cart: 'Cart | SabrePC',
    checkout: 'Checkout | SabrePC',
    'checkout/billing': 'Checkout - Billing | SabrePC',
    'checkout/confirmation': 'Checkout - Confirmation | SabrePC',
    'checkout/shipping': 'Checkout - Shipping | SabrePC',
    'Contact-Us': 'Contact Us | SabrePC',
    login: 'Login | SabrePC',
    'login-register': 'Login | SabrePC',
    register: 'Register | SabrePC',
    'policy/Return-Policy': 'Return Policy | SabrePC',
    'RMA-Request': 'RMA Request | SabrePC',
    'Service-Support': 'Service Support | SabrePC',
    'policy/Shipping-Policy': 'Shipping Policy | SabrePC',
    'policy/Warranty-Policy': 'Warranty Policy | SabrePC',
};
