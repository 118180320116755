import { Injectable } from '@angular/core';

import { ApiService } from 'lib/services/api.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { get } from 'lodash';

const scriptName = 'easypost-validation.service';

let apiService: ApiService;

@Injectable()
export class EasyPostValidationService {
    addressValid: boolean = false;
    validatingAddress: boolean = false;

    constructor(a: ApiService) {
        try {
            apiService = a;
        } catch (err) {
            console.error(...new ExxComError(439883, scriptName, err).stamp());
        }
    }

    async isValidAddress(address: any): Promise<boolean> {
        try {
            if (address.isConfirmed) {
                return true;
            }
            const res = await apiService.post('easypost/validation', address);
            if (res.error) {
                throw res.error;
            }
            const isValid = get(res, 'data.isValid', false);
            return isValid;
        } catch (err) {
            console.error(...new ExxComError(702883, scriptName, err).stamp());
            return false;
        }
    }
}
