import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'lib-overlay',
    templateUrl: './overlay.component.html',
    styleUrls: ['./overlay.component.scss'],
})
export class OverlayComponent implements OnInit {
    @Input() headerSelector: string = '#header';
    @Input() isVisible: boolean = false;
    @Input() showSpinner: boolean = false;
    @Input() spinnerColor: string = 'primary'; // Options: primary (default), accent, warn
    @Input() spinnerSize: number = 50;
    @Input() topOffset: number = 0;
    @Input() zIndex: number = 1000; // 1000 (over everything) is default

    headerHeight: string;

    constructor() {}

    ngOnInit() {}
}
