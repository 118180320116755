import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';

import { AuthService } from 'lib/services/auth.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';

const scriptName = 'header-account.component';

@Component({
    selector: 'app-header-account',
    templateUrl: './header-account.component.html',
    styleUrls: ['./header-account.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HeaderAccountComponent implements OnInit {
    @HostListener('document:click', ['$event.target']) click(target: any) {
        this.closeOnClickAway(target);
    }
    @Input() isBrowser: boolean;
    @Input() isGuestSession: boolean = false;
    @Input() isValidLocalSessionData: boolean = false;
    @Input() isNarrow: boolean;
    @Output() isActiveChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild('icon') icon: ElementRef;
    @ViewChild('menu') menu: ElementRef;

    isActive: boolean = false;
    wasActive: boolean = false;

    constructor(private authService: AuthService) {}

    ngOnInit() {}

    closeOnClickAway(target: HTMLElement) {
        try {
            const icon = this.icon.nativeElement;
            const menu = this.menu.nativeElement;
            if (this.isActive && target != icon && !icon.contains(target) && !menu.contains(target)) {
                this.setIsActive(false);
            }
        } catch (err) {
            console.error(...new ExxComError(298789, scriptName, err).stamp());
        }
    }

    private async setIsActive(active: boolean) {
        try {
            if (active == this.wasActive) {
                return;
            }
            this.wasActive = active;
            this.isActive = active;
            this.isActiveChange.emit(active);
            this.setBodyScroll();
        } catch (err) {
            console.error(...new ExxComError(792883, scriptName, err).stamp());
        }
    }

    private setBodyScroll() {
        try {
            if (!this.isBrowser || !this.isNarrow) {
                return;
            }
        } catch (err) {
            console.error(...new ExxComError(685744, scriptName, err).stamp());
        }
    }

    toggleMenu() {
        try {
            this.setIsActive(!this.isActive);
        } catch (err) {
            console.error(...new ExxComError(800402, scriptName, err).stamp());
        }
    }

    closeMenu() {
        try {
            this.setIsActive(false);
        } catch (err) {
            console.error(...new ExxComError(843465, scriptName, err).stamp());
        }
    }

    logout() {
        try {
            this.authService.logout();
        } catch (err) {
            console.error(...new ExxComError(932883, scriptName, err).stamp());
        }
    }
}
