import { Component } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ExxComComponentClass } from 'lib/components/exxcom-component.class';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-marketo-form-thank-you',
    templateUrl: './marketo-form-thank-you.component.html',
    styleUrls: ['./marketo-form-thank-you.component.scss'],
})
export class MarketoFormThankYouComponent extends ExxComComponentClass {
    constructor(
        breakpointObserver: BreakpointObserver,
        public bsModalRef: BsModalRef,
        public modalService: BsModalService
    ) {
        super({ dependencies: { breakpointObserver } });
    }
}
