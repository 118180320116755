import { Injectable } from '@angular/core';
import { isBrowser } from 'lib/tools';

@Injectable()
export class WebpService {
    private webpAvailable: boolean = false;

    async canUseWebp() {
        if (!isBrowser()) {
            return true;
        }

        // solution found here:
        // https://gist.github.com/jakearchibald/6c43d5c454bc8f48f83d8471f45698fa
        if (!self.createImageBitmap) return false;
        const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
        const blob = await fetch(webpData).then((r) => r.blob());
        return createImageBitmap(blob).then(
            () => true,
            () => false
        );
    }

    async setWebpAvailable() {
        this.webpAvailable = await this.canUseWebp();
    }

    getWebpAvailable() {
        return this.webpAvailable;
    }

    getWebpImg(src: string) {
        try {
            if (!src) {
                return null;
            }
            if (this.webpAvailable) {
                const url = new URL(src);
                // process image source to webp source
                const fileExt = url.pathname.split('.').pop();
                const siteName = url.hostname.split('.')[1];
                const domain = url.origin;
                const cmsImageDomains = new Set([
                    'https://assets.sabrepc.com',
                    'https://assets.exxactcorp.com',
                    'https://images.exxactcorp.com',
                    'https://images.sabrepc.com',
                ]);
                const supportedTypes = new Set(['png', 'jpeg', 'jpg']);
                if (!supportedTypes.has(fileExt) || !cmsImageDomains.has(domain)) {
                    if (domain === 'https://images.contentstack.io') {
                        return `${src}?format=webp`;
                    }
                    return src;
                }
                let dir = url.pathname;
                if (dir.indexOf('/img') === 0) {
                    dir = dir.substring(4);
                }
                const webpDir = `${dir.split('.')[0]}.webp`;
                return `https://assets.${siteName}.com/webp${webpDir}`;
            } else {
                return src;
            }
        } catch {
            return src;
        }
    }
}
