import { AbstractControl } from '@angular/forms';

import { countriesByNameLower, countriesByCode } from 'lib/lists/countries';

export function validateCountry(control: AbstractControl): { [key: string]: any } | null {
    const value = control.value;
    const byName = countriesByNameLower[value.toLowerCase()];
    const byCode = countriesByCode[value.toUpperCase()];
    const isValid = byName || byCode;
    return !isValid ? { invalid: true } : null;
}
