<header id="cms-blog-header" data-cy="cms-blog-header">
  <div id="cms-blog-header-row" class="fx-row fx-row-v-center header-row">
    <div *ngIf="!isNarrow || displayMagnifier" id="cms-blog-header-logo" class="col-lg-5 pl-0">
      <a routerLink="/">
        <img
          id="cms-blog-header-wide-logo"
          alt="SabrePC"
          src="https://assets.sabrepc.com/img/spc/logo/SPC-Wordmark.svg"
          data-cy="cms-blog-header-logo-wide"
        />
        <img
          id="cms-blog-header-narrow-logo"
          alt="SabrePC"
          src="https://assets.sabrepc.com/img/spc/logo/SPC_Logo.svg"
          data-cy="cms-blog-header-logo-narrow"
        />
      </a>
    </div>

    <div *ngIf="!isNarrow || displayMagnifier" id="cms-blog-header-icon" class="col-xs-2 fx-col-h-center">
      <a routerLink="/blog"><h1 class="pt-1" data-cy="cms-blog-header-title">Blog</h1></a>
    </div>

    <div class="fx-row col-xs-12 col-lg-5 fx-row-v-center fx-row-h-right px-0">
      <app-cms-blog-header-menu
        *ngIf="!isNarrow || displayMagnifier"
        id="app-cms-blog-header-menu"
        [isNarrow]="isNarrow"
        (isActiveChange)="onActiveChange('menu', $event, 100)"
      ></app-cms-blog-header-menu>

      <app-cms-blog-header-search
        *ngIf="!isNarrow || !displayMagnifier"
        class="col-lg-2_5 p-0"
        [isNarrow]="isNarrow"
        [displayMagnifier]="displayMagnifier"
        (showMagnifier)="setMagnifier($event)"
        (isActiveChange)="onActiveChange('search', $event, 100)"
      ></app-cms-blog-header-search>

      <img
        *ngIf="isNarrow && displayMagnifier"
        id="cms-blog-search-icon-narrow"
        src="https://images.sabrepc.com/spc-cms/assets/Nav_Search.svg"
        alt="magnifier"
        (click)="setMagnifier(false)"
        data-cy="cms-blog-header-search-narrow"
      />
    </div>
  </div>

  <lib-overlay [zIndex]="overlayZindex" [isVisible]="overlayVisible"></lib-overlay>
</header>
