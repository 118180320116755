import { Inject, Injectable, Optional } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';
import { get } from 'lodash';

import { contains, isBrowser } from 'lib/tools';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { RouterService } from 'lib/services/router.service';

const scriptName = 'auth.interceptor';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private baseUrls: any = {
        api: `${this.routerService.baseUrl}/api`,
        auth: `${this.routerService.baseUrl}/auth`,
    };

    constructor(
        @Optional() @Inject(REQUEST) protected req: Request,
        private routerService: RouterService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        try {
            if (contains(req.url, this.baseUrls.api) || contains(req.url, this.baseUrls.auth)) {
                const idToken: string = isBrowser() ? localStorage.getItem('idToken') : get(this.req, 'cookies.idToken', '');
                if (idToken) {
                    return next.handle(
                        req.clone({
                            headers: req.headers.set('Authorization', `Bearer ${idToken}`),
                        })
                    );
                }
            }
            return next.handle(req);
        } catch (err) {
            console.error(...new ExxComError(394884, scriptName, err).stamp());
        }
    }
}
