import { Directive } from '@angular/core';
import { CategoryService } from 'lib/services/category/category.service';

@Directive()
export class HeaderMenuComponent {
    constructor(private categoryService: CategoryService) {}

    getLinkUrl(path: string) {
        if (this.categoryService.paths.includes(path)) {
            return `/category/${path}`;
        } else {
            return `/${path}`;
        }
    }
}
