import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { AccountService } from 'lib/services/account/account.service';
import { AuthDialogComponent } from '../auth-dialog/auth-dialog.component';
import { AuthService } from 'lib/services/auth.service';
import { camelCase, get } from 'lodash';
import { CartService } from 'lib/services/cart/cart.service';
import { contains, isBrowser, saveEmailInBrowser, wait } from 'lib/tools';
import { validateEmailTopLevelDomainForNetSuite } from 'lib/validators/email-top-level-domain-for-netsuite';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { GrecaptchaService } from 'lib/services/google/grecaptcha.service';
import { MarketoService } from 'lib/services/marketo.service';
import { MessageService } from 'lib/services/message.service';
import { SessionService } from 'lib/services/session.service';

const scriptName = 'auth-register.component';

@Component({
    selector: 'app-auth-register',
    templateUrl: './auth-register.component.html',
    styleUrls: ['./auth-register.component.scss'],
})
export class AuthRegisterComponent implements OnInit {
    @Input() authType: string = 'account'; // Required. Options: account, checkout
    @Input() dialogRef: MatDialogRef<AuthDialogComponent>;
    @Input() formClass: string; // Required.
    @Input() showFormHeading: boolean = false; // Optional. Shows or hides heading inside the form
    @Input() showLabels: boolean = false; // Optional. Shows or hides input labels. If labels are shown, then placeholders are not shown.
    @Input() registrationType: string = 'customer'; // Required. Options: customer, guest

    customerForm: FormGroup;
    guestForm: FormGroup;
    actionPending: boolean = false;
    showOptions: boolean;
    showV2Captcha: boolean = false;
    private form: any;

    constructor(
        private accountService: AccountService,
        private authService: AuthService,
        private cartService: CartService,
        private formBuilder: FormBuilder,
        private grecaptchaService: GrecaptchaService,
        private marketoService: MarketoService,
        private router: Router,
        private sessionService: SessionService,
        public messageService: MessageService
    ) {}

    async ngOnInit() {
        try {
            this.showOptions = this.authType == 'checkout';
            this.defineForms();
        } catch (err) {
            console.error(...new ExxComError(782309, scriptName, err).stamp());
        }
    }

    defineForms() {
        try {
            const captchaV2Validator = this.showV2Captcha ? ['', { validators: [Validators.required], updateOn: 'change' }] : [];
            const kaptchaKey = this.showV2Captcha ? { captcha: captchaV2Validator } : {};
            this.customerForm = this.formBuilder.group(
                {
                    cFirst: ['', { validators: [Validators.required], updateOn: 'blur' }],
                    cLast: ['', { validators: [Validators.required], updateOn: 'blur' }],
                    cCompany: [''],
                    cEmail: [
                        '',
                        {
                            validators: [Validators.required, Validators.email, validateEmailTopLevelDomainForNetSuite],
                            updateOn: 'blur',
                        },
                    ],
                    cPassword: ['', { validators: [Validators.required], updateOn: 'blur' }],
                    cRetypePassword: [
                        '',
                        {
                            validators: [Validators.required],
                            updateOn: 'change',
                        },
                    ],
                    ...kaptchaKey,
                },
                { validators: this.validatePasswordsMatch, updateOn: 'change' }
            );
            this.guestForm = this.formBuilder.group(
                {
                    gFirst: ['', { validators: [Validators.required], updateOn: 'blur' }],
                    gLast: ['', { validators: [Validators.required], updateOn: 'blur' }],
                    gEmail: [
                        '',
                        {
                            validators: [Validators.required, Validators.email, validateEmailTopLevelDomainForNetSuite],
                            updateOn: 'blur',
                        },
                    ],
                    ...kaptchaKey,
                },
                { updateOn: 'change' }
            );
        } catch (err) {
            console.error(...new ExxComError(823094, scriptName, err).stamp());
        }
    }

    private validatePasswordsMatch(formGroup: FormGroup) {
        try {
            const password = formGroup.controls.cPassword;
            const retypePassword = formGroup.controls.cRetypePassword;
            if (retypePassword.errors && !retypePassword.errors.match) {
                return;
            }
            if (password.value != retypePassword.value) {
                retypePassword.setErrors({ match: true });
            } else {
                retypePassword.setErrors(null);
            }
        } catch (err) {
            console.error(...new ExxComError(923991, scriptName, err).stamp());
        }
    }

    get cFirst() {
        return this.customerForm.get('cFirst');
    }
    get cLast() {
        return this.customerForm.get('cLast');
    }
    get cCompany() {
        return this.customerForm.get('cCompany');
    }
    get cEmail() {
        return this.customerForm.get('cEmail');
    }
    get cPassword() {
        return this.customerForm.get('cPassword');
    }
    get cRetypePassword() {
        return this.customerForm.get('cRetypePassword');
    }

    get gFirst() {
        return this.guestForm.get('gFirst');
    }
    get gLast() {
        return this.guestForm.get('gLast');
    }
    get gEmail() {
        return this.guestForm.get('gEmail');
    }

    hasError(control: any) {
        return control.invalid && (control.dirty || control.touched);
    }
    hasErrorRequired(control: any) {
        return this.hasError(control) && get(control, 'errors.required');
    }
    hasErrorInvalid(control: any) {
        return this.hasError(control) && get(control, 'errors.email');
    }
    hasErrorMatch(control: any) {
        return this.hasError(control) && get(control, 'errors.match');
    }
    hasErrorEmailTopLevelDomainForNetSuite(control: any) {
        return this.hasError(control) && get(control, 'errors.emailTopLevelDomainForNetSuite');
    }

    selectRegistration(type: string) {
        try {
            this.showOptions = false;
            this.registrationType = type;
        } catch (err) {
            console.error(...new ExxComError(823092, scriptName, err).stamp());
        }
    }

    // FORM SUBMISSION

    async onSubmit(type: string) {
        try {
            if (!isBrowser()) {
                return;
            }
            const form = this[`${type}Form`];
            if (!form.valid) {
                return form.markAllAsTouched();
            }
            this.actionPending = true;
            await this.register(type);
            this.actionPending = false;
        } catch (err) {
            console.error(...new ExxComError(823991, scriptName, err).stamp());
        }
    }

    private async register(type: string) {
        try {
            this.form = await this.marketoService.load('2279');
            const form = this[`${type}Form`];
            const values: any = {
                status: 'LEAD-Registered',
                webstore: 'spc',
                registrationType: this.registrationType,
                grecaptchaToken: await this.grecaptchaService.getToken('auth_register'),
            };
            Object.keys(form.value).forEach((key: string) => (values[camelCase(key.slice(1))] = this[key].value));
            await this.form.addHiddenFields({
                FirstName: values.first,
                LastName: values.last,
                Email: values.email,
                Company: values.company || 'Not provided',
            });
            const res: any = await this.authService.register(values);
            res && res.success ? await this.onRegistrationSuccess(values) : this.onRegistrationError(res);
        } catch (err) {
            console.error(...new ExxComError(823984, scriptName, err).stamp());
        }
    }

    private onRegistrationError(res: any) {
        try {
            if (res.isServer) {
                return;
            }
            const message = get(res, 'error.message');
            const messages = ['Already registered'];
            const details = get(res, 'error.details');
            if (message == 'crg' || details == 'crg') {
                this.showV2Captcha = true;
                return;
            }
            if (contains(messages, message)) {
                this.messageService.addLocal('register', message).clearOnAction();
            } else {
                this.messageService.addLocal('register', 'Server error').clearOnAction();
                throw res;
            }
        } catch (err) {
            console.error(...new ExxComError(130002, scriptName, err).stamp());
        }
    }

    private async onRegistrationSuccess(values: any) {
        try {
            await wait('500ms');
            values.grecaptchaToken = await this.grecaptchaService.getToken('auth_register_login');
            const res: any = await this.authService.login(values);
            if (!res.success) {
                throw res;
            }
            await this.onLoginSuccess(res, values);
        } catch (err) {
            const details = get(err, 'error.details');
            if (get(err, 'error.message') == 'crg' || details == 'crg') {
                this.showV2Captcha = true;
                return;
            } // Ignore invalid reCAPTCHA response
            console.error(...new ExxComError(309921, scriptName, err).stamp());
        }
    }

    private async onLoginSuccess(res: any, values: any) {
        try {
            if (this.dialogRef) {
                this.dialogRef.close();
            }
            this.sessionService.setIsGuestSession(this.registrationType == 'guest');
            this.sessionService.set(res.data.idToken, res.data.expiresIn).start();
            saveEmailInBrowser(values.email.toLowerCase());
            await this.accountService.init(true);
            await this.cartService.onLogin();
            await this.form.submit();
            await this.router.navigateByUrl(this.authType == 'checkout' ? '/checkout' : '/account');
        } catch (err) {
            console.error(...new ExxComError(340993, scriptName, err).stamp());
        }
    }
}
