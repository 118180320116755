import { AbstractControl } from '@angular/forms';

/**
 * @function validateEmailTopLevelDomainForNetSuite
 * @param {AbstractControl} control
 * @description The email address top-level domain has to be validated for
 * NetSuite because NetSuite rejects email addresses in which top-level domains
 * contain numbers. If NetSuite rejects an email address, then customer records
 * cannot be created, which causes other issues.
 */
export function validateEmailTopLevelDomainForNetSuite(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (!value) {
        return null;
    }
    const isValid = /\.[A-z]{2,6}$/.test(value);
    return isValid ? null : { emailTopLevelDomainForNetSuite: true };
}
