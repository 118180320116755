import { Injectable, Inject } from '@angular/core';
import { ExxComError } from 'lib/classes/exxcom-error.class';

import { ConfigurationData } from './configuration.interface';
import { SharedRuleData, SharedRuleResponse } from './configurator-shared-rule.interface';

import { ApiService } from '../api.service';

const scriptName = 'configurator-shared-rule.service';

let apiService: ApiService;

@Injectable()
export class ConfiguratorSharedRuleService {
    constructor(@Inject('environment') e: any, a: ApiService) {
        try {
            apiService = a;
        } catch (err) {
            console.error(...new ExxComError(936763, scriptName, err).stamp());
        }
    }

    async findSharedRule(configuration: ConfigurationData['_id']): Promise<any> {
        try {
            const res: SharedRuleResponse = await apiService.get(`configurations/shared/rule/find/${configuration}`);
            if (!res.success) {
                throw res;
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(334458, scriptName, err).stamp());
        }
    }

    async createSharedRule(configuration: ConfigurationData['_id'], categories: string[], name: string, rule: object): Promise<any> {
        try {
            const body = { configuration, categories, name, rule };
            const res: SharedRuleResponse = await apiService.post('configurations/shared/rule/create', body);
            if (!res.success) {
                throw res;
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(334455, scriptName, err).stamp());
        }
    }

    async updateSharedRule(_id: string, configuration: ConfigurationData['_id'], categories: string[], name: string, rule: object): Promise<any> {
        try {
            const body = { configuration, categories, name, rule };
            const res: SharedRuleResponse = await apiService.post(`configurations/shared/rule/update/${_id}`, body);
            if (!res.success) {
                throw res;
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(334456, scriptName, err).stamp());
        }
    }

    async deleteSharedRule(_id: SharedRuleData['_id']): Promise<any> {
        try {
            const res: SharedRuleResponse = await apiService.delete(`configurations/shared/rule/delete/${_id}`);
            if (!res.success) {
                throw res;
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(334457, scriptName, err).stamp());
        }
    }
}
