<div [@slide]="activePane" class="fx-row fx-row-h-space-between" id="parent">
  <div
    class="child"
    [class.show]="activePane === 'left' || transitioning"
    *ngIf="activePane === 'left' || transitioning"
  >
    <ng-content select="[leftPane]"></ng-content>
  </div>
  <div
    class="child"
    [class.show]="activePane === 'right' || transitioning"
    *ngIf="activePane === 'right' || transitioning"
  >
    <ng-content select="[rightPane]"></ng-content>
  </div>
</div>
