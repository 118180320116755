import { Component, AfterViewInit, OnDestroy, Renderer2, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-privacy',
    templateUrl: './privacy.component.html',
    styleUrls: ['./privacy.component.scss'],
})
export class PrivacyComponent implements AfterViewInit, OnDestroy {
    private termlyScriptId = 'termly-jssdk';
    private isBrowser: boolean;

    constructor(
        private renderer: Renderer2,
        private el: ElementRef,
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    ngAfterViewInit() {
        if (!this.isBrowser) return;
        const divId = 'privacy-policy';

        const privacyPolicyDivValidation = this.el.nativeElement.querySelector(`#${divId}`);

        if (!privacyPolicyDivValidation) {
            console.error(`Element with id ${divId} not found.`);
            return;
        }

        const termlyDiv = this.renderer.createElement('div');
        this.renderer.setAttribute(termlyDiv, 'name', 'termly-embed');
        this.renderer.setAttribute(termlyDiv, 'data-id', 'a7b2a886-ca30-4024-85bc-55a9bfe10105');

        const termlyScript = this.renderer.createElement('script');
        this.renderer.setAttribute(termlyScript, 'type', 'text/javascript');
        this.renderer.appendChild(
            termlyScript,
            this.renderer.createText(`
            (function(d, s, id) {
                var js, tjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = "https://app.termly.io/embed-policy.min.js";
                tjs.parentNode.insertBefore(js, tjs);
            }(document, 'script', '${this.termlyScriptId}'));
        `)
        );

        const privacyPolicyDiv = this.el.nativeElement.querySelector(`#${divId}`);
        if (privacyPolicyDiv) {
            this.renderer.appendChild(privacyPolicyDiv, termlyDiv);
            this.renderer.appendChild(privacyPolicyDiv, termlyScript);
        }
    }

    ngOnDestroy() {
        if (!this.isBrowser) return;
        const termlyScript = this.document.getElementById(this.termlyScriptId);
        if (termlyScript) {
            termlyScript.remove();
        }
    }
}
