<div
  [id]="formId + '-container'"
  class="marketo-form fx-col fx-col-v-center fx-col-h-center"
  [ngClass]="formId == 'mktoForm_2347' ? 'justify-content-start' : ''"
>
  <div *ngIf="showSpinner" class="fx-row justify-content-center marketo-form-spinner">
    <mat-spinner diameter="25" color="accent"></mat-spinner>
  </div>

  <div #formWrapper class="marketo-form-wrapper fx-col fx-col-h-center fx-col-v-top hide">
    <form [id]="formId" #formElem></form>
    <div class="grecaptcha-message">
      This site is protected by reCAPTCHA and the Google
      <a href="https://policies.google.com/privacy">Privacy Policy</a> and
      <a href="https://policies.google.com/terms">Terms of Service</a> apply.
    </div>
  </div>

  <div
    #afterSubmitWrapper
    *ngIf="showAfterSubmitMessage && !configuratorData && contentType === 'inquiry'"
    data-analytics="form-submit"
    class="marketo-form-after-submit {{
      formId == 'mktoForm_1165' || formId == 'mktoForm_1485'
        ? 'contact-us'
        : formId == 'mktoForm_1080' || formId == 'mktoForm_2061'
        ? 'product-inquiry'
        : ''
    }}"
    [ngStyle]="afterSubmitMessage.css"
    [innerHTML]="afterSubmitMessage.text | safe : 'html'"
  ></div>

  <!-- In using standard config form || If send to sales is checked OR if using the standard config form -->
  <div
    #afterSubmitWrapper
    *ngIf="showAfterSubmitMessage && configuratorData && sentToSales"
    class="marketo-form-after-submit {{
      formId == 'mktoForm_1165' || formId == 'mktoForm_1485'
        ? 'contact-us'
        : formId == 'mktoForm_1080' || formId == 'mktoForm_2061'
        ? 'product-inquiry'
        : ''
    }}
    config-inquiry-sent
    d-flex flex-column align-items-center m-0
    "
    [ngStyle]="afterSubmitMessage.css"
  >
    <img class="mb-2x2" [src]="getBaseUrl() + '/assets/images/configurator-sent.png'" alt="hand receiving an inquiry" />
    <h3 class="mb-3 mt-0">Your Configuration has been sent!</h3>
    <h6 class="font-sm weight-400 mb-3 mt-0">
      One of our engineers will review your configuration and reach out to you soon with an
      <span class="blue">official quote</span>. Please check your email for a copy of your configuration.
    </h6>
    <a class="configure-btn button-primary fx-row fx-row-v-center mb-1 lh-1" (click)="reset()">
      Configure another system</a
    >
    <a class="return-btn font-sm ml-1 fx-row fx-row-v-center" href="/"
      >Back to our homepage <mat-icon>chevron_right</mat-icon></a
    >
  </div>

  <!-- If using pdf form and send to sales is NOT checked -->
  <div
    #afterSubmitWrapper
    *ngIf="showAfterSubmitMessage && configuratorData && !sentToSales"
    class="marketo-form-after-submit {{
      formId == 'mktoForm_1165' || formId == 'mktoForm_1485'
        ? 'contact-us'
        : formId == 'mktoForm_1080' || formId == 'mktoForm_2061'
        ? 'product-inquiry'
        : ''
    }}
    config-pdf-downloaded
    d-flex flex-column align-items-center m-0
    "
    [ngStyle]="afterSubmitMessage.css"
  >
    <img
      class="mb-2x2"
      [src]="getBaseUrl() + '/assets/images/configurator-downloaded.png'"
      alt="paper being given to you"
    />
    <h3 class="mb-3 mt-0">Your Configuration has been downloaded!</h3>
    <h6 class="font-sm weight-400 mb-3 mt-0">
      Check your email for a copy of your configuration.<br />
      This is <span class="blue">not an offical quote.</span> <br /><br />
      If this configuration works for your team, contact sales for an official quote with special pricing and applicable
      discounts.
    </h6>
    <h6 class="contact-info font-sm weight-400 mb-3 mt-0">
      <a href="tel:5102267366">(P) 510.226.7366 &nbsp;</a><a href="tel:5102267367">(F) 510.226.7367</a> &nbsp;
      <a class="email" href="mailto:sales@exxactcorp.com">sales@exxactcorp.com</a>
    </h6>
    <a class="configure-btn button-primary fx-row fx-row-v-center mb-1 lh-1" (click)="reset()">
      Configure another system</a
    >
    <a class="return-btn font-sm ml-1 fx-row fx-row-v-center" href="/"
      >Back to our homepage <mat-icon>chevron_right</mat-icon></a
    >
  </div>
</div>
