import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { CmsB2bServiceComponent } from './cms/pages/cms-b2b-service/cms-b2b-service.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ProductComponent } from './product/product.component';

import { environment } from '../environments/environment';
import { PrivacyComponent } from './privacy/privacy.component';
import { CookiesComponent } from './privacy/cookies/cookies.component';
import { ProductResolver } from 'lib/services/webstore-product/webstore-product-resolver';

// Category and CMS routes are added dynamically in ./app.initializers.ts.

export function matchProducts(url: UrlSegment[]) {
    const isMatch = url.length == 1 && new RegExp(environment.productUrlRegex).test(url[0].path);
    return isMatch ? { consumed: url } : null;
}

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./cms/pages/cms-homepage/cms-homepage.module').then((m) => m.HomePageModule),
    },
    {
        path: 'blog',
        loadChildren: () => import('./cms/blog/blog.module').then((m) => m.BlogModule),
    },
    {
        path: 'About-Us',
        loadChildren: () => import('./cms/pages/cms-about-us/cms-about-us.module').then((m) => m.AboutUsModule),
    },
    {
        path: 'Applications-Forms',
        loadChildren: () => import('./cms/pages/cms-applications-forms/cms-application.module').then((m) => m.ApplicationFormsModule),
    },
    { path: 'B2B-Services', component: CmsB2bServiceComponent },
    {
        path: 'category',
        loadChildren: () => import('./category/category.module').then((m) => m.CategoryModule),
    },
    {
        path: 'Contact-Us',
        loadChildren: () => import('./cms/pages/cms-contact-us/cms-contact-us.module').then((m) => m.ContactUsModule),
    },
    {
        path: 'policy',
        loadChildren: () => import('./cms/pages/cms-policies/cms-policies.module').then((m) => m.PoliciesModule),
    },
    {
        path: 'RMA-Request',
        loadChildren: () => import('./cms/pages/cms-rma-request/cms-rma-request.module').then((m) => m.RMARequestModule),
    },
    {
        path: 'Service-Support',
        loadChildren: () => import('./cms/pages/cms-service-support/cms-service-support.module').then((m) => m.ServiceSupportModule),
    },
    {
        path: 'account',
        loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
    },
    {
        path: 'checkout',
        loadChildren: () => import('./checkout/checkout.module').then((m) => m.CheckoutModule),
    },
    { path: 'privacy', component: PrivacyComponent },
    { path: 'cookies', component: CookiesComponent },
    {
        matcher: matchProducts,
        component: ProductComponent,
        resolve: { productData: ProductResolver },
    },
    { path: '404', component: NotFoundComponent },
    { path: '**', component: NotFoundComponent }, // Must be last
];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: 'enabledNonBlocking',
            scrollPositionRestoration: 'enabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
