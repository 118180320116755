import { Component, ElementRef, EventEmitter, HostListener, Inject, OnChanges, OnInit, Output, SimpleChanges, ViewChild, Input } from '@angular/core';

import { get } from 'lodash';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { getStyleIntValue, isBrowser, wait } from 'lib/tools';
import { WebstoreProduct } from 'lib/services/webstore-product/webstore-product.class';
import { RouterService } from 'lib/services/router.service';
import { SearchSpringService } from 'lib/services/searchspring.service';
import { WebpService } from 'lib/services/webp.service';

const scriptName = 'header-search.component';

@Component({
    selector: 'app-header-search',
    templateUrl: './header-search.component.html',
    styleUrls: ['./header-search.component.scss'],
})
export class HeaderSearchComponent implements OnChanges, OnInit {
    @HostListener('document:mouseover', ['$event.target']) onHover(target: any) {
        this.onHoverMenu(target);
    }
    @HostListener('document:click', ['$event.target']) click(target: any) {
        this.onClickAway(target);
    }

    @Input() isNarrow: boolean;
    @Output() isActiveChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() searchActive: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('searchInput') searchInput: ElementRef;
    @ViewChild('searchInputContainer') searchInputContainer: ElementRef;
    @ViewChild('searchMenu') searchMenu: ElementRef;
    @ViewChild('searchMenuTerms') searchMenuTerms: ElementRef;
    @ViewChild('searchSeeMore') searchSeeMore: ElementRef;
    @ViewChild('noResultsDisplay') noResultsDisplay: ElementRef;

    imgSelector: any = {};
    isActive: boolean;
    isSearching: boolean = false;
    menuSelector: any = {};
    searchSelector: any = {};
    suggested: any = { products: [], terms: [] };
    wasActive: boolean;
    getWebpImg: (src: string) => string;

    // Getters and setters

    get router() {
        return this.routerService.router;
    }
    get inputElement() {
        return this.searchInput && this.searchInput.nativeElement;
    }
    get searchValue() {
        return this.inputElement && this.inputElement.value;
    }
    set searchValue(value: string) {
        if (this.inputElement) {
            this.inputElement.value = value;
        }
    }
    get menuElement() {
        return this.searchMenu && this.searchMenu.nativeElement;
    }
    get inputContainerElement() {
        return this.searchInputContainer && this.searchInputContainer.nativeElement;
    }
    get searchInputHasFocus() {
        return isBrowser() ? this.inputElement && document.activeElement == this.inputElement : false;
    }
    get searchInputHasValue() {
        return this.searchValue != '';
    }

    constructor(
        @Inject('environment') private environment: any,
        private routerService: RouterService,
        private searchSpringService: SearchSpringService,
        webpService: WebpService
    ) {
        this.getWebpImg = (src: string) => webpService.getWebpImg(src);
    }

    // Initialization

    async ngOnChanges(changes: SimpleChanges) {
        try {
            if (changes.isNarrow && changes.isNarrow.previousValue != changes.isNarrow.currentValue) {
                this.clearInput();
                this.setIsActive();
            }
        } catch (err) {
            console.error(...new ExxComError(124999, scriptName, err).stamp());
        }
    }

    ngOnInit() {
        try {
            if (!isBrowser()) {
                return;
            }
            this.routerService.onRouteChange('headerSearchComponent', async (event: any) => {
                this.clearInput();
                this.setIsActive();
            });
            this.menuSelector = document.getElementById('app-header-menu');
            this.imgSelector = document.getElementById('header-logo-mob');
            this.searchSelector = document.getElementsByClassName('searchbar')[0];
        } catch (err) {
            console.error(...new ExxComError(121000, scriptName, err).stamp());
        }
    }

    clearInput() {
        try {
            this.searchValue = '';
            this.inputElement && this.inputElement.blur();
        } catch (err) {
            console.error(...new ExxComError(9121001, scriptName, err).stamp());
        }
    }

    // Helpers

    async searchClicked() {
        try {
            document.getElementById('searchIcon').focus();
            await this.setIsActive();
        } catch (err) {
            console.error(...new ExxComError(121004, scriptName, err).stamp());
        }
    }

    async closeClicked() {
        this.inputElement.blur();
        await this.setIsActive();
    }

    async setIsActive() {
        try {
            if (!isBrowser()) {
                return;
            }
            await wait('');
            this.wasActive = this.isActive;
            this.isActive = this.searchInputHasFocus && this.searchInputHasValue;

            this.searchActive.emit(this.searchInputHasFocus);
            if ((!this.wasActive && this.isActive) || (this.wasActive && !this.isActive)) {
                this.isActiveChange.emit(this.isActive);
            }
            if (this.isNarrow) {
                // for narrow view, we want to toggle full screen search bar
                const menuSelector = document.getElementById('app-header-menu');
                const cart = document.getElementById('header-row2-cart');
                const imgSelector = document.getElementById('header-logo-mob');
                const searchSelector: HTMLElement | null = document.getElementById('searchbar');
                if (this.searchInputHasFocus && this.isNarrow) {
                    menuSelector.classList.add('hide');
                    imgSelector.classList.add('hide');
                    cart.classList.add('hide');
                    searchSelector.style.width = '100%';
                } else {
                    this.clearInput();
                    searchSelector.style.width = '';
                    cart.classList.remove('hide');
                    menuSelector.classList.remove('hide');
                    imgSelector.classList.remove('hide');
                }
            }
        } catch (err) {
            console.error(...new ExxComError(121002, scriptName, err).stamp());
        }
    }

    private async setMenuHeight() {
        try {
            await wait('');
            const menu = this.menuElement;
            if (!menu) {
                return;
            }
            menu.style.height = '';
            const menuContentHeight = getStyleIntValue(menu, 'height');
            const windowHeight = window.innerHeight;
            const header = document.getElementById('header');
            const headerHeight = getStyleIntValue(header, 'height');
            const calculatedMenuHeight = windowHeight - headerHeight;
            if (menuContentHeight < calculatedMenuHeight) {
                menu.style.height = menuContentHeight + 'px';
            } else {
                menu.style.height = calculatedMenuHeight + 'px';
            }
        } catch (err) {
            console.error(...new ExxComError(739000, scriptName, err).stamp());
        }
    }

    // On focus

    async onFocus() {
        try {
            await this.setIsActive();
            this.setMenuHeight();
        } catch (err) {
            console.error(...new ExxComError(121004, scriptName, err).stamp());
        }
    }

    // On key up

    async onKeyup(event: KeyboardEvent) {
        try {
            if (!isBrowser() || event.code == 'Enter') {
                return;
            }
            const searchValue = this.searchValue;
            this.isSearching = true;
            const suggestRes = await this.searchSpringService.suggest(searchValue);
            this.isSearching = false;
            await this.setIsActive();
            if (get(suggestRes, 'products.length', 0) == 0) {
                return;
            }
            const allRes = await this.searchSpringService.search('search', searchValue, null, null, null, true);
            this.parseResults(suggestRes, get(allRes, 'pagination.totalResults', 0));
            this.setMenuHeight();
        } catch (err) {
            console.error(...new ExxComError(121005, scriptName, err).stamp());
        }
    }

    private parseResults(suggestRes: any, totalResults: number) {
        try {
            this.suggested = { products: [], terms: [], total: totalResults };
            suggestRes.terms.forEach((term: any) => this.suggested.terms.push(this.parseTerm(term)));
            suggestRes.products.forEach((product: any) => {
                product.isSearchSpring = true;
                this.suggested.products.push(
                    new WebstoreProduct(product, {
                        environment: this.environment,
                    })
                );
            });
        } catch (err) {
            console.error(...new ExxComError(121006, scriptName, err).stamp());
        }
    }

    private parseTerm(term: string) {
        try {
            return {
                text: term.replace(/<em>/g, '<strong>').replace(/<\/em>/g, '</strong>'),
                queryParams: { q: term.replace(/<\/?em>/g, '') },
            };
        } catch (err) {
            console.error(...new ExxComError(115007, scriptName, err).stamp());
        }
    }

    private async onHoverMenu(target: any) {
        try {
            const menu = document.getElementsByClassName('header-menu-wide-level1')[0];
            if (menu && menu.contains(target)) {
                this.inputElement && this.inputElement.blur();
                await this.setIsActive();
            }
        } catch (err) {
            console.error(...new ExxComError(115008, scriptName, err).stamp());
        }
    }

    // On click away

    async onClickAway(target: HTMLElement) {
        try {
            if (this.menuWasClicked(target) || this.inputWasClicked(target)) {
                return;
            }
            await this.setIsActive();
        } catch (err) {
            console.error(...new ExxComError(115009, scriptName, err).stamp());
        }
    }

    menuWasClicked(target: HTMLElement) {
        try {
            const menu = this.menuElement;
            return menu && (menu == target || menu.contains(target));
        } catch (err) {
            console.error(...new ExxComError(115010, scriptName, err).stamp());
        }
    }

    inputWasClicked(target: HTMLElement) {
        try {
            return this.inputElement == target;
        } catch (err) {
            console.error(...new ExxComError(115011, scriptName, err).stamp());
        }
    }

    // On pressing enter

    onKeydownEnter() {
        try {
            if (!isBrowser() || this.searchValue === '') {
                return;
            }
            this.router.navigateByUrl(`/category/search?q=${this.searchValue}&page=1`);
        } catch (err) {
            console.error(...new ExxComError(915112, scriptName, err).stamp());
        }
    }

    onClickSearch() {
        try {
            if (!isBrowser() || this.searchValue === '') {
                return;
            }
            this.router.navigateByUrl(`/category/search?q=${this.searchValue}&page=1`);
        } catch (err) {
            console.error(...new ExxComError(921002, scriptName, err).stamp());
        }
    }
}
