<div id="row-sets">
  <div *ngFor="let rowSet of get(rowSetData, 'products.category_top_facets', [])">
    <!-- ROW SET: CATEGORY TOP PRODUCT FACETS -->

    <section id="products-facets">
      <div
        *ngIf="get(rowSet, 'ad_banner.background_image.href', '')"
        class="d-flex flex-wrap flex-row align-items-center p-3 content-block"
        [ngStyle]="{
          'background-image':
            'url(' + (rowSet?.products?.category_top_facets)[0]?.ad_banner?.background_image?.href + ')'
        }"
      >
        <div class="col-md-6 text-center">
          <img
            *ngIf="get(rowSet, 'ad_banner.image.href', '')"
            [src]="getWebpImg(get(rowSet, 'ad_banner.image.href', ''))"
            [alt]="get(rowSet, 'ad_banner.image.title', '')"
          />
        </div>
        <div class="col-md-6 content-block-copy">
          <div [innerHTML]="get(rowSet, 'ad_banner.copy', '') | safe: 'html'"></div>
          <div class="col-xs-12 mt-2 p-0 mt-1">
            <a
              *ngIf="get(rowSet, 'ad_banner.link.title', '')"
              class="button-primary button-base"
              (click)="
                filter(get(rowSet, 'ad_banner.link.routerLink', ''), get(rowSet, 'ad_banner.link.queryParams', ''))
              "
            >
              {{ get(rowSet, "ad_banner.link.title", "") }}
            </a>
          </div>
        </div>
      </div>

      <div
        *ngIf="get(rowSet, 'category_title', '')"
        class="d-flex flex-row flex-wrap container-padless category-title py-3"
      >
        <h1 class="col-sm-12 p-0">
          {{ get(rowSet, "category_title", "") }}
        </h1>
      </div>

      <div
        *ngIf="!isEmpty(get(rowSet, 'text_facets', ''))"
        class="d-flex flex-row flex-wrap container-half-padding products-text"
      >
        <h3 class="col-sm-12 text-center pb-2 mb-2">
          {{ get(rowSet, "text_facets_title", "") }}
        </h3>

        <div class="col-sm-12 p-0 d-flex flex-row flex-wrap justify-content-center text-container">
          <div *ngFor="let text of get(rowSet, 'text_facets', [])" class="col-xs-6 col-sm-4 col-xl-2 mb-2">
            <a
              *ngIf="get(text, 'link.title', '')"
              class="button-secondary button-base"
              (click)="filter(get(text, 'link.routerLink', ''), get(text, 'link.queryParams', ''))"
            >
              {{ get(text, "link.title", "") }}
            </a>
          </div>
        </div>

        <hr class="divider" />
      </div>
      <!-- Facet for item type and -->
      <div
        *ngIf="!isEmpty(get(rowSet, 'type_facets', ''))"
        class="d-flex flex-row flex-wrap container-padless products-type"
      >
        <h3 class="col-sm-12 text-center pb-2 mb-2">
          {{ get(rowSet, "type_facets_title", "") }}
        </h3>

        <div class="col-sm-12 p-0 d-flex flex-row flex-wrap justify-content-center type-container">
          <div *ngFor="let type of get(rowSet, 'type_facets', [])" class="col-xs-6 col-sm-4 col-md-2">
            <div class="type-icon-facet p-2">
              <a (click)="filter(get(type, 'link.routerLink', ''), get(type, 'link.queryParams', ''))">
                <img
                  *ngIf="get(type, 'image.href', '')"
                  [src]="getWebpImg(type?.image?.href)"
                  [alt]="get(type, 'image.title')"
                />
              </a>
            </div>
            <p class="text-center">{{ get(type, "image.title", "") }}</p>
          </div>
        </div>

        <hr class="divider" />
      </div>

      <div
        *ngIf="!isEmpty(get(rowSet, 'logo_facets', ''))"
        class="d-flex flex-row flex-wrap container-padless products-brand"
      >
        <h3 class="col-sm-12 text-center mb-2">
          {{ get(rowSet, "logo_facets_title", "") }}
        </h3>
        <div class="col-sm-12 p-0">
          <div class="brand-icon-facets">
            <div class="brand-container">
              <a
                *ngFor="let logo of get(rowSet, 'logo_facets', [])"
                class="brand-link"
                (click)="filter(get(logo, 'link.routerLink', ''), get(logo, 'link.queryParams', ''))"
              >
                <img
                  *ngIf="get(logo, 'logo_image.href', '')"
                  [src]="getWebpImg(logo?.logo_image?.href)"
                  [alt]="get(logo, 'logo_image.title', '')"
                />
              </a>
            </div>
          </div>
        </div>
        <hr class="divider" />
      </div>
    </section>
  </div>
</div>
