<div id="auth-reset-password" class="auth-container">
  <h2>Forgot Password?</h2>

  <!-- SEND EMAIL FORM -->

  <form
    *ngIf="showForm == 'sendEmail'"
    class="form-1 mat-elevation-z5 p-4"
    [formGroup]="sendEmailForm"
    (ngSubmit)="onSubmit('sendEmail')"
  >
    <h3>Reset Password</h3>
    <h4>Enter your email below and we'll send you a link to reset your password.</h4>

    <div class="form-field">
      <label *ngIf="showLabels" for="auth-login-email">Email</label>
      <input
        type="text"
        formControlName="email"
        #emailInput
        id="auth-login-email"
        [placeholder]="!showLabels ? 'Email' : ''"
        [class.error]="hasError(email)"
        (keyup.enter)="emailInput.blur()"
      />
      <div class="form-error" [class.show]="hasError(email)">
        <div class="form-error" [class.show]="hasErrorRequired(email)">Email is required.</div>
        <div class="form-error" [class.show]="hasErrorInvalid(email)">Email is not valid.</div>
      </div>
    </div>

    <a class="link-gray-inverted fx-col-push-right font-xs" routerLink="/account/login"> Sign In? </a>

    <span class="form-messages">{{ messageService.locals.resetPassword }}</span>

    <button class="button-primary button-base" type="submit" #sendEmailButton [disabled]="buttonClicked">
      <mat-spinner *ngIf="actionPending" diameter="18" class="spc-blue-gray-300"></mat-spinner>
      <span *ngIf="!actionPending">Send Email</span>
    </button>

    <div class="grecaptcha-message mt-3">
      This site is protected by reCAPTCHA and the Google
      <a href="https://policies.google.com/privacy">Privacy Policy</a> and
      <a href="https://policies.google.com/terms">Terms of Service</a> apply.
    </div>
  </form>

  <!-- RESET PASSWORD FORM -->

  <form
    *ngIf="showForm == 'resetPassword'"
    class="form-1 mat-elevation-z5 p-4"
    [formGroup]="resetPasswordForm"
    (ngSubmit)="onSubmit('resetPassword')"
  >
    <h2>Reset Password</h2>

    <div class="form-field">
      <label *ngIf="showLabels" for="auth-register-password" class="form-label">
        Password <span class="required-asterisk">*</span>
      </label>
      <input
        type="password"
        formControlName="password"
        #passwordInput
        id="auth-register-password"
        [placeholder]="!showLabels ? 'Password' : ''"
        [class.error]="hasError(password)"
      />
      <div class="form-error" [class.show]="hasError(password)">
        <div class="form-error" [class.show]="hasErrorRequired(password)">Password is required.</div>
      </div>
    </div>

    <div class="form-field">
      <label *ngIf="showLabels" for="auth-register-retypePassword" class="form-label">
        Retype Password <span class="required-asterisk">*</span>
      </label>
      <input
        type="password"
        formControlName="retypePassword"
        #retypePasswordInput
        id="auth-register-retypePassword"
        [placeholder]="!showLabels ? 'Retype Password' : ''"
        [class.error]="hasError(retypePassword)"
      />
      <div class="form-error" [class.show]="hasError(retypePassword)">
        <div class="form-error" [class.show]="hasErrorRequired(retypePassword)">Please retype your password.</div>
        <div class="form-error" [class.show]="hasErrorMatch(retypePassword)">Passwords do not match.</div>
      </div>
    </div>

    <span class="form-messages">{{ messageService.locals.resetPassword }}</span>

    <button class="button-primary button-base" type="submit" [disabled]="buttonClicked">
      <mat-spinner *ngIf="actionPending" diameter="18" class="spc-blue-gray-300"></mat-spinner>
      <span *ngIf="!actionPending">Save</span>
    </button>

    <div class="grecaptcha-message mt-3">
      This site is protected by reCAPTCHA and the Google
      <a href="https://policies.google.com/privacy">Privacy Policy</a> and
      <a href="https://policies.google.com/terms">Terms of Service</a> apply.
    </div>
  </form>
</div>
