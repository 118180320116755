import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, AfterViewInit } from '@angular/core';

import { AuthService } from 'lib/services/auth.service';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { isBrowser, getHeight } from 'lib/tools';
import { SessionService } from 'lib/services/session.service';

const scriptName = 'header.component';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
    accountActive: boolean;
    cartActive: boolean;
    isBrowser: boolean = isBrowser();
    isNarrow: boolean;
    loggedInInterval: any;
    menuActive: boolean;
    overlayVisible: boolean = false;
    overlayZindex: number = 100;
    searchActive: boolean;
    toggleSearch: boolean;

    constructor(
        private authService: AuthService,
        private breakpointObserver: BreakpointObserver,
        private sessionService: SessionService
    ) {}

    ngOnInit() {
        try {
            if (!isBrowser()) {
                return;
            }
            this.initBreakpointObserver();
        } catch (err) {
            console.error(...new ExxComError(553004, scriptName, err).stamp());
        }
    }

    ngAfterViewInit() {
        // adjusts height based on the view height
        try {
            if (!isBrowser()) {
                return;
            }
            window.addEventListener('resize', () => {
                getHeight();
            });
            getHeight();
        } catch (err) {
            console.error(...new ExxComError(761824, scriptName, err).stamp());
        }
    }

    private initBreakpointObserver() {
        try {
            this.breakpointObserver.observe(['only screen and (min-width: 1100px)']).subscribe((result) => {
                this.isNarrow = !result.matches;
                /**
                 * Everything closes when the breakpoint changes. The overlay is
                 * controlled in this component. Closing the individual menus is
                 * controlled in the child components.
                 */
                this.overlayVisible = false;
                this.accountActive = false;
                this.cartActive = false;
                this.menuActive = false;
                this.searchActive = false;
            });
        } catch (err) {
            console.error(...new ExxComError(829831, scriptName, err).stamp());
        }
    }

    get isGuestSession() {
        return this.sessionService.isGuestSession();
    }
    get isValidLocalSessionData() {
        return this.sessionService.isValidLocalSessionData();
    }

    private setOverlayVisibility() {
        try {
            this.overlayVisible = this.accountActive || this.cartActive || this.menuActive || this.searchActive;
        } catch (err) {
            console.error(...new ExxComError(465759, scriptName, err).stamp());
        }
    }

    onActiveChange(which: string, $event: boolean, zIndex: number) {
        try {
            this[`${which}Active`] = $event;
            this.overlayZindex = zIndex;
            this.setOverlayVisibility();
        } catch (err) {
            console.error(...new ExxComError(936333, scriptName, err).stamp());
        }
    }
    onToggleSearch($event: boolean) {
        try {
            this.toggleSearch = $event;
        } catch (err) {
            console.error(...new ExxComError(996443, scriptName, err).stamp());
        }
    }

    logout() {
        try {
            this.authService.logout();
        } catch (err) {
            console.error(...new ExxComError(290345, scriptName, err).stamp());
        }
    }
}
