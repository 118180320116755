import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';

import { MaterialModule } from 'lib/modules/material.module';

import { AddressValidator } from 'lib/validators/address.validator';
import { ApiService } from 'lib/services/api.service';
import { AuthService } from 'lib/services/auth.service';
import { ConfiguratorService } from 'lib/services/configuration/configurator.service';
import { CmsService } from 'lib/services/cms.service';
import { GrecaptchaService } from 'lib/services/google/grecaptcha.service';
import { GtmService } from 'lib/services/google/gtm.service';
import { InfiniteScrollService } from 'lib/services/infinite-scroll.service';
import { MessageService } from 'lib/services/message.service';
import { RouterService } from 'lib/services/router.service';
import { SessionService } from 'lib/services/session.service';
import { StripeService } from 'lib/services/stripe/stripe.service';
import { ConfiguratorSharedRuleService } from 'lib/services/configuration/configurator-shared-rule.service';

import { AuthInterceptor } from 'lib/interceptors/auth.interceptor';
import { UniversalInterceptor } from 'lib/interceptors/universal.interceptor';
import { SwiperComponent } from 'lib/components/swiper/swiper.component';
import { SlidePanelComponent } from 'lib/components/slide-panel/slide-panel.component';
import { ConfiguratorErrorComponent } from 'lib/components/configurator-error/configurator-error.component';

import { MarketoDialogComponent } from 'lib/components/marketo-dialog/marketo-dialog.component';
import { MarketoFormComponent } from 'lib/components/marketo-form/marketo-form.component';
import { PopupDialogComponent } from 'lib/components/popup-dialog/popup-dialog.component';
import { MarketoFormThankYouComponent } from 'lib/components/marketo-form-thank-you/marketo-form-thank-you.component';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { BootstrapMarketoService } from 'lib/services/bootstrap/bootstrap-marketo.service';
import { SpecialModule } from './special.module';
import { cmsMarketoIDsService } from 'lib/services/cmsMarketoIDs.service';
import { BlogCountService } from 'lib/services/blog-count.service';
import { IconsModule } from './feather-icons.module';
import { CheckoutService } from 'lib/services/checkout.service';
import { AddressService } from 'lib/services/address/address.service';
@NgModule({
    declarations: [
        SwiperComponent,
        SlidePanelComponent,
        MarketoFormThankYouComponent,
        ConfiguratorErrorComponent,
        MarketoDialogComponent,
        MarketoFormComponent,
        PopupDialogComponent,
    ],
    imports: [
        //   replace with serverstatetransfermodule
        BrowserAnimationsModule,
        CommonModule,
        FormsModule,
        HttpClientModule,
        LayoutModule,
        MatDatepickerModule,
        MaterialModule,
        MatNativeDateModule,
        ReactiveFormsModule,
        RouterModule,
        ModalModule.forRoot(),
        ToastrModule.forRoot(),
        SpecialModule.forRoot(),
        IconsModule,
    ],
    exports: [
        BrowserModule,
        //   replace with serverstatetransfermodule
        BrowserAnimationsModule,
        CommonModule,
        FormsModule,
        MatDatepickerModule,
        MaterialModule,
        MatNativeDateModule,
        ReactiveFormsModule,
        SwiperComponent,
        SlidePanelComponent,
        ConfiguratorErrorComponent,
        MarketoDialogComponent,
        MarketoFormComponent,
        PopupDialogComponent,
        IconsModule,
    ],
})
export class SharedModule {
    static forRoot(environment?: any): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                AddressValidator,
                ApiService,
                AuthService,
                ConfiguratorService,
                CmsService,
                GrecaptchaService,
                GtmService,
                InfiniteScrollService,
                MessageService,
                RouterService,
                SessionService,
                StripeService,
                BsModalService,
                BootstrapMarketoService,
                ConfiguratorSharedRuleService,
                cmsMarketoIDsService,
                BlogCountService,
                CheckoutService,
                AddressService,
                { provide: 'environment', useValue: environment || {} },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: UniversalInterceptor,
                    multi: true,
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: AuthInterceptor,
                    multi: true,
                },
            ],
        };
    }
}
