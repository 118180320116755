import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthService } from 'lib/services/auth.service';
import { contains, isBrowser } from 'lib/tools';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { get } from 'lodash';
import { GrecaptchaService } from 'lib/services/google/grecaptcha.service';
import { MessageService } from 'lib/services/message.service';
import { take } from 'rxjs/operators';

const scriptName = 'auth-reset-password.component';

@Component({
    selector: 'app-auth-reset-password',
    templateUrl: './auth-reset-password.component.html',
    styleUrls: ['./auth-reset-password.component.scss'],
})
export class AuthResetPasswordComponent implements OnInit {
    actionPending: boolean;
    buttonClicked: boolean = false;
    message: string;
    params: any;
    resetPasswordForm: FormGroup;
    sendEmailForm: FormGroup;
    showForm: string;
    showLabels: boolean = true;
    stage: number;
    token: string;
    validating: boolean = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private grecaptchaService: GrecaptchaService,
        private router: Router,
        public messageService: MessageService
    ) {}

    ngOnInit() {
        try {
            this.defineForms();
            this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
                this.token = params.t;
                if (!this.token) {
                    this.showForm = 'sendEmail';
                } else {
                    this.showForm = '';
                    this.validateToken();
                }
            });
        } catch (err) {
            console.error(...new ExxComError(623040, scriptName, err).stamp());
        }
    }

    private defineForms() {
        try {
            this.sendEmailForm = this.formBuilder.group({
                email: [
                    '',
                    {
                        validators: [Validators.required, Validators.email],
                        updateOn: 'blur',
                    },
                ],
            });
            this.resetPasswordForm = this.formBuilder.group(
                {
                    password: ['', [Validators.required]],
                    retypePassword: ['', [Validators.required]],
                },
                { validators: this.validatePasswordsMatch, updateOn: 'change' }
            );
        } catch (err) {
            console.error(...new ExxComError(823984, scriptName, err).stamp());
        }
    }

    validatePasswordsMatch(formGroup: FormGroup) {
        try {
            const password = formGroup.controls.password;
            const retypePassword = formGroup.controls.retypePassword;
            if (retypePassword.errors && !retypePassword.errors.match) {
                return;
            }
            if (password.value != retypePassword.value) {
                retypePassword.setErrors({ match: true });
            } else {
                retypePassword.setErrors(null);
            }
        } catch (err) {
            console.error(...new ExxComError(932837, scriptName, err).stamp());
        }
    }

    get email() {
        return this.sendEmailForm.get('email');
    }
    get password() {
        return this.resetPasswordForm.get('password');
    }
    get retypePassword() {
        return this.resetPasswordForm.get('retypePassword');
    }

    hasError(control: any) {
        return control.invalid && (control.dirty || control.touched);
    }
    hasErrorRequired(control: any) {
        return this.hasError(control) && control.errors && control.errors.required;
    }
    hasErrorInvalid(control: any) {
        return this.hasError(control) && control.errors && control.errors.email;
    }
    hasErrorMatch(control: any) {
        return this.hasError(control) && control.errors && control.errors.match;
    }

    async onSubmit(type: string) {
        try {
            if (!isBrowser()) {
                return;
            }
            const form = this[`${type}Form`];
            if (form.invalid) {
                return form.markAllAsTouched();
            }
            this.buttonClicked = true;
            this.actionPending = true;
            await this[type]();
            this.actionPending = false;
        } catch (err) {
            console.error(...new ExxComError(834772, scriptName, err).stamp());
        }
    }

    private async sendEmail() {
        try {
            const res = await this.authService.password({
                action: 'sendEmail',
                email: this.sendEmailForm.value.email.toLowerCase(),
                grecaptchaToken: await this.grecaptchaService.getToken('auth_resetPassword_sendEmail'),
            });
            if (res.isServer) {
                return;
            }
            if (!res.success) {
                const message = get(res, 'error.message');
                const messages = ['Not registered'];
                if (message == 'crg') {
                    return;
                }
                if (contains(messages, message)) {
                    this.messageService.addLocal('resetPassword', message).clearOnTimeout(5);
                    setTimeout(() => (this.buttonClicked = false), 3000);
                } else {
                    this.messageService.addLocal('resetPassword', 'Server error');
                    throw res;
                }
            } else {
                this.messageService.addLocal('resetPassword', res.message);
            }
        } catch (err) {
            if (get(err, 'error.message') == 'crg') {
                return;
            } // Ignore invalid reCAPTCHA response
            console.error(...new ExxComError(209582, scriptName, err).stamp());
        }
    }

    private async validateToken() {
        try {
            const res = await this.authService.password({
                action: 'validateToken',
                token: this.token,
            });
            if (res.isServer) {
                return;
            }
            if (!res.success) {
                const message = get(res, 'error.message');
                const messages = ['Invalid token'];
                this.showForm = 'sendEmail';
                this.messageService.clear();
                if (message == 'crg') {
                    return;
                }
                if (contains(messages, message)) {
                    this.messageService.addLocal('resetPassword', message).clearOnTimeout(5);
                } else {
                    this.messageService.addLocal('resetPassword', 'Server error');
                    throw res;
                }
            } else {
                this.messageService.clear();
                this.showForm = 'resetPassword';
            }
        } catch (err) {
            if (get(err, 'error.message') == 'crg') {
                return;
            } // Ignore invalid reCAPTCHA response
            console.error(...new ExxComError(204982, scriptName, err).stamp());
        }
    }

    private async resetPassword() {
        try {
            const res = await this.authService.password({
                action: 'resetPassword',
                token: this.token,
                password: this.resetPasswordForm.value.password,
                grecaptchaToken: await this.grecaptchaService.getToken('auth_resetPassword_reset'),
            });
            if (res.isServer) {
                return;
            }
            const message = get(res, 'error.message');
            const messages = ['Invalid token'];
            if (message == 'crg') {
                return;
            }
            if (!res.success) {
                if (contains(messages, message)) {
                    this.messageService.addLocal('resetPassword', message).clearOnAction();
                } else {
                    this.messageService.addLocal('resetPassword', 'Server error');
                    throw res;
                }
            }
            if (message || res.message) {
                this.messageService.addLocal('login', message || res.message).clearOnAction();
            }
            this.router.navigateByUrl('/account/login');
        } catch (err) {
            if (get(err, 'error.message') == 'crg') {
                return;
            } // Ignore invalid reCAPTCHA response
            console.error(...new ExxComError(230949, scriptName, err).stamp());
        }
    }
}
