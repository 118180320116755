import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/core';

import * as moment from 'moment';
import { ApiService } from 'lib/services/api.service';
import { contains, onAvailable } from 'lib/tools';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { get, isEmpty, last, remove } from 'lodash';
import { getProductImageUrl, WebstoreProduct } from 'lib/services/webstore-product/webstore-product.class';
import { isBrowser, isDevServer } from 'lib/tools';
import { RouterService } from 'lib/services/router.service';
import { SwiperSlide } from 'lib/classes/swiper-slide.class';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

const scriptName = 'webstore-product.service';

declare let IntelliSuggest: any;

let apiService: ApiService;
let environment: any;
let routerService: RouterService;
let transferState: TransferState;

const PRODUCT_STATE_KEY = makeStateKey('product');

@Injectable()
export class WebstoreProductService {
    document: Document | undefined;
    constructor(
        @Inject('environment') e: any,
        @Inject(PLATFORM_ID) private platformId: Object,
        a: ApiService,
        r: RouterService,
        t: TransferState,
        @Inject(DOCUMENT) private _doc: any
    ) {
        try {
            apiService = a;
            environment = e;
            routerService = r;
            transferState = t;

            // Only inject the document when running in a browser environment
            if (isPlatformBrowser(this.platformId)) {
                this.document = _doc;
            }
        } catch (err) {
            console.error(...new ExxComError(827763, scriptName, err).stamp());
        }
    }

    async init() {
        try {
            if (isBrowser()) {
                this.initRecentlyViewedListener();
            }
        } catch (err) {
            console.error(...new ExxComError(398273, scriptName, err).stamp());
        }
    }

    private initRecentlyViewedListener() {
        try {
            routerService.onRouteChange('productService', () => {
                if (!new RegExp(environment.productUrlRegex).test(routerService.url.component)) {
                    return;
                }
                this.maintainRecentlyViewed();
            });
        } catch (err) {
            console.error(...new ExxComError(398182, scriptName, err).stamp());
        }
    }

    private maintainRecentlyViewed() {
        try {
            const urlComponent = routerService.url.component;
            const expiration = moment().add(3, 'd');
            // Get and parse recentlyViewed object from localStorage
            let recentlyViewed: any = localStorage.getItem('recentlyViewed');
            recentlyViewed = recentlyViewed ? JSON.parse(recentlyViewed) : [];
            // Check if current product already exists
            const found = remove(recentlyViewed, (p: any) => p.urlComponent == urlComponent)[0];
            if (found) {
                // Update existing product expiration
                found.expiresOn = expiration;
                recentlyViewed.push(found);
            } else {
                // Add new WebstoreProduct
                recentlyViewed.push({
                    urlComponent: urlComponent,
                    expiresOn: expiration,
                });
            }
            // Remove old products
            remove(recentlyViewed, (p: any) => moment().isAfter(p.expiresOn));
            // Set local storage to the updated array
            localStorage.setItem('recentlyViewed', JSON.stringify(recentlyViewed));
        } catch (err) {
            console.error(...new ExxComError(109238, scriptName, err).stamp());
        }
    }

    async getRecentlyViewed(): Promise<SwiperSlide[]> {
        try {
            if (!isBrowser()) {
                return;
            }
            const recentlyViewed = JSON.parse(localStorage.getItem('recentlyViewed') || '[]');
            const urlComponents = recentlyViewed.map((r: any) => r.urlComponent);
            const products: any = await this.getProductsByUrlComponents(urlComponents);
            const slides = products.map((p: any) => {
                return new SwiperSlide({
                    heading: p.description,
                    imageUrl: p.imageUrl,
                    linkUrl: p.urlComponent,
                });
            });
            return slides;
        } catch (err) {
            console.error(...new ExxComError(409933, scriptName, err).stamp());
        }
    }

    async getRecommendations(nsid: number | string): Promise<SwiperSlide[]> {
        try {
            const slides = [];
            if (!IntelliSuggest) {
                return;
            }
            await IntelliSuggest;
            // Create and append temporary element to DOM
            if (!isBrowser) return;
            const intellsuggestElem = this.document.createElement('DIV');
            intellsuggestElem.setAttribute('class', 'intellisuggest');
            intellsuggestElem.setAttribute('name', 'Product Recommendations');
            intellsuggestElem.style.visibility = 'hidden';
            this.document.body.appendChild(intellsuggestElem);
            // Initialize IntelliSuggest, which automatically puts the HTML into the element with that class and name
            IntelliSuggest?.init({
                siteId: environment.searchspring.siteId,
                context: `Product/${nsid}`,
                seed: [nsid],
            });
            IntelliSuggest?.viewItem({ sku: nsid });
            // Wait for IntelliSuggest to put the elements in the container
            let containerSelector: string = '';
            let intellisuggestSelector: string = '';
            if (environment.siteAbbr == 'spc') {
                containerSelector = '.product-details-full-content-related-items';
                intellisuggestSelector = 'product-details-full-content-related-items';
            } else {
                containerSelector = '.intellisuggest-item';
                intellisuggestSelector = 'intellisuggest-item';
            }
            const isAvailable = await onAvailable(containerSelector);
            if (!isAvailable) {
                return slides;
            }
            const elems = intellsuggestElem.getElementsByClassName(intellisuggestSelector);
            if (!get(elems, 'length')) {
                return slides;
            }
            Array.from(elems).forEach((e: HTMLElement) => {
                const anchorElem: HTMLElement = get(e, 'children[0]') as HTMLElement;
                const imgElem: HTMLImageElement = get(anchorElem, 'children[0]') as HTMLImageElement;
                let imgSrc: string;
                let href: string;
                if (environment.siteAbbr == 'spc') {
                    href = anchorElem.getAttribute('href');
                    imgSrc = imgElem && imgElem.src;
                } else {
                    href = get(anchorElem, 'children[0].attributes[0].nodeValue');
                    const imgAttr: HTMLElement = get(imgElem, 'children[0]') as HTMLElement;
                    imgSrc = imgAttr && get(imgAttr, 'nodeValue');
                }
                if (!anchorElem || !imgElem || !imgSrc) {
                    return;
                }
                const imgFullFilename: string = last(imgSrc.split('/') || []);
                const imgFilename: string = imgFullFilename && imgFullFilename.split('.')[0];
                if (!imgFilename) {
                    return;
                }
                slides.push(
                    new SwiperSlide({
                        heading: get(e, 'children[1].children[0].innerHTML', ''),
                        imageUrl: getProductImageUrl(imgFilename, environment.urls),
                        linkUrl: href,
                    })
                );
            });

            this.document.body.removeChild(intellsuggestElem);
            return slides;
        } catch (err) {
            console.error(...new ExxComError(491992, scriptName, err).stamp());
        }
    }

    async getProductByMpnComponentConfig(mpn: string) {
        try {
            if (mpn.indexOf('/') == 0) {
                mpn = mpn.slice(1);
            }

            const encodedMpn = encodeURIComponent(mpn);

            const res = await apiService.get([
                'products/find',
                `?filters={
            "mpn": "${encodedMpn}"
          }`,
                '&limit=1',
            ]);

            if (!res.success) {
                throw res;
            } else if (isEmpty(res.data)) {
                return null;
            } else {
                return new WebstoreProduct(res.data[0], { environment });
            }
        } catch (err) {
            console.error(...new ExxComError(689988, scriptName, err).stamp());
        }
    }

    async getProductByUrlComponent(urlComponent: string, isProductComponent?: boolean) {
        try {
            if (urlComponent.indexOf('/') == 0) {
                urlComponent = urlComponent.slice(1);
            }

            let transferredState = undefined;
            if (Boolean(isProductComponent) && transferState.get(PRODUCT_STATE_KEY, null) != null) {
                transferredState = transferState.get(PRODUCT_STATE_KEY, null);
            }
            const firstLoadServer = !isBrowser();
            const secondLoadBrowser = isBrowser() && transferredState;
            const subsequentLoadsBrowser = isBrowser() && !transferredState;

            let res: any;
            if (isDevServer() || !isProductComponent || firstLoadServer || subsequentLoadsBrowser) {
                res = await apiService.get([
                    'products/find',
                    `?filters={
            "sites.${environment.siteAbbr}.displayInStore": true,
            "sites.${environment.siteAbbr}.urlComponent": "/${urlComponent}"
          }`,
                    '&limit=1',
                ]);
                if (isProductComponent && firstLoadServer) {
                    transferState.set(PRODUCT_STATE_KEY, res);
                }
            } else if (secondLoadBrowser) {
                res = transferredState;
                transferState.set(PRODUCT_STATE_KEY, null);
            }

            if (!res.success) {
                throw res;
            } else if (isEmpty(res.data)) {
                return null;
            } else {
                return new WebstoreProduct(res.data[0], { environment });
            }
        } catch (err) {
            console.error(...new ExxComError(680920, scriptName, err).stamp());
        }
    }

    async getProductsByUrlComponents(urlComponents: string[], fields?: string[]) {
        try {
            const siteAbbr = environment.siteAbbr;
            const res = await apiService.get([
                'products/find',
                `?filters={
          "sites.${siteAbbr}.urlComponent": {
            "$in": ${JSON.stringify(urlComponents)}
          }
        }`,
                `&fields=${JSON.stringify(fields || ['images', `sites.${siteAbbr}.urlComponent`, `sites.${siteAbbr}.description`])}`,
            ]);

            if (!res.success) {
                throw res;
            } else {
                return res.data.map((p: any) => new WebstoreProduct(p, { environment }));
            }
        } catch (err) {
            const suppress = ['Unknown Error', 'cannotconnect'];
            if (err && contains(suppress, err.statusText)) {
                return [];
            }
            console.error(...new ExxComError(309198, scriptName, err).stamp());
        }
    }
}
