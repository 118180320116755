/**
 * Out of the box, Angular includes environment.ts and environment.prod.ts.
 * When the build command is run with the --prod flag, environments.prod
 * replaces environment. However, when building Universal without the --prod
 * flag, console.log does not work in the browser. Therefore, becacuse all
 * dev/prod specific settings can be handled in other ways, and because all the
 * data in the environment object is duplicated anyway, only one environment
 * file is used by setting production to true in the object below, and removing
 * the automatically generated environment file replacements in angular.json.
 */

export const environment = {
    siteAbbr: 'spc',
    siteName: 'SabrePC',
    testEnv: false,
    // eslint-disable-next-line no-useless-escape
    productUrlRegex: '-S[0-9]{6,9}$',
    hasCheckout: true,
    urls: {
        base: 'https://www.sabrepc.com',
        image: {
            base: 'https://images.sabrepc.com',
            logo: 'https://images.sabrepc.com/spc-cms/sabre-logo.svg',
            noImage: 'https://images.sabrepc.com/productimages/med/no-image-available-md.jpg',
            productBase: 'https://images.sabrepc.com/productimages/large/',
        },
    },
    contentstack: {
        baseUrl: 'https://cdn.contentstack.io/v3',
        sb: {
            name: 'sb-spc',
            api_key: 'bltb654d1b96a72ddc4',
            access_token: 'csd3ff2b645331356fec66cc9c',
        },
        prod: {
            name: 'prod-spc',
            api_key: 'bltb654d1b96a72ddc4',
            access_token: 'cs647a51cd08f9ba6a232e93d5',
        },
    },
    google: {
        sb: {
            gtmId: 'GTM-5H3C972',
        },
        prod: {
            gtmId: 'GTM-KP9GZRJ',
        },
        grecaptchaSiteKey: '6Lfm8pkUAAAAAOooKvWwkExyMHXSoqZd4a89SZeA',
        api_key: 'AIzaSyDzNwacW8JVbDdrMedWguuwUvlX68itAZY',
    },
    marketo: {
        clickyId: '101241090', // Exxact: 101234646
        forms: {
            baseUrl: 'https://go.exxactcorp.com',
        },
        munchkin: {
            id: '449-NMB-526',
            settings: { wsInfo: 'jVRU' },
        },
        rest: {
            baseUrl: 'https://449-NMB-526.mktorest.com/rest',
        },
    },
    searchspring: {
        siteId: 'm2xvgv',
        /**
         * In combination with col- classes, resultsPerPage defines the number of
         * columns in categories.component.html.
         * 2 columns = col-<xs/sm/md/lg/xl>-6
         *   Ex. If resultsPerPage is 16, there will be 8 rows
         * 3 columns = col-<xs/sm/md/lg/xl>-4
         *   Ex. If resultsPerPage is 16, there will be 6 rows, 1 with only 1 item
         * 4 columns = col-<xs/sm/md/lg/xl>-3
         *   Ex. If resutlsPerPage is 16, there will be 4 rows
         * 6 columns = col-<xs/sm/md/lg/xl>-2
         *   Ex. If resultsPerPage is 16, there will be 3 rows, 1 with 4 items
         */
        resultsPerPage: '24',
    },
    stripe: {
        sb: {
            publishableKey: 'pk_test_51FN4apKn6CbNmSYplld1EG7RASEwbCUHctYM2zeQvgbehs92Ki7nNk9fdWkU5ngOjw0QDSRhSXHI3q17OPAmnogb00Tbyol8pY',
        },
        prod: {
            publishableKey: 'pk_live_51FN4apKn6CbNmSYpywo3v1YHmVqzWllBuvBwxDS0nhpiSjzZJVIOe44mmYOCyL6nakqX2HmHNpCjCbL8cqpAKnxP00smVQyho7',
        },
    },
};
