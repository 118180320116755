import { Injectable } from '@angular/core';

import { ApiService } from 'lib/services/api.service';
import { Customer } from 'lib/services/customer/customer.class';
import { CustomerCount, CustomerData, CustomerResponse } from 'lib/services/customer/customer.interface';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { ExxComResponse } from 'lib/interfaces/exxcom-response.interface';
import { get } from 'lodash';

const scriptName = 'customer.service';

let apiService: ApiService;

@Injectable()
export class CustomerService {
    constructor(a: ApiService) {
        try {
            apiService = a;
        } catch (err) {
            console.error(...new ExxComError(620288, scriptName, err).stamp());
        }
    }

    // Core API

    /**
     * @function get
     * @description Enables retrieving one or more customer document(s) by _id or
     * by email.
     */
    private async get({
        _id,
        email,
        query,
        limit,
        page,
        sort,
        count,
    }: {
        _id?: string;
        email?: string;
        query?: any;
        limit?: number;
        page?: number;
        sort?: number;
        count?: boolean;
    } = {}): Promise<CustomerResponse> {
        try {
            let filters: any = {};
            if (_id) {
                filters._id = _id;
            } else if (email) {
                filters.email = email;
            }
            if (query) {
                filters = Object.assign(filters, query);
            }
            const url = ['customers/find', `?filters=${JSON.stringify(filters)}`];
            if (limit) {
                url.push(`&limit=${limit}`);
            }
            if (page) {
                url.push(`&page=${page}`);
            }
            if (sort) {
                url.push(`&sort=${sort}`);
            }
            if (count) {
                url.push(`&count=true`);
            }
            const res: CustomerResponse = await apiService.get(url);
            if (!res.success) {
                throw res;
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(203988, scriptName, err).stamp());
            return { success: false, error: err } as ExxComResponse;
        }
    }

    /**
     * @function update
     * @description Enables updating a given customer.
     */
    async update({ customer, values, action }: { customer: Customer; values: any; action?: string }): Promise<CustomerResponse> {
        try {
            const url = [`customers/update/${customer._id}`];
            if (action) {
                url.push(`/${action}`);
            }
            const res: CustomerResponse = await apiService.post(url, values);
            if (!res.success) {
                throw res;
            }
            customer.init(res.data as CustomerData);
            return res;
        } catch (err) {
            console.error(...new ExxComError(503277, scriptName, err).stamp());
            return { success: false, error: err } as ExxComResponse;
        }
    }

    /**
     * @function create
     * @description Enables creating new customers.
     */
    async create(values: CustomerData): Promise<CustomerResponse> {
        try {
            const res: CustomerResponse = await apiService.post('customers/create', values);
            if (!res.success) {
                throw res;
            }
            return res;
        } catch (err) {
            const message = get(err, 'error.message');
            if (message != 'Already exists') {
                console.error(...new ExxComError(230811, scriptName, err).stamp());
            }
            return err as ExxComResponse;
        }
    }

    /**
     * @function delete
     * @description Deletes a customer from the database.
     */
    async delete(_id: string): Promise<ExxComResponse> {
        try {
            const res: ExxComResponse = await apiService.delete(`customers/delete/${_id}`);
            if (!res.success) {
                throw res;
            }
            return res;
        } catch (err) {
            console.error(...new ExxComError(202887, scriptName, err).stamp());
            return { success: false, error: err } as ExxComResponse;
        }
    }

    // Get API

    /**
     * @function getDetached
     * @description For retrieving instantiated and initialized customer or
     * customers.
     */
    async getDetached({
        _id,
        email,
        query,
        limit,
        page,
        sort,
    }: {
        _id?: string;
        email?: string;
        query?: any;
        limit?: number;
        page?: number;
        sort?: number;
    } = {}): Promise<Customer[]> {
        try {
            const res: CustomerResponse = await this.get({
                _id,
                email,
                query,
                limit,
                page,
                sort,
            });
            if (res.error) {
                throw res;
            }
            const customers: Customer[] = ((res.data as CustomerData[]) || []).map((data: CustomerData) => new Customer(data));
            return customers;
        } catch (err) {
            console.error(...new ExxComError(240277, scriptName, err).stamp());
            return [];
        }
    }

    /**
     * @function getCount
     * @description Gets a total count for a given query.
     */
    async getCount({ email, query }: { email?: string; query?: any } = {}): Promise<number> {
        try {
            const res: CustomerResponse = await this.get({
                email,
                query,
                count: true,
            });
            if (res.error) {
                throw res;
            }
            const count: number = ((res.data as CustomerCount) || { count: 0 }).count;
            return count;
        } catch (err) {
            console.error(...new ExxComError(320476, scriptName, err).stamp());
            return 0;
        }
    }
}
