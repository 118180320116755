<div class="address">
  <!-- CREATE AND UPDATE FORM -->

  <form *ngIf="showForm" [formGroup]="form" [class]="'address-form ' + formClass" (ngSubmit)="submit()">
    <!-- Addressee -->

    <div *ngIf="useField('addressee')" class="form-field">
      <label *ngIf="showLabels" [for]="componentId + '_addressee'">Name</label>
      <input
        type="text"
        formControlName="addressee"
        #addresseeInput
        tabindex="1"
        name="addressee"
        autocomplete="name"
        [id]="componentId + '_addressee'"
        [placeholder]="!showLabels ? 'Addressee' : ''"
        [class.error]="hasError(addressee)"
        (keyup)="validatePristine('addressee', addresseeInput.value)"
      />
      <div class="form-error" [class.show]="hasError(addressee)">
        <div class="form-error" [class.show]="hasErrorRequired(addressee)">Addressee is required.</div>
      </div>
    </div>

    <!-- Grouped address fields -->

    <div *ngIf="groupAddressLines" class="address-form-field-group-address-lines">
      <div *ngIf="useField('address1')" class="form-field">
        <label *ngIf="showLabels" [for]="componentId + '_address1'">Address</label>
        <input
          type="text"
          formControlName="address1"
          #address1Input
          tabindex="2"
          name="address1"
          autocomplete="address-line1"
          [id]="componentId + '_address1'"
          [placeholder]="!showLabels ? 'Address 1' : ''"
          [class.error]="hasError(address1)"
          (keyup)="validatePristine('address1', address1Input.value)"
        />
        <div class="form-error" [class.show]="hasError(address1)">
          <div class="form-error" [class.show]="hasErrorRequired(address1)">Address 1 is required.</div>
        </div>
      </div>

      <div *ngIf="useField('address2')" class="form-field">
        <input
          type="text"
          formControlName="address2"
          #address2Input
          tabindex="2"
          name="address2"
          autocomplete="address-line2"
          [id]="componentId + '_address2'"
          [placeholder]="!showLabels ? 'Address 2' : ''"
          [class.error]="hasError(address2)"
          (keyup)="validatePristine('address2', address2Input.value)"
        />
      </div>
    </div>

    <!-- Ungrouped address fields -->

    <div *ngIf="!groupAddressLines && useField('address1')" class="form-field">
      <label *ngIf="showLabels" [for]="componentId + '_address1'">Address 1</label>
      <input
        type="text"
        formControlName="address1"
        #address1Input
        tabindex="2"
        name="address1"
        autocomplete="address-line1"
        [id]="componentId + '_address1'"
        [placeholder]="!showLabels ? 'Address 1' : ''"
        [class.error]="hasError(address1)"
        (keyup)="validatePristine('address1', address1Input.value)"
      />
      <div class="form-error" [class.show]="hasError(address1)">
        <div class="form-error" [class.show]="hasErrorRequired(address1)">Address 1 is required.</div>
      </div>
    </div>

    <div *ngIf="!groupAddressLines && useField('address2')" class="form-field">
      <label *ngIf="showLabels" [for]="componentId + '_address2'">Address 2</label>
      <input
        type="text"
        formControlName="address2"
        #address2Input
        tabindex="2"
        name="address2"
        autocomplete="address-line2"
        [id]="componentId + '_address2'"
        [placeholder]="!showLabels ? 'Address 2' : ''"
        [class.error]="hasError(address2)"
        (keyup)="validatePristine('address2', address2Input.value)"
      />
    </div>

    <!-- City -->

    <div *ngIf="useField('city')" class="form-field">
      <label *ngIf="showLabels" [for]="componentId + '_city'">City</label>
      <input
        type="text"
        formControlName="city"
        placeholder="City"
        #cityInput
        tabindex="5"
        name="city"
        autocomplete="address-level2"
        [id]="componentId + '_city'"
        [placeholder]="!showLabels ? 'City' : ''"
        [class.error]="hasError(city)"
        (keyup)="validatePristine('city', cityInput.value)"
      />
      <div class="form-error" [class.show]="hasError(city)">
        <div class="form-error" [class.show]="hasErrorRequired(city)">City is required.</div>
      </div>
    </div>

    <!-- Grouped state, zip -->

    <div *ngIf="groupStateAndZip" class="address-form-field-group-state-zip fx-row">
      <div *ngIf="useField('state')" class="form-field mr-base">
        <label *ngIf="showLabels" [for]="componentId + '_state'">State</label>
        <input
          *ngIf="country.value != 'United States'"
          type="text"
          #stateInput
          tabindex="6"
          name="state"
          autocomplete="address-level1"
          [id]="componentId + '_state'"
          [placeholder]="!showLabels ? 'State' : ''"
          [class.error]="hasError(state)"
          (keyup)="validatePristine('state', stateInput.value)"
        />
        <div *ngIf="country.value == 'United States'" class="select-1" #stateSelect [class.open]="stateMenuOpen">
          <input
            type="text"
            formControlName="state"
            #stateInput
            tabindex="6"
            name="state"
            autocomplete="address-level1"
            [id]="componentId + '_state'"
            [placeholder]="!showLabels ? 'State' : ''"
            [class.error]="hasError(state)"
            (blur)="formatSelectValue('state'); toggleSelectMenu('state', 'close')"
            (keyup)="
              validatePristine('state', stateInput.value);
              toggleSelectMenu('state', 'open', false);
              scrollToOption(stateMenu, stateInput.value)
            "
          />
          <mat-icon svgIcon="chevron_down" (click)="toggleSelectMenu('state')"></mat-icon>
          <div class="select-options mat-elevation-z3" #stateMenu>
            <span
              *ngFor="let s of statesByCodeLowerArray"
              class="select-option"
              (click)="setSelectValue('state', s.code); stateMenuOpen = false"
            >
              {{ s.code }}
            </span>
          </div>
        </div>
        <div class="form-error" [class.show]="hasError(state)">
          <div class="form-error" [class.show]="hasErrorRequired(state)">State is required.</div>
          <div class="form-error" [class.show]="hasErrorInvalid(state)">Invalid state abbreviation.</div>
        </div>
      </div>

      <div *ngIf="useField('zip')" class="form-field ml-base">
        <label *ngIf="showLabels" [for]="componentId + '_zip'">Zip</label>
        <input
          type="text"
          formControlName="zip"
          minlength="5"
          #zipInput
          tabindex="7"
          name="zip"
          autocomplete="postal-code"
          [id]="componentId + '_zip'"
          [placeholder]="!showLabels ? 'Zip' : ''"
          [class.error]="hasError(zip)"
          (keyup)="validatePristine('zip', zipInput.value)"
        />
        <div class="form-error" [class.show]="hasError(zip)">
          <div class="form-error" [class.show]="hasErrorRequired(zip)">Zip is required.</div>
        </div>
      </div>
    </div>

    <!-- Ungrouped state, zip -->

    <div *ngIf="!groupStateAndZip && useField('state')" class="form-field">
      <label *ngIf="showLabels" [for]="componentId + '_state'">State</label>
      <input
        *ngIf="country.value != 'United States'"
        type="text"
        #stateInput
        tabindex="6"
        name="state"
        autocomplete="address-level1"
        [id]="componentId + '_state'"
        [placeholder]="!showLabels ? 'State' : ''"
        [class.error]="hasError(state)"
        (keyup)="validatePristine('state', stateInput.value)"
      />
      <div *ngIf="country.value == 'United States'" class="select-1" #stateSelect [class.open]="stateMenuOpen">
        <input
          type="text"
          formControlName="state"
          #stateInput
          maxlength="2"
          tabindex="6"
          name="state"
          autocomplete="address-level1"
          [id]="componentId + '_state'"
          [placeholder]="!showLabels ? 'State' : ''"
          [class.error]="hasError(state)"
          (focus)="toggleSelectMenu('state', 'open')"
          (blur)="formatSelectValue('state'); toggleSelectMenu('state', 'close')"
          (keyup)="
            validatePristine('state', stateInput.value);
            toggleSelectMenu('state', 'open', false);
            scrollToOption(stateMenu, stateInput.value)
          "
        />
        <mat-icon svgIcon="chevron_down" (click)="toggleSelectMenu('state')"></mat-icon>
        <div class="select-options mat-elevation-z3" #stateMenu>
          <span
            *ngFor="let s of statesByCodeLowerArray"
            class="select-option"
            (click)="setSelectValue('state', s.code); stateMenuOpen = false"
          >
            {{ s.code }}
          </span>
        </div>
      </div>
      <div class="form-error" [class.show]="hasError(state)">
        <div class="form-error" [class.show]="hasErrorRequired(state)">State is required.</div>
        <div class="form-error" [class.show]="hasErrorInvalid(state)">Invalid state abbreviation.</div>
      </div>
    </div>

    <div *ngIf="!groupStateAndZip && useField('zip')" class="form-field">
      <label *ngIf="showLabels" [for]="componentId + '_zip'">Zip</label>
      <input
        type="text"
        formControlName="zip"
        maxlength="5"
        #zipInput
        tabindex="7"
        name="zip"
        autocomplete="postal-code"
        [id]="componentId + '_zip'"
        [placeholder]="!showLabels ? 'Zip' : ''"
        [class.error]="hasError(zip)"
        (keyup)="validatePristine('zip', zipInput.value)"
      />
      <div class="form-error" [class.show]="hasError(zip)">
        <div class="form-error" [class.show]="hasErrorRequired(zip)">Zip is required.</div>
      </div>
    </div>

    <!-- Country -->

    <div *ngIf="useField('country')" class="form-field">
      <label *ngIf="showLabels" [for]="componentId + '_country'">Country</label>
      <div class="select-1" #countrySelect [class.open]="countryMenuOpen">
        <input
          type="text"
          formControlName="country"
          #countryInput
          tabindex="8"
          name="country"
          autocomplete="country"
          [id]="componentId + '_country'"
          [placeholder]="!showLabels ? 'Country' : ''"
          [class.error]="hasError(country)"
          (blur)="formatSelectValue('country'); toggleSelectMenu('country', 'close')"
          (keyup)="
            validatePristine('country', countryInput.value);
            toggleSelectMenu('country', 'open', false);
            scrollToOption(countryMenu, countryInput.value)
          "
        />
        <mat-icon svgIcon="chevron_down" (click)="toggleSelectMenu('country')"></mat-icon>
        <div class="select-options mat-elevation-z3" #countryMenu>
          <span
            *ngFor="let c of countries"
            class="select-option"
            (click)="setSelectValue('country', c.name); countryMenuOpen = false"
          >
            {{ c.name }}
          </span>
        </div>
      </div>
      <div class="form-error" [class.show]="hasError(country)">
        <div class="form-error" [class.show]="hasErrorRequired(country)">Country is required.</div>
        <div class="form-error" [class.show]="hasErrorInvalid(country)">Invalid country name.</div>
      </div>
    </div>

    <!-- Phone -->

    <div *ngIf="useField('phone')" class="form-field">
      <label *ngIf="showLabels" [for]="componentId + '_phone'">Phone</label>
      <input
        type="text"
        formControlName="phone"
        #phoneInput
        tabindex="9"
        name="phone"
        autocomplete="tel"
        [id]="componentId + '_phone'"
        [placeholder]="!showLabels ? 'Phone' : ''"
        (keyup)="validatePristine('phone', phoneInput.value)"
      />
      <div class="form-error" [class.show]="hasError(phone)">
        <div class="form-error" [class.show]="hasErrorRequired(phone)">Phone is required.</div>
        <div class="form-error" [class.show]="hasErrorPhoneInvalid(phone)">Invalid phone number.</div>
      </div>
    </div>

    <!-- Default addresses checkboxes -->

    <div *ngIf="useField('isDefaultShipping') || useField('isDefaultBilling')" class="form-field">
      <label *ngIf="showLabels">&nbsp;</label>
      <div class="form-checkbox-group fx-row fx-row-v-center fx-row-h-left">
        <label *ngIf="useField('isDefaultShipping')" [class]="checkboxClass">
          <input type="checkbox" formControlName="isDefaultShipping" tabindex="10" name="isDefaultShipping" />
          <span class="checkmark"></span>
          <div class="label">Default shipping?</div>
        </label>
        <label *ngIf="useField('isDefaultBilling')" [class]="checkboxClass">
          <input type="checkbox" formControlName="isDefaultBilling" tabindex="11" name="isDefaultBilling" />
          <span class="checkmark"></span>
          <div class="label">Default billing?</div>
        </label>
      </div>
    </div>

    <!-- Invalid address -->

    <div
      *ngIf="useField('isConfirmed') && (hasErrorAddressInvalid() || isConfirmed.dirty)"
      class="form-field"
      data-cy="is-address-correct"
    >
      <div class="form-error show">Is that address correct?</div>
      <label [class]="checkboxClass">
        <input type="checkbox" data-cy="is-address-correct" formControlName="isConfirmed" tabindex="12" />
        <span class="checkmark"></span>
        <div class="label">I confirm this is a valid address.</div>
      </label>
    </div>

    <!-- Messages -->

    <div class="form-messages">{{ messageService.locals[componentId] }}</div>

    <!-- Controls -->

    <div class="address-form-actions fx-row fx-row-v-center">
      <button
        type="submit"
        [class]="saveButtonClass"
        #submitButton
        tabindex="13"
        [disabled]="form.pristine || form.invalid || isPending"
      >
        {{ saveButtonText }}
      </button>
      <span
        *ngIf="hasAddress && cancelButtonClass && readOnlyClass"
        [class]="'address-form-action-cancel ' + cancelButtonClass"
        (click)="initReadOnly()"
      >
        Cancel
      </span>
    </div>

    <mat-spinner *ngIf="isPending" class="address-form-spinner" diameter="20"></mat-spinner>
  </form>

  <!-- READ-ONLY VALUES -->

  <div *ngIf="!showForm && hasAddress" [class]="'address-read-only fx-col ' + readOnlyClass">
    <span class="address-read-only-first-row fx-row fx-row-h-space-between fx-row-v-top">
      <span *ngIf="useField('addressee')" class="bold pr-2">{{ address.addressee }}</span>
      <div *ngIf="editButtonClass" (click)="toggleEdit()">
        <mat-icon svgIcon="more-horizontal" class="font-color-purple-300"></mat-icon>
      </div>
    </span>

    <span *ngIf="useField('address1')">{{ address.address1 }}</span>
    <span *ngIf="useField('address2')">{{ address.address2 }}</span>
    <span *ngIf="useField('city') || useField('state') || useField('zip')">
      <span *ngIf="useField('city')">{{ address.city }}, </span>
      <span *ngIf="useField('state')">{{ address.state }} </span>
      <span *ngIf="useField('zip')">{{ address.zip }}</span>
    </span>
    <span *ngIf="useField('country')">{{ address.country }}</span>
    <span *ngIf="useField('phone')">{{ address.phone }}</span>
    <span *ngIf="useField('email')">{{ address.email }}</span>
    <div *ngIf="showEdit" class="text-center mt-3 mb-1" (click)="initUpdate()">
      <a class="link-purple">Edit</a>
    </div>

    <div
      *ngIf="readOnlyDefaultsClass"
      [class]="
        'address-read-only-defaults form-field fx-row fx-row-v-center fx-row-h-left mt-2 ' + readOnlyDefaultsClass
      "
    >
      <span
        *ngIf="useField('isDefaultShipping')"
        class="mr-4"
        [ngClass]="address.isDefaultShipping ? readOnlyDefaultsActiveClass : ''"
      >
        Shipping
      </span>
      <span
        *ngIf="useField('isDefaultBilling')"
        [ngClass]="address.isDefaultBilling ? readOnlyDefaultsActiveClass : ''"
      >
        Billing
      </span>
    </div>
  </div>
</div>
