import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ExxComError } from 'lib/classes/exxcom-error.class';
import { SessionService } from 'lib/services/session.service';

const scriptName = 'auth-login-register.component';

@Component({
    selector: 'app-auth-login-register',
    templateUrl: './auth-login-register.component.html',
    styleUrls: ['./auth-login-register.component.scss'],
})
export class AuthLoginRegisterComponent implements OnDestroy, OnInit {
    private queryParamsSubscription: any;

    authType: string = 'account';

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private sessionService: SessionService
    ) {}

    ngOnInit() {
        try {
            this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe((queryParams: any) => {
                this.authType = queryParams.type || 'account';
                if (!this.sessionService.isValidLocalSessionData()) {
                    return;
                }
                if (this.authType == 'account' && !this.sessionService.isGuestSession()) {
                    this.router.navigateByUrl('/account');
                } else if (this.authType == 'checkout') {
                    this.router.navigateByUrl('/checkout');
                }
            });
        } catch (err) {
            console.error(...new ExxComError(723094, scriptName, err).stamp());
        }
    }

    ngOnDestroy() {
        try {
            this.queryParamsSubscription && this.queryParamsSubscription.unsubscribe();
        } catch (err) {
            console.error(...new ExxComError(834002, scriptName, err).stamp());
        }
    }
}
