import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';

@Injectable()
export class RedirectService {
    constructor(private apiService: ApiService) {}

    async addRedirect(redirect: object) {
        const response = await this.apiService.post('redirect/create', redirect);
        return response;
    }

    async deleteRedirect(redirect: object) {
        const response = await this.apiService.post('redirect/delete', redirect);
        return response;
    }

    async searchRedirect(subString: string) {
        const response = await this.apiService.get(`redirect/search?subString=${subString}`);
        return response;
    }
}
