import { AfterViewInit, Component, ElementRef, Input, OnChanges, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ExxComError } from 'lib/classes/exxcom-error.class';
import { isBrowser } from 'lib/tools';

const scriptName = 'cms-img.component';

@Component({
    selector: 'cms-img',
    templateUrl: './cms-img.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CmsImgComponent implements AfterViewInit, OnChanges {
    elRef: ElementRef;
    // Properties
    @Input() src: any = '';
    @Input() alt: any = '';
    @Input() selector: string = '';
    @Input() classes: string = '';

    // Background Only
    @Input() bgSrc: string = '';
    @Input() bgClass: string = '';
    @Input() bgTemplate: TemplateRef<any>;

    @ViewChild('bgSection') bgSection: ElementRef;

    data: any = {};
    canUse: boolean = this.canUseWebP();

    constructor(bgSection: ElementRef) {
        this.bgSection = bgSection;
    }

    ngOnChanges(changes) {
        try {
            // for background images only
            this.data.bgSrc = this.bgSrc || '';
            this.data.bgClass = this.bgClass || '';

            // begin webp url
            this.data.src = this.src || '';
            this.data.alt = this.parseAltFromUrl(this.src, this.alt);
            this.data.classes = this.classes;
            this.data.id = this.selector;
            const fileExt = this.data.src.split('.').pop();
            this.data.type = 'image/' + fileExt;
            // basically check if the url is a cms one and the file is supported if so modify the url else just treat it like an img
            const cmsImageDomains = new Set([
                'https://assets.sabrepc',
                'https://assets.exxactcorp',
                'https://images.exxactcorp',
                'https://images.sabrepc',
                'http://assets.sabrepc',
                'http://assets.exxactcorp',
                'http://images.exxactcorp',
                'http://images.sabrepc',
            ]);
            const supportedTypes = new Set(['png', 'jpeg', 'jpg']);
            let path;

            // get the cms webp path for background if exists and supported
            if (this.canUse && changes.bgSrc && changes.bgSrc.currentValue) {
                const bgfileExt = changes.bgSrc.currentValue.split('.').pop();
                const bgsplit = changes.bgSrc.currentValue.split('.com');
                const bgdomain = bgsplit && bgsplit[0];
                if (bgsplit.length >= 1) {
                    path = bgsplit[1];
                }
                // get the cms webp path for normal cms images
                if (cmsImageDomains.has(bgdomain) && supportedTypes.has(bgfileExt)) {
                    const s3Key = path.split('.' + bgfileExt)[0].substring(4);
                    this.data.bgSrc = 'https://images.exxactcorp.com/webp' + s3Key + '.webp';
                }
            }
            // all domains route to exc-assets with /img so if it isn't in the URL don't perform the following logic
            if (this.data.src.indexOf('/img') === -1 || this.data.src.indexOf('.svg') !== -1) {
                const split = this.src && this.src.split('.com');
                const domain = split && split[0];
                if (cmsImageDomains.has(domain) && supportedTypes.has(fileExt) && this.canUseWebP()) {
                    // const bgfileExt = this.data.src.split('.').pop();
                    // let s3Key = (path.split('.' + bgfileExt)[0]).substring(4);
                    const s3Key = split[1].split('.')[0];
                    // console.log(domain, split, s3Key);
                    this.data.webp = 'https://assets.exxactcorp.com/webp' + s3Key + '.webp';
                }
                return;
            }

            const split = this.src && this.src.split('.com');
            const domain = split && split[0];

            if (split.length >= 1) {
                path = split[1];
            }

            // upgrade image to https
            if (cmsImageDomains.has(domain) && domain.indexOf('http://') != -1) {
                this.data.src = this.data.src.replace('http://', 'https://');
            }

            // get the cms webp path for normal cms images
            if (cmsImageDomains.has(domain) && supportedTypes.has(fileExt) && this.data.src.indexOf('/img') !== -1) {
                const s3Key = path.split('.' + fileExt)[0].substring(4);
                this.data.webp = 'https://images.exxactcorp.com/webp' + s3Key + '.webp';
            }
        } catch (err) {
            console.error(...new ExxComError(394555, scriptName, err).stamp());
        }
    }

    ngAfterViewInit() {
        if (!isBrowser()) {
            return;
        }
        if (this.bgTemplate) {
            this.bgSection.nativeElement.append(this.bgTemplate);
        } else {
            this.bgSection.nativeElement.remove();
        }
    }

    parseAltFromUrl(url: string, alt: string) {
        if (alt) {
            return alt;
        }
        if (!url) {
            return 'description';
        }
        const lastIndexOfDot = url.lastIndexOf('.');
        const lastIndexOfSlash = url.lastIndexOf('/');
        if (lastIndexOfDot == -1 || lastIndexOfSlash == -1) {
            return 'description';
        }
        return url.substring(lastIndexOfSlash + 1, lastIndexOfDot);
    }

    canUseWebP() {
        if (!isBrowser()) {
            return true;
        }
        const elem = document.createElement('canvas');
        if (elem.getContext && elem.getContext('2d')) {
            // was able or not to get WebP representation
            return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
        } // very old browser like IE 8, canvas not supported
        return false;
    }
}
