export const appIconsList = [
    'account',
    'activity',
    'alert-triangle',
    'arrow-drop-down-right',
    'box',
    'box-off',
    'calendar',
    'cart',
    'check',
    'check-circle',
    'chevron_down',
    'chevron-right',
    'close',
    'credit-card',
    'envelope',
    'facebook',
    'filter',
    'frown',
    'grid',
    'grid-9',
    'heart',
    'info',
    'linkedin',
    'list',
    'log-out',
    'mail',
    'map-pin',
    'maximize',
    'menu',
    'minus',
    'more-horizontal',
    'nav-search',
    'package',
    'pencil',
    'plus',
    'share-2',
    'shopping-bag',
    'sliders',
    'smile',
    'tag',
    'trash',
    'triangle',
    'truck',
    'twitter',
    'user',
    'x-logo',
    'x',
];
