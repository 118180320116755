import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { CategoryService } from 'lib/services/category/category.service';
import { RouterService } from 'lib/services/router.service';
import { isBrowser } from 'lib/tools';

@Component({
    selector: 'app-category-pagination',
    templateUrl: './category-pagination.component.html',
    styleUrls: ['./category-pagination.component.scss'],
})
export class CategoryPaginationComponent implements OnInit {
    constructor(
        private categoryService: CategoryService,
        private routerService: RouterService,
        private location: Location
    ) {}

    ngOnInit() {}

    get pagination() {
        return this.categoryService.category.pagination;
    }
    get urlComponent() {
        return this.routerService.url.component;
    }

    paginate(paramObj: any) {
        if (!isBrowser()) {
            return;
        }
        let url = window.location.pathname;
        const searchParams = new URLSearchParams();
        Object.keys(paramObj).forEach((param) => {
            searchParams.set(param, paramObj[param]);
        });
        if (searchParams.toString()) {
            url += '?';
        }
        url += decodeURIComponent(searchParams.toString());
        this.location.go(url);
        this.categoryService.loadPage();
        const categoryBody = document.getElementById('category-body');
        categoryBody.scrollIntoView({
            behavior: 'smooth',
        });
    }
}
