<div id="header-search">
  <div id="header-search-input-container-wide" class="fx-row fx-row-h-right" *ngIf="!isNarrow" #searchInputContainer>
    <input
      class="header-search-input"
      #searchInput
      placeholder="Search"
      [class.mat-purple]="searchInputHasFocus"
      [class.show-magnifier]="!isActive || !isNarrow"
      (focus)="onFocus()"
      (keyup)="onKeyup($event)"
      (keydown.enter)="onKeydownEnter()"
      data-cy="header-search-input"
    />
    <mat-icon
      (click)="onClickSearch()"
      class="search-icon"
      svgIcon="nav-search"
      data-cy="header-search-submit"
    ></mat-icon>
  </div>

  <div
    *ngIf="isNarrow"
    class="fx-row fx-row-v-center fx-row-h-right pl-1"
    id="header-search-input-container-narrow"
    #searchInputContainer
  >
    <mat-icon
      class="search-icon"
      svgIcon="nav-search"
      [class.active-icon]="searchInputHasFocus && isNarrow"
      (click)="searchClicked()"
      data-cy="header-search-icon"
    ></mat-icon>
    <input
      class="header-search-input"
      id="searchIcon"
      #searchInput
      (focus)="onFocus()"
      (keyup)="onKeyup($event)"
      (keydown.enter)="onKeydownEnter()"
      data-cy="header-search-input-mobile"
    />
  </div>

  <div id="header-search-menu" *ngIf="isActive" #searchMenu class="mat-elevation-z2" data-cy="header-search-menu">
    <div *ngIf="isSearching && suggested.products.length == 0">
      <h5 class="header-search-menu-title">Searching...</h5>
    </div>

    <div *ngIf="!isSearching && suggested.products.length == 0">
      <h5 class="header-search-menu-title">No results match your search query.</h5>
    </div>

    <div *ngIf="suggested.terms.length > 0" id="header-search-menu-terms">
      <div id="header-search-menu-terms-links">
        <div *ngFor="let term of suggested.terms" class="truncate-ellipsis">
          <a
            routerLink="/category/search"
            [queryParams]="{ q: term?.queryParams?.q, page: 1 }"
            [innerHTML]="term.text"
            (click)="setIsActive()"
          ></a>
        </div>
      </div>
    </div>

    <div *ngIf="suggested.products.length > 0" id="header-search-menu-products">
      <div class="header-search-menu-title">Here are some matching products</div>
      <div *ngFor="let product of suggested.products" class="header-search-menu-products-links">
        <a class="fx-row fx-row-h-left fx-row-v-center" [routerLink]="product.urlComponent" (click)="setIsActive()">
          <div class="link-container fx-row">
            <img
              *ngIf="product?.imageUrl"
              [src]="getWebpImg(product.imageUrl)"
              class="header-search-menu-product-image"
              [alt]="product.name"
            />
            <div class="fx-row fx-row-v-center">
              <span [innerHTML]="product.name"></span>
            </div>
          </div>
        </a>
      </div>
    </div>

    <div *ngIf="suggested.products.length > 0" id="header-search-menu-see-more-results" class="fx-row fx-row-h-center">
      <button
        class="button-primary button-base"
        routerLink="/category/search"
        [queryParams]="{ q: searchValue, page: 1 }"
        (click)="setIsActive()"
        data-cy="header-search-submit-alt"
      >
        See More
      </button>
    </div>
  </div>
</div>
